import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Internally `Card` is implemented with the `CardContent` component.
 * Through this component you can change flex settings for direction, basis, grow, and shrink with the `orientation`,
 * `initialSize`, `grow`, and `disableShrink` properties. You can also disable padding and gaps.
 * You can leverage it to build cards with horizontal layouts or full-width images.
 *
 * Related components: [Card](#card)
 *
 * Usage:
 *
 * ```jsx
 * import { CardContent } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardContent = React.forwardRef((props, ref) => {

  const {
    component: CardContentRoot = 'div',
    disableGutters = false,
    density = 'normal',
    disablePadding = false,
    disableShrink = false,
    grow = false,
    orientation = 'vertical',
    initialSize,
    ...other
  } = props;

  const paddingClassesNormal = classNames({
    'sui-py-4': disablePadding === 'x',
    'sui-px-4': disablePadding === 'y',
    'sui-p-4': !disablePadding && disablePadding !== 'x' && disablePadding !== 'y',
    'sui-gap-4': !disableGutters,
  });

  const paddingClassesLoose = classNames({
    'sui-py-6': disablePadding === 'x',
    'sui-px-6': disablePadding === 'y',
    'sui-p-6': !disablePadding && disablePadding !== 'x' && disablePadding !== 'y',
    'sui-gap-6': !disableGutters,
  });

  const classes = classNames('sui-flex sui-overflow-hidden', {
    [paddingClassesNormal]: density === 'normal',
    [paddingClassesLoose]: density === 'loose',
    'sui-flex-col': orientation === 'vertical',
    'sui-flex-row': orientation === 'horizontal',
    'sui-shrink-0': disableShrink,
    'sui-grow': grow,
    'sui-basis-1/4': initialSize === '1/4',
    'sui-basis-2/4': initialSize === '2/4',
    'sui-basis-3/4': initialSize === '3/4',
    'sui-basis-full': initialSize === '4/4' || initialSize === 'full'
  });

  return (
    <CardContentRoot
      className={classes}
      ref={ref}
      {...other}
    />
  );
});

CardContent.displayName = 'CardContent';

CardContent.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the gap is removed.
   * @default false
   */
  disableGutters: PropTypes.bool,
  /**
   * If `true`, all the padding is removed.
   * If `'x'` or `'y'`, the respective axis padding will be removed.
   * @default false
   */
  disablePadding: PropTypes.oneOf([true, 'x', 'y', false]),
  /**
   * If `true`, prevents a flex item from shrinking.
   * @default false
   */
  disableShrink: PropTypes.bool,
  /**
   * @ignore
   */
  density: PropTypes.oneOf(['normal', 'loose']),
  /**
   * If true, will set flex items to grow to fill available space.
   * @default false
   */
  grow: PropTypes.bool,
  /**
   * If set, will control the initial size of flex items.
   */
  initialSize: PropTypes.oneOf(['1/4', '2/4', '3/4', '4/4', 'full']),
  /**
   * The component orientation. Sets the direction of flex items.
   * @default 'vertical'
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

export { CardContent };
