import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '../private/components/button/ButtonBase';

/**
 * A clickable area inside a `Card`.
 * Could contain multiple non-interactive elements.
 *
 * Related components: [Card](#card)
 *
 * Usage:
 *
 * ```jsx
 * import { CardActionArea } from '@one-thd/sui-atomic-components';
 * ```
 */
const CardActionArea = React.forwardRef((props, ref) => {

  const {
    children,
    ...other
  } = props;

  const classes = classNames('sui-block sui-text-inherit sui-w-full sui-h-full focus-visible:sui-outline-button-active focus-visible:sui-outline focus-visible:sui-outline-1 focus-visible:sui-outline-offset-2 focus-visible:sui-rounded-md');

  return (
    <ButtonBase
      className={classes}
      ref={ref}
      {...other}
    >
      {children}
    </ButtonBase>
  );
});

CardActionArea.displayName = 'CardActionArea';

CardActionArea.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
};

export { CardActionArea };
