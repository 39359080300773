export const components = [
  'heroCarousel',
  'welcomeMessage',
  'ArticleGuides',
  'BuyItAgain',
  'ComponentSelector',
  'HDCC',
  'Hero',
  'HeroCarousel',
  'HomepageHero',
  'LatestOrder',
  'Loyalty',
  'Media',
  'MyList',
  'MyQuotes',
  'OnlineWorkshopsMetadata',
  'PersonalizedHomeServices',
  'PopularCategories',
  'ProjectGuides',
  'PromotionalProducts',
  'PromotionalNavigation',
  'RecentlyViewed',
  'RecentOrderHistory',
  'Recommendations',
  'ResourcesSection',
  'Section',
  'ShopByCategory',
  'SignIn',
  'SpecialBuyOfTheDay',
  'SponsoredBanner',
  'Spotlight',
  'TodaysRecommendations',
  'TopCategories',
  'TopSavings',
  'TrendingInYourNeighborhood',
  'VisualNavigation',
  'YourStore',
  'MedioInline',
  'PiccolaPencil',
];

// Component dictionary has min/max;
/**
 * Section vs Grid
 * Ordering taking precedence over x,y
 */

export const DEFAULT_SIZES = {
  maxWidthMedium: {
    min: 12,
    max: 12,
  },
  minWidthMedium: {
    min: 12,
    max: 12,
  },
  minWidthLarge: {
    min: 4,
    max: 4,
  },
};

const HeroMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 8,
      max: 12,
    },
  },
};

const HeroCarouselMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 8,
      max: 12,
    },
  },
};

const heroCarouselMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const HomepageHeroMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 8,
      max: 12,
    },
  },
};

const LoyaltyMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 6,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const RecentOrderHistoryMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const LatestOrderMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const MyListMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const RecentlyViewedMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const BentoHeroMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const BuyItAgainMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const MyQuotesMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const HDCCMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 6,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const TopCategoriesMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const TopSavingsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const TodaysRecommendationsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const MarketingMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const SpecialBuyOfTheDayMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const SpecialBuyOfTheWeekMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const TrendingInYourNeighborhoodMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const YourStoreMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const ProjectGuidesMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const PromotionalProductsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const PromotionalNavigationMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const ResourcesSectionMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const SponsoredBannerMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const SpotlightMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const MediaMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const VisualNavigationMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const SectionMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const RecommendationsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const welcomeMessageMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const PrivateLabelCreditCardMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 6,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const StoreDetailsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const OnlineWorkshopsMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const PopularCategoriesMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const ShopByCategoryMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const SignInMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 6,
    },
    minWidthLarge: {
      min: 4,
      max: 4,
    },
  },
};

const ArticleGuidesMetaData = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 6,
      max: 12,
    },
    minWidthLarge: {
      min: 4,
      max: 12,
    },
  },
};

const PersonalizedHomeServicesMetaData = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 8,
      max: 12,
    },
  },
};

const PiccolaPencilMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const MedioInlineMetadata = {
  sizes: {
    maxWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthMedium: {
      min: 12,
      max: 12,
    },
    minWidthLarge: {
      min: 12,
      max: 12,
    },
  },
};

const ComponentMetadata = {
  ArticleGuidesMetaData,
  BuyItAgainMetadata,
  HDCCMetadata,
  HeroMetadata,
  HeroCarouselMetadata,
  heroCarouselMetadata,
  HomepageHeroMetadata,
  LatestOrderMetadata,
  LoyaltyMetadata,
  MarketingMetadata,
  MediaMetadata,
  MyListMetadata,
  MyQuotesMetadata,
  OnlineWorkshopsMetadata,
  PopularCategoriesMetadata,
  PrivateLabelCreditCardMetadata,
  ProjectGuidesMetadata,
  PromotionalNavigationMetadata,
  PromotionalProductsMetadata,
  PersonalizedHomeServicesMetaData,
  RecentOrderHistoryMetadata,
  RecentlyViewedMetadata,
  RecommendationsMetadata,
  ResourcesSectionMetadata,
  SpecialBuyOfTheWeekMetadata,
  SectionMetadata,
  ShopByCategoryMetadata,
  SpecialBuyOfTheDayMetadata,
  SponsoredBannerMetadata,
  SpotlightMetadata,
  StoreDetailsMetadata,
  TodaysRecommendationsMetadata,
  TopCategoriesMetadata,
  TopSavingsMetadata,
  TrendingInYourNeighborhoodMetadata,
  VisualNavigationMetadata,
  welcomeMessageMetadata,
  YourStoreMetadata,
  SignInMetadata,
  PiccolaPencilMetadata,
  MedioInlineMetadata,
  BentoHeroMetadata
};

export const componentsWithDefaultAspectRatios = { 'Hero Carousel': true };

export default ComponentMetadata;
