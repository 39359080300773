import React, { useEffect, useRef } from 'react';
import {
  string, arrayOf, shape, bool, oneOfType, number
} from 'prop-types';
import { ComponentSelectorWrapper } from './ComponentSelectorWrapper';
import { createComponentProps } from '../utils/createComponentProps';

export function ComponentSelector({
  backgroundColor,
  componentId: personalizationId,
  componentMap,
  defaultComponent,
  isInCarousel,
  isSeoBot,
  renderDefault,
  usePlaceholder,
  variationIds,
  variations,
  targetingType = {},
  customPropMap
}) {
  const componentSelectorTimerRef = useRef(new Date());

  let contentType = 'content';

  useEffect(() => {
    if (window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.ready');
    }
  }, []);

  const publishEvent = ({ ...props }) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      const start = componentSelectorTimerRef.current.getTime();
      const end = new Date().getTime();
      const loadTime = end - start;
      window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.selected', { ...props, loadTime });
    }
  };

  const isSSR = typeof window === 'undefined';
  const noVariations = variationIds === null || variationIds?.length < 1;

  const { props: defaultProps } = createComponentProps({ defaultComponent, customPropMap });

  const DefaultComponent = componentMap[defaultProps.componentClass];

  if (isSSR) {
    return (
      <ComponentSelectorWrapper
        backgroundColor={backgroundColor}
        isVisible={!isSeoBot}
        usePlaceholder={usePlaceholder}
        isInCarousel={isInCarousel}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {DefaultComponent && <DefaultComponent {...defaultProps} />}
      </ComponentSelectorWrapper>
    );
  }
  // This is for the initial render client-side, before the useEffect kicks in
  if (!renderDefault && noVariations) {
    return (
      <ComponentSelectorWrapper
        backgroundColor={backgroundColor}
        isVisible
        usePlaceholder={usePlaceholder}
        isInCarousel={isInCarousel}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {DefaultComponent && <DefaultComponent {...defaultProps} />}
      </ComponentSelectorWrapper>
    );
  }
  // Skip searching through the variations if there were no offers returned from Adobe and render the default
  if (renderDefault) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.default-render', { renderDefault });

    const { componentId, componentName } = defaultProps;

    publishEvent({
      renderDefault,
      isVariation: false,
      componentName,
      componentId,
      isSSR,
      isSeoBot,
      noVariations,
    });

    return (
      <ComponentSelectorWrapper isInCarousel={isInCarousel} backgroundColor={backgroundColor}>
        {DefaultComponent && (
        /* eslint-disable-next-line react/jsx-props-no-spreading */
          <DefaultComponent {...defaultProps} />
        )}
      </ComponentSelectorWrapper>
    );
  }

  const mbox = targetingType ? targetingType.mbox : null;
  const campaignId = targetingType ? targetingType.campaignId : null;

  let selectorUniqueID = personalizationId;

  if (mbox) {
    selectorUniqueID = mbox;
  } else if (campaignId) {
    selectorUniqueID = campaignId;
  }
  /**
   * Filter out the variationIds to ones that have a matching uniqueID
   */
  const filteredIds = variationIds
    .filter(({ uniqueID }) => (selectorUniqueID ? selectorUniqueID === uniqueID : true)
    );

  const variation = variations.find(
    ({ slotNumber }) => !!filteredIds?.find(({ variation: potentialVariation }) => {
      const variationIsNotNum = Number.isNaN(parseInt(potentialVariation, 10));

      return !variationIsNotNum && (slotNumber === parseInt(potentialVariation, 10));
    })
  );

  const { props: componentProps } = createComponentProps({
    defaultComponent,
    variation,
    customPropMap
  });

  const Component = componentMap[componentProps.componentClass];

  contentType = variation ? 'content-variation' : 'content';

  const { componentName, componentId } = componentProps;

  if (contentType === 'content-variation') {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.variation-render', { componentId });
  } else {
    window.LIFE_CYCLE_EVENT_BUS.trigger('component-selector.default-render', {
      renderDefault: true,
    });
  }

  publishEvent({
    renderDefault,
    isVariation: !!variation,
    componentName,
    componentId,
    isSSR,
    isSeoBot,
    noVariations,
    variations: variations || [],
    variationIds: variationIds || [],
  });

  return (
    <ComponentSelectorWrapper isInCarousel={isInCarousel} backgroundColor={backgroundColor}>
      {Component
          && (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <Component {...componentProps} />
          )}
    </ComponentSelectorWrapper>
  );
}

ComponentSelector.displayName = 'ComponentSelector';

ComponentSelector.propTypes = {
  backgroundColor: string,
  componentId: string,
  componentMap: shape({}),
  customPropMap: shape({}),
  defaultComponent: shape({}).isRequired,
  isInCarousel: bool,
  isSeoBot: bool,
  renderDefault: bool,
  usePlaceholder: bool,
  variationIds: arrayOf(
    shape({
      provider: string.isRequired,
      id: oneOfType([string, number]),
      mbox: string,
      componentID: string,
      uniqueID: oneOfType([string, number]),
      slotNumber: oneOfType([string, number]),
      variation: oneOfType([string, number])
    })
  ),
  variations: arrayOf(shape({})).isRequired,
  targetingType: shape({ mbox: string })
};

ComponentSelector.defaultProps = {
  backgroundColor: 'white',
  componentId: '',
  componentMap: {},
  isInCarousel: false,
  isSeoBot: false,
  renderDefault: false,
  usePlaceholder: true,
  variationIds: [],
  targetingType: {},
  customPropMap: {}
};
