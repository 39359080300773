import React, { useContext } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { WelcomeCardContext } from '../../../contexts';

export const UserProfileSummary = () => {
  const { userData, isAdmin, isChildAccount } = useContext(WelcomeCardContext);
  const companyName = userData?.customerInfo?.companyName;
  const userName = `${userData?.userProfile?.name?.firstName} ${userData?.userProfile?.name?.lastName}`;

  let companyNameText = companyName;
  if (!isAdmin) companyNameText = `Purchaser, ${companyName}`;
  else if (isChildAccount) companyNameText = `Welcome, ${companyName}`;

  return (
    <div className="sui-flex sui-flex-col sui-justify-center sui-pb-2">
      {!isChildAccount
        && (
          <Typography variant="body-base" weight="bold" height="tight">
            Welcome, {userName}
          </Typography>
        )}
      <Typography truncate variant="body-base" weight={isChildAccount ? 'bold' : 'regular'} height="snug">
        {companyNameText}
      </Typography>
    </div>
  );
};
