export const publish = (eventObj) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(
      `${eventObj.componentName}.${eventObj.eventName}`,
      eventObj.data
    );
  }
};

export const publishATC = (eventObj) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.top.LIFE_CYCLE_EVENT_BUS.trigger(
      `${eventObj.componentName}.${eventObj.eventName}`,
      eventObj.data
    );
  }
};