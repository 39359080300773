import { useEffect, useRef, useState } from 'react';
import { useReviewsDeprecated, useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { ReviewModel } from './models/ReviewModel';
import { ReviewStatsModel } from './models/ReviewStatsModel';
import { pipReviewsPerPage, seoReviewsPerPage } from '../constants';
import { getPageSize, getProductReviewsVariables, getSeoStartIndex, getStartIndex } from '../helpers';
// TODO: UPDATE DEPRECATED HOOKS
/**
 * This hook calls the review services (BV) and falls back to review data from product model if it fails.
 * @param itemId
 * @param options - RR state which contains filtering options for the BV call
 * @returns { averageRating, loading, FilterSelected, reviewModels, statistics, SortBy, totalReviews } -
 *   based on available data source
 */
export const useProductReviews = ({ itemId, seoPageNumber, ...options }) => {
  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = options;
  const initialOptions = useRef({});

  let seoStartIndex = getSeoStartIndex(seoPageNumber, seoReviewsPerPage);

  const pageSize = getPageSize(seoPageNumber, seoReviewsPerPage, pipReviewsPerPage);

  const [queryVariables, setQueryVariables] = useState({
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null
    },
    searchTerm: null,
    excludeFamily: false,
    sortBy: 'photoreview',
    startIndex: seoStartIndex,
    ...(seoPageNumber && {
      recfirstpage: pageSize.toString(),
      pagesize: pageSize.toString()
    })
  });

  useEffect(() => {
    if (options && !initialOptions?.current?.pageContext) {
      initialOptions.current = options;
      return;
    }

    if (JSON.stringify(options) === JSON.stringify(initialOptions?.current)) return;
    initialOptions.current = options;

    const { currentPage } = pageContext;

    const {
      reviewsPerPage,
      sentimentReviewIds,
      starRatings,
      isVerifiedPurchase,
      excludeFamily
    } = getProductReviewsVariables(filters, selectedSentiment, seoPageNumber, seoReviewsPerPage, pipReviewsPerPage);

    const reviewQueryFilters = {
      isVerifiedPurchase,
      prosCons: sentimentReviewIds,
      starRatings
    };

    let startIndex = getStartIndex(currentPage, reviewsPerPage);

    const variables = {
      excludeFamily,
      filters: reviewQueryFilters,
      searchTerm: searchText,
      sortBy,
      startIndex,
      recfirstpage: pageSize.toString(),
      pagesize: pageSize.toString()
    };

    setQueryVariables(variables);
  }, [options]);

  const reviewService = useReviewsDeprecated({
    itemId,
    variables: queryVariables
  });

  const reviewData = reviewService.data?.reviews;
  // todo: add store id

  const productModel = useProductDeprecated({
    itemId,
    domains: ['identifiers', 'info', 'reviews'], // match war query to leverage caching
    skip: !!reviewData
  });

  if (reviewService.loading || productModel.loading) {
    return { loading: true };
  }

  const productData = productModel.data?.product?.reviews;

  if (reviewData) {
    const { FilterSelected, Results, SortBy } = reviewData;
    const results = Results || [];
    const reviewModels = results.map((result) => new ReviewModel(result));
    const statistics = new ReviewStatsModel(reviewData);
    const totalReviewPages = Math.ceil(reviewData.TotalResults / seoReviewsPerPage);
    return {
      FilterSelected, reviewModels, statistics, SortBy, totalReviewPages
    };
  }
  if (productData) {
    const { averageRating, totalReviews } = productData.ratingsReviews || {};
    return { averageRating, totalReviews };
  }

  return {};
};