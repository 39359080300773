import React from 'react';
import { string, number } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { bentoHeroDataModel } from './bentoHeroDataModel';
import { BentoHeroRenderer } from './sub-components/BentoHeroRenderer';

const BentoHero = ({ componentId = '', componentClass = '', componentPosition = 1 }) => {

  const { data, loading, error } = useDataModel('bentoHeroComponent', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <BentoHeroRenderer
      componentId={componentId}
      componentPosition={componentPosition}
      data={data}
      displayName={BentoHero.displayName}
    />
  );
};

BentoHero.propTypes = {
  componentId: string,
  componentClass: string,
  componentPosition: number
};

BentoHero.displayName = 'BentoHero';

BentoHero.dataModel = bentoHeroDataModel;

export { BentoHero };
