import { createTheme } from '@one-thd/sui-atomic-components';

const EndcapBeige = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

EndcapBeige.palette['--sui-palette-background-primary'] = '238 226 215';
EndcapBeige.palette['--sui-palette-button-background-primary'] = '32 32 32';
EndcapBeige.palette['--sui-palette-text-primary'] = '32 32 32';
EndcapBeige.palette['--sui-palette-text-subtle'] = '32 32 32';

export { EndcapBeige };