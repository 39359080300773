import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from '../button/IconButton';
import { InputBase } from '../input/InputBase';
import useControlled from '../private/hooks/useControlled';
import { Add } from '../private/icons/Add';
import { Subtract } from '../private/icons/Subtract';

const capMin = (min, value) => (value < min ? min : value);
const capMax = (max, value) => (value > max ? max : value);

const SUBSTRACT = 'SUBSTRACT';
const ADD = 'ADD';

/**
 * `Stepper` displays a numerical input field along with add / subtract buttons that allow
 * the selection of a numeric value. It does not handle any labels or status messages,
 * whereas `QuantityPicker` does.
 *
 * Related components: [QuantityPicker](#quantitypicker)
 *
 * Usage:
 *
 * ```jsx
 * import { Stepper } from '@one-thd/sui-atomic-components';
 * ```
 */
const Stepper = React.forwardRef((props, ref) => {

  const {
    min,
    max,
    step = 1,
    id,
    defaultValue: defaultValueProp,
    value: valueProp,
    disabled,
    decrementTitle = 'Decrement',
    incrementTitle = 'Increment',
    onChange,
    ...other
  } = props;

  const [disabledMinus, setDisabledMinus] = useState(valueProp <= min || disabled);
  const [disabledPlus, setDisabledPlus] = useState(valueProp >= max || disabled);

  const [value, setValue] = useControlled({
    controlled: valueProp,
    defaultValue: defaultValueProp || min
  });

  const updateValue = (newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const clickButtonHandler = (increment) => (event) => {
    const newValue = Number(value) + increment;
    updateValue(capMax(max, capMin(min, newValue)));
  };

  const inputChangeHandler = (event) => {
    if (event.target.checkValidity()) {
      updateValue(event.target.value);
    }
  };

  const keyDownHandler = (event) => {
    if (event.keyCode === 40) { // Down key
      const newValue = Number(value) - step;
      updateValue(capMax(max, capMin(min, newValue)));
    } else if (event.keyCode === 38) { // Up key
      const newValue = Number(value) + step;
      updateValue(capMax(max, capMin(min, newValue)));
    }
  };

  useEffect(() => {
    setDisabledMinus(disabled || value <= min);
    setDisabledPlus(disabled || value >= max);
  }, [disabled, value, min, max]);

  const subContainerCls = classNames(
    'sui-outline -sui-outline-offset-1 sui-outline-1 sui-rounded-base', {
      'sui-outline-strong': !disabledMinus,
      'sui-outline-primary': disabledMinus,
      '[&:has(:focus-visible)]:sui-outline-none': !disabled && !disabledMinus
    }
  );

  const addContainerCls = classNames(
    'sui-outline -sui-outline-offset-1 sui-outline-1 sui-rounded-base', {
      'sui-outline-strong': !disabledPlus,
      'sui-outline-primary': disabledPlus,
      '[&:has(:focus-visible)]:sui-outline-none': !disabled && !disabledPlus
    }
  );

  const rootInputClasses = 'sui--mx-1px sui-w-16 sui-bg-primary';

  const outlineInputClasses = classNames('sui-rounded-none', {
    'sui-border-input-inactive': disabled
  });

  return (
    <div
      className="sui-flex"
      ref={ref}
    >
      <div className={subContainerCls}>
        <IconButton
          aria-label={decrementTitle}
          buttonSize="relaxed"
          disabled={disabledMinus}
          icon={Subtract}
          iconSize="small"
          onClick={clickButtonHandler(-step)}
        />
      </div>
      <InputBase
        id={id}
        value={value}
        disabled={disabled}
        onChange={inputChangeHandler}
        onKeyDown={keyDownHandler}
        classes={{
          root: rootInputClasses,
          input: classNames('sui-text-center', { 'sui-text-inactive': disabled }),
          outline: outlineInputClasses
        }}
        inputProps={{
          'aria-valuemin': min,
          'aria-valuemax': max,
          'aria-valuenow': value,
          pattern: '[0-9]*'
        }}
        disableStatus
        {...other}
      />
      <div className={addContainerCls}>
        <IconButton
          aria-label={incrementTitle}
          buttonSize="relaxed"
          disabled={disabledPlus}
          icon={Add}
          iconSize="small"
          onClick={clickButtonHandler(step)}
        />
      </div>
    </div>
  );
});

Stepper.displayName = 'Stepper';

Stepper.propTypes = {
  /**
   * Minimum value allowed when using the left button
   */
  min: PropTypes.number.isRequired,
  /**
   * Maximum value allowed using the right button
   */
  max: PropTypes.number.isRequired,
  /**
   * Increment to be applied when using the buttons
   */
  step: PropTypes.number,
  /**
   * The default value for the input element.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * If true, the buttons and text input will be inactive
   */
  disabled: PropTypes.bool,
  /**
   * id given to the text input
   */
  id: PropTypes.string,
  /**
   * `Stepper` value.
   */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Event fired when the user changes the value using the buttons or typing into the text input
   */
  onChange: PropTypes.func,
  /**
   * Title to be set on the right button
   */
  incrementTitle: PropTypes.string,
  /**
   * Title to be set on the left button
   */
  decrementTitle: PropTypes.string,
};

export { Stepper };
