import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export function RecentOrdersCardReceipt({ orderReceiptDetails = null }) {
  const receiptNumber = orderReceiptDetails || '';
  return (
    <div data-testid="receiptDetails" className="sui-flex">
      <span className="sui-mr-2">
        <Typography variant="body-lg" weight="bold">
          Order:
        </Typography>
      </span>
      <Typography variant="body-lg" truncate weight="bold">#{receiptNumber}</Typography>
    </div>
  );
}

RecentOrdersCardReceipt.propTypes = {
  orderReceiptDetails: string,
};
