import React, { useContext, useEffect } from 'react';
import {
  arrayOf, bool, func, number, oneOfType, shape, string
} from 'prop-types';
import classNames from 'classnames';
import { StarFilled } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { usePresentation } from '../../context/PresentationProvider';
import './Filter.styles.scss';

export const Filter = ({ onChange, ratingFilters, reviewStatistics }) => {
  useEffect(() => {
    if (reviewStatistics) {
      // initialize filter counts from distributions
      const { ratingDistribution, verifiedPurchaserStats } = reviewStatistics;
      // eslint-disable-next-line no-param-reassign
      ratingFilters.find((rf) => rf.id === 'verified').count = Number(verifiedPurchaserStats);
      ratingDistribution.forEach((rating) => {
      // eslint-disable-next-line no-param-reassign
        ratingFilters.find((rf) => rf.value === rating.ratingValue.toString()).count = rating.count;
      });
    }
  }, [reviewStatistics]);
  const { useCondensedLayout } = usePresentation();
  const { channel } = useContext(ExperienceContext);
  const wrapperClasses = classNames('ratings-reviews-filter', {
    'ratings-reviews-filter--vertical': channel === 'mobile' || useCondensedLayout
  });

  return (
    <div className={wrapperClasses}>
      <span className="ratings-reviews__review-filter-heading">Filter by:</span>
      <div className="ratings-reviews-filter__buttons sui-overflow-x-auto sui-overflow-y-hidden">
        {ratingFilters.map((filter, index) => {
          const getDisabledLogic = () => {
            if (filter.value === 'current_item_selections') {
              return false;
            }
            return !filter.count;
          };

          const filterWrapperClasses = classNames(
            'ratings-reviews__review-filter-button-wrapper',
            {
              'ratings-reviews__review-filter-button-wrapper--disabled': getDisabledLogic()
            }
          );
          const filterClasses = classNames(
            'ratings-reviews__review-filter-button',
            {
              'ratings-reviews__review-filter-button-desktop': !useCondensedLayout,
              'ratings-reviews__review-filter-button__selected': filter.checked
            }
          );
          const filterVerifiedPillText = useCondensedLayout ? 'Verified' : 'Verified Purchases Only';
          const filterCurrentSelectionPillText = useCondensedLayout ? 'Current' : 'Current Item Selections';
          const getFilterText = () => {
            if (filter.value === 'verified') {
              return filterVerifiedPillText;
            }
            if (filter.value === 'current_item_selections') {
              return filterCurrentSelectionPillText;
            }
            return filter.value;
          };

          return (
            <div className={filterWrapperClasses} key={filter.value}>
              <button
                className={filterClasses}
                disabled={getDisabledLogic()}
                onClick={() => onChange(filter.value, false)}
                type="button"
                name={`filter-button-${filter.value}`}
              >
                {filter.value === 'verified' || filter.value === 'current_item_selections' ? getFilterText() : (
                  <>
                    {filter.value}
                    <span className="sui-text-brand sui-ml-1">
                      <StarFilled size="small" color="current" />
                    </span>
                  </>
                )}

              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Filter.displayName = 'RatingsAndReviewsFilter';

Filter.propTypes = {
  onChange: func.isRequired,
  ratingFilters: arrayOf(shape({
    checked: bool,
    id: string,
    value: oneOfType([number, string]),
    count: number
  })),
  reviewStatistics: shape({
    ratingDistribution: arrayOf(shape({
      ratingValue: number,
      count: number,
      ratingPercentage: oneOfType([number, string])
    })),
    verifiedPurchaserStats: number
  }).isRequired
};

Filter.defaultProps = {
  ratingFilters: []
};