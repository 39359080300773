import React from 'react';
import { shape, func } from 'prop-types';
import { useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import {
  isShowBuyInStoreNearBy,
  getSellableQuantityYourStore,
  productShape,
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';
import { BopisOffIcon } from '../../helpers/icon-utils';
import { isFunction, getPickupStoreName } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const BopisOffMessage = ({ product, customRender }) => {
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({
        pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {}
      });
    }
    return (<div className="sui-flex" />);
  };

  if (!(product && storeName)) {
    return emptyComponent();
  }

  const sellableQuantity = getSellableQuantityYourStore(product);
  const formattedQty = formatSellableQuantity(sellableQuantity);

  /** *  BopisOnOffMessage Subcomponents  ** */
  const storeNameLink = (!isShowBuyInStoreNearBy(product))
    ? <span className="sui-text-primary">{storeName}</span>
    : <CheckNearByStore product={product} />;

  const secondLineMessage = sellableQuantity > 0
    ? (
      <>
        <span className="sui-text-success">{`${formattedQty} in stock`}</span>
        <span className="sui-text-primary">{' at '}</span>
        {storeNameLink}
      </>
    )
    : (
      <>
        <span className="sui-text-primary">{'For availability, visit '}</span>
        {storeNameLink}
      </>
    );

  if (isFunction(customRender)) {
    return customRender({
      pickUpTemplate: PICKUP_TEMPLATES.BOPIS_OFF_MESSAGE,
      icon: BopisOffIcon,
      values: {
        storeNameLink, storeName, formattedQty, message: secondLineMessage
      }
    });
  }

  return (
    <PodMessage
      messageIcon={BopisOffIcon}
      messageType="At Your Store"
    >
      <>
        <span className="sui-text-success">{`${formattedQty} in stock`}</span>
        , not eligible for online order Pickup
      </>
    </PodMessage>

  );
};

BopisOffMessage.propTypes = {
  product: shape({ productShape }).isRequired,
  customRender: func
};

BopisOffMessage.defaultProps = {
  customRender: null
};
