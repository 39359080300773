import React, { useEffect } from 'react';
import { string } from 'prop-types';

import {
  arrayOf,
  customType,
  namedFragment,
  params,
  shape,
  string as stringType,
  typename,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { ShopByCategoryRenderer } from './ShopByCategoryRenderer';

const ShopByCategory = ({ componentId, componentClass = '' }) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('shop-by-category.ready');
  }, []);

  const categoryList = data?.component?.shopByCategoryNavigationItems;
  if (error || (!categoryList?.length && !loading)) {
    return null;
  }
  return (
    <ShopByCategoryRenderer
      categoryList={categoryList}
      data={data}
      loading={loading}
    />
  );
};

ShopByCategory.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
};

ShopByCategory.displayName = 'ShopByCategory';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

ShopByCategory.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['ShopByCategory'])
      .isRequired(),
  }).shape({
    ShopByCategory: namedFragment({ inline: true, fragmentType: 'ShopByCategory' }).shape({
      id: stringType(),
      componentName: stringType(),
      shopByCategoryNavigationItems: arrayOf(
        shape({
          id: stringType(),
          title: stringType(),
          link: stringType(),
          icon: shape({
            url: stringType(),
          }),
          stencilIcon: stringType(),
          previewImage: DamMediaFragment,
        }),
      ),
      __typename: typename('ShopByCategory'),
    }),
  }),
};

export { ShopByCategory };
