import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ModalManager, { ariaHidden } from './ModalManager';
import { ModalFocus } from './ModalFocus';
import { Backdrop } from '../private/components/backdrop/Backdrop';
import { Portal } from '../private/components/portal/Portal';
import useForkRef from '../private/hooks/useForkRef';
import useEventCallback from '../private/hooks/useEventCallback';
import { ownerDocument, isHostElement } from './helpers';
import HTMLElementType from '../private/utils/HTMLElementType';

function createChainedFunction(...funcs) {
  return funcs.reduce((acc, func) => {
    if (func == null) {
      return acc;
    }
    return function chainedFunction(...args) {
      acc.apply(this, args);
      func.apply(this, args);
    };
  }, () => {});
}

function getContainer(container) {
  return typeof container === 'function' ? container() : container;
}

function getHasTransition(props) {
  return props.children
    ? Object.prototype.hasOwnProperty.call(props.children.props, 'in')
    : false;
}

const defaultManager = new ModalManager();

const ModalBase = React.forwardRef((props, ref) => {
  const {
    children = null,
    className,
    closeAfterTransition = false,
    component = 'div',
    components = {},
    componentsProps = {},
    container,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableEscapeKeyDown = false,
    disableRestoreFocus = false,
    disableScrollLock = false,
    hideBackdrop = false,
    keepMounted = false,
    manager = defaultManager,
    onBackdropClick,
    onClose,
    onKeyDown,
    open,
    onTransitionEnter,
    onTransitionExited,
    ...other
  } = props;

  const [exited, setExited] = React.useState(true);
  const modal = React.useRef({});
  const mountNodeRef = React.useRef(null);
  const modalRef = React.useRef(null);
  const handleRef = useForkRef(modalRef, ref);
  const hasTransition = getHasTransition(props);

  const ariaHiddenProp = props['aria-hidden'] ?? true;

  const getDoc = () => ownerDocument(mountNodeRef.current);
  const getModal = () => {
    modal.current.modalRef = modalRef.current;
    modal.current.mountNode = mountNodeRef.current;
    return modal.current;
  };

  const handleMounted = () => {
    manager.mount(getModal(), { disableScrollLock });
    modalRef.current.scrollTop = 0;
  };

  const handleOpen = useEventCallback(() => {
    const resolvedContainer = getContainer(container) || getDoc().body;

    manager.add(getModal(), resolvedContainer);

    if (modalRef.current) {
      handleMounted();
    }
  });

  const isTopModal = React.useCallback(() => manager.isTopModal(getModal()), [manager]);

  const handlePortalRef = useEventCallback((node) => {
    mountNodeRef.current = node;

    if (!node) {
      return;
    }

    if (open && isTopModal()) {
      handleMounted();
    } else {
      ariaHidden(modalRef.current, ariaHiddenProp);
    }
  });

  const handleClose = React.useCallback(() => {
    manager.remove(getModal(), ariaHiddenProp);
  }, [manager, ariaHiddenProp]);

  React.useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  React.useEffect(() => {
    if (open) {
      handleOpen();
    } else if (!hasTransition || !closeAfterTransition) {
      handleClose();
    }
  }, [open, handleClose, hasTransition, closeAfterTransition, handleOpen]);

  const handleEnter = () => {
    setExited(false);

    if (onTransitionEnter) {
      onTransitionEnter();
    }
  };

  const handleExited = () => {
    setExited(true);

    if (onTransitionExited) {
      onTransitionExited();
    }

    if (closeAfterTransition) {
      handleClose();
    }
  };

  const handleBackdropClick = (event) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(event);
    }

    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
    if (event.key !== 'Escape' || !isTopModal()) {
      return;
    }

    if (!disableEscapeKeyDown) {
      event.stopPropagation();
      if (onClose) {
        onClose(event, 'escapeKeyDown');
      }
    }
  };

  const childProps = {};
  if (children.props?.tabIndex === undefined) {
    childProps.tabIndex = '-1';
  }

  // It's a Transition like component
  if (hasTransition) {
    childProps.onEnter = createChainedFunction(handleEnter, children.props.onEnter);
    childProps.onExited = createChainedFunction(handleExited, children.props.onExited);
  }

  const rootClasses = classNames('sui-fixed sui-z-max sui-inset-0', className);

  const Root = components.Root || component;
  const rootProps = {
    role: 'presentation',
    ...(!isHostElement(Root) && {
      as: component
    }),
    ...other,
    ...componentsProps.root,
    ref: handleRef,
    onKeyDown: handleKeyDown,
    className: rootClasses
  };

  const BackdropComponent = components.Backdrop || Backdrop;
  const backdropProps = {
    'aria-hidden': true,
    open,
    onClick: handleBackdropClick,
    ...componentsProps.backdrop,
  };

  if (!keepMounted && !open && (!hasTransition || exited)) {
    return null;
  }

  return (
    <Portal ref={handlePortalRef} container={container}>
      <Root {...rootProps}>
        {!hideBackdrop && BackdropComponent ? <BackdropComponent {...backdropProps} /> : null}
        <ModalFocus
          disableEnforceFocus={disableEnforceFocus}
          disableAutoFocus={disableAutoFocus}
          disableRestoreFocus={disableRestoreFocus}
          isEnabled={isTopModal}
          open={open}
        >
          {React.cloneElement(children, childProps)}
        </ModalFocus>
      </Root>
    </Portal>
  );
});

ModalBase.displayName = 'ModalBase';

ModalBase.propTypes = {
  'aria-hidden': PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  closeAfterTransition: PropTypes.bool,
  component: PropTypes.elementType,
  components: PropTypes.shape({
    Backdrop: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
  componentsProps: PropTypes.shape({
    backdrop: PropTypes.object,
    root: PropTypes.object,
  }),
  container: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.func,
  ]),
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  keepMounted: PropTypes.bool,
  manager: PropTypes.object,
  onBackdropClick: PropTypes.func,
  onClose: PropTypes.func,
  onKeyDown: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onTransitionEnter: PropTypes.func,
  onTransitionExited: PropTypes.func,
};

export { ModalBase };
