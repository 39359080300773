import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Rating } from './Rating';
import { Typography } from '../typography/Typography';
import { ButtonBase } from '../private/components/button/ButtonBase';
import { StarFilled } from '../private/icons/StarFilled';

/**
 * The `RatingMeter` wraps a rating and an associated label within a touch target for link and on click user events.
 *
 * The `RatingMeter` component is built on top of the `Rating` and `Typography` components,
 * meaning that you can use their props.
 *
 * Usage:
 *
 * ```jsx
 * import { RatingMeter } from '@one-thd/sui-atomic-components';
 * ```
 *
 * Related components: [Rating](#rating), [Typography](#typography)
 */
const RatingMeter = React.forwardRef((props, ref) => {

  const {
    disabled = false,
    edge = false,
    href,
    label,
    onClick,
    precision = 0.5,
    RatingProps,
    size = 'small',
    TypographyProps,
    value,
    ...other
  } = props;

  const notActionable = !(href || onClick) && !disabled;

  const handleClick = (event) => {
    if (onClick) {
      onClick(event, value);
    }
  };

  const classes = classNames('sui-gap-2 sui-items-end sui-p-2', {
    'sui-cursor-default sui-pointer-events-none': notActionable,
    'sui-border-input-inactive sui-text-inactive': disabled,
    '-sui-ml-2': edge === 'start',
    '-sui-mr-2': edge === 'end',
    'sui-border-input-primary sui-text-primary sui-rounded-md focus-visible:sui-outline focus-visible:sui-outline-highlight focus-visible:sui-outline-2 focus-visible:-sui-outline-offset-2': !disabled
  });

  return (
    <ButtonBase
      className={classes}
      disabled={disabled}
      href={href}
      onClick={handleClick}
      {...other}
    >
      <Rating
        disabled={disabled}
        name="simple-rating"
        value={value}
        precision={precision}
        size={size}
        emptyIcon={<StarFilled />}
        {...RatingProps}
      />
      {label ? (
        <Typography
          component="span"
          height="tight"
          variant="body-xs"
          {...TypographyProps}
        >
          {label}
        </Typography>
      ) : null}
    </ButtonBase>
  );
});

RatingMeter.displayName = 'RatingMeter';

RatingMeter.propTypes = {
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side. Helpful for aligning the left or right side of the icon
   * with content above or below.
   * @default false
   */
  edge: PropTypes.oneOf(['end', 'start', false]),
  /**
   * The text for the meter label.
   */
  label: PropTypes.string,
  /**
   * @ignore
   */
  href: PropTypes.string,
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * The minimum increment value change allowed.
   * @default '0.5'
   */
  precision: PropTypes.number,
  /**
   * Props applied to the [`Rating`](#rating) component.
   */
  RatingProps: PropTypes.object,
  /**
   * The size of the rating svg icons.
   * @default 'small'
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Props applied to the [`Typography`](#typography) component.
   */
  TypographyProps: PropTypes.object,
  /**
   * The rating value.
   */
  value: PropTypes.number,
};

export { RatingMeter };
