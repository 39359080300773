import React from 'react';
import { bool, shape as shapeType, string as stringType, number as numberType } from 'prop-types';
import classNames from 'classnames';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { ImageHotspot } from '@thd-olt-component-react/image-hotspot';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { getTarget } from './utils/getTarget';

const MOBILE_MEDIA_QUERY = '(max-width: 767px)';
const TABLET_MEDIA_QUERY = '(min-width: 768px) and (max-width: 1023px)';
const DESKTOP_MEDIA_QUERY = '(min-width: 1024px)';

export const retrieveUrl = (previewImage) => {
  const damDownloadedContentImage = previewImage?.damDownloadedContent?.url;
  const damContentSelectorImage = previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl;

  return damDownloadedContentImage || damContentSelectorImage || '';
};

const getHotspotId = (data, isMobileViewport, isTabletViewport) => {
  const mobileHotspotId = data?.mobileImage?.imageHotspot?.id;
  const desktopHotspotId = data?.desktopImage?.imageHotspot?.id;
  const tabletHotspotId = data?.tabletImage?.imageHotspot?.id || desktopHotspotId;

  if (!mobileHotspotId && !desktopHotspotId) {
    return null;
  }
  if (isMobileViewport) {
    return mobileHotspotId;
  }
  if (isTabletViewport) {
    return tabletHotspotId;
  }
  return desktopHotspotId;
};

const BannerRenderer = ({
  data,
  desktopAspectRatio,
  tabletAspectRatio,
  mobileAspectRatio,
  lazyLoad,
  componentId,
  componentPosition
}) => {
  const {
    link,
    altText
  } = data || {};

  const bannerResponsiveClasses = classNames(
    mobileAspectRatio,
    tabletAspectRatio,
    desktopAspectRatio,
  );

  const isSSR = typeof window === 'undefined';
  const mobileUrl = retrieveUrl(data?.mobileImage);
  const desktopUrl = retrieveUrl(data?.desktopImage);
  const tabletUrl = retrieveUrl(data?.tabletImage) || desktopUrl;

  const isMobileViewport = useBreakpoint('md').lesser;
  const isTabletViewport = useBreakpoint('lg').lesser;

  const hotspotId = getHotspotId(data, isMobileViewport, isTabletViewport);

  if (hotspotId) {
    const ssrImageData = {
      mobileImageSrc: mobileUrl,
      responsiveImages: [
        {
          imageSrc: desktopUrl,
          mediaQuery: DESKTOP_MEDIA_QUERY,
        },
        {
          imageSrc: tabletUrl,
          mediaQuery: TABLET_MEDIA_QUERY,
        }
      ]
    };

    return (
      <QueryProvider cacheKey="image-hotspot">
        <ImageHotspot
          componentId={hotspotId}
          position={componentPosition}
          ssrImageData={ssrImageData}
          parentId={componentId}
        />
      </QueryProvider>
    );
  }

  const pictureElement = (
    <picture data-contentful-entry-id={componentId} data-contentful-field-id="desktopImage">
      <source media={MOBILE_MEDIA_QUERY} srcSet={mobileUrl} />
      <source media={TABLET_MEDIA_QUERY} srcSet={tabletUrl} />
      <source media={DESKTOP_MEDIA_QUERY} srcSet={desktopUrl} />
      <img loading={lazyLoad ? 'lazy' : ''} src={desktopUrl} alt={altText} height="100%" width="100%" />
    </picture>
  );

  return (
    <div data-testid="internal-marketing-banner" className={bannerResponsiveClasses}>
      {link && (
        <>
          <a href={link} target={getTarget(link)}>
            {pictureElement}
          </a>
        </>
      )}
      {!link && (
        <>
          {pictureElement}
        </>
      )}
    </div>
  );
};

BannerRenderer.propTypes = {
  componentId: stringType.isRequired,
  desktopAspectRatio: stringType.isRequired,
  tabletAspectRatio: stringType.isRequired,
  mobileAspectRatio: stringType.isRequired,
  lazyLoad: bool,
  data: shapeType({}),
  componentPosition: numberType
};

BannerRenderer.defaultProps = {
  data: {},
  lazyLoad: false,
  componentPosition: 1
};

BannerRenderer.displayName = 'BannerRenderer';

export { BannerRenderer };
