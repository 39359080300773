import { createTheme } from '@one-thd/sui-atomic-components';

const SawDustTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

SawDustTheme.palette['--sui-palette-background-primary'] = '246 243 239';
SawDustTheme.palette['--sui-palette-button-background-primary'] = '32 32 32';
SawDustTheme.palette['--sui-palette-text-primary'] = '32 32 32';
SawDustTheme.palette['--sui-palette-text-subtle'] = '32 32 32';

export { SawDustTheme };