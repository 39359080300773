import * as React from 'react';
import { Alert, Typography, Button } from '@one-thd/sui-atomic-components';
import { PacDrawer, LoadingPacDrawer } from '../../common/Drawer/PacDrawer/PacDrawer';
import { RenderWithUpdatedEventObject } from '../../common/Drawer/EventComponentModifier';
import { DetailsMarkdown } from '../../common/Drawer/DetailsMarkdown';
import { ExpireLine } from '../../common/Drawer/ExpireLine';
import { DrawerBarcode } from '../../common/Drawer/Barcode';
import { PacDrawerContext } from '../../contexts/PacDrawerContext';
import { windowExists } from '../../common/utils/AccountUtils';
import { locatePerkWithNewData } from '../../common/utils/ContextUtils';
import { getImageURL } from '../../common/utils/CardUtils';
import {
  getRewardAttributes, getCookieProperty, capitalizeFirstLetter, getHostURL, formatRewardText
} from '../../common/utils/DrawerUtils';
import { useFetchActivatePerks, useCheckAndFetchOffers } from '../../hooks';
import {
  FOOTER_BUTTON_TEXT, SELECTION_CONSTANTS, BARCODE_TEXT
} from '../../core/DrawerConstants';

export const ErrorActivationDrawer = ({ refreshFn, handleOnClose, handleOnBack }) => {
  const { setOpen } = React.useContext(PacDrawerContext) ?? {};
  const header = SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.HEADER;
  const title = SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.TITLE;
  const iconImgUrl = getImageURL(SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.ICON_PATH);
  const onClose = () => setOpen(false);
  const Refresh = () => <button type="button" className="sui-underline" onClick={refreshFn}>Refresh</button>;
  const Content = () => (
    <>
      <Typography variant="body-base" weight="bold">{SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.HEADER}</Typography>
      <div className="sui-h-6" />
      <Alert status="error">{SELECTION_CONSTANTS.OFFER_ACTIVATION_ERROR.MSG}<br /><Refresh /></Alert>
      <div className="sui-h-20" />
    </>
  );
  return (
    <PacDrawer
      headerText={header}
      onClose={handleOnClose || onClose}
      OnBack={handleOnBack}
      iconImgUrl={iconImgUrl}
      title={title}
      Content={Content}
    />
  );
};

export const UnclaimedOfferDrawer = (props) => {
  const { infoOffers, eventOutput } = React.useContext(PacDrawerContext) ?? {};
  const { checkAndFetchPerks } = useCheckAndFetchOffers({ infoOffers });
  const { handleOnClose, handleOnBack } = props;
  const { perkObj = {} } = eventOutput;
  const { programId: programIdFromEvent, perkId } = perkObj;
  const programId = programIdFromEvent ?? infoOffers?.infoOffersData?.program?.programId;

  React.useEffect(() => {
    if (!programId) checkAndFetchPerks();
  }, []);

  const perkActivationRequest = React.useMemo(() => ({
    perkId,
    programId,
  }), [programId]);
  const {
    isLoadingActivatePerks,
    activatePerksError,
    activatePerksData,
    getActivateResponse
  } = useFetchActivatePerks({ perkActivationRequest });

  React.useEffect(() => {
    if (programIdFromEvent || (programId && !infoOffers?.isLoadingInfoOffers)) getActivateResponse();
  }, [programId, infoOffers?.isLoadingInfoOffers]);

  React.useEffect(() => {
    if (!activatePerksError && activatePerksData) {
      if (windowExists()) {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
          'loyalty-benefits.reward-barcode-drawer__activate-offer',
          {},
        );
      }
    }
  }, [activatePerksData]);

  const isLoading = (!programId && infoOffers?.isLoadingInfoOffers) || (programId && isLoadingActivatePerks);
  const hasInfoOffersError = !infoOffers?.isLoadingInfoOffers
    && (infoOffers?.infoOffersError || !perkActivationRequest.programId);
  const hasActivatePerksError = !isLoadingActivatePerks && (activatePerksError || !activatePerksData?.paymentId);

  if (isLoading) return <LoadingPacDrawer headerText="New Offer Unlocked" />;
  if (hasInfoOffersError) {
    return (
      <ErrorActivationDrawer
        handleOnClose={handleOnClose}
        handleOnBack={handleOnBack}
        refreshFn={infoOffers?.refreshInfoOffers}
      />
    );
  }
  if (hasActivatePerksError) {
    return (
      <ErrorActivationDrawer
        handleOnClose={handleOnClose}
        handleOnBack={handleOnBack}
        refreshFn={getActivateResponse}
      />
    );
  }

  const activatedOffer = {
    ...perkObj,
    perkStatus: 'ACTIVE',
    paymentId: activatePerksData?.paymentId,
    activationTime: activatePerksData?.activationTime,
    expirationTime: activatePerksData?.expirationTime,
  };

  return (
    <RenderWithUpdatedEventObject
      eventObject={{ perkObj: activatedOffer }}
      Component={<RewardBarcodeDrawer isNewlyActivated />}
    />
  );
};

export const RewardBarcodeDrawer = ({ isNewlyActivated }) => {
  const {
    open, setOpen, infoPerks, infoOffers, eventOutput, isDesktop, enablePerkAmountRemoval
  } = React.useContext(PacDrawerContext) ?? {};

  const {
    perkObj, perkId, perkList, tierList, onBack, onClose
  } = eventOutput;

  const currentReward = locatePerkWithNewData({
    perkObj, perkId, perkList, tierList, infoOffers, infoPerks
  });

  const {
    isPerk, isGiftCard, isInStore, isOmni, isOffer, isUnclaimed
  } = getRewardAttributes(currentReward);

  React.useEffect(() => {
    if (windowExists()) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('loyalty-benefits.reward-barcode-drawer__open', {});
    }
  }, []);

  const handleOnClose = () => {
    setOpen(false);
    if (onClose) onClose();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.reward-barcode-drawer__close',
      { perkObj: currentReward },
    );
  };
  const handleOnBack = () => {
    setOpen(false);
    if (onBack) onBack();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.reward-barcode-drawer__back',
      { perkObj: currentReward },
    );
  };

  if (isOffer && isUnclaimed) {
    return (
      <UnclaimedOfferDrawer
        handleOnBack={handleOnBack}
        handleOnClose={handleOnClose}
      />
    );
  }

  const showInstoreAppDisclaimer = isDesktop && (isInStore || isOmni);
  const { header, title, subtitle } = formatRewardText(currentReward, enablePerkAmountRemoval);
  const cartCount = getCookieProperty('THD_PERSIST', 'C6');
  const showToCheckoutButton = cartCount > 0 && currentReward && isGiftCard;
  const barcodeProps = {
    currentReward,
    isOpen: open,
    isNewlyActivated,
    isDesktop,
  };

  const content = () => {
    return (
      <div>
        <DrawerBarcode barcodeProps={barcodeProps} />
        {currentReward && (
          <>
            <div className="sui-mb-8">
              <ExpireLine expiration={currentReward?.expirationTime} />
            </div>
            <DetailsMarkdown markdownText={currentReward?.perkDescription} />
          </>
        )}
      </div>
    );
  };

  const footer = () => {
    const toCheckoutButton = () => {
      return (
        <div className={showInstoreAppDisclaimer ? 'sui-pt-8' : null}>
          <Button fullWidth href={getHostURL('cart')}>
            {FOOTER_BUTTON_TEXT.PXD}
          </Button>
        </div>
      );
    };

    const instoreAppDisclaimer = () => {
      return (
        <div className="sui-flex sui-flex-row sui-justify-start sui-align-center">
          {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
          <div className="sui-flex sui-flex-col sui-justify-center sui-align-center sui-min-w-[48px]">
            {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
            <img
              src="https://assets.thdstatic.com/images/v1/loyaltyBenefits/icon-mobile.svg"
              alt="Mobile Logo"
            />
          </div>
          <div className="sui-pl-4">
            {BARCODE_TEXT.inStoreAppDisclaimer(capitalizeFirstLetter(currentReward.offerType))}
          </div>
        </div>
      );
    };

    return (
      <>
        {showInstoreAppDisclaimer && instoreAppDisclaimer()}
        {showToCheckoutButton && toCheckoutButton()}
      </>
    );
  };

  return (
    <PacDrawer
      headerText={header}
      iconImgUrl={currentReward?.perkImageUrl}
      title={title}
      subTitle={subtitle}
      onBack={onBack ? handleOnBack : null}
      onClose={handleOnClose}
      Content={content}
      Footer={footer}
    />
  );
};
