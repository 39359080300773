/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { useStoreId } from '@thd-nucleus/experience-context';
import { handleUpdateCall, useCartMutation } from './helpers/mutation-helper';
import { useCheckForAlternateProvider } from './useAlternateUpdateCartProvider';
import { getOptimisticResponse } from './optimistic-cart-update';
import {
  getItemDetails,
  debounce
} from './updateCartHelper';
import {
  buildUpdateCartRequest,
  setLocalStoreId,
  buildFulfillmentStoreUpdateRequest
} from './update-cart-request-builder';
import { EVENTS } from '../util/AnalyticsHelper';
import { optimalOverride, getTmxId, getExchangeCartId, getIsBrandPricingPolicyCompliant } from '../util/utils';

// Primary/default mutation provider - cart-information graphql
const useUpdateCartDefault = () => {
  const [updateCartMutator, updateResponse] = useCartMutation('updateCart');

  const updateItemQuantity = async (
    newQuantity,
    item,
    onComplete
  ) => {
    newQuantity = ((typeof newQuantity === 'string') ? (parseInt(newQuantity, 10)) : newQuantity);
    const itemDetails = getItemDetails(item, {
      quantity: newQuantity,
      event: EVENTS.QUANTITY_CHANGE
    });
    const cartRequest = buildUpdateCartRequest(itemDetails);
    const params = {
      updateCartMutator,
      cartRequest,
      updateType: 'quantity',
      onComplete
    };
    return debounce(doUpdateCartItem.bind(this, params));
  };

  const updateItemFulfillment = async (
    fulfillmentMethod,
    fulfillmentLocation,
    item,
    onComplete
  ) => {
    return updateItemsFulfillment([{
      fulfillmentMethod,
      fulfillmentLocation,
      item,
    }], onComplete);
  };

  const updateItemsFulfillment = async (
    updateItems = [],
    onComplete
  ) => {
    if (!Array.isArray(updateItems)) {
      return new Error('updateItemsFulfillment() expects an array of items and update details', updateItems);
    }
    const itemsDetails = updateItems.map((updateItem) => {
      const {
        fulfillmentMethod,
        fulfillmentLocation,
        item,
      } = updateItem;
      return getItemDetails(item, {
        fulfillmentMethod,
        fulfillmentLocation,
        event: EVENTS.FULFILLMENT_CHANGE
      });
    });

    const cartRequest = buildUpdateCartRequest(itemsDetails);
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest,
      updateType: 'fulfillment',
      onComplete
    });
  };

  const updateItemAddOn = async (selected, item, newAddOn, onComplete = null) => {
    newAddOn.selected = selected;
    let itemDetails = getItemDetails(item, {}, newAddOn);
    const cartRequest = buildUpdateCartRequest(itemDetails);
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest,
      updateType: 'addOn',
      onComplete
    });
  };

  const updateProjectId = async (request, onComplete = null) => {
    const cartRequest = { cartAttr: { projectId: request.projectId } };
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest,
      updateType: 'projectId',
      onComplete
    });
  };

  const updateItemAddOns = async (item, newAddOnArray, onComplete = null) => {
    if (!Array.isArray(newAddOnArray)) {
      return new Error('updateItemAddOns() expects an array of addOn update details', newAddOnArray);
    }
    let itemDetails = getItemDetails(item, {}, newAddOnArray);
    const cartRequest = buildUpdateCartRequest(itemDetails);
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest,
      updateType: 'addOn',
      onComplete
    });
  };

  const updateItemsAddOns = async (
    updateItems = [],
    newAddOnArray = [],
    onComplete
  ) => {
    if (!Array.isArray(updateItems)) {
      return new Error(
        'updateItemsAddOns() expects an array of items iwth update details and an array of addOns',
        updateItems
      );
    }
    const itemsDetails = updateItems.map((updateItem, index) => {
      const {
        fulfillmentMethod,
        fulfillmentLocation,
        item,
      } = updateItem;
      return getItemDetails(item, {
        fulfillmentMethod,
        fulfillmentLocation,
        event: EVENTS.FULFILLMENT_CHANGE
      }, newAddOnArray[index]);
    });

    const cartRequest = buildUpdateCartRequest(itemsDetails);
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest,
      updateType: 'fulfillment',
      onComplete
    });
  };

  const updateFulfillmentStore = async (storeId, onComplete = null) => {
    return doUpdateCartItem({
      updateCartMutator,
      cartRequest: buildFulfillmentStoreUpdateRequest(storeId),
      updateType: 'fulfillmentStore',
      onComplete
    });
  };

  // Update delivery address to calculate taxes. Used for apple pay
  const updateDeliveryAddress = async (request, onComplete = null) => {
    const params = {
      updateCartMutator,
      cartRequest: request.cartRequest,
      cartRequestContext: request.requestContext,
      updateType: 'DeliveryAddress',
      onComplete
    };
    return debounce(doUpdateCartItem.bind(this, params));
  };

  return {
    updateDeliveryAddress,
    updateItemQuantity,
    updateItemFulfillment, // single item
    updateItemsFulfillment, // multi items
    updateItemAddOn, // single item, single addon
    updateItemAddOns, // single item, multiple addons
    updateItemsAddOns, // single item, multiple addons
    updateFulfillmentStore,
    updateProjectId, // update project id for cart
    updateResponse
  };
};

const doUpdateCartItem = async ({
  updateCartMutator,
  cartRequest,
  cartRequestContext,
  updateType,
  onComplete = null
}) => {
  const variables = { cartRequest,

    requestContext: cartRequestContext || {
      ...optimalOverride(),
      ...(getExchangeCartId() && { cartId: getExchangeCartId() }),
      ...(getTmxId() && { tmxProfileId: getTmxId() }),
      isBrandPricingPolicyCompliant: getIsBrandPricingPolicyCompliant()
    } };
  const optimisticResponse = getOptimisticResponse(variables, updateType);

  // returns promise
  return handleUpdateCall({
    mutationName: 'updateCart',
    mutator: updateCartMutator,
    variables,
    optimisticResponse,
    onComplete
  });

};

/**
 * Wrapper allows swapping out data provider (cart-info, mcc-cart, automation)
 * @returns Promise
 */
export const useUpdateCart = () => {
  const localStoreId = useStoreId();
  setLocalStoreId(localStoreId);
  let useUpdateCart = useCheckForAlternateProvider(useUpdateCartDefault, localStoreId);
  return useUpdateCart();
};
