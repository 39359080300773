/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading, react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';

const isServer = typeof window === 'undefined';
export const withDynamicImport = ({
  server, client, name, placeholder, inherit
}) => {

  if (isServer) return server();

  const Component = (props) => {
    const moduleRef = useRef(null);
    const [_, forceRender] = useState();
    // declareContexts([QueryContext, ExperienceContext]);

    useEffect(() => {
      client().then((module) => {
        moduleRef.current = module[name];
        if (!moduleRef.current) {
          // eslint-disable-next-line max-len
          throw new Error(`Error importing ${name} in withDynamicImport. Make sure module name matches component export name.`);
        }

        Component.dataModel = moduleRef.current?.dataModel || {};
        forceRender(new Date().getTime());
      });
    }, []);
    if (!moduleRef.current) return placeholder || null;
    const queryProviderProps = (props.queryProvider || {});
    // allow the component to override the QueryProvider values
    // because cacheKey may be different across sibling components
    // you want it to be the same so that there is no race condition.
    // for example, for all accordions must be the same
    // otherwise a race condition will occur generating duplicate requests
    return (
      <QueryProvider
        defaultVariables={props?.qctx?.defaultVariables?.current}
        inherit={inherit}
        cacheKey={`dynamic-import-${name}`}
        {...queryProviderProps}
      >
        <moduleRef.current
          {...props}
        />
      </QueryProvider>
    );
  };

  Component.displayName = name;

  return Component;
};
