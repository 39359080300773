const componentSelectorFilter = (comp) => {
  return comp?.__typename === 'ComponentSelector';
};

const hasOwn = (object, property) => {
  return Object.prototype.hasOwnProperty.call(object || {}, property);
};

const getRootContainerComponents = (componentData = [], containerKeys = {}) => {
  return componentData.reduce(
    (acc, sections) => {
      if (hasOwn(containerKeys, sections?.__typename)) {
        return [...acc, ...(sections[containerKeys[sections.__typename]] || [])];
      }
      return [...acc, ...(sections.components || [])];
    }, []);
};

const getNestedContainerVariations = (components = [], containerKeys = {}) => {
  return components.filter((comp) => {
    return hasOwn(containerKeys, comp?.__typename);
  }).map((container) => {
    return container[containerKeys[container.__typename]].filter(componentSelectorFilter);
  }).reduce((acc, componentSelectors) => {
    return [...acc, ...(componentSelectors || [])];
  }, []);
};

const getVariationsFromContent = (content, containerKeys) => {
  const variations = [];

  Object.keys(content).forEach((key) => {
    const componentData = content[key];
    const isRootArrayContentNode = Array.isArray(componentData) && key !== 'breadCrumbs';
    const isContentfulComponent = !!componentData?.__typename || isRootArrayContentNode;
    // This will work for specialized containers directly at the root (which is none as of q3 2024)
    const isSpecializedContainer = !!componentData?.__typename && hasOwn(containerKeys, componentData?.__typename);
    const componentsKey = isSpecializedContainer ? containerKeys[componentData.__typename] : '';

    if (isContentfulComponent) {
      let childComponents = [];
      if (isRootArrayContentNode) {
        childComponents = getRootContainerComponents(componentData, containerKeys);
      } else if (isSpecializedContainer) {
        // If a Specialized Container is directly at the root
        childComponents = componentData[componentsKey];
      } else {
        childComponents = componentData.components || [];
      }

      const nestedVariations = getNestedContainerVariations(childComponents, containerKeys);
      const rootVariations = childComponents.filter(componentSelectorFilter);
      variations.push(...nestedVariations);
      variations.push(...rootVariations);
    }
  });

  return variations;
};

/**
 * @function getContentfulTargetingIds
 * @param {{content: ContentfulContent}} config
 * @description Takes the content key from a typical Contentful response, and loops through the entire
 * object, pulling out any mboxes, campaignIds or innervateTags that are associated with a ComponentSelector.
 * @returns {{
 * mboxes: String[],
 * campaignIds: [{ campaignId: String, channel: String }],
 * innervateTags: [{ tagID: String, adkey: String, csId: String, campaignMetadata: String }]
 * }}
 */
export const getContentfulTargetingIds = ({ content, containerKeys }) => {
  const campaignIds = [];
  const mboxes = [];
  const innervateTags = [];

  if (!content) {
    return { mboxes, campaignIds, innervateTags };
  }

  const variations = getVariationsFromContent(content, containerKeys);
  variations.forEach((comp) => {
    const { targetingType, id, campaignMetadata } = comp;
    if (targetingType?.__typename === 'AdobeTarget') {
      mboxes.push(targetingType.mbox);
    } else if (targetingType?.__typename === 'MultiArmBandit') {
      campaignIds.push({ campaignId: targetingType.campaignId, channel: targetingType.channel });
    } else if (targetingType?.__typename === 'Innervate') {
      innervateTags.push({
        tagID: targetingType.tagID,
        adkey: targetingType.adkey,
        csId: id,
        campaignMetadata,
      });
    }
  });

  return { mboxes, campaignIds, innervateTags };
};
