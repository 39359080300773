import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { BentoHero as BentoHeroComponent } from '@thd-olt-component-react/hero';
import { string } from 'prop-types';

export function BentoHero({ id }) {

  return (
    <ErrorBoundary name="bento-hero">
      <QueryProvider cacheKey="bento-hero-cache" defaultVariables={{ isCarousel: false }}>
        <BentoHeroComponent componentId={id} componentClass="BentoHero" />
      </QueryProvider>
    </ErrorBoundary>
  );
}

BentoHero.propTypes = {
  id: string.isRequired
};
