import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Button } from '@one-thd/sui-atomic-components';
import { getHostCURL } from '../../../common/utils/CardUtils';
import { analyticsStash } from '../../../common/utils/AnalyticsUtils';
import { analytics } from '../../../core/Constants';

export const WelcomeCardFooter = () => {
  const { channel } = useContext(ExperienceContext);
  const learnMoreLink = getHostCURL('/myaccount/proXtraRewards');
  const linkText = 'View All Rewards & Benefits';
  const isMobile = channel === 'mobile';
  const variant = !isMobile ? 'secondary' : 'text';
  const mobileClass = isMobile ? 'sui-w-full sui-flex sui-justify-center' : '';
  return (
    <div className={mobileClass}>
      <Button
        variant={variant}
        href={learnMoreLink}
        fullWidth
        onClick={() => analyticsStash(
          'welcomeCard', analytics.ANALYTICS_MESSAGE.BOTTOM_LINK, analytics.ANALYTICS_LINKS.VIEW_BENEFITS)}
      >
        {linkText}
      </Button>
    </div>
  );
};
