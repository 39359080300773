import React, { useEffect, useState } from 'react';
import { useStore } from '@thd-nucleus/experience-context';
import { useDataModel } from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import { getDeliveryZip, isOutOfStock } from '../../helpers/utils';
import { DeliveryContentDrawerLoader } from './DeliveryContentDrawerLoader';
import { DeliveryDrawer } from './DeliveryDrawer';
import { EXPRESS_DELIVERY, SHIP_TO_HOME } from '../../helpers/constants';
import DeliveryContentDrawerError from './DeliveryContentDrawerError';

export const DeliveryContentDrawer = (props) => {

  const {
    itemId,
    quantity,
    isQuickViewOpen,
    setIsQuickViewOpen,
    channelDemo,
    useLoader, useError,
    showTodayOrTomorrow = null,
    disableSTH,
    configId
  } = props;

  let {
    storeId,
    zipCode,
  } = props;

  const [channel, setChannel] = useState(null);
  const [updatedQuantity, setQuantityToQuery] = useState(quantity);

  useEffect(() => {
    if (!channelDemo) {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width <= 640 || screen.height <= 640) {
        setChannel('Mobile');
      } else {
        setChannel('Desktop');
      }
    }
  }, []);

  useEffect(() => {
    if (channelDemo !== null) {
      setChannel(channelDemo);
    }
  }, [channelDemo]);

  const storeDetails = useStore();
  const { storeZip } = storeDetails;
  if (!storeId) {
    storeId = storeDetails.storeId;
  }
  const productQueryProps = {
    variables: { itemId, storeId },
    ssr: false
  };
  const { data } = useDataModel('clientOnlyProduct', productQueryProps);
  const { value } = data?.product?.pricing || {};
  const deliveryZip = getDeliveryZip();
  zipCode = zipCode || deliveryZip || storeZip;

  const shippingQueryProps = {
    variables: {
      itemId,
      storeId,
      zipCode,
      quantity: updatedQuantity,
      configId,
      price: parseInt((value * updatedQuantity).toFixed(2), 10)
    },
  };
  const { data: shippingResponseData, loading, error, refetch } = useDataModel('shipping', shippingQueryProps);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Update the quantity after a wait so as to avoid unnecessary re-renders.
      if (storeId !== '8119' && data) {
        setQuantityToQuery(quantity);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [data, quantity, storeId, zipCode]);

  if (!isQuickViewOpen) {
    return null;
  }

  const handleOnClose = () => {
    setIsQuickViewOpen(false);
  };

  if (!shippingResponseData || loading || useLoader) {
    return (
      <DeliveryContentDrawerLoader
        handleOnClose={handleOnClose}
        isQuickViewOpen={isQuickViewOpen}
      />
    );
  }

  if ((error && !shippingResponseData)
    || shippingResponseData?.shipping?.shipModes?.length === 0 || useError) {
    return (
      <DeliveryContentDrawerError
        handleOnClose={handleOnClose}
        isQuickViewOpen={isQuickViewOpen}
        refetch={refetch}
      />
    );
  }

  const shippingModel = shippingResponseData?.shipping;

  const isBackordered = data?.product?.fulfillment?.backordered;

  const sthServices = disableSTH ? [] : shippingModel?.services.filter((service) => {
    return service?.type?.toLowerCase() === SHIP_TO_HOME && !isOutOfStock(service, isBackordered);
  });
  const bodfsService = shippingModel?.services.filter((service) => {
    return service?.type?.toLowerCase() === EXPRESS_DELIVERY && !isOutOfStock(service, isBackordered);
  });

  return (
    <DeliveryDrawer
      sthServices={sthServices}
      bodfsService={bodfsService}
      isQuickViewOpen={isQuickViewOpen}
      handleOnClose={handleOnClose}
      showTodayOrTomorrow={showTodayOrTomorrow}
    />
  );
};

DeliveryContentDrawer.propTypes = {
  zipCode: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  isQuickViewOpen: PropTypes.bool,
  setIsQuickViewOpen: PropTypes.func,
  channelDemo: PropTypes.string,
  useLoader: PropTypes.bool,
  useError: PropTypes.bool,
  showTodayOrTomorrow: PropTypes.func,
  disableSTH: PropTypes.bool,
  configId: PropTypes.string
};

DeliveryContentDrawer.defaultProps = {
  isQuickViewOpen: false,
  setIsQuickViewOpen: () => {
  },
  channelDemo: null,
  useLoader: false,
  useError: false,
  showTodayOrTomorrow: null,
  disableSTH: false,
  configId: null,
};
