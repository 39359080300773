/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import { obfuscatePii } from './pii-obfuscator';

export const extractCartErrors = (rawErrors) => {
  let cartErrors = [];
  try {
    (rawErrors || []).forEach((error) => {
      const cartInfoErrors = error?.extensions?.cart_info_errors;
      if (cartInfoErrors?.length) {
        cartInfoErrors.forEach((cartError) => {
          cartErrors.push(cartError);
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
  return cartErrors;
};

export const getCurrentResponse = (operation) => {
  if (typeof window !== 'undefined') {
    try {
      return window.cachedRawResponse[operation];
    } catch (err) {
      console.error(err);
    }
  }
  return '';
};

export const getErrorCode = (errors) => {
  try {
    return errors?.[0]?.errorCode || '';
  } catch (err) {
    console.error(err);
  }
  return '';
};

const getGraphQLErrors = (errors = []) => {
  const rawGraphQLErrors = errors?.graphQLErrors || [];
  let graphQLErrors = [];
  try {
    rawGraphQLErrors.forEach((gqlError) => {
      if (gqlError?.extensions?.errors?.length) {
        graphQLErrors = graphQLErrors?.concat(gqlError?.extensions?.errors);
      } else if (gqlError?.extensions?.cart_info_errors?.length) {
        graphQLErrors = graphQLErrors?.concat(gqlError?.extensions?.cart_info_errors);
      }
      if (gqlError?.extensions?.customer_payment_info?.length) {
        // create a map of payment_info errors indexed by correlationId, correlationType, and errorCode
        const errorMap = new Map();
        gqlError?.extensions?.customer_payment_info.forEach((paymentInfoError) => {
          const { errorCode } = paymentInfoError || {};
          errorMap.set(errorCode, paymentInfoError);
        });
        graphQLErrors = graphQLErrors?.concat(Array.from(errorMap.values()));
      }
    });
  } catch (err) {
    console.error(err);
  }
  return graphQLErrors;
};

const getDisplayableError = (error, operation = '') => {
  try {
    error = error?.graphQLErrors || error;
    if (error) {
      error = error[0] || error;
      error = error.extensions || error;
      error = error.errors || error.cart_info_errors || error;
      error = error[0] || error;
      const {
        correlationId = null,
        errorCode = null,
        description = null,
        correlationType = null,
        inventory = null
      } = error;
      error = {
        correlationId, // 'fa44f300-e8a3-11ee-95a3-c18ee0203a1b',
        correlationType, // 'cartLineItem',
        description, // 'Please provide a valid quantity.',
        errorCode, // 'SCH_DLVRY_ERR_2001',
        inventory, // null,
        queryName: operation // 'updateCart', 'cartSubmit', etc
      };
    }
  } catch (err) {
    console.error(err);
  }
  return error;
};

export const getDisplayableErrors = (rawErrors = [], operation = '', props) => {
  let checkoutErrors = [];
  if (!rawErrors?.length && !rawErrors?.message) {
    return checkoutErrors;
  }
  if (!rawErrors?.length && rawErrors?.message) {
    rawErrors = [rawErrors];
  }
  try {
    (rawErrors || []).forEach((error) => {
      const cartInfoErrors = error?.extensions?.cart_info_errors || [];
      const normalErrors = error?.extensions?.errors || [];
      const gqlErrors = getGraphQLErrors(error);
      const allErrorsArray = [...cartInfoErrors, ...normalErrors, ...gqlErrors];
      const simpleError = (!allErrorsArray?.length && error?.message) ? error : '';

      if (allErrorsArray?.length) {
        allErrorsArray.forEach((errorObject) => {
          checkoutErrors.push(getDisplayableError(errorObject, operation));
        });
      }

      /* "simple errors" could look like this in backend response:
      "errors": [{
          "message": "a failure message"
      }]
      // This will error will also show for any updateCart call that fails
      */
      const resp = obfuscatePii(getCurrentResponse(operation)) || '';
      const req = (typeof props === 'object') ? obfuscatePii({ ...props }) : (props || '');
      if (simpleError?.message?.length) {
        checkoutErrors.push({
          errorCode: simpleError?.errorCode || 'CHECKOUT_SECTIONS_GEN_ERR',
          description: 'We\'re sorry, something went wrong. Please try again or refresh the page.',
          queryName: operation,
          message: simpleError?.message || '',
          req,
          resp,
          type: simpleError?.type || 'error'
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
  return checkoutErrors;
};

export const publishNewRelicPageAction = ({ actionName = '', actionObject = {} }) => {
  if (typeof window?.parent?.LIFE_CYCLE_EVENT_BUS !== 'undefined') {
    window?.parent?.LIFE_CYCLE_EVENT_BUS?.trigger('checkout.pageAction', { actionName, actionObject });
  }
};

export const logDataForAutomation = ({ operation, req, resp }) => {
  try {
    if (typeof window === 'undefined') return;
    if (operation === 'getCart') {
      window.getOrderClient = resp;
      // should this return here?
      return;
    }
    if (operation === 'updateCart') {
      window.getOrderClient = resp;
    }
    if (operation === 'getCalendar') {
      window.getOrderClient = resp;
    }
    // all other operations
    window.AutomationData = window.AutomationData || {};
    window.AutomationData[operation] = window.AutomationData[operation] || [];
    window.AutomationData[operation].push({ req, resp });
  } catch (err) {
    console.error(err);
  }
};

export const logServiceCallToNewRelic = ({ operation, req, resp, errors = [] }) => {
  try {
    req = (typeof req === 'object') ? obfuscatePii({ ...req }) : (req || '');
    resp = (typeof resp === 'object') ? obfuscatePii({ ...resp }) : (resp || '');
    publishNewRelicPageAction({
      actionName: 'serviceCall',
      actionObject: {
        queryName: operation,
        errorCode: getErrorCode(errors),
        resp,
        req,
      }
    });
  } catch (error) {
    console.error('Error in logServiceCall', error);
  }
};

export const logServiceCallToNewRelicAndLogDataForAutomation = (resp, operation, props) => {
  try {
    const error = resp?.errors || resp?.error;

    const baseNewRelicProps = {
      operation,
      req: props,
      resp
    };
    const newRelicPropOverrides = error ? {
      resp: getCurrentResponse(operation),
      errors: getDisplayableErrors(error, operation, props)
    } : {};

    const baseAutomationProps = {
      operation,
      req: props || {},
      resp
    };
    const automationPropOverrides = error ? { resp: error } : {};
    logServiceCallToNewRelic({ ...baseNewRelicProps, ...newRelicPropOverrides });
    logDataForAutomation({ ...baseAutomationProps, ...automationPropOverrides });
  } catch (err) {
    console.error(err);
  }
};