import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Paper = React.forwardRef((props, ref) => {

  const {
    component: PaperRoot = 'div',
    className,
    shadow = 'none',
    square = false,
    variant = 'shadow',
    ...other
  } = props;

  const classes = classNames('sui-paper', {
    'sui-shadow-none': variant === 'shadow' && shadow === 'none',
    'sui-shadow-base': variant === 'shadow' && shadow === 'base',
    'sui-shadow-sm': variant === 'shadow' && shadow === 'sm',
    'sui-shadow-md': variant === 'shadow' && shadow === 'md',
    'sui-shadow-lg': variant === 'shadow' && shadow === 'lg',
    'sui-shadow-xl': variant === 'shadow' && shadow === 'xl',
    'sui-shadow-2xl': variant === 'shadow' && shadow === '2xl',
    'sui-paper-outlined': variant === 'border',
    'sui-rounded-none': variant === 'border' && square,
    'sui-rounded-base': variant === 'border' && !square
  }, className);

  return (
    <PaperRoot
      className={classes}
      ref={ref}
      {...other}
    />
  );
});

Paper.displayName = 'Paper';

Paper.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * Shadow variant.
   * @default 'none
   */
  shadow: PropTypes.PropTypes.oneOf(['none', 'base', 'sm', 'md', 'lg', 'xl', '2xl']),
  /**
   * If `true`, rounded corners are disabled.
   * @default false
   */
  square: PropTypes.bool,
  /**
   * The variant to use.
   * @default 'shadow'
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['border', 'shadow']),
    PropTypes.string,
  ])
};

export { Paper };