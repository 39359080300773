import React, { Fragment, useState, useCallback } from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';
import {
  ButtonGroup, DrawerBody, DrawerFooter, RatingMeter, Typography, Skeleton, SkeletonBlock
  , CardMedia
} from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';

import { Price } from '@thd-olt-component-react/price';
import { useMessage } from '../../../hooks/useMessage';
import { usePromoDrawerModel } from '../../../hooks/usePromoDrawerModel';
import { useProduct } from '../../../hooks/useProduct';
import { DRAWER_TYPES, MESSAGE_KEY, PRODUCT_CTA_TYPE } from '../../../utils/constants';
import { sanitizeImageUrl } from '../../../utils/promo-utils';
import { AddToCartCta } from '../../AddToCartCta/AddToCartCta';
import { SeeFullDetailsButton } from '../../SeeFullDetailsButton/SeeFullDetailsButton';
import { PromoSelectCta } from '../../PromoSelectCta/PromoSelectCta';
import { QuickviewDrawerDataModel } from '../../../models/QuickviewDrawerDataModel';
import { PromoDrawerHeader } from './subcomponents/PromoDrawerHeader';
import { QuickviewButton } from './subcomponents/QuickviewButton';
import { usePromoModel } from '../../../hooks/usePromoModel';

export const QuickviewDrawer = ({ onBack, onClose, onDrawerViewChange }) => {
  const storeId = useStoreId();
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const { quickviewItemDisplayPosition, quickviewItemId } = usePromoDrawerModel();
  const { type, anchorItemId } = usePromoModel();
  const {
    isSoldOut,
    productCtaType,
    selected,
    selectProduct
  } = useProduct(quickviewItemId, quickviewItemDisplayPosition);
  const title = useMessage(MESSAGE_KEY.title);
  const quickviewOverviewButton = useMessage(MESSAGE_KEY.quickviewOverviewButton);
  const quickviewSpecsButton = useMessage(MESSAGE_KEY.quickviewSpecsButton);
  const quickviewReviewsButton = useMessage(MESSAGE_KEY.quickviewReviewsButton);

  const { data } = useDataModel('product', {
    variables: {
      itemId: quickviewItemId,
    },
  });

  const images = data?.product?.media?.images || [];
  const brandName = data?.product?.identifiers?.brandName || '';
  const productLabel = data?.product?.identifiers?.productLabel || '';
  const averageRating = Number(data?.product?.reviews?.ratingsReviews?.averageRating || 0);
  const totalRatings = String(data?.product?.reviews?.ratingsReviews?.totalReviews || 0);
  const isBogoCardAnchorSku = type !== 'card' && quickviewItemId !== anchorItemId;
  const showAddToCartCta = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.addToCart;
  const showPromoSelectButton = isBogoCardAnchorSku && productCtaType === PRODUCT_CTA_TYPE.select;

  const lazyLoadCallback = useCallback(() => {
    setIsLoadingImage(false);
  }, []);

  const skeleyClass = classNames('sui-w-full', 'sui-h-full', {
    'sui-hidden': !isLoadingImage,
  });

  return (
    <>
      <PromoDrawerHeader onBack={onBack} onClose={onClose} title={title} />
      <DrawerBody>
        <section className="sui-flex sui-flex-col sui-gap-2">
          <Typography variant="h5">{brandName}</Typography>
          <Typography>{productLabel}</Typography>
          <Carousel disableMargin disableShadow type="hero">

            {images.map((image, i) => (
              <Fragment key={`image-${i}`}>
                <div className={skeleyClass}>
                  <Skeleton disablePadding SkeletonContentProps={{ grow: true }}>
                    <SkeletonBlock />
                  </Skeleton>
                </div>
                <CardMedia
                  onLoad={lazyLoadCallback}
                  lazy
                  src={sanitizeImageUrl(image.url)}
                  alt={image.type}
                  style={{ width: '384px', height: '384px' }}
                />
              </Fragment>
            ))}
          </Carousel>
          <Price
            large
            itemId={quickviewItemId}
            storeId={storeId}
            displayEachUom={false}
            basic
            hideBadge
            hidePromotions
            hideBulkPrice
            disableRangePricing
            isSignInToolTip
          />
        </section>
        <div className="sui-flex sui-flex-col sui-gap-2">
          <QuickviewButton onClick={() => onDrawerViewChange(DRAWER_TYPES.QUICKVIEW_OVERVIEW_DRAWER)}>
            {quickviewOverviewButton}
          </QuickviewButton>
          <QuickviewButton onClick={() => onDrawerViewChange(DRAWER_TYPES.QUICKVIEW_SPECS_DRAWER)}>
            {quickviewSpecsButton}
          </QuickviewButton>
          <QuickviewButton onClick={() => onDrawerViewChange(DRAWER_TYPES.QUICKVIEW_REVIEWS_DRAWER)}>
            {quickviewReviewsButton}
            <RatingMeter
              component="div"
              label={`(${totalRatings})`}
              role="presentation"
              tabIndex={-1}
              value={averageRating}
            />
          </QuickviewButton>
        </div>
      </DrawerBody>
      <DrawerFooter data-testid="quickview-drawer-footer">
        <ButtonGroup>
          <SeeFullDetailsButton fullWidth itemId={quickviewItemId} />
          {showPromoSelectButton ? (
            <PromoSelectCta
              isSoldOut={isSoldOut}
              itemId={quickviewItemId}
              onSelect={selectProduct}
              selected={selected}
            />
          ) : <div hidden />}
          {showAddToCartCta ? (
            <AddToCartCta
              align="center"
              displayPosition={quickviewItemDisplayPosition}
              itemId={quickviewItemId}
            />
          ) : <div hidden />}
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

QuickviewDrawer.displayName = 'QuickviewDrawer';

QuickviewDrawer.propTypes = {
  onBack: func.isRequired,
  onClose: func.isRequired,
  onDrawerViewChange: func.isRequired,
};

QuickviewDrawer.dataModel = QuickviewDrawerDataModel;
