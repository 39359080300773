/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { LoyaltyPerksDrawerModel } from '../common/models/UserInfoModels';
import { windowExists } from '../common/utils/AccountUtils';
import {
  useFetchInfoPerks,
  useFetchInfoOffers,
  useFetchActivatePerks,
  useFetchRedeemPerks,
} from '../hooks';

export const PacDrawerContext = createContext({});

export const PacDrawerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [drawerType, setDrawerType] = React.useState('');
  const [eventOutput, setEventOutput] = React.useState({});
  const { channel } = React.useContext(ExperienceContext);
  const isTablet = channel === 'tablet';
  const isMobile = useBreakpoint('sm').lesser;
  const isDesktop = useBreakpoint('lg').greater && (window?.innerWidth !== 1024) && !isTablet;
  const enablePerkAmountRemoval = ['1', 1].includes(
    useConfigService('ENABLE_PERK_AMOUNT_REMOVAL'),
  );

  // name of this event up for discussion
  const drawerEvent = (event) => {
    if (event.eventName === 'close-pac-drawer') setOpen(false);
    else {
      setDrawerType(event.eventName);
      setEventOutput(event.output);
      setOpen(true);
    }
  };

  useEffect(() => {
    [
      'choose-perk-drawer',
      'perk-details-drawer',
      'reward-barcode-drawer',
      'perk-selection-drawer',
      'close-pac-drawer',
    ].forEach((eventStr) => {
      if (windowExists()) {
        window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(
          `loyalty-benefits.${eventStr}`,
          drawerEvent,
        );
      }
    });

    return () => {
      [
        'choose-perk-drawer',
        'perk-details-drawer',
        'reward-barcode-drawer',
        'perk-selection-drawer',
      ].forEach((eventStr) => {
        if (windowExists()) {
          window.LIFE_CYCLE_EVENT_BUS.lifeCycle.off(
            `loyalty-benefits.${eventStr}`
          );
        }
      });
    };
  },
  []);

  const { isLoadingInfoPerks, infoPerksError, infoPerksData, refreshInfoPerks, getPerksResponse } = useFetchInfoPerks();
  // const { isLoadingInfoOffers, infoOffersError, infoOffersData, refreshInfoOffers, getOffersResponse } = useFetchInfoOffers();
  const { isLoadingRedeemPerks, redeemPerksError, redeemPerksData, refreshRedeemPerks } = useFetchRedeemPerks();
  const { isLoadingActivatePerks, activatePerksError, activatePerksData, refreshActivatePerks } = useFetchActivatePerks();

  // const initiateInfoPerks = open && !infoPerksData; // TODO: Modify to check if perk object is passed in from event bus
  // useEffect(() => {
  //   if (initiateInfoPerks && getPerksResponse) getPerksResponse();
  // }, [initiateInfoPerks]);

  const contextValue = {
    open,
    setOpen,
    drawerType,
    setDrawerType,
    eventOutput,
    setEventOutput,
    isMobile,
    isDesktop,
    infoPerks: {
      isLoadingInfoPerks, infoPerksError, infoPerksData, refreshInfoPerks, getPerksResponse
    },
    // infoOffers: { isLoadingInfoOffers, infoOffersError, infoOffersData, refreshInfoOffers, getOffersResponse },
    infoOffers: { // TODO: Use above once "offerType=offer" endpoint/model figured out
      isLoadingInfoOffers: isLoadingInfoPerks,
      infoOffersError: infoPerksError,
      infoOffersData: infoPerksData,
      refreshInfoOffers: refreshInfoPerks,
      getOffersResponse: getPerksResponse,
    },
    useFetchActivatePerks,
    useFetchRedeemPerks,
    enablePerkAmountRemoval,
  };

  return (
    <PacDrawerContext.Provider value={useMemo(() => contextValue,
      [open, isMobile, isLoadingInfoPerks, isLoadingActivatePerks, isLoadingRedeemPerks, eventOutput])}
    >{children}
    </PacDrawerContext.Provider>
  );
};

PacDrawerProvider.displayName = 'Loyalty Drawer';
PacDrawerProvider.dataModel = LoyaltyPerksDrawerModel;
