import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, Typography, IconButton } from '@one-thd/sui-atomic-components';
import { Close } from '@one-thd/sui-icons';

export const CustomTooltip = ({
  children,
  pricingMessage,
  color,
  PricingIcon = null,
  title = null
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sui-relative">
      {PricingIcon && (
        <div className="sui-cursor-pointer">
          <PricingIcon ref={anchorRef} color={color} size="small" onClick={handleToggle} />
        </div>
      )}
      {pricingMessage && (
        <Typography component="span" variant="body-sm" onClick={handleToggle} color={color}>
          <span ref={anchorRef} className="sui-underline sui-m-0 sui-cursor-pointer">
            {pricingMessage}
          </span>
        </Typography>
      )}
      {open && (
        <Popover
          title={title}
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          style={{ zIndex: 9999 }}
          disableEnforceFocus
        >
          <div className="sui-flex sui-text-left sm:sui-max-w-[300px] sui-w-full">
            <Typography ref={contentRef} variant="body-sm" weight="regular">{children}</Typography>
            {!title && (
              <div className="sui-ml-2">
                <IconButton aria-label="close" onClick={handleToggle} icon={Close} />
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.node.isRequired
};