import { createTheme } from '@one-thd/sui-atomic-components';

const EndcapDarkGreen = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  }
});

EndcapDarkGreen.palette['--sui-palette-background-primary'] = '73 121 50';
EndcapDarkGreen.palette['--sui-palette-button-background-primary'] = '255 255 255';
EndcapDarkGreen.palette['--sui-palette-text-primary'] = '255 255 255';
EndcapDarkGreen.palette['--sui-palette-text-subtle'] = '255 255 255';

export { EndcapDarkGreen };