import React from 'react';
import Responsive from 'react-responsive';
import { ArrowForward } from '@one-thd/sui-icons';
import { Image } from '@thd-olt-component-react/core-ui';
import { Typography } from '@one-thd/sui-atomic-components';
import { getHostCURL, getImageURL } from '../../../../common/utils/CardUtils';
import { analyticsStash } from '../../../../common/utils/AnalyticsUtils';

const eliteSupportLine = 'Elite Support Line';

export const WelcomeCardTile = (props) => {
  const {
    imageURL,
    imageClass,
    analyticsMessage,
    analyticsLinks,
    refLink,
    altText,
    linkText,
    children,
  } = props;

  let imgClass = imageClass || '';
  const icon = (
    <div className={`notification-image sui-mt-1 ${imgClass}`}>
      <Responsive minWidth={1024}>
        <Image src={getImageURL(imageURL)} width="48px" height="48px" alt={altText} />
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Image src={getImageURL(imageURL)} width="40px" height="40px" alt={altText} />
      </Responsive>
      <Responsive maxWidth={767}>
        <Image src={getImageURL(imageURL)} width="48px" height="48px" alt={altText} />
      </Responsive>
    </div>
  );

  let linkURL = getHostCURL(refLink);
  if (altText === eliteSupportLine) {
    linkURL = refLink;
  }

  return (
    <WelcomeCardTileDynamicIcon
      icon={icon}
      analyticsMessage={analyticsMessage}
      analyticsLinks={analyticsLinks}
      refLink={linkURL}
      linkText={linkText}
    >
      {children}
    </WelcomeCardTileDynamicIcon>
  );
};

// (eliteSupportLine === 'Elite Support Line' ? handleTileAnalyticsButton('elite support line: phone number') :
export const WelcomeCardTileDynamicIcon = (props) => {
  const {
    analyticsMessage, analyticsLinks, refLink, icon, linkText, children
  } = props;
  return (
    <div className="sui-w-full">
      <a
        className="welcome-card-tile link-url sui-flex sui-flex-row sui-justify-between sui-w-full sui-px-0"
        href={refLink}
        rel=" noreferrer"
        onClick={() => analyticsMessage && analyticsStash('welcomeCard', analyticsMessage, analyticsLinks)}
      >
        {icon}
        <div className="sui-flex sui-flex-col sui-justify-center startAlign sui-mx-3 sui-w-full">
          {children}
          <Responsive minWidth={1024}>
            <Typography variant="body-base" weight="regular" height="tight" decoration="underline">
              {linkText}
            </Typography>
          </Responsive>
        </div>
        <div className="sui-flex sui-flex-row sui-justify-end sui-items-center sui-pr-0">
          <ArrowForward size="small" />
        </div>
      </a>
    </div>
  );
};
