import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useTile from '../private/components/tile/useTile';

/**
 * The `TileMedia` component can be used to display a media (img or picture) element with `square`
 * [aspect ratio](https://tailwindcss.com/docs/aspect-ratio) inside a `Tile` component.
 *
 * It renders a diagonal line on `unavailable` tiles, unless the `disableDiagonal` prop is set to `true`.
 *
 * Related components: [Tile](#tile), [TileContent](#tilecontent), [TileText](#tiletext).
 *
 * Usage:
 *
 * ```jsx
 * import { TileMedia } from '@one-thd/sui-atomic-components';
 * ```
 */
const TileMedia = React.forwardRef((props, ref) => {
  const {
    children,
    src,
    component: Component = 'img',
    disableDiagonal = false,
    height,
    ...other
  } = props;

  const { unavailable, disabled } = useTile();

  const diagonal = unavailable && !disableDiagonal ? (
    <svg
      data-testid="DiagonalTileMedia"
      viewBox="0 0 100 100"
      focusable="false"
      aria-hidden="true"
      preserveAspectRatio="none"
      style={{
        overflow: 'visible',
        stroke: 'inherit',
        width: '100%',
        height: '100%',
      }}
    >
      <path d="M-2 100 100-2 102 0 0 102Z" />;
    </svg>
  ) : null;

  const rootClasses = classNames('sui-relative sui-stroke-inverse sui-fill-primary', {
    'sui-opacity-25': disabled
  });

  const mediaClasses = classNames('sui-object-cover sui-aspect-square', {
    'sui-opacity-25': disabled,
    'sui-opacity-50': unavailable,
    'sui-h-4': height === '4',
    'sui-h-5': height === '5',
    'sui-h-6': height === '6',
    'sui-h-7': height === '7',
  });

  return (
    <div className={rootClasses}>
      <Component
        className={mediaClasses}
        src={src}
        ref={ref}
        {...other}
      />
      {children}
      {diagonal && (
        <div className="sui-absolute sui-top-0 sui-left-0">
          {diagonal}
        </div>
      )}
    </div>
  );
});

TileMedia.displayName = 'TileMedia';

TileMedia.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Applies the height styles.
   */
  height: PropTypes.oneOf([
    '4',
    '5',
    '6',
    '7'
  ]),
  /**
   * Route to the media resource.
   */
  src: PropTypes.string,
  /**
   * Root component to be used to contain the media. By default, an 'img' component is used.
   */
  component: PropTypes.elementType,
  /**
   * If true, the image's dimensions will be reduced to fit a swatch use case.
   */
  swatch: PropTypes.bool,
  /**
   * If `true` the component will never show a diagonal if unavailable.
   */
  disableDiagonal: PropTypes.bool,
};

export { TileMedia };