/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardTitle,
  CardMedia,
  CardBody,
  CardActions,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Typography,
  Link,
  Button,
  ButtonGroup
} from '@one-thd/sui-atomic-components';
import { clickEvent, impressionEvent } from '../../utils/publisher';
import { CARD_IMAGE, DEFAULT_PREQUAL_URL } from '../../utils/constants';
import { loadEstimatorScript, getPrequalOfferLink } from '../../utils/payment-estimator-utils';
import { getUserName } from '../../utils/general';
import { fetchDeclinePrequalOffer } from '../../utils/api';

const Prequal = ({
  prequalInvitationNumber,
  paymentEstimatorVersion,
  showApplyNow,
  isMobile,
}) => {
  const [prequalLink, setPrequalLink] = useState(
    DEFAULT_PREQUAL_URL
  );

  // On click Learn More -- open Drawer
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleOpenDrawer = () => setOpenDrawer(true);
  const handleCloseDrawer = () => setOpenDrawer(false);

  const analyticsInteraction = () => {
    clickEvent('plcc-check prequal', 'card');
  };

  const onClickNoThanks = () => {
    fetchDeclinePrequalOffer(prequalInvitationNumber, isMobile);
    showApplyNow();
  };

  const generateCardHeadingText = () => {
    const userFirstName = getUserName();
    const offerText = 'GET UP TO $100 OFF*';
    return !userFirstName
      ? offerText
      : `${userFirstName.toUpperCase()}, ${offerText}`;
  };

  const LegalText = () => {
    return (
      <div className="sui-border-solid sui-border-1 sui-border-primary sui-p-1">
        <Typography variant="body-xs">
          You can choose to stop receiving &quot;prescreened&quot; offers of credit from
          this and other companies by calling toll-free 1-888-5-OPTOUT
          (1-888-567-8688). See <u>PRESCREEN & OPT-OUT NOTICE</u> in the Terms
          and Conditions for more information about prescreened offers.
        </Typography>
      </div>
    );
  };

  const CardImage = () => {
    return (
      <div className="sui-flex sui-place-content-center sui-bg-subtle sui-px-10 sui-py-6">
        <CardMedia
          className="sui-object-fill"
          src={CARD_IMAGE.PLCN_HOMEDEPOT}
          alt="Credit Card Icon"
          aspect="wide"
        />
      </div>
    );
  };

  // componentDidMount
  useEffect(() => {
    loadEstimatorScript(
      paymentEstimatorVersion,
      () => setPrequalLink(getPrequalOfferLink(isMobile, 'opc', prequalInvitationNumber)),
      isMobile
    );
    impressionEvent('plcc-check prequal', 'card');
  }, []);

  return (
    <CardContent
      className="sui-flex sui-flex-col sui-gap-4 sui-h-full sui-w-full"
      data-testid={openDrawer ? 'PrequalifedModalOpen' : 'PrequalifedModalClose'}
      data-component="Prequal"
    >
      <CardTitle
        disableTypography
        header={(
          <Typography variant="h3">You&apos;re Pre-Qualified!</Typography>
        )}
      />
      <CardImage />
      <CardBody>
        <div className="sui-flex sui-flex-col sui-gap-4">
          <Typography variant="body-base" weight="bold">
            {generateCardHeadingText()}
          </Typography>
          <Typography variant="body-base">
            Get up to $100 OFF* your qualifying purchase and 6-month
            everyday financing** on purchases over $299.
            <div>
              <Link
                component="button"
                onClick={handleOpenDrawer}
              >
                Learn More
              </Link>
            </div>
          </Typography>
          <LegalText />
        </div>
      </CardBody>
      <CardActions className="sui-flex sui-flex-col sui-items-center sui-gap-4">
        <Button variant="secondary" href={prequalLink} onClick={analyticsInteraction} fullWidth>Apply Now</Button>
        <Button variant="text" onClick={onClickNoThanks}>No Thanks</Button>
      </CardActions>
      <Drawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        position="right"
      >
        <DrawerHeader title="You&apos;re Prequalified!" onClose={handleCloseDrawer} />
        <div className="sui-flex sui-flex-col sui-justify-between sui-h-full">
          <DrawerBody>
            <div className="sui-flex sui-flex-col sui-gap-4">
              <div className="sui-m-auto sui-px-6 sui-max-w-xs">
                <CardImage />
              </div>
              <Typography variant="body-base">
                You&apos;re been pre-qualified for a Home Depot Consumer Card.
              </Typography>
              <Typography variant="body-base" weight="bold">
                {generateCardHeadingText()}
              </Typography>
              <Typography variant="body-base">
                Get $25 off* purchases of $25-$299 or $50 off* purchases of $300-$999 or $100 off* purchases of $1,000
                or more* on your next qualifying purchase when you open a new card. Plus, receive 6-months everyday
                financing** on purchases over $299 when you use your Consumer Card.
              </Typography>
              <LegalText />
              <div className="sui-grow">
                <Typography variant="body-base" weight="bold">
                  Terms & Conditions
                </Typography>
                <ul className="sui-list-disc sui-list-outside sui-mt-2 sui-pl-4">
                  <li>
                    Offer is for new accounts, is subject to credit approval and valid 1/30-7/29/2021.
                    This offer is redeemable for $25 off your purchase of $25-$299, $50 off your
                    purchase of $300-$999 or $100 off your purchase of $1,000 or more
                    on a single receipt within 30 days of your account open date at
                    The Home Depot® retail stores or homedepot.com when made with your
                    The Home Depot Consumer Credit Card, The Home Depot Home Improver Card,
                    The Home Depot Commercial Revolving Charge or The Home Depot Commercial Account.
                    Valid in the U.S., U.S.V.I., Puerto Rico and Guam. Offer does not apply to prior purchases,
                    The Home Depot Gift Cards or Certificates, third party gift cards,
                    installation products or services purchased in home,
                    Tool Rental or to Weber®, Bona®, Simplehuman®, Dacor®, Viking®,
                    Fisher & Paykel®, Sharp Insight™, Delonghi®, Buckhaven,
                    Lynx®, Alfresco™, OCI, Marvel®, Bertazzoni, Aga, Vent-A-Hood®, Samsung Chef Collection,
                    Bosch Benchmark® Series, Liebherr, Zephyr, Miele,
                  </li>
                </ul>
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <ButtonGroup orientation="vertical">
              <Button variant="primary" href={prequalLink} onClick={analyticsInteraction}>Apply Now</Button>
              <Button variant="secondary" onClick={onClickNoThanks}>No Thanks</Button>
            </ButtonGroup>
          </DrawerFooter>
        </div>
      </Drawer>
    </CardContent>
  );
};

Prequal.propTypes = {
  paymentEstimatorVersion: PropTypes.string,
  prequalInvitationNumber: PropTypes.string,
  showApplyNow: PropTypes.func,
  isMobile: PropTypes.bool
};
Prequal.defaultProps = {
  paymentEstimatorVersion: 'v1.0.36',
  prequalInvitationNumber: null,
  showApplyNow: null,
  isMobile: false,
};

export default Prequal;
