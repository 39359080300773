/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// import { useDataModel } from '@thd-nucleus/data-sources';
import { useEffect, useState, useRef } from 'react';
import { useDataModel, useLazyDataModel } from '@thd-nucleus/data-sources';
import { useConfigService } from '@thd-nucleus/experience-context';
import { shouldUseMcc, useMccGetCart } from './useMccCart';
import { patchResult } from '../transformers/patchDataModel';
import { shouldUseAutomation, useAutomationGetCart } from './useAutomationModel';
import { getCartQueryAndResponse } from './cartResponseDataModel';
import { useHandleFailures } from './useHandleFailures';
import { useCaptureRequestEvent } from '../automation-lib/AutomationUtil';
import { optimalOverride, getTmxId, getExchangeCartId, getIsBrandPricingPolicyCompliant } from '../util/utils';

if (typeof window !== 'undefined') {
  window.injectMockData = (mockData) => {
    window.__cart_item_mockData = mockData;
  };
}

let RAW_CART_RESP = null;
let CURRENT_CART = null;
let CURRENT_ERRORS = null;
let PATCHED = false;

// eslint-disable-next-line import/no-mutable-exports
const useCartInfoGetCart = (props = {}) => {
  const isSetOldServiceChargeEnabled = !!useConfigService('fs:setOldServiceChargeEnabled');
  let response = useDataModel('getCart', {
    ssr: false,
    dataModel: getCartQueryAndResponse,
    ...props,
    variables: { requestContext: {
      ...optimalOverride('getCart'),
      ...(getExchangeCartId() && { cartId: getExchangeCartId() }),
      ...(getTmxId() && { tmxProfileId: getTmxId() }),
      isBrandPricingPolicyCompliant: getIsBrandPricingPolicyCompliant()
    } }
  });

  // response = useHandleFailures(response);

  let { data, loading, error } = response;

  const refetch = () => {
    CURRENT_ERRORS = null;
    return response.refetch();
  };

  data = patchRetrievedData(data, error, isSetOldServiceChargeEnabled);
  return {
    ...response,
    data,
    loading,
    error,
    refetch
  };
};

const useLazyGetCart = () => {
  const [getCart, cartData] = useLazyDataModel('getCart', {
    ssr: false,
    dataModel: getCartQueryAndResponse,
    fetchPolicy: 'no-cache',
    variables: {
      requestContext: {
        ...optimalOverride('getCart'),
        ...(getExchangeCartId() && { cartId: getExchangeCartId() }),
        ...(getTmxId() && { tmxProfileId: getTmxId() }),
        isBrandPricingPolicyCompliant: getIsBrandPricingPolicyCompliant()
      },
    },
    onCompleted: (data) => {
      promiseResolver.current(data);
    },
    onError: (error) => {
      promiseResolver.current(error);
    }
  });

  const promiseResolver = useRef();
  const getCartData = () => {
    return new Promise((resolve, reject) => {
      promiseResolver.current = resolve;
      getCart();
    });
  };

  return [getCartData, cartData];
};

const patchRetrievedData = (data, error, isSetOldServiceChargeEnabled = false) => {
  try {
    RAW_CART_RESP = data ? JSON.parse(JSON.stringify(data)) : data;
    if (data) {
      // CURRENT_ERRORS = error;
      data = patchResult(data, error || CURRENT_ERRORS, isSetOldServiceChargeEnabled);
      if (!CURRENT_CART) {
        setCartDataAndErrorsForAutomatedTests();
      }
    }
    CURRENT_CART = data;
  } catch (err) {
    console.error(err);
  }
  return data;
};

export const getCart = () => (CURRENT_CART);
export const getErrors = () => (CURRENT_ERRORS);
export const getRawCartResp = () => (RAW_CART_RESP);
export const setRawCartResp = (cart) => { RAW_CART_RESP = cart; };
export const updateErrors = (errors) => {
  CURRENT_ERRORS = errors;
  PATCHED = false;
  setCartDataAndErrorsForAutomatedTests();
};
export const resetPatched = () => {
  PATCHED = false;
};

const useCartDataModel = (props) => {
  const isSetOldServiceChargeEnabled = !!useConfigService('fs:setOldServiceChargeEnabled');
  let useGetCart = useCartInfoGetCart;
  if (shouldUseMcc()) {
    useGetCart = (_props) => {
      const { data, loading, error } = useMccGetCart(_props);
      return { data: patchRetrievedData(data, error, isSetOldServiceChargeEnabled), loading, error };
    };
  }
  if (shouldUseAutomation()) {
    useGetCart = (_props) => {
      const { data, loading, error } = useAutomationGetCart(_props);
      return { data: patchRetrievedData(data, error, isSetOldServiceChargeEnabled), loading, error };
    };
  }
  return useGetCart(props);
};

const setCartDataAndErrorsForAutomatedTests = () => {
  try {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.cartResponse = {
          data: (CURRENT_CART ? JSON.parse(JSON.stringify(CURRENT_CART)) : null),
          errors: ((typeof CURRENT_ERRORS !== 'undefined') ? JSON.parse(JSON.stringify(CURRENT_ERRORS)) : null),
        };
      }, 10);
    }
  } catch (err) {
    console.error(err);
  }
};
export { useCartDataModel, useLazyGetCart };
