import React from 'react';
import {
  arrayOf, shape, string, number, bool, elementType
} from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Hydrator } from '@thd-olt-component-react/hydrator';

import {
  TableItemsPlaceholder,
  CardItemsPlaceholder,
} from '../../placeholders/orderDetailsLoading';
import {
  CardOrderItemThumbnails,
  TableOrderItemThumbnails,
} from './OrderItemThumbnails';

export function OrderItems({
  customerInfo = {},
  isOnline = false,
  layout = undefined,
  order = {},
  OrderDetailsType = undefined,
  orderDetailsUrl = undefined,
  windowWidth = undefined,
}) {
  const id = isOnline ? order.orderNumbers[0] : order.receiptDetails;
  const detailsSource = 'orderDetails';

  return (
    <QueryProvider cacheKey={`recent-purchases-status-${id}`}>
      <OrderDetailsType order={order} customerInfo={customerInfo}>
        {({ data, loading }) => {
          if (layout === 'card') {
            return loading ? (
              <CardItemsPlaceholder />
            ) : (
              <CardOrderItemThumbnails
                items={
                  data?.[detailsSource]?.lineItems
                    ? data[detailsSource]?.lineItems
                    : []
                }
                windowWidth={windowWidth}
                href={orderDetailsUrl}
              />
            );
          }

          // layout === 'table'
          return loading ? (
            <TableItemsPlaceholder />
          ) : (
            <TableOrderItemThumbnails
              items={
                data?.[detailsSource]?.lineItems
                  ? data[detailsSource]?.lineItems
                  : []
              }
              windowWidth={windowWidth}
              href={orderDetailsUrl}
            />
          );
        }}
      </OrderDetailsType>
    </QueryProvider>
  );
}

export function LazyOrderItems({
  customerInfo = {},
  isOnline = false,
  layout = undefined,
  order = {},
  OrderDetailsType = undefined,
  orderDetailsUrl = undefined,
  windowWidth = undefined,
  index = undefined,
}) {
  const id = isOnline ? order.orderNumbers[0] : order.receiptDetails;
  const detailsSource = 'orderDetails';

  return (
    <Hydrator
      id={`recent-purchases-items-hydrator-${id}-${layout}-${index}`}
      placeholder={
        layout === 'card' ? <CardItemsPlaceholder /> : <TableItemsPlaceholder />
      }
      delay={1200}
    >
      <OrderItems
        order={order}
        customerInfo={customerInfo}
        layout={layout}
        detailsSource={detailsSource}
        OrderDetailsType={OrderDetailsType}
        orderDetailsUrl={orderDetailsUrl}
        windowWidth={windowWidth}
        isOnline={isOnline}
      />
    </Hydrator>
  );
}

OrderItems.propTypes = {
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  isOnline: bool,
  layout: string,
  order: shape({
    salesDate: string,
    orderNumber: string,
    orderNumbers: arrayOf(string),
    totalAmount: number,
    storeName: string,
    accountIdUsed: string,
    accountIdType: string,
    showReceiptImage: bool,
    receiptDetails: string,
    registerNumber: number,
    orderOrigin: string,
    rewardsDiscountAmount: number,
    otherDiscountAmount: number,
    preTaxAmount: number,
    receiptAddedDate: string,
    showBigReceipt: bool,
    purchaserId: string,
    detailsSource: string,
    invoiceNumbers: number,
    invoiceNumbersForCSV: number,
    isGiftCardPerksUsed: bool,
    preferredDiscFlg: string,
    transactionId: number,
    POJobName: string,
  }),
  OrderDetailsType: elementType,
  orderDetailsUrl: string,
  windowWidth: number,
};


LazyOrderItems.propTypes = {
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  isOnline: bool,
  layout: string,
  order: shape({
    salesDate: string,
    orderNumber: string,
    orderNumbers: arrayOf(string),
    totalAmount: number,
    storeName: string,
    accountIdUsed: string,
    accountIdType: string,
    showReceiptImage: bool,
    receiptDetails: string,
    registerNumber: number,
    orderOrigin: string,
    rewardsDiscountAmount: number,
    otherDiscountAmount: number,
    preTaxAmount: number,
    receiptAddedDate: string,
    showBigReceipt: bool,
    purchaserId: string,
    detailsSource: string,
    invoiceNumbers: number,
    invoiceNumbersForCSV: number,
    isGiftCardPerksUsed: bool,
    preferredDiscFlg: string,
    transactionId: number,
    POJobName: string,
  }),
  OrderDetailsType: elementType,
  orderDetailsUrl: string,
  windowWidth: number,
  index: number,
};
