import React from 'react';
import { string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export function TableStatusMessage({ orderStatus = null }) {
  if (!orderStatus) {
    return <div className="sui-h-20" data-testid="orderStatus" />;
  }

  return (
    <div
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden sui-w-24 sui-h-20"
      data-testid="orderStatus"
    >
      <Typography variant="body-base">{orderStatus}</Typography>
    </div>
  );
}

TableStatusMessage.propTypes = {
  orderStatus: stringType,
};

export function CardStatusMessage({ orderStatus = null }) {
  if (!orderStatus) {
    return <div className="sui-mb-3" data-testid="orderStatus" />;
  }

  return (
    <div className="sui-mb-3" data-testid="orderStatus">
      <Typography variant="body-base">{orderStatus}</Typography>
    </div>
  );
}

CardStatusMessage.propTypes = {
  orderStatus: stringType,
};
