import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '../private/components/button/ButtonBase';

/**
 * A standalone icon button that uses an icon without a text label.
 *
 * See available icons in the
 * [Icon section](https://brand.homedepot.com/site/knapsack-nucleus/latest/pattern/icons?subPageId=DESIGN)
 *
 * Related components: [ButtonGroup](#buttongroup), [Button](#button), [LoadingButton](#loadingbutton),
 * [SvgIcon](#svgicon).
 *
 * Usage:
 *
 * ```jsx
 * import { IconButton } from '@one-thd/sui-atomic-components';
 * ```
 */
const IconButton = React.forwardRef((props, ref) => {

  const {
    disabled,
    icon: Icon,
    color = 'primary',
    iconSize = 'small',
    buttonSize = 'normal',
    edge = false,
    ...rest
  } = props;

  const focusStyles = 'focus-visible:sui-bg-button-focus focus-visible:sui-outline-none focus-visible:sui-fill-primary focus-visible:sui-rounded-base';

  const hoverStyles = classNames('hover:sui-rounded-base', {
    'hover:sui-bg-button-hover-subtle': !disabled
  });
  const colorStyles = disabled ? 'sui-fill-subtle' : classNames({
    'sui-fill-primary': color === 'primary',
    'sui-fill-subtle': color === 'subtle',
    'sui-fill-inverse': color === 'inverse',
    'sui-fill-medium': color === 'medium',
    'sui-fill-brand': color === 'brand',
    'sui-fill-brand-subtle': color === 'brand-subtle',
    'sui-fill-success': color === 'success',
    'sui-fill-success-strong': color === 'success-strong',
    'sui-fill-warning': color === 'warning',
    'sui-fill-warning-strong': color === 'warning-strong',
    'sui-fill-danger': color === 'danger',
    'sui-fill-danger-strong': color === 'danger-strong',
    'sui-fill-info': color === 'info',
    'sui-fill-info-strong': color === 'info-strong'
  });

  const edgeStyles = edge !== false && classNames({
    'sui--ml-1': ((iconSize === 'small' && buttonSize === 'tight') || (iconSize === 'regular' && buttonSize === 'normal')) && edge === 'start',
    'sui--mr-1': ((iconSize === 'small' && buttonSize === 'tight') || (iconSize === 'regular' && buttonSize === 'normal')) && edge === 'end',
    'sui--ml-2': iconSize === 'small' && buttonSize === 'normal' && edge === 'start',
    'sui--mr-2': iconSize === 'small' && buttonSize === 'normal' && edge === 'end',
    'sui--ml-[1.4rem]': iconSize === 'small' && buttonSize === 'relaxed' && edge === 'start',
    'sui--mr-[1.4rem]': iconSize === 'small' && buttonSize === 'relaxed' && edge === 'end',
    'sui--ml-[1rem]': iconSize === 'regular' && buttonSize === 'relaxed' && edge === 'start',
    'sui--mr-[1rem]': iconSize === 'regular' && buttonSize === 'relaxed' && edge === 'end',
    'sui--ml-[0.6rem]': iconSize === 'large' && buttonSize === 'relaxed' && edge === 'start',
    'sui--mr-[0.6rem]': iconSize === 'large' && buttonSize === 'relaxed' && edge === 'end',
  });

  const buttonClasses = classNames('sui-inline-flex sui-items-center sui-justify-center sui-border-none sui-border-0',
    focusStyles,
    hoverStyles,
    {
      'sui-h-6 sui-w-6': buttonSize === 'tight',
      'sui-h-8 sui-w-8': buttonSize === 'normal',
      'sui-h-11 sui-w-11': buttonSize === 'relaxed'
    },
    edgeStyles,
    colorStyles);

  // if (process.env.NODE_ENV !== 'production') {
  //   if (buttonSize === 'tight' && iconSize === 'large') {
  //     console.error('A large icon size is not supported on a tight icon button');
  //   }
  // }
  const sizedIcon = Icon && (<Icon size={iconSize} color="inherit" />);

  return (
    <ButtonBase ref={ref} className={buttonClasses} disabled={disabled} {...rest}>
      {sizedIcon}
    </ButtonBase>
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  /**
   * The icon of the component.
   */
  icon: PropTypes.elementType,
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side. Helpful for aligning the left or right side of the icon
   * with content above or below.
   * @default false
   */
  edge: PropTypes.oneOf(['end', 'start', false]),
  /**
   * The icon color
   * @default 'primary'
   */
  color: PropTypes.oneOf(['primary', 'inverse', 'subtle', 'brand', 'brand-subtle', 'success', 'success-strong', 'danger', 'danger-strong', 'medium', 'warning', 'warning-strong', 'info', 'info-strong']),
  /**
   * Defines the button (container) size
   * @default 'normal'
   */
  buttonSize: PropTypes.oneOf(['tight', 'normal', 'relaxed']),
  /**
   * Defines the icon size
   * @default 'small'
   */
  iconSize: PropTypes.oneOf(['small', 'regular', 'large'])
};

export { IconButton };
