export const getProductReviews = ({
  reviewModels, searchText, selected, initialReviews, itemId, storeId
}) => {
  const hasReviewModels = reviewModels.length || searchText || selected.length;
  let id = itemId || storeId;
  return hasReviewModels ? reviewModels : (initialReviews.current[id] || []);
};

export const onFilterChange = (updatedFilters, { dispatch, useCondensedLayout = false, category = 'product' }) => {
  dispatch({ type: 'filter', value: updatedFilters });
  const searchAndSort = document.getElementById('search-and-sort');
  if (useCondensedLayout) {
    const isHistogramClick = Number.isInteger(updatedFilters);
    const overlay = document.querySelector('.content-card__overlay');
    const backButton = document.getElementsByClassName('content-card__overlay-back');
    const offsetHeight = backButton
      ? document.getElementsByClassName('content-card__overlay-back')[0]?.offsetHeight : 0;
    let scrollToPosition = searchAndSort.getBoundingClientRect()?.top - offsetHeight;

    if (overlay && overlay.scrollTo && isHistogramClick) {
      overlay.scrollTo({ behavior: 'smooth', top: scrollToPosition });
    } else {
      const filters = document.querySelector('.ratings-and-reviews__search-and-filter');
      scrollToPosition = filters.getBoundingClientRect()?.top + window.scrollY;
      window.scrollTo({ behavior: 'smooth', top: scrollToPosition });
    }
  } else {
    const stickyNav = document.getElementById('sticky-nav');
    const currentPosition = window.scrollY || window.pageYOffset;
    if (category === 'product') {
      const scrollToPosition = searchAndSort.getBoundingClientRect().top - stickyNav.offsetHeight + currentPosition;
      window.scroll({ behavior: 'smooth', top: scrollToPosition });
    }
  }
};

export const badgesFilterLogic = (badgesOrder) => {
  let badges = [...badgesOrder];

  if (badges.includes('earlyReviewerIncentive')) {
    // Home Depot Reviewer Program can't be Seed Reviews
    badges = badges.filter((badge) => badge !== 'incentivizedReview');

    // All Home Depot Reviewer Programs are Verified Purchasers
    if (badges.includes('verifiedPurchaser') === false) {
      badges.push('verifiedPurchaser');
    }
  }

  if (badges.includes('incentivizedReview')) {
    // Seed reviews can't be Verified Purchasers
    badges = badges.filter((badge) => badge !== 'verifiedPurchaser');
  }

  return badges;
};

export const getFilterText = (checkbox) => {
  if (checkbox.value === 'verified') {
    return 'Verified Purchases';
  }
  if (checkbox.value === 'current_item_selections') {
    return 'Current Item Selections';
  }
  return `${checkbox.value} Star`;
};

export const getUpdatedTotalPage = (selectedSentiment, totalPages) => {
  return selectedSentiment ? Math.ceil(selectedSentiment?.TotalResults / 10) || 1 : totalPages || 1;
};

export const getReviewStatistics = (persistedStatistics, statistics, itemId) => {
  return persistedStatistics.current && persistedStatistics.current[itemId]
    ? persistedStatistics.current[itemId]
    : statistics;
};

export const getSelectedSentiment = (sentiment, filters) => {
  return sentiment ? [sentiment] : filters.filter((rf) => rf.checked);
};

export const getImageUrl = (media) => {
  const { image, images } = media || {};
  const { sizes } = (images || []).length ? images[0] : {};
  return !!(image?.url && (sizes || []).length)
    && image.url.replace(/1000/, sizes[0]);
};

export const shouldRenderFirstReview = (
  category,
  reviewModels,
  reviewStatistics
) => {
  return category === 'product'
    && (!(reviewModels?.length || reviewStatistics?.currentSku?.FilteredReviewStatistics?.TotalReviewCount));
};

export const shouldRenderFirstReviewDesktop = (
  productReviews,
  persistedStatistics,
  itemId,
  searchText,
  selected
) => {
  return !(productReviews.length || persistedStatistics.current[itemId]?.length || searchText || selected.length);
};

export const shouldNotRenderAccordionBody = (hasProductData, productReviews, persistedStatistics, itemId) => {
  return !(hasProductData || productReviews || persistedStatistics.current[itemId]);
};

export const getSeoLink = (category, canonicalUrl = '', seoStoreLink = '', currentPage = 1) => {
  if (category === 'product') {
    return `${canonicalUrl?.replace('/p', '/p/reviews')}/${currentPage}`;
  }
  if (category === 'store') {
    return `${seoStoreLink}/reviews/${currentPage}`;
  }

  return '';
};

export const shouldRenderWriteAreview = (hasProductData, searchText, productReviews, selected) => {
  return hasProductData && !(searchText || productReviews.length || selected.length);
};

export const shouldNotRender = (error, data, loading, reviewResponse) => {
  return error
  || !data
  || loading
  || reviewResponse.error
  || !reviewResponse.data
  || reviewResponse.loading;
};

export const getProfileInfo = (results, includes) => {
  return {
    numberOfReviews: results?.ReviewStatistics?.TotalReviewCount || 0,
    numberOfQuestions: results?.QAStatistics?.TotalQuestionCount || 0,
    numberOfAnswers: results?.QAStatistics?.TotalAnswerCount || 0,
    allReviews: includes.Reviews ? Object.values(includes?.Reviews) : [],
    allQuestions: includes.Questions ? Object.values(includes?.Questions) : [],
    allAnswers: includes.Answers ? Object.values(includes?.Answers) : [],
    firstReview: results.ReviewStatistics
      ? new Date(results?.ReviewStatistics?.FirstSubmissionTime).toDateString()
      : 'None',
    lastReview: results.ReviewStatistics
      ? new Date(results?.ReviewStatistics?.LastSubmissionTime).toDateString()
      : 'None',
    helpfulVotes: results.ReviewStatistics
      ? results?.ReviewStatistics?.HelpfulVoteCount
      : 0,
    averageRating: results.ReviewStatistics
      ? Math.round(results?.ReviewStatistics?.AverageOverallRating * 10) / 10
      : 0,
  };
};

export const getPageSize = (seoPageNumber, seoReviewsPerPage, pipReviewsPerPage) => {
  return seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;
};

export const getSeoStartIndex = (seoPageNumber, seoReviewsPerPage) => {
  let seoStartIndex = Math.max(((seoPageNumber || 1) - 1) * seoReviewsPerPage, 1);
  return seoPageNumber > 1 ? seoStartIndex + 1 : seoStartIndex;
};

export const getStartIndex = (currentPage, reviewsPerPage) => {
  let startIndex = Math.max((currentPage - 1) * reviewsPerPage, 1);
  return currentPage > 1 ? startIndex + 1 : startIndex;
};

export const shouldRenderPlaceholder = (loading, persistedStatistics, id) => {
  return loading && !persistedStatistics.current[id];
};

const filterValue = (checkedFilters, comparableValue) => {
  return checkedFilters
    .filter((item) => item.id.toString().includes(comparableValue))
    .map((item) => item.value);
};

export const getProfileFilters = (checkedFilters) => {
  const starFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('rating'));
  const starRatings = starFilter ? filterValue(checkedFilters, 'rating') : null;

  const ageFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('age'));
  const ageRange = ageFilter ? filterValue(checkedFilters, 'age') : null;

  const profileFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('profile'));
  const profile = profileFilter ? filterValue(checkedFilters, 'profile') : null;

  const genderFilter = checkedFilters.find((eachFilter) => eachFilter.id.toString().includes('gender'));
  const gender = genderFilter ? filterValue(checkedFilters, 'gender') : null;

  return {
    starRatings,
    ageRange,
    profile,
    gender
  };
};

export const getStarRatings = (filters) => {
  const checkedFilters = filters.filter(
    (eachFilter) => eachFilter.id !== 'verified'
      && eachFilter.id !== 'current_item_selections'
      && eachFilter.checked
  );
  return checkedFilters.length ? checkedFilters.map((filter) => Number(filter.value)) : null;
};

export const getProductReviewsVariables = (
  filters,
  selectedSentiment,
  seoPageNumber,
  seoReviewsPerPage,
  pipReviewsPerPage
) => {
  const verifiedFilter = filters.find((eachFilter) => eachFilter.id === 'verified');
  const currentItemSelectionFilter = filters.find((eachFilter) => eachFilter.id === 'current_item_selections');

  const isVerifiedPurchase = verifiedFilter && verifiedFilter.checked;
  const starRatings = getStarRatings(filters);
  const { ReviewIds } = selectedSentiment || {};
  const reviewsPerPage = seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;
  const excludeFamily = currentItemSelectionFilter?.checked || false;
  let sentimentReviewIds = null;
  if ((ReviewIds || []).length) {
    sentimentReviewIds = ReviewIds.join();
  }

  return {
    reviewsPerPage,
    sentimentReviewIds,
    starRatings,
    isVerifiedPurchase,
    currentItemSelectionFilter,
    excludeFamily
  };
};
