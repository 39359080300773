import React, { useContext } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  ComponentSelector as ComponentSelectorComponent,
} from '@thd-olt-component-react/component-selector';

import { HeroFlattenImage } from '../HeroFlattenImage';
import { Spotlight } from '../Spotlight';
import { HomeDecorCard } from '../HomeDecorCard';

const componentMap = {
  Spotlight,
  HeroFlattenImage,
  HomeDecorCard,
};

export function ComponentSelector({
  id = '',
  defaultComponent = {},
  variations = [],
  renderDefault,
  variationIds,
  mboxes,
  targetingType,
  ...rest
}) {
  const variationsWithAddedProps = variations.map(function (variation) {
    const variationWithAddedProps = { ...rest, ...variation };
    return variationWithAddedProps;
  });

  return (
    <QueryProvider cacheKey={`componentSelector-${id}`}>
      <ComponentSelectorComponent
        defaultComponent={{ ...rest, ...defaultComponent }}
        componentMap={componentMap}
        variations={variationsWithAddedProps || []}
        componentId={id}
        variationIds={variationIds || []}
        renderDefault={renderDefault}
        usePlaceholder
        targetingType={targetingType}
      />
    </QueryProvider>
  );
}

ComponentSelector.propTypes = {
  id: string,
  variations: arrayOf(shape({})),
  defaultComponent: shape({}),
  renderDefault: bool,
  variationIds: arrayOf(shape({})),
  mboxes: arrayOf(string),
  targetingType: shape({}),
};

ComponentSelector.defaultProps = {
  id: undefined,
  variations: [],
  defaultComponent: {},
  renderDefault: undefined,
  variationIds: [],
  mboxes: undefined,
  targetingType: undefined,
};
