import React from 'react';
import { string as stringType, number, shape as shapeType } from 'prop-types';
import { arrayOf } from '@thd-nucleus/data-sources';
import {
  Card, CardBody, CardMedia, Typography, Button
} from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { getImageUrl } from '../utils';

export function CapabilitiesTileRenderer({
  componentClass = '',
  id = undefined,
  componentPosition = 0,
  dataComponent = null,
  componentId,
}) {

  let {
    description,
    link,
    title,
    cta,
    previewImage
  } = dataComponent || {};

  const imageSrcUrl = getImageUrl({
    damImage: previewImage
  });

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'CapabilitiesTile',
      component: 'CapabilitiesTile',
      type: 'content',
      position: componentPosition,
      category: title?.replace(/\W+/g, '')
    },
  });

  return (
    <div
      className="sui-grid sui-h-full"
      key={id}
      data-component="CapabilitiesTile"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <Card>
        <CardMedia
          data-contentful-entry-id={componentId}
          data-contentful-field-id="previewImage"
          aspect="wide"
          src={imageSrcUrl}
          alt={title}
        />
        <Typography
          variant="h4"
          weight="display"
          color="primary"
          data-contentful-entry-id={componentId}
          data-contentful-field-id="title"
        >
          {title}
        </Typography>
        <CardBody>
          <Typography
            variant="body-base"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="description"
          >
            {description}
          </Typography>
        </CardBody>
        {link && cta && (
          <Button
            href={link}
            variant="secondary"
            data-contentful-entry-id={componentId}
            data-contentful-field-id="cta"
          >{cta}
          </Button>
        )}
      </Card>
    </div>
  );
}

CapabilitiesTileRenderer.propTypes = {
  componentId: stringType.isRequired,
  componentClass: stringType,
  id: stringType,
  componentPosition: number,
  dataComponent: shapeType({
    id: stringType,
    title: stringType,
    cta: stringType,
    description: stringType,
    link: stringType,
    previewImage: shapeType({
      damContentSelector: shapeType({
        assetData: arrayOf(shapeType({
          selectedImageUrl: stringType
        }))
      }),
      damDownloadedContent: shapeType({
        url: stringType
      })
    }),
  }),
};
