import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useFormController from '../hooks/useFormController';
import formControllerState from '../utils/formControllerState';

/**
 * A utility component to help render additional text related to a form input.
 *
 * Related components: [TextField](#textfield), [QuantityPicker](#quantitypicker), [DropdownField](#dropdownfield),
 * [FormController](#formcontroller)
 *
 * Usage:
 *
 * ```jsx
 * import { FormHelpMessage } from '@one-thd/sui-atomic-components';
 * ```
 */
const FormHelpMessage = React.forwardRef((props, ref) => {
  const {
    children,
    component: FormMessagingRoot = 'p',
    disabled,
    ...other
  } = props;

  const formControl = useFormController();
  const fcs = formControllerState({
    props,
    formControl,
    states: ['disabled']
  });

  const classes = classNames('sui-font-regular sui-mt-1 sui-text-xs sui-leading-none sui-text-left', {
    'sui-text-inactive': fcs.disabled,
    'sui-text-subtle': !fcs.disabled
  });

  return (
    <FormMessagingRoot
      className={classes}
      ref={ref}
      {...other}
    >
      {children}
    </FormMessagingRoot>
  );
});

FormHelpMessage.displayName = 'FormHelpMessage';

FormHelpMessage.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the help messaging should be displayed in a disabled state.
   */
  disabled: PropTypes.bool
};

export { FormHelpMessage };
