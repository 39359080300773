import THDStorageUtils, { LOCAL_STORAGE } from '@thd-olt-global/thd-storage-utils';

export const formatStorageKey = (l1Label) => {
  if (!l1Label) {
    return null;
  }
  const storageKey = 'CALC-' + l1Label.toUpperCase();
  return storageKey;
};

export const getStorageObject = (storageKey) => {
  try {
    const storageValue = THDStorageUtils.get(LOCAL_STORAGE, { key: storageKey, returnString: false });
    const storageObject = storageValue !== undefined ? storageValue : null;

    return storageObject;
  } catch (error) {
    return null;
  }
};

export const removeStorageObject = (storageKey) => {
  THDStorageUtils.remove(LOCAL_STORAGE, { key: storageKey });
  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
    'thdStorageUtils.localStorage',
    {
      key: storageKey,
      hookId: 'not-a-hook'
    }
  );
};

export const saveStorageObject = (storageKey, form, calculate) => {
  THDStorageUtils.set(LOCAL_STORAGE, {
    key: storageKey,
    value: {
      form,
      calculate
    }
  });

  window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
    'thdStorageUtils.localStorage',
    {
      key: storageKey,
      hookId: 'not-a-hook'
    }
  );
};

export const getFormFromLocalStorage = (localStorageKey) => {
  const { form } = getStorageObject(localStorageKey) ?? {};

  if (form) {
    return form;
  }

  return null;
};

export const getCalculateFromLocalStorage = (localStorageKey) => {
  const { calculate } = getStorageObject(localStorageKey) ?? {};

  if (calculate) {
    return calculate;
  }

  return null;
};

export const getCalculatorLocalStorage = ({ l1Label }) => {
  const storageKey = formatStorageKey(l1Label);
  let storageObj = null;
  if (storageKey) {
    storageObj = getStorageObject(storageKey);
  }
  return {
    storageKey,
    storageObj
  };
};