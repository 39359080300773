import React from 'react';
import { number, shape, bool } from 'prop-types';
import { useImpression, ImpressionProvider } from '@thd-olt-component-react/impression';
import { BayImage } from './BayImage';

// This Component is responsible for adding the useImpressions hook for this bay.
const ImpressionableBay = ({ bentoBayData, position, isSponsored = false, carouselPosition = 0 }) => {
  const isHotspotImage = !!bentoBayData?.previewImage?.imageHotspot?.id
    || !!bentoBayData?.components?.[0]?.previewImage?.imageHotspot?.id;

  // sys.id is the unique identifier for the contentful entry and required for livePreview
  const impressionId = bentoBayData?.id || bentoBayData?.sys?.id;

  const { ref, clickID } = useImpression({
    data: {
      id: impressionId,
      component: 'BentoBay',
      name: 'BentoBay',
      type: isSponsored ? 'sponsoredcontent' : 'content',
      position,
    },
  });

  const heroLink = bentoBayData?.components?.[0]?.link || bentoBayData?.link;
  return (
    <>
      {!isHotspotImage ? (
        <a
          aria-label={`Navigate to ${bentoBayData?.altText || 'No description available'}`}
          href={heroLink}
          target="_self"
          // eslint-disable-next-line react/no-unknown-property
          clickid={clickID}
          ref={ref}
        >
          <BayImage bayImageData={bentoBayData} isHotspotImage={isHotspotImage} carouselPosition={carouselPosition} />
        </a>
      ) : (
        <ImpressionProvider
          data={{
            id: impressionId,
            component: 'BentoHero',
            name: 'BentoHero',
            type: 'content'
          }}
        >
          <div
            ref={ref}
            // eslint-disable-next-line react/no-unknown-property
            clickid={clickID}
          >
            <BayImage
              bayImageData={bentoBayData}
              isHotspotImage={isHotspotImage}
              position={position}
              carouselPosition={carouselPosition}
            />
          </div>
        </ImpressionProvider>
      )}
    </>
  );
};

ImpressionableBay.propTypes = {
  bentoBayData: shape({}).isRequired,
  position: number.isRequired,
  isSponsored: bool,
  carouselPosition: number,
};

export { ImpressionableBay };
