/* eslint-disable consistent-return */
/* globals resx, mockRVData */
import * as helpers from './helpers';

const shouldLoadFromStorage = () => {
  // checks to see if user came from PIP
  return !(helpers.getProductPageStatus(document?.referrer));
};

const useFetchPolicy = (isLocalized) => {
  return isLocalized && !shouldLoadFromStorage() ? 'network-only' : 'cache-first';
};

const shouldIncludeThdDefaultLightTheme = (theme) => {
  return [
    'EndcapLightGreen',
    'EndcapDarkGreen',
    'EndcapSawdust',
    'EndcapBeige',
    'PrimarySpringGreen',
    'PrimarySpringLightGreen',
    'SecondarySpringDarkGreen',
    'SecondarySpringKhaki',
  ].includes(theme);
};

export {
  shouldLoadFromStorage,
  useFetchPolicy,
  shouldIncludeThdDefaultLightTheme
};
