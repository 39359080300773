import { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useLocalStorage } from '@thd-olt-global/thd-storage-utils';
import { getCoverage, getQuantity } from '../helpers/helpers';
import { formatStorageKey } from '../helpers/localStorageUtil';
import { INSTALLED_CARPET_SEGMENT, FIXED_WIDTH } from '../constants';

/* TODO: Look into the potential of lumping in a good bit of the localStorageUtil functions
        into this hook and cleaning up where those functions are exported from. */
export const useCalculatorLocalStorage = ({ uomObj, toggleOn = false, label, pipCalc }) => {
  const { sizeValue, caseUnitOfMeasure } = uomObj || {};
  const {
    publisher,
    display,
  } = pipCalc || {};
  const [localStorageValue, _] = useLocalStorage(formatStorageKey(label));
  const { segment } = useContext(ExperienceContext);
  const { entries } = localStorageValue?.form || {};
  const { addAdditionalCoverage } = localStorageValue?.calculate || { addAdditionalCoverage: true };
  const calculatorType = segment === INSTALLED_CARPET_SEGMENT ? FIXED_WIDTH : 'Area';

  const areaLength = entries?.length || 0;
  const unitsPerCase = publisher || sizeValue;
  let coverage = 0;
  if (areaLength) {
    coverage = getCoverage({
      entries,
      calculatorType,
      isInstalledCarpet: segment === INSTALLED_CARPET_SEGMENT,
    });
  }
  const uomCheck = (unitsPerCase && (caseUnitOfMeasure === 'sq. ft.' || display));
  let quantity;
  if (!toggleOn || !uomCheck) quantity = null;
  else {
    quantity = getQuantity({
      coverage,
      sizeValue: unitsPerCase,
      info: { pipCalculator: pipCalc },
      addAdditionalCoverage,
      allowAdditionalCoverage: true,
    });
  }

  return { projectQuantity: quantity, coverage, calculatorData: localStorageValue };
};
