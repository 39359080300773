import { string, shape, arrayOf } from 'prop-types';
import {
  useHelmet, LinkTag, MetaTag, ScriptTag, TitleTag
} from '@thd-nucleus/thd-helmet';
import { GOOGLE_SITE_VERIFICATION_ID } from '../constants';

const HD_URL = 'https://www.homedepot.com';

const homepageMetadataHandler = ({ data }) => {
  const {
    preloadedImages,
    metadata
  } = data;

  let tags = [];
  let {
    description, title, siteName, canonicalURL, facebookAdmins, robots, type, alternate
  } = metadata;

  const content = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.homedepot.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://www.homedepot.com/s/{search_term_string}?NCNI-5&gsitesearch',
      'query-input': 'required name=search_term_string',
    },
  };

  const organizationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'The Home Depot',
    alternateName: 'Home Depot',
    url: 'https://www.homedepot.com/',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '1-800-466-3337',
      contactType: 'customer service'
    },
    sameAs: [
      'https://www.facebook.com/homedepot/ ',
      'https://twitter.com/HomeDepot ',
      'https://www.instagram.com/homedepot/ ',
      'https://www.youtube.com/homedepot ',
      'https://www.linkedin.com/company/the-home-depot',
      'https://www.pinterest.com/homedepot/ '
    ],
  };

  alternate = [
    { link: 'https://www.homedepot.com/', lang: 'en' },
    { link: 'https://www.homedepot.ca/en/home.html/', lang: 'en-ca' },
    { link: 'https://www.homedepot.ca/fr/accueil.html', lang: 'fr-ca' },
    { link: 'https://www.homedepot.com.mx/', lang: 'es-mx' },
    { link: 'https://www.homedepot.com/', lang: 'x-default' },
  ];

  tags.push(new ScriptTag({ content, id: 'site-search' }));
  tags.push(new ScriptTag({ content: organizationSchema, id: 'site-organization' }));
  // This below 'google-site-verification' tag is critical for SEO google search console ownership rights
  tags.push(new MetaTag({
    name: 'google-site-verification',
    content: GOOGLE_SITE_VERIFICATION_ID,
    id: 'gscVerification'
  }));

  if (preloadedImages) {
    preloadedImages.forEach((image) => {
      const { preloadedImageUrl, preloadedImageId } = image;
      tags.push(
        new LinkTag({
          rel: 'preload',
          as: 'image',
          href: preloadedImageUrl,
          id: preloadedImageId,
          fetchpriority: 'high',
        })
      );
    });
  }

  if (description) {
    tags.push(new MetaTag({ name: 'description', content: description, id: 'description' }));
    tags.push(
      new MetaTag({ property: 'og:description', content: description, id: 'ogDescription' })
    );
  }
  if (title) {
    tags.push(new MetaTag({ property: 'og:title', content: title, id: 'ogTitle' }));
    tags.push(new TitleTag(title));
  }
  if (siteName) {
    tags.push(new MetaTag({ property: 'og:site_name', content: siteName, id: 'ogSiteName' }));
  }
  if (type) {
    tags.push(new MetaTag({ property: 'og:type', content: type, id: 'ogType' }));
  }
  if (canonicalURL) {
    tags.push(
      new MetaTag({ property: 'og:url', content: `${HD_URL}${canonicalURL || ''}`, id: 'ogUrl' })
    );
    tags.push(
      new LinkTag({ rel: 'canonical', href: `${HD_URL}${canonicalURL || ''}`, id: 'canonical' })
    );
  }
  if (facebookAdmins) {
    tags.push(new MetaTag({ property: 'fb:admins', content: facebookAdmins, id: 'fbAdmins' }));
  }
  if (robots) {
    tags.push(new MetaTag({ name: 'robots', content: robots, id: 'robots' }));
  }
  if (alternate) {
    alternate.forEach(({ link, lang }) => {
      tags.push(
        new LinkTag({ rel: 'alternate', href: link, hreflang: lang, id: `alternate-${link}` })
      );
    });
    tags.push(
      new LinkTag({
        rel: 'alternate',
        href: 'android-app://com.thehomedepot/homedepot/view/homepage',
        id: 'alternate-android-app://com.thehomedepot/homedepot/view/homepage',
      })
    );
  }
  return tags;
};

const HomepageMetadata = (props) => {
  const { metadata, preloadedImages } = props;

  const data = {
    preloadedImages,
    metadata
  };

  useHelmet(
    'homepageMetadataHandler',
    { props, data },
    homepageMetadataHandler
  );

  return null;
};

HomepageMetadata.displayName = 'HomepageMetadata';

HomepageMetadata.propTypes = {
  preloadedImages: arrayOf({}).isRequired,
  metadata: shape({}).isRequired
};

HomepageMetadata.defaultProps = {};

export { HomepageMetadata };
