import React, { useContext } from 'react';
import { IconButton, Link, Popover, Typography } from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { getHostCURL } from '../../../common/utils/CardUtils';
import { spendToolTipLabel, spendToolTipLabel1, spendToolTipLabel2, spendToolTipLabel3 } from '../../../core/Constants';
import './SpendSummary.scss';
import { WelcomeCardContext } from '../../../contexts';

const ToolTipContent = React.forwardRef(() => {
  const termLink = getHostCURL('/c/pro-xtra-terms-and-conditions');
  return (
    <Typography className="LoyaltySummary__toolTipLabel">
      {spendToolTipLabel}
      <br />
      {spendToolTipLabel1}
      <br />
      <br />
      {spendToolTipLabel2}
      <br />
      {spendToolTipLabel3}
      <br />
      <br />
      For full details, see the&nbsp;
      <Link href={termLink} target="_blank">
        Pro Xtra Program Terms &amp; Conditions
      </Link>
    </Typography>
  );
});

const ToolTip = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className="sui-pl-1">
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        icon={InfoFilled}
        iconSize="small"
        buttonSize="tight"
        color="info"
      />
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} placement="bottom">
        <ToolTipContent />
      </Popover>
    </div>
  );
};

export const SpendSummary = () => {
  const { userData, isChildAccount } = useContext(WelcomeCardContext);
  const proXtraSpend = userData && userData?.proXtraSpend;
  const spendAmount = proXtraSpend?.totalCurrentYearSpend || '0';
  const savingsAmount = parseInt(proXtraSpend?.totalCurrentYearSavings, 10) || '0';
  const totalCurrentYearSavings = proXtraSpend?.totalCurrentYearSavings || '0';
  const hasSavings = savingsAmount > 0;

  const nameText = isChildAccount ? 'Total Company' : 'Pro Xtra';
  const loyaltyText = !hasSavings ? 'Loyalty Spend' : 'Spend';
  const spendText = `${nameText} ${loyaltyText}`;
  const savingsText = isChildAccount ? 'Total Company Savings' : 'Savings';

  return (
    <div className="sui-flex sui-flex-row LoyaltySummary">
      <div className={`col__${hasSavings ? '6' : '12'}-12 sui-relative no-left-padding`}>
        <div
          className={`sui-flex sui-flex-row sui-justify-start ${!hasSavings && 'sui-items-center'}`}
        >
          <Typography variant="h6" weight="display" height="snug">
            $
          </Typography>
          <Typography truncate variant="h3" weight="display" height="tight">
            {spendAmount}
          </Typography>
        </div>
        <div
          className={`sui-flex sui-flex-row sui-justify-start ${hasSavings && 'sui-items-center'}`}
        >
          <Typography variant={isChildAccount ? 'body-sm' : 'body-base'} height="tight" weight="regular">
            {spendText}
          </Typography>
          {!hasSavings && <ToolTip />}
        </div>
      </div>
      {hasSavings && (
        <div className="col__6-12 LoyaltySummary__border">
          <div className="sui-flex sui-flex-row sui-justify-start sui-w">
            <Typography variant="h6" weight="display" height="snug" color="success">
              $
            </Typography>
            <Typography truncate variant="h3" weight="display" height="tight" color="success">
              {totalCurrentYearSavings}
            </Typography>
          </div>
          <div className="sui-flex sui-flex-row sui-justify-start sui-items-center">
            <Typography variant={isChildAccount ? 'body-sm' : 'body-base'} height="tight" weight="regular">
              {savingsText}
            </Typography>
            <ToolTip />
          </div>
        </div>
      )}
    </div>
  );
};
