/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, CardMedia } from '@one-thd/sui-atomic-components';
import helpers, { CLEARANCE_MESSAGE } from '../../helpers/price-helper';
import { ClearancePopoverContent } from './ClearancePopoverContent';
import { CustomTooltip } from './subcomponents/common/CustomTooltip';

export const ClearancePricingBadge = ({
  storeName, originalPrice, clearanceValue, dollarOff, percentageOff, sku, unitsClearancePrice, caseUnitOfMeasure,
  unitsOriginalPrice, alternatePriceDisplay
}) => {

  const CLEARANCE_POPOVER_MESSAGE = `Visit ${storeName} to purchase this item on Clearance in store.`
    + ' While supplies last.';
  const badgeSizeWidth = '32px';
  const badgeSizeHeight = '16px';

  useEffect(() => {
    helpers.setClearanceAnalytics('badge', {
      recommendationStrategy: 'n/a',
      recommendationVersion: 'n/a',
      sku
    });
  }, []);

  const handleClick = () => {
    helpers.setClearanceAnalytics('tooltipEvent', {
      primaryCategory: 'tooltip',
      eventName: 'in-store clearance message',
      sku
    });
  };

  return (
    <div className="sui-flex sui-pb-3 sui-pt-[10px] sui-leading-snug">
      <span className="sui-pr-2 sui-pt-[4px]">
        <CardMedia
          src="https://assets.thdstatic.com/images/v1/Value-Pricing-Clearance.svg"
          style={{
            width: `${badgeSizeWidth}`,
            height: `${{ badgeSizeHeight }}`
          }}
          alt="Clearance Badge"
          title="In-Store Clearance Pricing"
        />
      </span>
      <CustomTooltip
        title="In-Store Clearance Item"
        pricingMessage={
          <Typography variant="body-sm" onClick={handleClick}>
            {CLEARANCE_MESSAGE}
          </Typography>
        }>
        <ClearancePopoverContent
          message={CLEARANCE_POPOVER_MESSAGE}
          originalPrice={originalPrice}
          clearanceValue={clearanceValue}
          dollarOff={dollarOff}
          percentageOff={percentageOff}
          unitsClearancePrice={unitsClearancePrice}
          caseUnitOfMeasure={caseUnitOfMeasure}
          unitsOriginalPrice={unitsOriginalPrice}
          alternatePriceDisplay={alternatePriceDisplay}
        />
      </CustomTooltip>
    </div>
  );
};

ClearancePricingBadge.propTypes = {
  storeName: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  clearanceValue: PropTypes.number.isRequired,
  dollarOff: PropTypes.number.isRequired,
  percentageOff: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  unitsClearancePrice: PropTypes.number.isRequired,
  unitsOriginalPrice: PropTypes.number.isRequired,
  caseUnitOfMeasure: PropTypes.string.isRequired,
  alternatePriceDisplay: PropTypes.bool
};

ClearancePricingBadge.defaultProps = { alternatePriceDisplay: false };
