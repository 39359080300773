import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from './Button';
import { LoadingIcon } from '../private/icons/LoadingIcon';

/**
 * A `Button` component that can show a loading state and disable interactions.
 *
 * Props of the [Button](#button) component are also available.
 *
 * Usage:
 *
 * ```jsx
 * import { LoadingButton } from '@one-thd/sui-atomic-components';
 * ```
 */
const LoadingButton = (props) => {
  const {
    children,
    variant,
    loading = false,
    disabled,
    'aria-label': ariaLabelProp,
    ...rest
  } = props;
  const isPrimary = variant === 'primary' || !variant;
  const isSecondary = variant === 'secondary';
  const isTertiary = variant === 'tertiary';
  const isGhost = variant === 'ghost';
  const isText = variant === 'text';

  const iconClasses = classNames('sui-absolute sui-mt-2px sui--translate-x-1/2 sui-left-1/2',
    {
      'sui-text-inverse': isPrimary && !disabled,
      'sui-text-subtle': isPrimary && disabled,
      'sui-text-brand': isSecondary && !disabled,
      'sui-text-medium': isTertiary && !disabled,
      'sui-text-inactive': (isSecondary || isTertiary || isGhost || isText) && disabled,
      'sui-text-primary': (isGhost || isText) && !disabled
    });

  const ariaLabel = ariaLabelProp || (loading ? 'loading' : undefined);

  return (
    <Button
      loading={loading}
      variant={variant}
      disabled={disabled}
      aria-label={ariaLabel}
      {...rest}
    >
      { loading && (
        <span className={iconClasses}>
          <LoadingIcon />
        </span>
      )}
      {children}
    </Button>
  );
};

LoadingButton.displayName = 'LoadingButton';

LoadingButton.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If true, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If true, the loading indicator is shown.
   */
  loading: PropTypes.bool,
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text', 'ghost']),
  /**
   * @ignore
   */
  'aria-label': PropTypes.string
};

export { LoadingButton };
