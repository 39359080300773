/* eslint-disable consistent-return */
import ComponentMetadata, { DEFAULT_SIZES } from '../ComponentDictionaryEntries/Entries';

const getSizesMetadata = (type) => {
  return ComponentMetadata[`${type}Metadata`]?.sizes || DEFAULT_SIZES;
};

export const getComponent = (item, index, key) => {
  const {
    id, __typename, components, customRenderId
  } = item || {};
  const type = __typename || key;

  let componentProps = {
    ...item,
    id: `${id || index + type + 'Component'}`,
    __typename: type, // we keep this for now as a fallback, in case we don't have a render func for `customRenderId`
    customRenderId: customRenderId || type,
    sizes: getSizesMetadata(__typename),
  };

  if (!componentProps.__typename) return null;

  if (components && components.length > 0) {
    componentProps.children = components.map((component) => getComponent(component, index));
  }

  return componentProps;
};

export const getComponentsFromItems = (items, idx) => {
  let arrOfData = [];

  items.forEach((component, index) => {
    const {
      id, __typename, components, customRenderId
    } = component || {};

    let componentProps = {
      ...component,
      id: `${id || index + __typename + 'Component'}`,
      __typename,
      customRenderId: customRenderId || __typename,
      sizes: getSizesMetadata(__typename),
    };

    if (components && components.length > 0) {
      componentProps.children = components.map((comp) => getComponent(comp, idx));
    }

    arrOfData.push({
      ...componentProps,
    });
  });

  return arrOfData;
};

export const transformDataFromContentful = (arr = [], rawData) => {
  let arrOfData = arr;
  if (!rawData) return null;
  Object.entries(rawData).forEach(([key, value], idx) => {
    if (!value) return null;
    if (typeof value === 'string') {
      arrOfData.push(
        getComponent(
          {
            value,
            id: `${key}`,
            __typename: key,
          },
          idx,
          key,
        ),
      );
      return;
    }

    if (value?.components) {
      const val = getComponent(value, idx, key);
      if (val) {
        arrOfData.push(val);
      }
    } else if (Array.isArray(value)) {
      arrOfData.push(...getComponentsFromItems(value, idx));
    } else if (key === 'internalMarketingBanner' || key === 'bentoHero') {
      // to make internalMarketingBanner the actual customRenderId
      const newVal = { ...value, customRenderId: key };
      arrOfData.push(getComponent(newVal, idx, key));
    } else {
      transformDataFromContentful(arrOfData, value);
    }
  });
  return arrOfData;
};

const getHeroImages = ({ heroCarouselData, isMobile }) => {
  const firstHero = heroCarouselData?.components?.[0]?.__typename === 'ComponentSelector'
    ? heroCarouselData?.components?.[0]?.defaultComponent?.content : heroCarouselData?.components?.[0];
  const heroImageId = isMobile ? 'preloadHeroSmallImage' : 'preloadHeroLargeImage';

  const previewImage = isMobile ? firstHero?.mobilePreviewImage : firstHero?.previewImage;
  let imageUrl = previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl
                  || previewImage?.damDownloadedContent?.url;
  if (previewImage?.imageHotspot?.imageUrl) {
    imageUrl = previewImage?.imageHotspot?.imageUrl;
  }

  return [{
    preloadedImageUrl: imageUrl,
    preloadedImageId: heroImageId
  }];
};

const getBentoHeroImages = ({ bays, isMobile }) => {
  const bentoHeroImages = [];
  bays.forEach((bay, id) => {
    const bentoHeroId = isMobile ? `preloadBentoSmallImage${id}` : `preloadBentoLargeImage${id}`;
    const firstComponent = bay?.components?.[0];
    if (!firstComponent) return;

    const previewImage = isMobile ? firstComponent?.mobilePreviewImage : firstComponent?.previewImage;
    let imageUrl = previewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl
                  || previewImage?.damDownloadedContent?.url;
    if (previewImage?.imageHotspot?.imageUrl) {
      imageUrl = previewImage?.imageHotspot?.imageUrl;
    }

    if (imageUrl) {
      bentoHeroImages.push({
        preloadedImageUrl: imageUrl,
        preloadedImageId: bentoHeroId
      });
    }
  });
  return bentoHeroImages;
};

export const getPreloadedImages = ({ layoutComponents = [], isMobile }) => {
  const preloadedImages = [];
  const heroCarouselData = layoutComponents?.find((component) => component.customRenderId === 'Hero Carousel');
  const bentoHeroData = layoutComponents?.find((component) => component.customRenderId === 'bentoHero');

  if (heroCarouselData) {
    preloadedImages.push(...getHeroImages({ heroCarouselData, isMobile }));
  }

  if (bentoHeroData) {
    const { firstBay, secondBay, thirdBay, fourthBay } = bentoHeroData;
    const bays = isMobile ? [firstBay, secondBay] : [firstBay, secondBay, thirdBay, fourthBay];
    preloadedImages.push(...getBentoHeroImages({ bays, isMobile }));
  }

  return preloadedImages;
};