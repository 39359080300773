import { createTheme } from '@one-thd/sui-atomic-components';

const PrimarySpringLightGreen = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

PrimarySpringLightGreen.palette['--sui-palette-background-primary'] = '184 210 115';
PrimarySpringLightGreen.palette['--sui-palette-button-background-primary'] = '32 32 32';
PrimarySpringLightGreen.palette['--sui-palette-text-primary'] = '32 32 32';
PrimarySpringLightGreen.palette['--sui-palette-text-subtle'] = '32 32 32';

export { PrimarySpringLightGreen };