import React from 'react';
import { arrayOf, number, string, shape } from 'prop-types';
import { publishAnalytics } from '../../../../utils';

function countAndRemoveDuplicateItems(items) {
  const newItems = [];
  const quantities = {};
  items.forEach(function countDistinctItems(item) {
    if (quantities[item.skuNumber]) {
      quantities[item.skuNumber] += 1;
    } else {
      quantities[item.skuNumber] = item.quantity;
      newItems.push({ ...item });
    }
  });

  for (let i = 0; i < newItems.length; i += 1) {
    newItems[i].quantity = quantities[newItems[i].skuNumber];
  }
  return newItems;
}

function publishImageClickEvent() {
  publishAnalytics('recentPurchases.image-click');
}

export function TableOrderItemThumbnails({ items = [], windowWidth = undefined, href = undefined }) {
  const noImageUrl = 'https://assets.thdstatic.com/images/v1/no-img.svg';

  const getMaxDisplayNumber = () => {
    if (windowWidth > 1024 || windowWidth < 640) return 3;
    return 2;
  };

  const maxDisplayNumber = getMaxDisplayNumber();
  let itemOverflowCount = 0;
  let displayedItems = countAndRemoveDuplicateItems(items);
  if (displayedItems.length > maxDisplayNumber) {
    itemOverflowCount = displayedItems.length - maxDisplayNumber + 1;
    displayedItems = displayedItems.slice(0, maxDisplayNumber - 1);
  }

  let itemImages = displayedItems.map((item, idx) => (
    <a
      href={href}
      key={`tableOrderItemThumbnailItemImage${idx}`}
      className="sui-block sui-relative sui-w-14 sui-mr-2"
      onClick={publishImageClickEvent}
    >
      <img
        src={item.imageUrl || noImageUrl}
        alt={item.itemDescFromCatalog}
        key={`image-${idx}`}
        title={item.itemDescFromCatalog}
        width={56}
        height={56}
      />
      {item.quantity > 1 && (
        <div className="sui-flex sui-absolute sui-justify-center sui-items-center sui-min-w-min sui-h-4 sui-w-4 sui-right-0 sui-top-0 sui-bg-medium sui-text-inverse">
          {item.quantity}
        </div>
      )}
    </a>
  ));

  return (
    <div
      className="sui-flex sui-items-center sui-h-20 sui-overflow-hidden"
      data-testid="recent-purchase-items"
    >
      {itemImages}

      {itemOverflowCount > 0 && (
        <a
          href={href}
          className="sui-w-14 sui-h-14 sui-flex sui-items-center sui-justify-center"
        >
          {`+${itemOverflowCount}`}
        </a>
      )}
    </div>
  );
}

export function CardOrderItemThumbnails({ items = [], href = undefined, windowWidth = undefined }) {
  const getMaxDisplayNumber = () => {
    if (windowWidth > 330) return 5;
    return 2;
  };

  let maxDisplayNumber = getMaxDisplayNumber();
  let itemOverflowCount = 0;
  let displayedItems = countAndRemoveDuplicateItems(items);
  if (displayedItems.length > maxDisplayNumber) {
    itemOverflowCount = displayedItems.length - maxDisplayNumber + 1;
    displayedItems = displayedItems.slice(0, maxDisplayNumber - 1);
  }

  const noImageUrl = 'https://assets.thdstatic.com/images/v1/no-img.svg';

  let itemImages = displayedItems.map((item, idx) => (
    <a
      href={href}
      key={`CardOrderItemThumbnailItemImage${idx}`}
      onClick={publishImageClickEvent}
      className="sui-block sui-relative sui-w-12 sui-mr-3"
    >
      <img
        src={item.imageUrl || noImageUrl}
        alt={item.itemDescFromCatalog}
        key={`image-${idx}`}
        title={item.itemDescFromCatalog}
        width={56}
        height={56}
      />
      {item.quantity > 1 && (
        <div className="sui-flex sui-absolute sui-justify-center sui-items-center sui-min-w-min sui-h-4 sui-w-4 sui-right-0 sui-top-0 sui-bg-medium sui-text-inverse">
          {item.quantity}
        </div>
      )}
    </a>
  ));
  return (
    <div
      className="sui-flex sui-items-center"
      data-testid="recent-purchase-items"
    >
      {itemImages}
      {itemOverflowCount > 0 && (
        <a
          href={href}
          className="sui-h-12 sui-w-12 sui-flex sui-items-center sui-justify-center"
        >
          {`+${itemOverflowCount}`}
        </a>
      )}
    </div>
  );
}

TableOrderItemThumbnails.propTypes = {
  items: arrayOf(
    shape({
      itemDescFromCatalog: string,
      quantity: number,
      imageUrl: string,
    })
  ),
  windowWidth: number,
  href: string,
};

CardOrderItemThumbnails.propTypes = {
  items: arrayOf(
    shape({
      itemDescFromCatalog: string,
      quantity: number,
      imageUrl: string,
    })
  ),
  href: string,
  windowWidth: number,
};
