/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import Responsive from 'react-responsive';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { TILE_LIMIT, TILE_TYPE } from '../../core/Constants';
import {
  prioritizedListNotifications,
  showingTileNotifications,
} from '../../components/LoyaltyWelcomeCard/NotificationSection/NotificationSection';
import { PrioritizeTabletList }
from '../../components/LoyaltyWelcomeCard/NotificationSection/Notifications/TabletTiles';
import {
  prioritizedListPromotions,
  showingTilePromotions,
} from '../../components/LoyaltyWelcomeCard/PromotionSection/PromotionSection';

// Utility methods
const createListOfSectionTiles = (section, elementList) => {
  return elementList.map((element) => {
    return { section, element };
  });
};

const displayTilesForSection = (section, tiles) => {
  return (
    tiles?.map((tile, idx) => {
      return (
        <React.Fragment key={`${section}_${idx}`}>
          {tile.element}
          {idx < tiles.length - 1 && (
            <Responsive minWidth={768} maxWidth={1023}>
              <div className="tablet-line sui-flex sui-flex-row sui-justify-end" />
            </Responsive>
          )}
        </React.Fragment>
      );
    }) || <></>
  );
};

const RenderNotificationTiles = (prioritizedList) => {
  const section = TILE_TYPE.NOTIFICATION;
  const filteredTiles = prioritizedList.filter(Boolean);
  let sectionTiles = createListOfSectionTiles(section, filteredTiles);
  sectionTiles.splice(TILE_LIMIT);
  return displayTilesForSection(section, sectionTiles) || <></>;
};

const RenderPromotionTiles = (prioritizedList) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const section = TILE_TYPE.PROMOTION;
  const filteredTiles = prioritizedList.filter(Boolean);
  let sectionTiles = createListOfSectionTiles(section, filteredTiles);
  const spaceRemaining = isMobile ? 2 : 4;
  sectionTiles.splice(spaceRemaining);
  return displayTilesForSection(section, sectionTiles) || <></>;
};

const RenderTabletTiles = (prioritizedList) => {
  const section = TILE_TYPE.NOTIFICATION;
  const filteredTiles = prioritizedList.filter(Boolean);
  let sectionTiles = createListOfSectionTiles(section, filteredTiles);
  sectionTiles.splice(3);
  return displayTilesForSection(section, sectionTiles) || <></>;
};

export const createTiles = ({
  isAdmin,
  userData,
  availablePerksData,
  isLoadingPerks,
  refreshAvailablePerks,
  refreshAvailableOffers,
  availablePerksError,
}) => {
  const showingTile = {
    NOTIFICATION: showingTileNotifications({ isAdmin, userData, availablePerksData }),
    PROMOTION: showingTilePromotions({ isAdmin, userData }),
  };
  const prioritizedList = {
    NOTIFICATION: prioritizedListNotifications({
      showingTile: showingTile.NOTIFICATION,
      userData,
      availablePerksData,
      isLoadingPerks,
      refreshAvailablePerks,
      refreshAvailableOffers,
      availablePerksError,
      isAdmin,
    }),
    PROMOTION: prioritizedListPromotions({ showingTile: showingTile.PROMOTION, userData }),
  };
  const prioritizedTabletList = isAdmin
    ? PrioritizeTabletList(prioritizedList.NOTIFICATION, availablePerksData, isLoadingPerks)
    : PrioritizeTabletList(prioritizedList.PROMOTION, availablePerksData, isLoadingPerks);
  const renderedNotifications = RenderNotificationTiles(prioritizedList.NOTIFICATION);
  const renderedPromotions = RenderPromotionTiles(prioritizedList.PROMOTION);
  const renderedTablet = RenderTabletTiles(prioritizedTabletList);
  return {
    NOTIFICATION: renderedNotifications,
    PROMOTION: renderedPromotions,
    TABLET: renderedTablet,
  };
};
