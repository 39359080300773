/**
 * @function formatVariations
 * @description Takes variation ID responses from various sources and formats them into a structure the
 * ComponentSelector can use. The important thing is attaching a "uniqueID" property that can be used to
 * filter certain IDs to only ones that should be used for a specific ComponentSelector.
 * @param {{
 * adobeVariationIds: [{ id: String, mbox: String }],
 * decisionEngineVariationIds: [{ response_details: [{ campaign_name: String, decision: String }]}],
 * innervateVariationIds: [{ value: [any] }]
 * }} config
 * object that holds lists of variation IDs from Adobe, Decision Engine or Innervate.
 * @returns {[{ provider: String, uniqueID: String }]}
 */
const formatVariations = ({
  adobeVariationIds = [],
  decisionEngineVariationIds = [],
  innervateVariationIds = [],
  urlVariationIds = []
}) => {
  const deResponseDetails = decisionEngineVariationIds[0]
    ? decisionEngineVariationIds[0].response_details
    : null;

  const decisionEngineIds = deResponseDetails
    ? deResponseDetails
      .filter(({ campaign_name: campaignName }) => !!campaignName)
      .map(({ campaign_name: campaignName, decision }) => ({
        campaignName,
        decision,
        variation: decision,
        provider: 'de',
        uniqueID: campaignName,
      }))
    : [];

  const adobeIds = adobeVariationIds
    .filter(({ id }) => !!id)
    .map(({ id, mbox }) => ({
      id, variation: id, provider: 'adobe', mbox, uniqueID: mbox
    }));

  const innervateIds = innervateVariationIds
    .filter((obj) => !!obj.value
      && obj.value.length > 0
      && Object.hasOwn(obj.value[0], 'variation')
      && Object.hasOwn(obj.value[0], 'personalization_id')
    )
    .map(({ value }) => ({
      ...value[0],
      decision: value[0].variation,
      variation: value[0].variation,
      uniqueID: value[0].personalization_id,
      provider: 'innervate',
    }));

  const urlIds = urlVariationIds.map((paramValues) => {
    const [variation, personalizationId] = paramValues.split('|');

    return {
      variation,
      personalizationId,
      uniqueID: personalizationId,
      provider: 'url'
    };
  });

  const allIds = adobeIds.concat(decisionEngineIds, innervateIds, urlIds);

  return allIds;
};

export default formatVariations;
