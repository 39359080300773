import React from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';

const Fade = React.forwardRef((props, ref) => {
  const {
    in: open,
    children,
    classes,
    onEnter,
    onExited,
    timeout = 350,
    ...other
  } = props;

  const style = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: open ? 1 : 0
    },
    config: {
      duration: timeout
    },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div
      className={classes}
      ref={ref}
      style={{ ...style, visibility: style.opacity.to((opacity) => (opacity === 0 ? 'hidden' : 'visible')) }}
      {...other}
    >
      {children}
    </animated.div>
  );
});

Fade.displayName = 'Fade';

Fade.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  timeout: PropTypes.number
};

export { Fade };