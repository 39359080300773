import React, { useEffect } from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { PacDrawer } from '../../../common/Drawer/PacDrawer/PacDrawer';
import { PacDrawerContext } from '../../../contexts/PacDrawerContext';
import { DetailsMarkdown } from '../../../common/Drawer/DetailsMarkdown';
import { HEADER_TEXT, SUBTITLE_TEXT, FOOTER_BUTTON_TEXT, DRAWER_ANALYTICS } from '../../../core/DrawerConstants';
import { analyticsOverlay, analyticsClick } from '../../../common/utils/AnalyticsUtils';
import {
  editLiveChatZIndex,
  formatRewardText,
  formatSpendUntilThisPerkSubtitle
} from '../../../common/utils/DrawerUtils';

export const PerkDetailsDrawer = ({
  tierObj, optionObj, setDetailsDrawerParams, choosePerk, lockedSubtitle, onClose
}) => {
  const { setOpen, enablePerkAmountRemoval } = React.useContext(PacDrawerContext);
  const isLocked = !tierObj?.earned;
  const header = isLocked ? HEADER_TEXT.LOCKED_PERK_SELECTION : HEADER_TEXT.PERK_DETAILS;
  const { title } = formatRewardText(optionObj, enablePerkAmountRemoval);
  const subTitle = isLocked
    ? lockedSubtitle ?? formatSpendUntilThisPerkSubtitle({ tierObj })
    : SUBTITLE_TEXT.UNCLAIMED_PERK(tierObj.maxThresholdDisplay);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      editLiveChatZIndex();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    analyticsOverlay('pacDrawer', isLocked
      ? DRAWER_ANALYTICS.perkDetailsDrawer.viewDetailsLockedFromRH(optionObj.perkTitle)
      : DRAWER_ANALYTICS.perkDetailsDrawer.viewDetailsUnlockedFromRH(optionObj.perkTitle)
    );
  }, [isLocked]);

  const handleChooseThisPerk = () => {
    analyticsClick('pacDrawer', DRAWER_ANALYTICS.perkDetailsDrawer.choosePerkButtonFromDetails(optionObj.perkTitle));
    choosePerk(optionObj, tierObj);
  };

  const content = () => {
    return (
      <DetailsMarkdown markdownText={optionObj?.perkDescription} />
    );
  };

  const footer = () => {
    return (
      <Button fullWidth onClick={handleChooseThisPerk}>
        {FOOTER_BUTTON_TEXT.SELECTION}
      </Button>
    );
  };

  const handleOnClose = () => {
    setOpen(false);
    if (onClose) onClose();
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger(
      'loyalty-benefits.perk-selection-drawer__close',
      {},
    );
  };

  const handleOnBack = () => {
    setDetailsDrawerParams({ showingDetails: false, optionObj: null });
  };

  return (
    <PacDrawer
      headerText={header}
      iconImgUrl={optionObj?.perkImageUrl}
      title={title}
      subTitle={subTitle}
      onClose={handleOnClose}
      onBack={handleOnBack}
      Content={content}
      Footer={isLocked ? null : footer}
    />
  );
};
