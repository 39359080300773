/* eslint-disable @mizdra/layout-shift/require-size-attributes */
// This variant of spotlight is deprecated and is not approved for general use
// It was added exclusively and temporarily to support the timeline of the B2B homepage migration in q3 of 2022.
import React from 'react';
import { number, string, shape, bool } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames/bind';
import {
  Button,
  Card,
  CardMedia,
  CardTitle,
  Link
} from '@one-thd/sui-atomic-components';
import styles from './spotlightb2b.module.scss';
import { publish } from './utils/publisher';

const SpotlightB2b = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  proAnalyticsData = {}
}) => {

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  const cardContentProps = {
    initialSize: 'full'
  };

  const impressionProviderContextData = useImpression({
    data: {
      id: componentId,
      name: 'Spotlight',
      component: 'Spotlight',
      position: componentPosition,
      type: 'content'
    }
  });

  const cx = classNames.bind(styles);
  const dataComponent = data?.component;

  let {
    cta,
    link,
    title,
    altText,
    proAnalyticsCampaign,
    proAnalyticsComponent
  } = dataComponent || {};

  const {
    previewImage: dataComponentPreviewImage
  } = dataComponent || {};

  let imageUrl;
  const damDownloadedContentImage = dataComponentPreviewImage?.damDownloadedContent;

  if (damDownloadedContentImage?.url) {
    imageUrl = damDownloadedContentImage?.url;
  } else {
    imageUrl = dataComponentPreviewImage?.damContentSelector?.assetData?.[0]?.selectedImageUrl;
  }

  const handleContentClick = () => {
    if (proAnalyticsData.isB2B) {
      // Input Data for widget should be collected from component data
      publish({
        widgetId: componentId,
        widgetTemplates: proAnalyticsData.templates,
        widgetCampaign: proAnalyticsCampaign,
        widgetComponent: proAnalyticsComponent,
      });
    }
  };

  return (
    <div
      data-component-id={componentId}
      id={`${componentClass}-${componentPosition}-${componentId}`}
      data-component="SpotlightB2b"
      data-component-name="Spotlight"
      data-component-position={componentPosition}
      className="sui-grid sui-w-full"
      ref={impressionProviderContextData.ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={impressionProviderContextData.clickID}
    >
      <Link href={link} className={cx('spotlight--flattened')} underline="none" onClick={handleContentClick}>
        <Card className="sui-flex" CardContentProps={cardContentProps}>
          {/* eslint-disable-next-line max-len,tailwindcss/no-arbitrary-value */}
          <div className="sui-flex sui-flex-row sui-max-h-[13rem] sui-h-full sm:sui-flex-col sm:sui-max-h-[none] lg:sui-flex-row lg:sui-max-h-[13rem]">
            {/* eslint-disable-next-line max-len */}
            <div className="sui-flex sui-flex-col sui-w-7/12 sui-justify-between sui-grow sm:sui-order-last sm:sui-w-full sm:sui-mt-2 lg:sui-order-none lg:sui-w-7/12 lg:sui-mt-0">
              <CardTitle header={title} />
              <div className="sui-whitespace-nowrap">
                <Button variant="text" fullWidth>{cta || ''}</Button>
              </div>
            </div>
            {/* eslint-disable-next-line max-len,tailwindcss/no-arbitrary-value */}
            <div className="sui-max-w-[13rem] sui-ml-4 sui-aspect-square sm:sui-ml-0 sm:sui-order-first sm:sui-max-w-[none] sm:sui-w-full lg:sui-ml-4 lg:sui-order-none lg:sui-max-w-[13rem]">
              <CardMedia
                src={imageUrl}
                aspect="square"
                alt={altText || title}
                title={altText || title}
              />
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

SpotlightB2b.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  proAnalyticsData: shape({
    isB2B: bool,
    templates: string
  })
};

SpotlightB2b.displayName = 'SpotlightB2b';

export { SpotlightB2b };
