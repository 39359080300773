import React, { useContext } from 'react';
import { string } from 'prop-types';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { Card, ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { HomepageContext } from './context';

export function SpecialBuyOfTheDay({ id, theme }) {
  const { defaultVariables } = useContext(QueryContext);

  const newDefaultVariables = {
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant
      === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  return (
    <ErrorBoundary name="sbotd">
      <QueryProvider
        cacheKey={`sbotd-php-${id}`}
        defaultVariables={newDefaultVariables}
      >
        <ThemeProvider theme={getThemeByName(theme)}>
          <Card
            id={`sbotd-php-${id}`}
            className="sui-h-full"
            CardContentProps={{
              className: 'sui-h-full sui-flex sui-flex-col sui-w-full sui-gap-4 sui-p-4'
            }}
          >
            <SpecialBuyBanner
              specialBuyType="sbotd"
              entryId={id}
            />
          </Card>
        </ThemeProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}

SpecialBuyOfTheDay.propTypes = {
  id: string,
  theme: string,
};

SpecialBuyOfTheDay.defaultProps = {
  id: undefined,
  theme: undefined
};
