import React, { useEffect } from 'react';
import { bool as boolean, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf, customType,
  namedFragment, typename,
  params, shape, string, useDataModel
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { BannerRenderer } from './BannerRenderer';
import { withImpressions } from '../hocs/withImpressions';

const MedioInline = withImpressions(({
  componentClass, componentId, componentPosition, lazyLoad, theme
}) => {

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: MedioInline.displayName,
      component: MedioInline.displayName,
      position: componentPosition,
      type: 'content'
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('medio-inline.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      componentClass,
      id: componentId
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <div
      // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        data-component-id={componentId}
        data-component-name="MedioInline"
        data-component-position={componentPosition}
        data-component="MedioInline"
        id={`${componentClass}-${componentPosition}-${componentId}`}
        ref={ref}
      >
        <BannerRenderer
          data={data?.component}
          mobileAspectRatio="sui-aspect-[640/241]"
          tabletAspectRatio="md:sui-aspect-[142/15]"
          desktopAspectRatio="xl:sui-aspect-[142/15]"
          lazyLoad={lazyLoad}
          componentId={componentId}
          componentPosition={componentPosition}
        />
      </div>
    </ThemeProvider>
  );
});

MedioInline.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
  lazyLoad: boolean,
  theme: stringType
};

MedioInline.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  lazyLoad: false,
  theme: undefined
};

MedioInline.displayName = 'MedioInline';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string()
      })
    )
  }),
  damDownloadedContent: shape({
    url: string()
  }),
  id: string(),
  imageHotspot: shape({
    id: string(),
    componentName: string()
  }),
  __typename: typename('DamMedia')
});

MedioInline.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['MedioInline']).isRequired()
  }).shape({
    MedioInline: namedFragment({
      inline: true,
      fragmentType: 'MedioInline'
    }).shape({
      componentName: string(),
      id: string(),
      link: string(),
      altText: string(),
      desktopImage: DamMediaFragment,
      mobileImage: DamMediaFragment,
      tabletImage: DamMediaFragment,
      theme: string()
    })
  })
};

export { MedioInline };
