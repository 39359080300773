import React from 'react';
import PropTypes from 'prop-types';
import {
  useDataModel,
  extend,
  arrayOf,
  namedFragment,
  customType,
  bool as boolType,
  shape,
  string as stringType,
  alias
} from '@thd-nucleus/data-sources';
import { useBreakpoint } from '@one-thd/sui-atomic-components';
import { Spotlight } from './Spotlight';
import { SpotlightContainerRenderer } from './SpotlightContainerRenderer';

const SpotlightContainer = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  showDescription = false,
  useB2bStyles = false,
  fontWeight = 'display',
  cmMmcParam = '' // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi,
  // so that we can track credit card campaigns through application completions
}) => {
  const { current: currentBreakpoint } = useBreakpoint(null, { current: true });

  const { data, loading, error } = useDataModel('specializedContainer', {
    variables: {
      id: componentId,
      componentClass
    }
  });

  if (!data || loading || error) {
    return null;
  }

  const title = data?.component?.title;
  const overflowBehavior = data?.component?.overflowBehavior;
  const extraCardOnLargeAndAbove = data?.component?.extraCardOnLargeAndAbove;
  const spotlightList = data?.component?.spotlightList;
  const cardCount = spotlightList?.length;
  const spotlightOrientation = (
    cardCount === 1
    && currentBreakpoint !== 'unprefixed'
    && currentBreakpoint !== 'sm'
  ) ? 'horizontal' : 'vertical';

  const spotlightComponents = spotlightList.map((spotlight, index) => (
    <Spotlight
      key={spotlight.id}
      componentId={spotlight.id}
      componentClass="Spotlight"
      componentPosition={index + 1}
      showDescription={showDescription}
      useB2bStyles={useB2bStyles}
      orientation={spotlightOrientation}
      cmMmcParam={cmMmcParam}
    />
  ));

  return (
    <SpotlightContainerRenderer
      componentId={componentId}
      componentPosition={componentPosition}
      title={title}
      overflowBehavior={overflowBehavior}
      extraCardOnLargeAndAbove={extraCardOnLargeAndAbove}
      cardCount={cardCount}
      spotlightComponents={spotlightComponents}
      fontWeight={fontWeight}
    />
  );
};

SpotlightContainer.displayName = 'SpotlightContainer';
SpotlightContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  showDescription: PropTypes.bool,
  useB2bStyles: PropTypes.bool,
  fontWeight: PropTypes.string,
  cmMmcParam: PropTypes.string
};

SpotlightContainer.dataModel = extend({
  specializedContainer: alias('component').params({
    id: stringType()
      .isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['SpotlightContainer'])
      .isRequired(),
  }).shape({
    SpotlightContainer: namedFragment({
      inline: true,
      fragmentType: 'SpotlightContainer'
    }).shape({
      id: stringType(),
      title: stringType(),
      componentName: stringType(),
      overflowBehavior: boolType(),
      extraCardOnLargeAndAbove: boolType(),
      spotlightList: arrayOf(shape({
        ...Spotlight.dataModel.component
      })),
    }),
  }),
}, Spotlight);

export { SpotlightContainer };
