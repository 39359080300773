import { useMemo } from 'react';

const useProductPodRedirectUrl = (props) => {
  const { canonicalUrl } = props;

  // --
  const redirectUrl = useMemo(() => {
    // build the base url
    const url = new URL(canonicalUrl, window?.location?.origin);

    // return the url string
    return url.href;
  }, [canonicalUrl]);

  return { redirectUrl };
};

export { useProductPodRedirectUrl };
