import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useImpression, ImpressionProvider } from '@thd-olt-component-react/impression';
import {
  useDataModel, params, string, shape, bool, arrayOf
} from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import classNames from 'classnames';

export const ProductPod = ({
  render,
  itemId,
  storeId: storeIdFromProp,
  analyticsData,
  hoverDelay,
  onHover,
  padding,
  gap,
  analyticImpressionData,
  hasSSKU,
}) => {
  const [selectedId, setSelecetedId] = useState(itemId);
  const [hoveredId, setHoveredId] = useState();
  const [productQuantity, setProductQuantity] = useState(1);
  const [loadedItemIds, setLoadedItemIds] = useState([]);
  const [sponsoredValues, setSponsoredValues] = useState({
    isSponsored: false,
    sponsoredBeacon: null,
    sponsoredMetadata: null
  });
  const [podState, setPodState] = useState({
    showSecondaryImage: false
  });

  const store = useStore();
  const storeId = storeIdFromProp || store?.storeId;
  const activeItemId = hoveredId || selectedId || itemId || null;

  const { data } = useDataModel('product', {
    variables: {
      itemId: activeItemId,
      storeId
    },
    skip: !activeItemId
  });

  const { product } = data || {};
  const {
    isSponsored,
    sponsoredBeacon,
    sponsoredMetadata,
    productSubType,
    swatches
  } = product?.info || {};

  useEffect(() => {
    if (product?.itemId && Object.keys(analyticsData).length) {
      const loadedState = loadedItemIds.find((obj) => obj.itemId === product.itemId);
      const isLoaded = !!loadedState;
      if (!isLoaded || (isLoaded && !loadedState?.isSponsored && isSponsored)) {
        const newSponsoredValues = {
          isSponsored: !!(sponsoredValues?.isSponsored || isSponsored),
          sponsoredBeacon: sponsoredValues?.sponsoredBeacon || sponsoredBeacon,
          sponsoredMetadata: sponsoredValues?.sponsoredMetadata || sponsoredMetadata
        };

        const position = analyticsData?.position + 1;
        const productPodLoadObj = {
          ...analyticsData,
          position,
          sponsoredValues: newSponsoredValues,
          sponsoredProduct: isSponsored,
          itemId: product?.itemId,
          badges: product?.badges
        };
        // NOTE: does this need to be updated to v8 ???
        LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.load', productPodLoadObj);

        if (isLoaded) {
          setLoadedItemIds(loadedItemIds.map((obj) => {
            if (obj.itemId === product.itemId) {
              return { itemId: product.itemId, isSponsored: newSponsoredValues.isSponsored };
            }
            return obj;
          }));
        } else {
          setLoadedItemIds([...loadedItemIds, {
            itemId: product.itemId, isSponsored: newSponsoredValues.isSponsored
          }]);
        }
        setSponsoredValues(newSponsoredValues);
      }
    }
  }, [analyticsData, product?.itemId, isSponsored]);

  let useImpressionData = {
    id: itemId,
    name: 'ProductPod',
    component: 'ProductPod',
    position: analyticsData?.position + 1,
    type: isSponsored ? 'sponsoredproduct' : 'product',
    // eslint-disable-next-line no-nested-ternary
    category: swatches?.length > 1 ? hasSSKU ? 'ssku' : '' : ''
  };

  if (sponsoredMetadata) {
    useImpressionData = {
      ...useImpressionData,
      ...sponsoredMetadata,
    };
  }

  const { onViewBeacon, onViewBeacons } = sponsoredBeacon || {};

  if (onViewBeacon) {
    useImpressionData.sponsoredViewBeacon = onViewBeacon;
  }

  if (!!onViewBeacons && onViewBeacons.length > 0) {
    useImpressionData.sponsoredViewBeacons = onViewBeacons;
  }

  const impressionContextData = useImpression({ data: useImpressionData });
  const productPodRef = impressionContextData.ref;

  const idToDisplay = hoveredId || selectedId;

  const preFetchTimerRef = useRef(null);
  const onProductPodHover = (hoveredItemId) => {
    if (onHover) {
      preFetchTimerRef.current = setTimeout(() => {
        onHover(hoveredItemId);
      }, hoverDelay);
    }
  };

  const onProductPodHoverExit = () => {
    clearInterval(preFetchTimerRef.current);
  };

  const onChange = (clickData) => {
    if (clickData.itemId) {
      setSelecetedId(clickData.itemId);
    }
  };

  const onSSKUHover = (hoverData) => {
    const { itemId: hoveredItemId, isHovered } = hoverData;
    if (isHovered && hoveredItemId) {
      setHoveredId(hoveredItemId);
    } else {
      setHoveredId();
    }
  };

  const onSavingsCenterToggle = () => {
    setPodState({
      ...podState,
      showSecondaryImage: !podState.showSecondaryImage
    });
  };

  const onQuantityUpdate = ({ quantity }) => {
    setProductQuantity(quantity);
  };

  const state = {
    onChange,
    onSSKUHover,
    onSavingsCenterToggle,
    itemId: idToDisplay,
    storeId,
    quantity: productQuantity,
    onQuantityUpdate,
    ref: productPodRef,
    productSubType: productSubType?.name,
    ...podState
  };

  const impressionData = {
    id: analyticImpressionData?.id || itemId,
    name: analyticImpressionData?.name || 'ProductPod',
    component: analyticImpressionData?.component || 'ProductPod',
    type: isSponsored ? 'sponsoredproduct' : 'product',
  };

  const productPodParentClasses = classNames(
    padding, gap,
    'sui-flex sui-flex-col sui-relative',
    'sui-h-full sui-w-full sui-mb-2 sui-bg-primary',
    'sui-w-full sui-mb-2 sui-bg-primary', {
      'sui-p-4': !padding,
      'sui-gap-2': !gap
    });

  return (
    <div
      ref={productPodRef}
      data-testid="product-pod"
      className={productPodParentClasses}
      onMouseEnter={() => onProductPodHover(idToDisplay)}
      onMouseLeave={() => onProductPodHoverExit()}
      /* eslint-disable-next-line react/no-unknown-property */
      clickid={impressionContextData?.clickID}
      data-component="ProductPod"
    >
      <ImpressionProvider data={impressionData} additionalData={analyticsData}>
        {render(state)}
      </ImpressionProvider>
    </div>
  );

};

ProductPod.displayName = 'ProductPod';

ProductPod.propTypes = {
  render: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  hoverDelay: PropTypes.number,
  onHover: PropTypes.func,
  padding: PropTypes.string,
  gap: PropTypes.string,
  hasSSKU: PropTypes.bool,
  analyticsData: PropTypes.shape({
    position: PropTypes.number,
    parent: PropTypes.string.isRequired
  }),
  analyticImpressionData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    component: PropTypes.string
  })
};

ProductPod.defaultProps = {
  storeId: null,
  hoverDelay: 500,
  onHover: null,
  padding: null,
  gap: null,
  hasSSKU: false,
  analyticsData: { position: 0 },
  analyticImpressionData: {
    id: null,
    name: null,
    component: null
  }
};

ProductPod.dataModel = {
  product: params({
    itemId: string().isRequired(),
    dataSource: string()
  }).shape({
    itemId: string(),
    dataSources: string(),
    badges: params({ storeId: string() }).arrayOf(shape({
      name: string(),
    })),
    info: shape({
      isSponsored: bool(),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string(),
        sponsoredId: string(),
        trackSource: string()
      }),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string(),
        onClickBeacons: arrayOf(string()),
        onViewBeacons: arrayOf(string())
      }),
      productSubType: shape({
        name: string()
      }),
    }),
  }),
};
