import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerBody,
  DrawerFooter,
  TextField,
  FormController,
  FormLabel,
  Button,
  ButtonGroup
} from '@one-thd/sui-atomic-components';
import {
  useLazyDataModel, extend,
  arrayOf, customType, params, string, number
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { AddToListContext } from '../AddToListProvider';
import { TRACK, ERROR } from '../constants';
import { ProductInfo } from './ProductInfo';
import { AddToListSuccess } from './AddToListSuccess';
import { isCartExperience } from '../utils';

const CreateNewList = ({
  itemId,
  quantity,
  onClose,
  onCreate,
  products,
  isExistingLists,
  isSharedList,
  setFavoriteCount,
  favoriteCount,
  isMultiSelect
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const { productValue } = useContext(AddToListContext);
  const { path } = useContext(ExperienceContext);

  const singleProduct = [
    { itemId, quantity }
  ];

  const multipleProduct = products.map((prod) => ({
    itemId: prod.itemId,
    quantity: prod.quantity
  }));

  const [createList, createListResponse] = useLazyDataModel('createList', {
    variables: {
      products: itemId ? singleProduct : multipleProduct.reverse()
    },
    fetchPolicy: 'no-cache',
    context: { withAuth: true }
  });

  const getValidationError = (value) => {
    const validCharacters = /^[ [\]{}|a-z|A-Z|!@#$%^&*()0-9-_+,.?"':;\\]+$/;
    const isBlank = value.length === 0;
    let isValid = validCharacters.test(value);
    if (!isValid || isBlank) {
      if (isBlank) {
        setError(ERROR.EMPTY_LIST);
      } else {
        setError(ERROR.INVALID_LIST_NAME);
      }
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!error) {
      await createList({
        variables: {
          name
        }
      });
    }
  };

  useEffect(() => {
    if (!createListResponse.error && createListResponse?.data?.createList?.name) {
      let creationLocation = isExistingLists ? 'choose a list overlay' : 'create a list overlay';
      if (itemId && !isSharedList) {
        if (window.THDCustomer?.default?.customerType === 'B2C') { creationLocation = 'save to your list overlay'; }
        LIFE_CYCLE_EVENT_BUS.trigger(TRACK.CREATE_LIST_WITH_ITEM, {
          creationLocation,
          quantity,
          price: productValue || 0,
          sku: itemId,
          bulkAction: isMultiSelect,
          listLocation: isCartExperience(path) ? 'cart' : null
        });
      } else if (products && !itemId) {
        const listItem = products.map((prod) => ({
          listLocation: isCartExperience(path) ? 'cart' : window?.digitalData?.page?.pageInfo?.pageType,
          quantity: prod.quantity || 'n/a',
          price: { basePrice: prod.price || 'n/a' },
          productInfo: { sku: prod.itemId || 'n/a' }
        }));
        if (isSharedList) { creationLocation = 'save shared list'; }
        LIFE_CYCLE_EVENT_BUS.trigger(TRACK.CREATE_LIST_WITH_PRODUCTS, {
          creationLocation, listItem, bulkAction: isMultiSelect
        });
      }
      onCreate();
    } else if (createListResponse?.error) {
      const gqlErrors = createListResponse?.error?.graphQLErrors;
      gqlErrors.forEach(function (err, index) {
        if (err?.message === ERROR.LIST_ALREADY_EXISTS) {
          setError(ERROR.LIST_ALREADY_EXISTS);
        } else {
          setError(ERROR.GENERAL_API_FAIL);
        }
      });
    }
  }, [createListResponse?.data, createListResponse?.error]);

  const newList = createListResponse?.data?.createList;

  useEffect(() => {
    if (newList?.id) {
      setFavoriteCount(favoriteCount + 1);
    }
  }, [newList?.id]);

  if (newList?.id) {
    return (
      <AddToListSuccess
        listName={newList?.name}
        listId={newList?.id}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <DrawerBody>
        <ProductInfo />
        <FormController className="sui-px-6 sui-pt-6">
          <FormLabel id="create-list" className="sui-cursor-default sui-text-base">
            Create List Name:
          </FormLabel>
          <div className="sui-pt-2">
            <TextField
              fullWidth
              placeholder="Enter a List Name"
              value={name}
              onChange={(evt) => {
                setName(evt.target.value);
                getValidationError(evt.target.value);
              }}
              status={error ? 'error' : null}
              statusMessage={error}
            />
          </div>
        </FormController>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            onClick={handleSubmit}
            type="submit"
            disabled={!name || !!error}
          >
            Save
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

CreateNewList.propTypes = {
  itemId: PropTypes.string,
  quantity: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  isExistingLists: PropTypes.bool.isRequired,
  isSharedList: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
  })),
  setFavoriteCount: PropTypes.func.isRequired,
  favoriteCount: PropTypes.number,
  isMultiSelect: PropTypes.bool
};

CreateNewList.defaultProps = {
  itemId: null,
  quantity: 1,
  products: [],
  favoriteCount: null,
  isMultiSelect: null
};

CreateNewList.displayName = 'CreateNewList';

CreateNewList.dataModel = extend(
  {
    createList: params({
      name: string().isRequired(),
      products: arrayOf(
        customType('AddToListProductInput').shape({
          itemId: string(),
          quantity: number()
        })
      )
    }).mutation().shape({
      id: string(),
      name: string(),
      listAccessType: string(),
    })
  }
);

export { CreateNewList };