import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const secondaryFillMap = {
  success: '[&_[data-color="secondary"]]:sui-fill-success',
  warning: '[&_[data-color="secondary"]]:sui-fill-warning',
  danger: '[&_[data-color="secondary"]]:sui-fill-danger',
  info: '[&_[data-color="secondary"]]:sui-fill-info',
  brand: '[&_[data-color="secondary"]]:sui-fill-brand'
};

/**
 * `SvgIcon` is the base component for every icon in the
 * [Icon Library](https://brand.homedepot.com/site/knapsack-nucleus/latest/pattern/icons?subPageId=DESIGN).
 *
 * Note that Stencil Icons are meant for **ornament purposes only**.
 * To use icons with user action capabilities and to properly handle states such as focus and hover please see the
 * [IconButton](#iconbutton) component.
 *
 * All available icons are provided in the `@one-thd/sui-icons` package.
 * Although `SvgIcon` is not publicly available, you can use all the props defined in it.
 *
 * Usage:
 *
 * ```jsx
 * import { Apron, Cart } from '@one-thd/sui-icons';
 * ```
 *
 */
const SvgIcon = forwardRef((props, ref) => {
  const {
    children,
    rounded,
    backgroundColor,
    color = 'current',
    secondaryColor: secondaryColorProp,
    shrink,
    size = 'regular',
    ...other
  } = props;

  const secondaryColor = secondaryFillMap[secondaryColorProp];

  const classes = classNames('sui-inline-block sui-align-baseline', {
    'sui-fill-current': color === 'current',
    'sui-fill-inherit': color === 'inherit',
    'sui-fill-primary': color === 'primary',
    'sui-fill-inverse': color === 'inverse',
    'sui-fill-subtle': color === 'subtle',
    'sui-fill-medium': color === 'medium',
    'sui-fill-success': color === 'success',
    'sui-fill-brand': color === 'brand',
    'sui-fill-brand-subtle': color === 'brand-subtle',
    'sui-fill-success-strong': color === 'success-strong',
    'sui-fill-warning': color === 'warning',
    'sui-fill-warning-strong': color === 'warning-strong',
    'sui-fill-danger': color === 'danger',
    'sui-fill-danger-strong': color === 'danger-strong',
    'sui-fill-info': color === 'info',
    'sui-fill-info-strong': color === 'info-strong',
    'sui-bg-primary': backgroundColor === 'primary',
    'sui-bg-inverse': backgroundColor === 'inverse',
    'sui-bg-inactive': backgroundColor === 'inactive',
    'sui-rounded-full': rounded,
    'sui-w-1em sui-h-1em sui-text-size-inherit': size === 'inherit',
    'sui-w-4 sui-h-4': size === 'small', // 16px
    'sui-w-6 sui-h-6': size === 'regular', // 24px
    'sui-w-8 sui-h-8': size === 'large', // 32px,
    'sui-shrink': shrink === 1,
    'sui-shrink-0': shrink === 0,
  }, secondaryColor);

  return (
    <svg
      className={classes}
      ref={ref}
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      {...other}
    >
      {children}
    </svg>
  );
});

SvgIcon.displayName = 'SvgIcon';

SvgIcon.propTypes = {
  /**
   * @ignore
   * If `true`, the svg element will have a rounded border.
   */
  rounded: PropTypes.bool,
  /**
   * @ignore
   * The set of path that conforms this Icon
   */
  children: PropTypes.node,
  /**
   * The fill color to be applied to all descendant elements
   */
  color: PropTypes.oneOf(['primary', 'inverse', 'inactive', 'brand', 'brand-subtle', 'subtle', 'success', 'success-strong', 'danger', 'danger-strong', 'medium', 'warning', 'warning-strong', 'info', 'info-strong', 'current', 'inherit']),
  /**
   * Indicates the second color applied to certain elements in a two-tone icon
   */
  secondaryColor: PropTypes.oneOf(['success', 'warning', 'danger', 'info', 'brand']),
  /**
   * Indicates the background color of the svg element
   */
  backgroundColor: PropTypes.oneOf(['primary', 'inactive', 'inverse']),
  /**
   * Indicates whether to allow (1) or prevent (0) the icon from shrinking in a flex container.
   */
  shrink: PropTypes.oneOf([0, 1]),
  /**
   * Indicates the icon size
   */
  size: PropTypes.oneOf(['small', 'regular', 'large', 'inherit'])
};

export { SvgIcon };