import React from 'react';

const maxCharactersForMobileLine = 50;
const textHeightPlusPadding = 25;
const numberOfLinesWhenTruncated = 4;
class ReviewHelper {

  static getHighlightedText = (reviewId, text, searchKeyWord) => {
    if (!searchKeyWord) {
      return text;
    }
    const parts = text.split(new RegExp(`(${searchKeyWord})`, 'gi'));
    return (
      <span key={`search_by_${searchKeyWord}`}>
        {parts.map((part, index) => (part.toLowerCase() === searchKeyWord.toLowerCase()
          // eslint-disable-next-line max-len
          ? <span key={`search_keyword_${reviewId}_${index}`} style={{ backgroundColor: 'rgba(249, 99, 2, 0.4)', fontWeight: 'bold' }}>{part}</span>
          : part))}
      </span>
    );
  };

  static getCalculatedReviewHeight = (reviewText) => {
    return (reviewText.length / maxCharactersForMobileLine) * textHeightPlusPadding;
  };

  static shouldTruncate = (reviewText) => {
    const charactersToTruncate = numberOfLinesWhenTruncated * maxCharactersForMobileLine;
    return (reviewText && reviewText.length > charactersToTruncate);
  };

  static fetchRatingProperties = (rating) => {
    return { totalReviews: rating, averageRating: rating };
  };

  static formatSubmissionDate = (date) => {
    if (!date) { return date; }
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May',
      'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = new Date(Date.parse(date));
    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  static numberWithCommas = (number) => {
    const nfObject = new Intl.NumberFormat('en-US');
    return nfObject.format(number?.toString());
  };
}

export default ReviewHelper;
