import { params } from '@thd-nucleus/data-sources';
import { homepageWelcomeCardModel } from './HomepageWelcomeCardModel';
import { availablePerksCountModel } from './AvailablePerksCountModel';
import { offerChallengesInfoModel } from './OfferChallengesInfoModel';
import { acceptChallengeModel } from './AcceptChallengeModel';
import { activatePerksModel } from './ActivatePerksModel';
import { infoPerksModel } from './InfoPerksModel';
import { redeemPerksModel } from './RedeemPerksModel';
import {
  authParams, acceptChallengeParams, activatePerkParams,
  infoPerksParams, redeemPerkParams
} from './ParamsModel';
import { ENDPOINTS } from '../../core/Constants';

const LoyaltyWelcomeCardModel = {
  [ENDPOINTS.HOMEPAGE]: params(authParams).shape(homepageWelcomeCardModel),
  [ENDPOINTS.AVAILABLE_PERKS]: params(authParams).shape(availablePerksCountModel),
};

const LoyaltyPerksDrawerModel = {
  [ENDPOINTS.ACTIVATE_PERKS]: params(activatePerkParams).mutation().shape(activatePerksModel),
  [ENDPOINTS.INFO_PERKS]: params(infoPerksParams).shape(infoPerksModel),
  // [ENDPOINTS.INFO_OFFERS]: params(infoPerksParams).shape(infoOffersModel),
  // [ENDPOINTS.INFO_PERKS]: params(infoPerksParams).shape({ // TODO: Figure out how to handle "offerType=offer"
  //   perks: shape(infoPerksModel),
  //   offers: shape(infoOffersModel),
  // }),
  [ENDPOINTS.REDEEM_PERKS]: params(redeemPerkParams).mutation().shape(redeemPerksModel),
};

const LoyaltyOffersDrawerModel = { ...LoyaltyPerksDrawerModel };

const TesterCardModel = {
  [ENDPOINTS.HOMEPAGE]: params(authParams).shape(homepageWelcomeCardModel),
  [ENDPOINTS.AVAILABLE_PERKS]: params(authParams).shape(availablePerksCountModel),
  [ENDPOINTS.CHALLENGES_INFO]: params(authParams).shape(offerChallengesInfoModel),
  [ENDPOINTS.ACTIVATE_PERKS]: params(activatePerkParams).shape(activatePerksModel),
  [ENDPOINTS.INFO_PERKS]: params(infoPerksParams).shape(infoPerksModel),
  [ENDPOINTS.REDEEM_PERKS]: params(redeemPerkParams).shape(redeemPerksModel),
  [ENDPOINTS.ACCEPT_CHALLENGES]: params(acceptChallengeParams).shape(acceptChallengeModel),
};

export {
  LoyaltyWelcomeCardModel,
  LoyaltyPerksDrawerModel,
  LoyaltyOffersDrawerModel,
  TesterCardModel
};
