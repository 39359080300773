/* eslint-disable tailwindcss/no-custom-classname */
import React, { forwardRef, useEffect, Children } from 'react';
import {
  bool, func, node, number, string
} from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Hydrator } from '@thd-olt-component-react/hydrator';

import { CardContent } from '@one-thd/sui-atomic-components';
import { OrderPlaceholder } from '../../placeholders/orderPlaceholder';

export const OrderWrapper = forwardRef(({
  children,
  index = null,
  loading = true,
  orderNumber = '',
  setShouldFetchOrderDetails
}, ref) => {

  useEffect(() => {
    if (ref?.current) {
      setShouldFetchOrderDetails(true);
    }
  }, [ref]);

  const cardContentClasses = {
    flexClasses: 'sui-flex sui-flex-col sui-content-between',
    sizeClasses: 'sui-p-4 sui-w-full sui-h-full',
    decorationClasses: 'sui-border-1 sui-border-solid sui-border-primary sui-rounded-sm'
  };

  return (
    <QueryProvider cacheKey={`recent-orders-item-${orderNumber}`}>
      <CardContent
        ref={ref}
        data-testid={`recentOrders-contentCard${index}`}
        className={`${cardContentClasses.flexClasses} ${cardContentClasses.sizeClasses} ${cardContentClasses.decorationClasses} recentOrdersCarouselContainer`}
      >
        { loading ? (
          <OrderPlaceholder />
        ) : (
          Children.toArray(children)
        )}
      </CardContent>
    </QueryProvider>
  );
});

export const LazyOrderWrapper = forwardRef(({
  children,
  index = null,
  loading = true,
  orderNumber = null,
  setShouldFetchOrderDetails
}, ref) => {

  return (
    <Hydrator
      delay={1200}
      className="sui-w-full sui-h-full recentOrdersCarouselContainer"
      id={`recent-orders-status-hydrator-${orderNumber}-${index}`}
      placeholder={<OrderPlaceholder />}
    >
      <OrderWrapper
        ref={ref}
        index={index}
        loading={loading}
        orderNumber={orderNumber}
        setShouldFetchOrderDetails={setShouldFetchOrderDetails}
      >
        {Children.toArray(children)}
      </OrderWrapper>
    </Hydrator>
  );
});

OrderWrapper.propTypes = {
  children: node.isRequired,
  index: number,
  loading: bool,
  orderNumber: string,
  setShouldFetchOrderDetails: func.isRequired
};

LazyOrderWrapper.propTypes = {
  children: node.isRequired,
  index: number,
  loading: bool,
  orderNumber: string,
  setShouldFetchOrderDetails: func.isRequired,
};


