import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  arrayOf,
  shape,
  string,
  number,
  bool,
  func
} from 'prop-types';
import { TableRow, TableCell } from '@one-thd/sui-atomic-components';

import { InstoreOrderDetails } from '../orderDetails/inStoreOrderDetails';
import { OnlineOrderDetails } from '../orderDetails/onlineOrderDetails';
import { RecentPurchasesTableDate } from '../subcomponents/orderDate/RecentPurchasesDate';
import { RecentPurchasesTableJobName } from '../subcomponents/jobName/RecentPurchasesJobName';
import { RecentPurchasesTableOrigin } from '../subcomponents/orderOrigin/RecentPurchasesOrigin';
import { RecentPurchasesTableReceipt } from '../subcomponents/orderReceipt/RecentPurchasesReceipt';
import { RecentPurchasesTableTotal } from '../subcomponents/orderTotal/RecentPurchasesTotal';
import { RecentPurchasesTableButton } from '../subcomponents/ordersButton/RecentPurchasesButton';
import { InstoreEditPOJobName } from '../editPOJobName/instoreEditPOJobName';
import { OnlineEditPOJobName } from '../editPOJobName/onlineEditPOJobName';
import { OrderStatus, LazyOrderStatus } from '../subcomponents/orderStatus';
import { OrderItems, LazyOrderItems } from '../subcomponents/orderItems';
import {
  createOrderDetailsURL,
  getStoreNumber,
  isOnlineOrder,
} from '../helpers';

const LAZY_LOAD_INDEX_THRESHOLD = 4;

export function TableOrder({
  customerInfo = {},
  index = undefined,
  onError = () => {},
  order = {},
  windowWidth = undefined,
}) {
  const isOnline = order.orderNumbers && order.orderNumbers[0] ? isOnlineOrder(order.orderNumbers[0]) : false;
  const storeNumber = getStoreNumber(order);
  const orderDetailsUrl = createOrderDetailsURL({
    isOnline,
    order,
    customerInfo,
    storeNumber,
  });
  const EditPOJobName = isOnline ? OnlineEditPOJobName : InstoreEditPOJobName;
  const OrderDetailsType = isOnline ? OnlineOrderDetails : InstoreOrderDetails;
  const OrderStatusComponent = index < LAZY_LOAD_INDEX_THRESHOLD ? OrderStatus : LazyOrderStatus;
  const OrderItemsComponent = index < LAZY_LOAD_INDEX_THRESHOLD ? OrderItems : LazyOrderItems;

  return (
    <QueryProvider cacheKey={order.receiptDetails}>
      <TableRow className="sui-table sui-w-full sui-table-fixed sui-border-b-1 sui-border-solid sui-border-primary">
        <TableCell className="sui-w-28 sui-p-4">
          <RecentPurchasesTableDate orderDate={order.salesDate} />
        </TableCell>
        <TableCell className="sui-w-28 sui-p-4">
          <OrderStatusComponent
            customerInfo={customerInfo}
            isOnline={isOnline}
            layout="table"
            order={order}
            OrderDetailsType={OrderDetailsType}
            index={index}
          />
        </TableCell>
        <TableCell className="sui-w-52 sui-hidden md:sui-table-cell sui-p-4">
          <OrderItemsComponent
            isOnline={isOnline}
            customerInfo={customerInfo}
            layout="table"
            order={order}
            OrderDetailsType={OrderDetailsType}
            windowWidth={windowWidth}
            orderDetailsUrl={orderDetailsUrl}
            index={index}
          />
        </TableCell>
        <TableCell className="sui-w-32 sui-p-4">
          <RecentPurchasesTableReceipt
            orderReceiptDetails={
              isOnline ? order.orderNumbers[0] : order.receiptDetails
            }
          />
        </TableCell>
        <TableCell className="sui-w-32 sui-p-4">
          <QueryProvider cacheKey={order.POJobName}>
            <EditPOJobName order={order} customerInfo={customerInfo}>
              {({ mutatePOJobName }) => {
                return (
                  <RecentPurchasesTableJobName
                    POJobName={order.POJobName}
                    customerInfo={customerInfo}
                    order={order}
                    isOnline={isOnline}
                    onError={onError}
                    mutatePOJobName={mutatePOJobName}
                    storeNumber={storeNumber}
                  />
                );
              }}
            </EditPOJobName>
          </QueryProvider>
        </TableCell>
        <TableCell className="sui-w-28 sui-p-4">
          <RecentPurchasesTableOrigin orderOrigin={order.orderOrigin} />
        </TableCell>
        <TableCell className="sui-w-24 sui-p-4">
          <RecentPurchasesTableTotal orderTotal={order.totalAmount} />
        </TableCell>
        <TableCell className="sui-w-16 sui-p-4">
          <RecentPurchasesTableButton href={orderDetailsUrl} />
        </TableCell>
      </TableRow>
    </QueryProvider>
  );
}

TableOrder.propTypes = {
  order: shape({
    salesDate: string,
    orderNumber: string,
    orderNumbers: arrayOf(string),
    totalAmount: number,
    storeName: string,
    accountIdUsed: string,
    accountIdType: string,
    showReceiptImage: bool,
    receiptDetails: string,
    registerNumber: number,
    orderOrigin: string,
    rewardsDiscountAmount: number,
    otherDiscountAmount: number,
    preTaxAmount: number,
    receiptAddedDate: string,
    showBigReceipt: bool,
    purchaserId: string,
    detailsSource: string,
    invoiceNumbers: number,
    invoiceNumbersForCSV: number,
    isGiftCardPerksUsed: bool,
    preferredDiscFlg: string,
    transactionId: number,
    POJobName: string,
  }),
  windowWidth: number,
  customerInfo: shape({
    customerAccountId: string,
    userId: string,
  }),
  onError: func,
  index: number,
};

