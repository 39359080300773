import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Paper } from '../private/components/paper/Paper';
import { CardActionArea } from './CardActionArea';
import { CardContent } from './CardContent';

function createComponent(Component, displayName) {

  const Comp = (props, ref) => {

    const {
      as,
      ...other
    } = props;

    return (
      <Component component={as} ref={ref} {...other} />
    );
  };

  Comp.displayName = displayName;
  return React.forwardRef(Comp);
}

const CardRoot = createComponent(Paper, 'CardRoot');

/**
 * A card provides a brief summary of content or a task. Cards can be informational or navigational.
 * Cards are often displayed alongside other cards to group related content or tasks.
 *
 *
 * Props of the [CardContent](#cardcontent) component are also available.
 *
 *
 * Use `Card` with the following sub-components:
 * [CardActionArea](#cardactionarea), [CardActions](#cardactions), [CardBody](#cardbody), [CardContent](#cardcontent),
 * [CardMedia](#cardmedia), [CardTitle](#cardtitle), [Carousel](#carousel)
 *
 * Usage:
 *
 * ```jsx
 * import { Card } from '@one-thd/sui-atomic-components';
 * ```
 */
const Card = React.forwardRef((props, ref) => {

  const {
    CardActionAreaProps,
    CardContentProps,
    component = 'div',
    children,
    disableGutters = false,
    disablePadding = false,
    disableShrink = false,
    density,
    grow = false,
    href,
    initialSize,
    orientation = 'vertical',
    onClick,
    PaperProps: PaperPropsProp = {},
    variant: variantProp = 'border',
    ...other
  } = props;

  const isActionable = href || onClick;
  const paperShadow = variantProp === 'shadow' ? 'base' : 'none';

  const { variant = variantProp, shadow = paperShadow, ...rest } = PaperPropsProp;

  const PaperProps = {
    variant,
    shadow,
    ...rest,
    ...other
  };

  const cardRootClasses = classNames('sui-flex sui-rounded-md', {
    'sui-border-none sui-shadow-base hover:sui-shadow-lg sui-group/card-group': isActionable
  });

  return (
    <CardRoot
      as={component}
      ref={ref}
      className={cardRootClasses}
      {...PaperProps}
    >
      {isActionable ? (
        <CardActionArea href={href} onClick={onClick} {...CardActionAreaProps}>
          <CardContent
            orientation={orientation}
            disableGutters={disableGutters}
            disablePadding={disablePadding}
            disableShrink={disableShrink}
            grow={grow}
            initialSize={initialSize}
            {...CardContentProps}
          >
            {children}
          </CardContent>
        </CardActionArea>
      ) : (
        <CardContent
          orientation={orientation}
          disableGutters={disableGutters}
          disablePadding={disablePadding}
          disableShrink={disableShrink}
          density={density}
          grow={grow}
          initialSize={initialSize}
          {...CardContentProps}
        >
          {children}
        </CardContent>
      )}
    </CardRoot>
  );
});

Card.displayName = 'Card';

Card.propTypes = {
  /**
   * Props applied to the CardActionArea element.
   */
  CardActionAreaProps: PropTypes.object,
  /**
   * Props applied to the CardContent element.
   */
  CardContentProps: PropTypes.object,
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * @ignore
   */
  density: PropTypes.oneOf(['normal', 'loose']),
  /**
   * @ignore
   */
  disableGutters: PropTypes.bool,
  /**
   * @ignore
   */
  disablePadding: PropTypes.oneOf([true, 'x', 'y', false]),
  /**
   * @ignore
   */
  disableShrink: PropTypes.bool,
  /**
   * @ignore
   */
  grow: PropTypes.bool,
  /**
   * @ignore
   */
  href: PropTypes.string,
  /**
   * @ignore
   */
  initialSize: PropTypes.oneOf(['1/4', '2/4', '3/4', '4/4', 'full']),
  /**
   * @ignore
   */
  onClick: PropTypes.func,
  /**
   * @ignore
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * Props applied to the `Paper` component.
   */
  PaperProps: PropTypes.object,
  /**
   * Card variant to use.
   * @default 'border'
   */
  variant: PropTypes.oneOf(['border', 'shadow'])
};

export { Card };
