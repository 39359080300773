import React, { useEffect } from 'react';
import { string, number, shape } from 'prop-types';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { BentoHeroContainer } from './BentoHeroContainer';

const BentoHeroRenderer = ({ componentId, componentPosition = 1, data, displayName }) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('bentoHero.ready');
  }, []);

  return (
    <ImpressionProvider
      key={componentPosition}
      data={{
        id: componentId,
        component: displayName,
        name: displayName,
        type: 'content'
      }}
    >
      <ThemeProvider theme={getThemeByName(data?.component?.theme)}>
        <BentoHeroContainer data={data?.component || data} />
      </ThemeProvider>
    </ImpressionProvider>
  );
};

BentoHeroRenderer.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  data: shape({}).isRequired,
  displayName: string.isRequired,
};

export { BentoHeroRenderer };
