import { track } from './ratings-reviews-analytics';
import { DEFAULT_FILTERS, DEFAULT_SORTBY } from './constants';

export const stateReducer = (state, action) => {
  const { type, value } = action;
  const { filters, selectedSentiment, pageContext, searchText } = state;

  const getChecked = (filter, filterValue) => {
    return filter.value === filterValue ? !filter.checked : filter.checked;
  };

  switch (type) {
  case 'filter': {
    pageContext.currentPage = 1;
    const updatedFilters = filters.map((filter) => {
      return {
        checked: getChecked(filter, value.toString()),
        id: filter.id,
        value: filter.value,
        count: filter.count,
        label: filter.label
      };
    });
    const isChecked = updatedFilters.find((filter) => filter.value === value.toString())?.checked;
    if (isChecked) {
      track('event', {
        origin: 'ratings and reviews',
        value,
        location: type
      });
    }
    return {
      ...state,
      pageContext,
      filters: updatedFilters,
      selectedSentiment: null,
      searchText
    };
  }
  case 'removeFilter': {
    pageContext.currentPage = 1;
    const updatedSentiment = value.Feature ? null : selectedSentiment;
    let removeUpdatedFilters = filters;
    if (!value.Feature) {
      removeUpdatedFilters = filters.map((filter) => {
        return {
          checked: getChecked(filter, value.value),
          id: filter.id,
          value: filter.value,
          count: filter.count,
          label: filter.label
        };
      });
    }
    return {
      ...state,
      pageContext,
      filters: removeUpdatedFilters,
      selectedSentiment: updatedSentiment
    };
  }
  case 'pageChange': {
    return {
      ...state,
      pageContext: value
    };
  }
  case 'search': {
    pageContext.currentPage = 1;
    return {
      ...state,
      pageContext,
      searchText: value
    };
  }
  case 'sentiment': {
    // on sentiment change reset other filters, if the sentiment is currently selected reset that as well
    const { Feature } = value;
    pageContext.currentPage = 1;
    return {
      ...state,
      filters: [...DEFAULT_FILTERS],
      pageContext,
      searchText: null,
      selectedSentiment: Feature === selectedSentiment?.Feature ? null : value,
      sortBy: DEFAULT_SORTBY
    };
  }
  case 'sort': {
    pageContext.currentPage = 1;
    return {
      ...state,
      pageContext,
      sortBy: value
    };
  }
  case 'totalPages': {
    pageContext.totalPages = value;
    return {
      ...state,
      pageContext
    };
  }
  case 'pageLoad': {
    pageContext.currentPage = value;
    return {
      ...state,
      pageContext
    };
  }
  default: {
    return state;
  }
  }
};
