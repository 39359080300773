import React from 'react';
import {
  string,
  shape,
  arrayOf,
  element,
  bool,
  oneOf
} from 'prop-types';
import classnames from 'classnames/bind';
import { LayoutSection } from './LayoutSection';

export const Layout = ({
  layoutType,
  components,
  layoutComponents = [],
  componentSelectorData,
  loading = { server: false, client: false },
  LoadingLayout = null,
  alwaysHydrate = false
}) => {
  const heroCarouselIdx = layoutComponents?.findIndex(
    (component) => component?.customRenderId === 'Hero Carousel'
  );
  const heroCarousel = layoutComponents[heroCarouselIdx];

  const marketingBannerIdx = layoutComponents?.findIndex(
    (component) => component?.customRenderId === 'internalMarketingBanner'
  );
  const marketingBanner = layoutComponents[marketingBannerIdx];

  const bentoHeroIdx = layoutComponents?.findIndex(
    (component) => component?.customRenderId === 'bentoHero'
  );
  const bentoHero = layoutComponents[bentoHeroIdx];

  const style = {
    layoutRoot: classnames(
      'sui-w-full',
      'sui-max-w-screen-2xl',
      'sui-m-auto',
      {
        'lg:sui-pb-12': layoutType === 'themed-layout',
        'sui-pb-8': layoutType === 'themed-layout',
      }
    ),
    layoutGrid: classnames(
      'sui-grid',
      'sui-grid-flow-row-dense',
      'sui-grid-cols-12',
      'sui-mx-4',
      'lg:sui-mx-0',
      'sui-gap-4',
      { 'lg:sui-gap-y-12': layoutType === 'themed-layout' },
    )
  };

  return layoutComponents.length > 0 ? (
    <div id={layoutType} className={style.layoutRoot}>
      <LayoutSection
        layoutComponent={marketingBanner}
        index={marketingBannerIdx}
        components={components}
        componentSelectorData={componentSelectorData}
      />
      <LayoutSection
        layoutComponent={heroCarousel}
        index={heroCarouselIdx}
        components={components}
        componentSelectorData={componentSelectorData}
      />
      <LayoutSection
        layoutComponent={bentoHero}
        index={bentoHeroIdx}
        components={components}
        componentSelectorData={componentSelectorData}
      />
      <div
        data-testid="layout-test"
        className={style.layoutGrid}
      >
        {layoutComponents.map((layoutComponent, index) => {
          if (
            !layoutComponent
            || !layoutComponent?.customRenderId
            || layoutComponent?.customRenderId === 'Hero Carousel'
            || layoutComponent?.customRenderId === 'internalMarketingBanner'
            || layoutComponent?.customRenderId === 'bentoHero'
          ) return false;

          return (
            <LayoutSection
              key={index}
              layoutComponent={layoutComponent}
              index={index}
              components={components}
              componentSelectorData={componentSelectorData}
              alwaysHydrate={alwaysHydrate}
              bentoHeroExists={!!bentoHero}
            />
          );
        })}
      </div>
      {(loading.server || loading.client) && LoadingLayout}
    </div>
  ) : <></>;
};

Layout.propTypes = {
  layoutType: oneOf(['themed-layout', 'default-layout']).isRequired,
  components: shape({
    [string]: element,
  }).isRequired,
  layoutComponents: arrayOf(shape),
  componentSelectorData: shape({
    variationIds: arrayOf(shape({ [string]: element })),
    renderDefault: bool,
    mboxes: arrayOf(string),
    campaignIds: arrayOf(shape({ [string]: element })),
    innervateTags: arrayOf(shape({ [string]: element }))
  }).isRequired,
  loading: shape({
    server: bool,
    client: bool,
  }),
  LoadingLayout: element,
  alwaysHydrate: bool
};