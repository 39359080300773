import React from 'react';
import '../NotificationSection.scss';
import { ArrowForward } from '@one-thd/sui-icons';
import { Image } from '@thd-olt-component-react/core-ui';
import { analyticsStash } from '../../../../common/utils/AnalyticsUtils';
import { getImageURL } from '../../../../common/utils/CardUtils';

export const SkinnyBar = (
  {
    linkURL,
    analyticsMessage,
    analyticsLinks,
    imgUrl = '',
    bodyMessage = 'Get Easy Purchase Tracking',
  },
  small
) => {
  const hasImg = imgUrl !== '';
  let textSize = small ? 'sui-text-small' : 'sui-text-base';
  const tyopographyClass = `sui-font-regular ${textSize} sui-leading-tight sui-tracking-normal
  sui-normal-case sui-line-clamp-unset sui-text-left`;
  return (
    <div className="notification-block sui-w-full sui-mt-4 skinny-bar">
      <a
        className="link-url sui-flex sui-flex-row sui-justify-between sui-w-full"
        href={linkURL}
        rel="noreferrer"
        onClick={() => analyticsStash('welcomeCard', analyticsMessage, analyticsLinks)}
      >
        {hasImg && (
          <div className="sui-flex sui-flex-col sui-justify-center">
            <Image src={getImageURL(imgUrl)} width="32px" height="32px" alt="perks" />
          </div>
        )}
        <div
          className={`notification-label sui-flex sui-flex-col sui-justify-center sui-w-full
          ${hasImg ? 'sui-pl-3' : ''}`}
        >
          <p className={tyopographyClass}>{bodyMessage}</p>
        </div>
        <div className="sui-flex sui-flex-col sui-justify-center">
          <ArrowForward size="small" />
        </div>
      </a>
    </div>
  );
};

export const ChallengeSkinnyBar = ({ linkURL, analyticsMessage, analyticsLinks, bodyMessage }) => {
  return (
    <div className="notification-block sui-w-full sui-mt-4 challengeSkinny-bar">
      <a
        className="link-url sui-flex sui-flex-row sui-justify-between sui-w-full"
        href={linkURL}
        rel="noreferrer"
        onClick={() => analyticsStash('welcomeCard', analyticsMessage, analyticsLinks)}
      >
        <div className="notification-label sui-flex sui-flex-col sui-justify-center sui-w-full">
          <p
            className={`sui-font-regular sui-text-base sui-leading-tight sui-tracking-normal
  sui-normal-case sui-line-clamp-2 sui-text-left challengeMessage`}
          >
            {bodyMessage}
          </p>
        </div>
        <div className="sui-flex sui-flex-col sui-justify-center">
          <ArrowForward size="small" />
        </div>
      </a>
    </div>
  );
};
