import React from 'react';
import { string as stringType, number } from 'prop-types';
import {
  params,
  string,
  json,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { PopularCategory } from './PopularCategory';

const POPULAR_CATEGORIES = {
  home: 'homePopularCategories',
  storeDetails: 'storeDetailsPopularCategories',
  storeReviews: 'storeReviewsPopularCategories',
  homeServices: 'homeServicesPopularCategories',
  rentals: 'rentalsPopularCategories',
  gardenCenter: 'gardenCenterPopularCategories',
  proDesk: 'proDeskPopularCategories'
};

const PopularCategories = ({ contentId, componentId, pageType }) => {
  const { data, loading, error } = useDataModel('emtContent', {
    variables: {
      contentId,
      pageName: pageType
    },
    skip: !contentId || !pageType
  });

  const popCatKey = POPULAR_CATEGORIES[pageType];
  const popCatList = data?.emtContent?.content?.[popCatKey]?.popularCategories || [];

  if (!data || loading || error || popCatList?.length === 0) {
    return null;
  }

  return (
    <div className="sui-flex sui-flex-col sui-gap-4" data-component="PopularCategories">
      <Typography variant="h2">Popular Categories</Typography>
      <div className="sui-flex sui-flex-wrap sui-leading-tight sui-gap-4">
        {popCatList.map((popCat, index) => (
          <PopularCategory
            key={`popcat-${popCat?.title}${index}`}
            componentId={componentId}
            url={popCat?.paramlink}
            copyText={popCat?.title}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

PopularCategories.displayName = 'PopularCategories';

PopularCategories.propTypes = {
  contentId: stringType.isRequired,
  pageType: stringType.isRequired,
  componentId: stringType
};

PopularCategories.defaultProps = {
  componentId: ''
};

PopularCategories.dataModel = {
  emtContent: params({
    contentId: string().isRequired(),
    pageName: string().isRequired()
  }).shape({
    content: json()
  })
};

export { PopularCategories };
