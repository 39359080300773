import React, { useContext } from 'react';
import { Carousel } from '@one-thd/sui-carousel';
import { useStore } from '@thd-nucleus/experience-context';
import { QueryProvider, QueryContext } from '@thd-nucleus/data-sources';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  arrayOf, bool, shape, string, oneOf
} from 'prop-types';
import { NoProductsMessage } from './NoProductsMessage';
import { CustomProductPod } from './CustomProductPod';
import { CategoryBlock } from './CategoryBlock';

const CategoryCarousel = ({
  categoryImageURL = '',
  categoryName = '',
  categoryShopAllLink = '',
  categoryURL = '',
  channel = 'DESKTOP',
  componentName = '',
  exclude = [],
  displayImageOnMobileWeb = false,
  endCapData = {},
  entryId = '',
  storeId = '121',
  theme = '',
  shouldResetTheme = false,
  title = '',
  activeIndex = '',
  name = null
}) => {
  const { storeZip: zipCode } = useStore();
  const { defaultVariables } = useContext(QueryContext);
  const { isBrandPricingPolicyCompliant = false } = defaultVariables?.current;
  const hasProducts = (endCapData?.products || []).filter((product) => {
    return (exclude || []).indexOf(product.itemId) === -1;
  }).length !== 0;
  // TODO: Temporary fix to add a max width for serverside render. Stencil team working on long term fix
  const productPodMaxWidth = typeof window !== 'undefined' ? 'none' : '240px';

  const properUrl = categoryShopAllLink !== '' ? categoryShopAllLink : categoryURL;
  const showCategoryBlock = categoryImageURL && properUrl;
  const dataSources = endCapData?.products?.[0]?.dataSources === 'personalizedDeals'
    ? 'personalizedDeals'
    : 'topDeals';
  const cacheKey = dataSources && dataSources === 'personalizedDeals'
    ? 'personalized-deals-child'
    : 'top-deals-child';

  let impressionName = title.replace(/\s/g, '');
  let impressionCategoryName = categoryName.replace(/\W+/g, '');

  const impressionData = {
    id: entryId || '',
    component: 'Endcap',
    name: impressionName || '',
    type: 'product',
    recType: dataSources || '',
    position: '',
    category: impressionCategoryName || '',
    categoryPosition: activeIndex,
  };
  if (name) {
    impressionData.sectionSlotID = name;
  }

  const itemTheme = shouldResetTheme ? 'ThdDefaultLightTheme' : theme;
  const processedItemTheme = getThemeByName(itemTheme);

  if (!hasProducts) {
    return (
      <NoProductsMessage
        categoryName={categoryName !== 'All' ? categoryName : ''}
        imageUrl={categoryImageURL}
        url={properUrl}
        title={title}
      />
    );
  }

  return (
    <ImpressionProvider data={impressionData}>
      <div className="endcap-section sui-w-full sui-flex sui-gap-4 sui-pt-5">
        <div
          id={'digital-endcap-' + endCapData.catName}
          className="digital-endcap sui-w-full sui-overflow-hidden"
        >
          <QueryProvider
            dataSource={endCapData?.products?.[0]?.dataSources}
            dataSources={endCapData?.products?.[0]?.dataSources}
            defaultVariables={{
              storeId,
              zipCode,
              skipSpecificationGroup: true,
              isBrandPricingPolicyCompliant
            }}
            cacheKey={cacheKey}
          >
            <Carousel
              slidesPerView={2}
              slidesPerGroup={2}
              breakpoints={{
                sm: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                md: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                lg: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
                xl: {
                  slidesPerView: 5,
                  slidesPerGroup: 5,
                }
              }}
              initialSlide={0}
              disableMargin
            >
              {(showCategoryBlock ? [
                <ThemeProvider theme={processedItemTheme}>
                  <CategoryBlock
                    categoryName={categoryName}
                    categoryImageURL={categoryImageURL}
                    properUrl={properUrl}
                    impressionData={impressionData}
                  />
                </ThemeProvider>
              ] : []).concat((endCapData?.products || [])
                .filter((product) => {
                  return (exclude || []).indexOf(product.itemId) === -1;
                })
                .map((product, productIndex) => (
                  <ThemeProvider theme={processedItemTheme}>
                    <div
                      id={'top-deals__wrapper-' + productIndex}
                      key={product.itemId}
                      style={{ maxWidth: productPodMaxWidth }}
                      className="sui-bg-primary sui-text-primary"
                    >
                      <CustomProductPod
                        itemId={product.itemId}
                        storeId={storeId}
                        scheme={product.dataSources}
                        productIndex={productIndex}
                        theme={theme}
                      />
                    </div>
                  </ThemeProvider>
                )))}
            </Carousel>
          </QueryProvider>
        </div>
      </div>
    </ImpressionProvider>
  );
};

CategoryCarousel.displayName = 'CategoryCarousel';
CategoryCarousel.propTypes = {
  channel: oneOf(['DESKTOP', 'MOBILE']),
  displayImageOnMobileWeb: bool,
  exclude: arrayOf(string),
  title: string,
  categoryName: string,
  categoryImageURL: string,
  categoryURL: string,
  categoryShopAllLink: string,
  componentName: string,
  endCapData: shape({
    products: arrayOf(shape)
  }),
  entryId: string,
  storeId: string,
  theme: string,
  shouldResetTheme: bool,
  activeIndex: string,
  name: string
};

export { CategoryCarousel };
