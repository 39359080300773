import React, {
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { triggerNewRelic, mkTelemetryField } from '../utils';
import { newRelicConstants } from '../constants';

export const useRetailMediaCarouselTelemetry = (props) => {
  const {
    carouselNode,
    data,
    loading,
    error,
    filteredProducts,
    requestedAdCount,
    renderNewCarousel,
  } = props;

  /**
   * IMPRESSION
   *
   * Triggers product-pod 'impression' event to NewRelic by listening to the
   * LIFE_CYCLE_EVENT_BUS.
   */
  useEffect(() => {
    // check
    if (!filteredProducts?.length) return undefined;

    const handler = ({ output }) => {

      const { impression, container } = output;

      // check
      if (container?.component !== 'SponsoredCarousel') return;
      if (impression?.component !== 'ProductPod') return;

      const product = filteredProducts.find((item) => item.itemId === impression?.id);
      if (!product) return;

      // ---
      const value1 = mkTelemetryField({ key: 'itemId', value: impression?.id });
      const value2 = mkTelemetryField({ key: 'trackSource', value: impression?.trackSource });
      const value3 = mkTelemetryField({ key: 'position', value: impression?.position });
      const value4 = mkTelemetryField({ key: 'slotId', value: impression?.slotId });
      const telemetryFields = `${value1}-${value2}-${value3}-${value4}`;
      // ---

      const kpiName = `${newRelicConstants.CAROUSEL_PLA}-${telemetryFields}`;
      const kpiValue = newRelicConstants.IMPRESSION;

      triggerNewRelic(kpiName, kpiValue);
    };

    // install
    window.LIFE_CYCLE_EVENT_BUS.on('impression.trigger', handler);

    // uninstall
    return () => {
      window.LIFE_CYCLE_EVENT_BUS.off('impression.trigger', handler);
    };
  }, [filteredProducts]);

  /**
   * FILL RATE
   * Push 'renderedProducts' and 'requestedAdCount' and 'carouselFillRate' data to new relic for fill rate
   */
  useEffect(() => {
    // check
    if (loading) return;
    if (!error && filteredProducts === null) return; // case: skipped

    const renderedProducts = filteredProducts?.length || 0;
    const carouselFillRate = renderedProducts / requestedAdCount;

    triggerNewRelic(`${newRelicConstants.CAROUSEL_PLA}-renderedProducts`, renderedProducts);
    triggerNewRelic(`${newRelicConstants.CAROUSEL_PLA}-requestedAdCount`, requestedAdCount);
    triggerNewRelic(`${newRelicConstants.CAROUSEL_PLA}-carouselFillRate`, carouselFillRate);

  }, [loading, error, filteredProducts, requestedAdCount]);

  /**
   * PLA_CAROUSEL_MOUNT
   */
  useEffect(() => {
    // check
    if (!carouselNode) return; // case: not mounted

    triggerNewRelic(`${newRelicConstants.CAROUSEL_PLA}`, newRelicConstants.PLA_CAROUSEL_MOUNT);

  }, [carouselNode]);

  /**
   * PLA_CAROUSEL_NOT_MOUNTED
   */
  useEffect(() => {
    // check
    if (loading) return;
    if (!error && filteredProducts === null) return; // case: skipped
    if (carouselNode || (filteredProducts?.length && renderNewCarousel)) return; // case: mounted

    // --
    let reason = 'other';
    if (error) reason = 'error_from_graphql';
    else if (!data?.plaModel?.products?.length) reason = 'zero_products_from_graphql';
    else if (!filteredProducts?.length) reason = 'zero_products_after_filtering';
    else if (!renderNewCarousel) reason = 'render_flag_is_false';
    // --

    const kpiName = `${newRelicConstants.CAROUSEL_PLA}-${newRelicConstants.PLA_CAROUSEL_NOT_MOUNTED}`;
    const kpiValue = mkTelemetryField({ key: 'reason', value: reason });

    triggerNewRelic(kpiName, kpiValue);

  }, [
    loading,
    error,
    carouselNode,
    filteredProducts?.length,
    data?.plaModel?.products?.length,
    renderNewCarousel
  ]);

  /**
   * PLA_CAROUSEL_FILTERED_PRODUCTS
   */
  useEffect(() => {
    // check
    if (loading) return;
    if (error) return;
    if (!error && filteredProducts === null) return; // case: skipped

    // --
    const field1 = mkTelemetryField({
      key: 'requestedAdCount',
      value: requestedAdCount
    });
    const field2 = mkTelemetryField({
      key: 'filteredProductsByPlaModel',
      value: requestedAdCount - (data?.plaModel?.products?.length || 0)
    });
    const field3 = mkTelemetryField({
      key: 'filteredProductsByPlaCarousel',
      value: (data?.plaModel?.products?.length || 0) - (filteredProducts?.length || 0)
    });
    const field4 = mkTelemetryField({
      key: 'renderedProducts',
      value: (filteredProducts?.length || 0)
    });
    const field5 = mkTelemetryField({
      key: 'numberOfMissingProductInformationProducts',
      value: (data?.plaModel?.sponsoredReport?.numberOfMissingProductInformationProducts || 0)
    });
    const field6 = mkTelemetryField({
      key: 'numberOfNonAvailableProducts',
      value: (data?.plaModel?.sponsoredReport?.numberOfNonAvailableProducts || 0)
    });
    const field7 = mkTelemetryField({
      key: 'numberOfSponsoredProducts',
      value: (data?.plaModel?.sponsoredReport?.numberOfSponsoredProducts || 0)
    });
    // --

    const kpiName = `${newRelicConstants.CAROUSEL_PLA}-${newRelicConstants.PLA_CAROUSEL_FILTERED_PRODUCTS}`;
    const kpiValue = `${field1}-${field2}-${field3}-${field4}-${field5}-${field6}-${field7}`;

    triggerNewRelic(kpiName, kpiValue);
  }, [
    loading,
    error,
    filteredProducts,
    requestedAdCount,
    data?.plaModel?.products?.length,
    data?.plaModel?.sponsoredReport?.numberOfMissingProductInformationProducts,
    data?.plaModel?.sponsoredReport?.numberOfNonAvailableProducts,
    data?.plaModel?.sponsoredReport?.numberOfSponsoredProducts,
  ]);

  /**
   * SLIDES CHANGES
   */
  const slideChangesRef = useRef(0);
  useEffect(() => {
    // check
    if (!carouselNode) return undefined; // case: not mounted

    const handler = () => {
      triggerNewRelic(`${newRelicConstants.CAROUSEL_PLA}-slideChanges`, slideChangesRef.current);
      slideChangesRef.current = 0;
    };

    // case: unload (window)
    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);

      // case: umount (component)
      handler();
    };
  }, [carouselNode]);

  // ---- ----
  // Callbacks
  // ---- ----

  /**
   * PLA_CAROUSEL_SLIDE_CHANGE
   */
  const triggerSlideChange = useCallback((swiper) => {
    // check
    if (!swiper) return;

    slideChangesRef.current += 1;

    const fieldSlidesPerPage = mkTelemetryField({ key: 'slidesPerPage', value: swiper.slidesPerViewDynamic() });
    const fieldActiveIndex = mkTelemetryField({ key: 'activeIndex', value: swiper.activeIndex });
    const telemetryStr = `${fieldSlidesPerPage}-${fieldActiveIndex}`;

    const kpiName = `${newRelicConstants.CAROUSEL_PLA}-${telemetryStr}`;
    const kpiValue = newRelicConstants.PLA_CAROUSEL_SLIDE_CHANGE;

    triggerNewRelic(kpiName, kpiValue);
  }, []);

  // --- ---
  return {
    triggerSlideChange,
  };
};
