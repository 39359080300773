/* eslint-disable no-param-reassign */
import * as React from 'react';
import {
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Skeleton,
  SkeletonLine
} from '@one-thd/sui-atomic-components';
import { SvgComponent } from '../../utils/IconUtils';
import { PacDrawerContext } from '../../../contexts/PacDrawerContext';
import { getImageURL } from '../../utils/CardUtils';
import './PacDrawer.scss';

export const PacDrawer = ({
  headerText,
  onBack,
  onClose,
  iconImgUrl,
  iconSvgUrl,
  IconComponent,
  title,
  subTitle,
  Content,
  Footer,
}) => {

  const fallbackImage = React.useCallback(
    (image, fallbackSrc = getImageURL('ProTiers/cardIcon-ProXtraPerks-square.svg')) => {
      image.target.onerror = null;
      image.target.src = fallbackSrc;
    }, []);
  const showDrawerTitle = !!title || !!subTitle || !!iconImgUrl || !!iconSvgUrl || !!IconComponent;

  const DrawerTitle = () => {
    return (
      <>
        {/* eslint-disable-next-line tailwindcss/no-arbitrary-value */}
        <div className="pac-drawer__bg sui-mx-[-16px] sui-mt-[-16px] sui-px-4
        sm:sui-px-6 sui-pt-5 sui-pb-6 sm:sui-py-9"
        >
          {!!iconImgUrl && (
            <div className="sui-pb-8">
              <img
                width="56"
                height="56"
                src={iconImgUrl}
                alt="Reward"
                onError={fallbackImage}
              />
            </div>
          )}
          {(!!iconSvgUrl || !!IconComponent) && (
            <div className="sui-pb-8">
              {!!IconComponent && <IconComponent />}
              {!!iconSvgUrl && <SvgComponent url={iconSvgUrl} height="56px" width="56px" />}
            </div>
          )}
          {!!title && (
            <div className="sui-text-6xl sm:sui-text-7xl sui-font-display sui-mb-2">
              {title}
            </div>
          )}
          {!!subTitle && (
            <div className="sui-text-base sm:sui-text-lg sui-font-bold">
              {subTitle}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {!!headerText && (
        <div className="pac-drawer__bg sui-relative">
          <DrawerHeader
            title={headerText}
            onBack={onBack}
            onClose={onClose}
            className="sui-flex sui-flex-col pac-drawer__bg sui-p-2"
          />
          <span className="pac-drawer__bg sui-h-4 sui-absolute sui-top-12 sui-left-0 sui-right-0 sui-z-max" />
        </div>
      )}
      <DrawerBody>
        {showDrawerTitle && <DrawerTitle />}
        {!!Content && (
          <div className="sui-pt-8 sui-text-base sui-leading-normal sm:sui-px-2">
            <Content />
          </div>
        )}
      </DrawerBody>
      {!!Footer && (
        <DrawerFooter>
          <div className="sm:sui-px-2 sm:sui-pb-2">
            <Footer />
          </div>
        </DrawerFooter>
      )}
    </>
  );
};

export const LoadingPacDrawer = (props) => {
  const { headerText, onBack, numberOfLines, numberOfRepeats } = props ?? {};
  const { setOpen } = React.useContext(PacDrawerContext) ?? {};
  const onClose = () => setOpen(false);
  const Content = () => (
    <Skeleton disablePadding grow>
      {[...Array(numberOfRepeats ?? 3)].map((num, idx) => (
        <React.Fragment key={'skelly_' + idx}>
          <SkeletonLine numberOfLines={numberOfLines ?? 3} />
          <div className="sui-h-1" />
        </React.Fragment>
      ))}
    </Skeleton>
  );
  return (
    <>
      <DrawerHeader
        title={headerText}
        onClose={onClose}
        onBack={onBack}
      />
      <DrawerBody>
        <Content />
      </DrawerBody>
    </>
  );
};