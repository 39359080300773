/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import helpers from '../../helpers/price-helper';
import { ClearancePriceDetails } from './ClearancePriceDetails';

export const ClearancePopoverContent = ({
  message, originalPrice, clearanceValue, dollarOff, percentageOff, unitsClearancePrice, caseUnitOfMeasure,
  unitsOriginalPrice, alternatePriceDisplay
}) => {
  const isClearanceValueSameOrMoreOrginalPrice = (clearanceValue >= originalPrice);
  const originalPriceComponents = helpers.parsePriceValue(originalPrice);
  const clearancePriceComponents = helpers.parsePriceValue(clearanceValue);
  const dollarOffComponents = helpers.parsePriceValue(dollarOff);
  const saveDollarOffPercentage = percentageOff !== 0 ? `(${percentageOff}%)` : '';
  const saveDollarOffText = `Save $${dollarOffComponents.dollars}.${dollarOffComponents.cents} `
    + `${saveDollarOffPercentage} `;
  const saveAlternateDollarOffPercentageText = percentageOff !== 0 ? `Save ${percentageOff}% ` : '';

  const unitsClearancePriceComponents = helpers.parsePriceValue(unitsClearancePrice);
  const unitsOriginalPriceComponents = helpers.parsePriceValue(unitsOriginalPrice);

  const priceDetailsClass = classNames('sui-pt-1 sui-pb-1',
    {
      'price-detailed-left-wrapper': (alternatePriceDisplay && unitsClearancePriceComponents !== ''),
      'sui-w-80': unitsClearancePriceComponents === ''
    });
  return (
    <Typography
      variant="body-xs"
      data-automation-id="clearancePopoverMessages"
      component="div"
    >
      <span className="sui-text-base">
        {message}
      </span>
      <div className="sui-pt-4" />
      <span className="sui-flex">
        <span className={priceDetailsClass} data-testid="clearancePriceContainer">
          <ClearancePriceDetails
            clearancePriceComponents={clearancePriceComponents}
            originalPriceComponents={originalPriceComponents}
            isClearanceValueSameOrMoreOrginalPrice={isClearanceValueSameOrMoreOrginalPrice}
            dollarOffComponents={dollarOffComponents}
            saveDollarOffText={saveDollarOffText}
            message={!isClearanceValueSameOrMoreOrginalPrice ? 'Online Price ' : ''}
          />
        </span>
        {(alternatePriceDisplay && unitsClearancePriceComponents !== '')
        && (
          <span className="sui-pt-1 sui-pb-1 sui-pl-3" data-testid="unitsPriceContainer">
            <ClearancePriceDetails
              clearancePriceComponents={unitsClearancePriceComponents}
              originalPriceComponents={unitsOriginalPriceComponents}
              dollarOffComponents={dollarOffComponents}
              saveDollarOffText={saveAlternateDollarOffPercentageText}
              message={caseUnitOfMeasure ? `/${caseUnitOfMeasure} ` : ''}
              isClearanceValueSameOrMoreOrginalPrice={isClearanceValueSameOrMoreOrginalPrice}
            />
          </span>
        )}
      </span>
      <div className="sui-pb-4" />
    </Typography>
  );
};

ClearancePopoverContent.propTypes = {
  message: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  clearanceValue: PropTypes.number.isRequired,
  dollarOff: PropTypes.number.isRequired,
  percentageOff: PropTypes.number.isRequired,
  unitsClearancePrice: PropTypes.number,
  unitsOriginalPrice: PropTypes.number,
  caseUnitOfMeasure: PropTypes.string,
  alternatePriceDisplay: PropTypes.bool
};

ClearancePopoverContent.defaultProps = {
  unitsClearancePrice: '',
  alternatePriceDisplay: false,
  caseUnitOfMeasure: '',
  unitsOriginalPrice: ''
};
