import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { triggerNewRelic, mkTelemetryField } from '../utils';
import { newRelicConstants } from '../constants';

export const useRetailMediaCarouselPodTelemetry = (props) => {
  const {
    productPodNode,
    itemId,
    trackSource,
    position,
    slotId,
    podKpiName = newRelicConstants.CAROUSEL_PLA,
  } = props;

  // --
  const baseTelemetryFields = useMemo(() => {
    // ---
    const fieldItemId = mkTelemetryField({ key: 'itemId', value: itemId });
    const fieldTrackSource = mkTelemetryField({ key: 'trackSource', value: trackSource });
    const fieldPosition = mkTelemetryField({ key: 'position', value: position });
    const fieldSlotId = mkTelemetryField({ key: 'slotId', value: slotId });
    // ---

    return `${fieldItemId}-${fieldTrackSource}-${fieldPosition}-${fieldSlotId}`;

  }, [itemId, trackSource, position, slotId]);

  /**
   * PLA_PRODUCT_POD_MOUNT - NewRelic.
   */
  useEffect(() => {
    // check
    if (!productPodNode) return;

    const kpiName = `${podKpiName}-${baseTelemetryFields}`;
    const kpiValue = newRelicConstants.PLA_PRODUCT_POD_MOUNT;

    triggerNewRelic(kpiName, kpiValue);

  }, [productPodNode, baseTelemetryFields]);

  // ---- ----
  // Callbacks
  // ---- ----

  /**
   * 'CLICK' - Base handler to trigger click to NewRelic.
   *
   * @param src should be one of:
   * - productShowNowButton
   * - productAtcButton
   * - productImage
   * - productHeader
   * - productRating
   * - productPod
   * - clickNewTab
   */
  const triggerClick = useCallback((src) => {
    const srcField = mkTelemetryField({ key: 'src', value: src });
    const telemetryFields = `${baseTelemetryFields}-${srcField}`;

    const kpiName = `${podKpiName}-${telemetryFields}`;
    const kpiValue = newRelicConstants.CLICK;

    triggerNewRelic(kpiName, kpiValue);
  }, [baseTelemetryFields]);

  /**
   * 'CLICK' on ATC button - NewRelic.
   */
  const triggerClickOnAtcButton = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_ATC_BUTTON);
  }, [triggerClick]);

  /**
   * 'CLICK' on Show Now button - NewRelic.
   */

  const triggerClickOnShowNowButton = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_SHOP_NOW_BUTTON);
  }
  , [triggerClick]);

  /**
   * 'CLICK' on Image - NewRelic.
   */
  const triggerClickOnImage = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_IMAGE);
  }, [triggerClick]);

  /**
   * 'CLICK' on Header - NewRelic.
   */
  const triggerClickOnHeader = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_HEADER);
  }, [triggerClick]);

  /**
   * 'CLICK' on Rating - NewRelic.
   */
  const triggerClickOnRating = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_RATING);
  }, [triggerClick]);

  /**
   * 'CLICK' on Pod - NewRelic.
   */
  const triggerClickOnPod = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_PRODUCT_POD);
  }, [triggerClick]);

  /**
   * 'CLICK' on NewTab - NewRelic.
   */
  const triggerClickOnNewTab = useCallback(() => {
    triggerClick(newRelicConstants.CLICK_SRC_NEW_TAB);
  }, [triggerClick]);

  /**
   * Triggers 'product-pod-v7.click' event - Analytics & AdServer.
   *
   * This event ensures:
   *    - Analytics capture the click event.
   *    - AdServer onClickBeacon is fired.
   */
  const triggerProductPodV7Click = useCallback(() => {

    const productPodEventData = {
      podAction: 'product pod',
      podAnchorSku: itemId,
      target: '_self',
      parent: 'sponsored-carousel'
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productPodEventData);
  }, [itemId]);
  // --- ---

  return {
    triggerClickOnShowNowButton,
    triggerClickOnAtcButton,
    triggerClickOnImage,
    triggerClickOnHeader,
    triggerClickOnRating,
    triggerClickOnPod,
    triggerClickOnNewTab,
    triggerProductPodV7Click,
  };
};
