/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import {
  bool as boolType,
  any,
  string as stringType,
  func
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  bool,
  extend,
  string,
  shape,
  QueryProvider,
  QueryContext,
  params
} from '@thd-nucleus/data-sources';
import { Carousel } from '@one-thd/sui-carousel';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import {
  isAppliance,
  getLoyaltyMembershipInput,
  getRecsBreakPoint,
  getDisplayProducts,
  getPlaceholderTxtLines,
  getRecsTitle
} from '../utils/helpers';
import { LoadingPlaceholder } from './LoadingPlaceholder';
import { CarouselHeader } from './CarouselHeader';
import { FallbackCard } from './FallbackCard';
import { RecsProductPod } from './RecsProductPod';
import { IRGUtils } from './IRGUtils';
import { VerticalRecsProductPodV7 } from '../vertical-recommendation/VerticalRecsProductPodV7';
import { VerticalRecsPodPlaceholder } from '../vertical-recommendation/VerticalRecsPodPlaceholder';

// the QueryProvider on line 54 doesnt get access to ProductPod because data?.products is empty at first
// this is what hoist() was originally intended for but the problem with hoist() is that it mutates
// components and runs into issues when they are in multiple places
// eslint-disable-next-line react/prop-types
const Wrapper = ({ children }) => children;
Wrapper.dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string()
  })
}, RecsProductPod);
const ProductPodV7Carousel = (props) => {

  const { channel, customer } = useContext(ExperienceContext);
  const { defaultVariables } = useContext(QueryContext) || {};

  const isB2BCustomer = customer?.type === 'b2b';
  const isMobile = channel === 'mobile';

  const {
    anchorImage,
    data,
    hideTitle,
    loading,
    scheme,
    showLoading,
    storeId,
    showFallback,
    fallbackInfo,
    userId,
    subtitle,
    //  Displaying the category tabs
    isCategoryTab,
    hideATC,
    hideFavorites,
    hideBadge,
    membershipInformation,
    zipCode,
    slidesPer,
    slidesToShow,
    hideSwatches,
    hideCarouselArrows,
    analyticsAnchorProductSku,
    parent,
    zoneName,
    zonePosition,
    podResults,
    brandTitleMaxLine,
    hideRating,
    isIRG,
    noATCFulfillment,
    tntTitle,
    isPipsem,
    hidePrice,
    setVisualTabsCategoriesData,
    theme,
    shouldResetTheme
  } = props;

  const qtxDefaultVariables = {
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: getLoyaltyMembershipInput(membershipInformation),
    skipFavoriteCount: !!isB2BCustomer,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const { products, metadata, categories } = data || {};

  const classes = classNames('sui-grid', 'sui-gap-4', 'sui-grid-cols-1', { 'sui-grid-cols-3': anchorImage && !isIRG && !isMobile });

  const colSpanClasses = classNames({ 'sui-col-span-2 sui-overflow-hidden': anchorImage });

  let title = getRecsTitle(tntTitle, metadata);

  const [filtered, setFiltered] = useState([]);
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  const pullFilteredData = (filteredProducts) => {
    setIsCategorySelected(true);
    setFiltered(filteredProducts);
  };

  let displayProducts = getDisplayProducts(isCategorySelected, filtered, products);

  let breakpoints = ({ ...getRecsBreakPoint(slidesToShow, slidesPer, anchorImage) });

  let placeholderTxtLines = getPlaceholderTxtLines(isMobile, isCategoryTab, anchorImage, zoneName, hideATC);

  if (isIRG && (products && products.length < 3)) {
    return null;
  }

  const getScheme = (schema) => {
    return schema.startsWith('rv_') ? 'rv_views' : schema;
  };

  const itemTheme = shouldResetTheme ? 'ThdDefaultLightTheme' : theme;
  const processedItemTheme = getThemeByName(itemTheme);

  const placeholder = () => {
    return isPipsem ? (
      <VerticalRecsPodPlaceholder
        placeholderLines={4}
        placeholderCount={isMobile ? 1 : 3}
      />
    )
      : <LoadingPlaceholder txtPlaceholderLines={placeholderTxtLines} hideTitle={hideTitle} />;
  };
  return (
    <>
      {showLoading && loading && (
        showFallback && !userId && !products
          ? (
            <FallbackCard
              imageUrl={fallbackInfo.imageUrl}
              header={fallbackInfo.header}
              subheader={fallbackInfo.subheader}
              footerUrl={fallbackInfo.footerUrl}
              footer={fallbackInfo.footer}
            />
          )
          : placeholder()
      )}
      {displayProducts?.length > 0 && (
        <>
          {!hideTitle && !isIRG && (
            isCategoryTab && !metadata?.collectionName ? (
              <CarouselHeader title="More From This Brand" />
            ) : (
              <CarouselHeader title={title} subtitle={subtitle} />
            )
          )}
          {isIRG && (
            <IRGUtils
              products={products}
              scheme={scheme}
              isIRG={isIRG}
              isCategoryTab={isCategoryTab}
              categories={categories}
              pullFilteredData={pullFilteredData}
              tntTitle={tntTitle}
              setVisualTabsCategoriesData={setVisualTabsCategoriesData}
            />
          )}

          <div className={classNames(classes, 'sui-pl-2')}>
            {!isMobile && anchorImage && (
              <div>
                <Image
                  src={anchorImage}
                  alt="main-product-image"
                  height="400"
                  width="400"
                  data-testid="anchor-image"
                  className="sui-w-full"
                />
                <div className="sui-bg-strong sui-text-center sui-p-1 sui-mb-4 sui-text-sm">Current Item</div>
              </div>
            )}
            <div className={colSpanClasses}>
              <QueryProvider
                dataSource={getScheme(scheme)}
                cacheKey={`${scheme}-recommendations-carousel`}
                defaultVariables={qtxDefaultVariables}
              >
                <Carousel
                  breakpoints={breakpoints}
                  hideCarouselArrows={hideCarouselArrows}
                  disableMargin
                  // Show only one slide
                  type={(isPipsem && isMobile) ? 'hero' : 'content'}
                  // To show two or more slides
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(isMobile ? ({ slidesPerGroup: 2 }) : {})}
                >
                  {displayProducts.map((dynamicProduct, idx) => {
                    const { product } = dynamicProduct;
                    const { itemId } = product;
                    const { canonicalUrl } = product?.identifiers || {};
                    const { preferredPriceFlag } = product.pricing;
                    return (
                      <Wrapper key={idx}>
                        {isPipsem ? (
                          <div className="sui-rounded-base sui-paper-outlined">
                            <VerticalRecsProductPodV7
                              itemId={itemId}
                              position={idx}
                              storeId={storeId}
                              parent={parent}
                              preferredPriceFlag={preferredPriceFlag}
                              scheme={scheme}
                              anchorProduct={analyticsAnchorProductSku}
                              strategy={dynamicProduct?.strategy}
                              brandTitleMaxLine={brandTitleMaxLine}
                              hideRating={hideRating}
                              hideBadge={hideBadge}
                              hideATC={hideATC}
                              noATCFulfillment={isAppliance(product) ? false : noATCFulfillment}
                            />
                          </div>
                        ) : (
                          <ThemeProvider theme={processedItemTheme}>
                            <RecsProductPod
                              itemId={itemId}
                              key={itemId}
                              position={idx}
                              storeId={storeId}
                              scheme={scheme}
                              anchorProduct={analyticsAnchorProductSku}
                              parent={parent}
                              zoneName={zoneName}
                              zonePosition={zonePosition}
                              podResults={podResults}
                              hideSwatches={hideSwatches}
                              hideFavorites={hideFavorites}
                              hideBadge={hideBadge}
                              hideATC={hideATC}
                              hidePrice={hidePrice}
                              hideRating={hideRating}
                              preferredPriceFlag={preferredPriceFlag}
                              brandTitleMaxLine={brandTitleMaxLine}
                              strategy={dynamicProduct?.strategy}
                              noATCFulfillment={isAppliance(product) ? false : noATCFulfillment}
                              url={canonicalUrl}
                              theme={theme}
                            />
                          </ThemeProvider>
                        )}
                      </Wrapper>
                    );
                  }
                  )}
                </Carousel>
              </QueryProvider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const productModel = extend({}, {
  itemId: string(),
  dataSource: string()
}, RecsProductPod.dataModel.product);

export const wrappedProduct1 = shape({
  category: string(),
  description: string(),
  idEndDate: string(),
  idPromoDetails: string(),
  idStartDate: string(),
  strategy: string(),
  isAnchor: bool(),
  product: params({
    dataSource: string()
  })
    .shape(productModel),
  slot: shape({
    description: string(),
    isRequired: bool(),
    name: string()
  })
});
ProductPodV7Carousel.dataModel = extend(Wrapper, wrappedProduct1);

ProductPodV7Carousel.displayName = 'ProductPodV7Carousel';

ProductPodV7Carousel.propTypes = {
  ...RecsProductPod.propTypes,
  // eslint-disable-next-line react/forbid-prop-types
  data: any,
  loading: boolType,
  hideTitle: boolType,
  scheme: stringType,
  showLoading: boolType,
  storeId: stringType.isRequired,
  subtitle: stringType,
  hideATC: boolType,
  hideFavorites: boolType,
  hideBadge: boolType,
  keepAddToCart: boolType,
  isCategoryTab: boolType,
  hideSwatches: boolType,
  hideCarouselArrows: boolType,
  analyticsAnchorProductSku: stringType,
  hideRating: boolType,
  noATCFulfillment: boolType,
  hidePrice: boolType,
  setVisualTabsCategoriesData: func,
  theme: stringType,
  shouldResetTheme: stringType
};

ProductPodV7Carousel.defaultProps = {
  hideTitle: false,
  scheme: 'drecs',
  showLoading: true,
  loading: false,
  data: null,
  subtitle: null,
  hideBadge: false,
  hideATC: false,
  hideFavorites: true,
  keepAddToCart: null,
  isCategoryTab: false,
  hideSwatches: true,
  hideCarouselArrows: false,
  analyticsAnchorProductSku: '',
  hideRating: false,
  noATCFulfillment: true,
  hidePrice: false,
  setVisualTabsCategoriesData: null,
  theme: null,
  shouldResetTheme: false
};

export { ProductPodV7Carousel };
