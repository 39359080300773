import React from 'react';
import {
  bool,
  number,
  oneOfType,
  oneOf,
  shape,
  string,
  func
} from 'prop-types';
import { Badge } from '@one-thd/sui-atomic-components';
import { useCalculatorLocalStorage, checkBulkPrice } from '@thd-olt-component-react/calculator';
import classNames from 'classnames';
import helpers from '../../helpers/price-helper';
import { LAYOUT_TYPES } from '../../utils/utils';
import '../../styles/price-detailed.style.scss';
import PriceFormat from './PriceFormat';
import { PriceDetailed } from './PriceDetailed';
import { PriceSimple } from './PriceSimple';

export const PriceProject = ({
  basic,
  channel,
  disableRangePricing,
  displayEachUom,
  hideBadge,
  hideBulkPrice,
  hideRebates,
  hideSavingsText,
  hideLimitPerOrder,
  hidePromotions,
  large,
  omitDetails,
  type,
  skuModel,
  showStartingAt,
  storeId,
  zipCode,
  disableOuterSpacing,
  useNewMapPolicy
}) => {
  const layout = helpers.getLayout(type, large, false);
  const { product } = skuModel.eject();
  const {
    unitsPerCase: sizeValue,
    caseUnitOfMeasure
  } = product?.pricing?.alternate?.unit || {};

  const { projectQuantity, coverage } = useCalculatorLocalStorage({
    label: skuModel.label,
    uomObj: { sizeValue, caseUnitOfMeasure },
    toggleOn: true
  });

  if (caseUnitOfMeasure !== 'sq. ft.' || !sizeValue) {
    // if the product doesn't have the required attributes, return normal price
    if (layout === LAYOUT_TYPES.DETAILED) {
      return (
        <PriceDetailed
          channel={channel}
          disableRangePricing={disableRangePricing}
          showStartingAt={showStartingAt}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          skuModel={skuModel}
          storeId={storeId}
          zipCode={zipCode}
          disableOuterSpacing={disableOuterSpacing}
          useNewMapPolicy={useNewMapPolicy}
        />
      );
    } if (layout === LAYOUT_TYPES.SIMPLE) {
      return (
        <PriceSimple
          basic={basic}
          channel={channel}
          disableRangePricing={disableRangePricing}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hidePromotions={hidePromotions}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          skuModel={skuModel}
          zipCode={zipCode}
          disableOuterSpacing={disableOuterSpacing}
          useNewMapPolicy={useNewMapPolicy}
        />
      );
    }
  }

  if (!product) {
    return null;
  }

  const { price: value } = checkBulkPrice(projectQuantity, product?.pricing);

  const priceProjectDetailed = ({
    price, alternateUom, uom, pricingValues,
    save, bulkPrice, alternatePrice, bulkPriceThresholdQty, priceBadge,
    unitPricingValues, hasEcoRebates
  }) => {
    const alternatePriceTemplate = helpers.handlePriceParsing(alternatePrice, false);
    const savePriceClass = classNames({
      'price-detailed__save': bulkPrice && !hideBulkPrice
    });

    return (
      <>
        <Badge variant="strong" color="success">Updated price for your project</Badge>
        <div className="sui-flex sui-flex-col sui-gap-2 md:sui-flex-row" data-testid="price-project-detailed">
          <div
            className={
              'sui-border-primary sui-border-solid sui-pr-2 sui-border-b-1 sui-mr-auto'
            + ' md:sui-mt-6 md:sui-border-r-1 md:sui-border-b-0 md:sui-mr-0 '
            }
          >
            <PriceFormat
              price={(value * projectQuantity).toFixed(2).toString()}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge
              disableOuterSpacing={disableOuterSpacing}
            />
            <span className="sui-ml-2">
              /{projectQuantity} {alternateUom}{projectQuantity > 1 && 's'}
              <span className="sui-ml-1 md:sui-hidden">
                ({coverage} sq. ft.)
              </span>
            </span>
          </div>
          <div className="sui-flex sui-flex-row">
            <div className="sui-flex sui-flex-col">
              <h4>
                Unit Price
              </h4>
              <PriceFormat
                price={price}
                priceBadge={priceBadge}
                isSmallBadge={false}
                isSmallSize={false}
                hideBadge={hideBadge}
                disableOuterSpacing={disableOuterSpacing}
              />
            </div>

            <div className="sui-ml-2 sui-flex sui-flex-col sui-justify-end sui-pb-3">
              <span>
                {`${helpers.parseUom(uom, displayEachUom)}
                \xA0`}
                {
                  alternateUom
                  && (
                    <>
                      (
                      <span className="sui-font-bold">
                        {alternatePriceTemplate}
                      </span>{helpers.parseUom(alternateUom, false)}
                      )
                    </>
                  )
                }
              </span>
              <div className="sui-flex sui-flex-row sui-gap-1">
                {helpers.getWasPrice(pricingValues)}
                {helpers.getSavingsText(false, hideSavingsText, save, false, savePriceClass)}
              </div>
            </div>
          </div>
        </div>
        {helpers.getRebates(hideRebates, hasEcoRebates, skuModel.pricing.itemId, zipCode, true, LAYOUT_TYPES.DETAILED)}
      </>
    );
  };

  const priceProjectSimple = ({
    price, alternateUom, uom, pricingValues,
    save, bulkPrice, alternatePrice, bulkPriceThresholdQty, priceBadge,
    unitPricingValues, hasEcoRebates
  }) => {
    const priceTemplate = helpers.handlePriceParsing(price, false);
    const alternatePriceTemplate = helpers.handlePriceParsing(alternatePrice, false);

    return (
      <div className="sui-flex sui-flex-col sui-gap-1" data-testid="price-project-simple">
        <div className="">
          <PriceFormat
            price={(value * projectQuantity).toFixed(2).toString()}
            priceBadge={priceBadge}
            hideBadge={hideBadge}
            disableOuterSpacing={disableOuterSpacing}
          />
          <span className="sui-ml-2">
            /{projectQuantity} {alternateUom}{projectQuantity > 1 && 's'}
            <span className="sui-ml-1">
              ({coverage} sq. ft.)
            </span>
          </span>
        </div>
        <span>Unit price - {priceTemplate}/{uom}</span>
        <span>Bulk price - {alternatePriceTemplate}/{alternateUom}</span>
      </div>
    );
  };

  const { _price } = helpers.getPricingObject({
    skuModel, displayEachUom, omitDetails, basic: false, disableRangePricing
  });

  return (
    <div data-testid="price-project">
      {
        layout === LAYOUT_TYPES.DETAILED
        && priceProjectDetailed(_price)
      }
      { layout === LAYOUT_TYPES.SIMPLE
        && priceProjectSimple(_price)}
    </div>
  );
};

PriceProject.propTypes = {
  basic: bool,
  channel: string.isRequired,
  disableRangePricing: bool,
  displayEachUom: bool,
  hideBadge: bool,
  hideBulkPrice: bool,
  hideLimitPerOrder: bool,
  hidePromotions: bool,
  hideRebates: bool,
  hideSavingsText: bool,
  large: bool,
  omitDetails: bool,
  skuModel: shape({
    pricing: shape({
      uom: string,
      unitsPerCase: number,
      wasPrice: oneOfType([number, string]),
      nowPrice: oneOfType([number, string]),
      specialBuyPrice: oneOfType([number, string]),
      dollarOff: oneOfType([number, string]),
      percentageOff: oneOfType([number, string]),
      specialBuySavings: oneOfType([number, string]),
      specialBuyDollarOff: oneOfType([number, string]),
      alternatePriceDisplay: bool,
      quantityLimit: oneOfType([number, string]),
      itemId: oneOfType([number, string]),
      unitWasPrice: oneOfType([number, string]),
      unitNowPrice: oneOfType([number, string]),
      storePromotion: shape(),
      unitOfMeasureCoverage: string
    }).isRequired
  }).isRequired,
  storeId: string.isRequired,
  type: oneOf([...Object.values(LAYOUT_TYPES), '']),
  zipCode: string,
  showStartingAt: bool,
  disableOuterSpacing: bool,
  useNewMapPolicy: bool
};

PriceProject.defaultProps = {
  basic: false,
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideRebates: false,
  hideSavingsText: false,
  large: true,
  omitDetails: false,
  type: '',
  zipCode: null,
  showStartingAt: false,
  disableOuterSpacing: false,
  useNewMapPolicy: false,
};
