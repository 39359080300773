import React from 'react';
import { string as stringType, elementType } from 'prop-types';
import {
  Button,
  Typography
} from '~/@one-thd/sui-atomic-components';

const FallbackCard = ({
  header, subheader, footerUrl, footer, componentName, icon
}) => {
  return (
    <div
    // eslint-disable-next-line tailwindcss/no-arbitrary-value
      className="sui-flex sui-flex-col sui-h-full sui-min-h-[350px]"
      data-type="container"
      data-component={`${componentName}FallbackCard`}
    >
      <div className="sui-flex sui-flex-col sui-gap-4 sui-items-center sui-text-center sui-m-auto">
        {icon && React.createElement(icon, { size: 'large', color: 'medium' })}
        <Typography className="sui-mt-8 sui-mb-4" variant="h5" weight="bold" height="tight">{header}</Typography>
        <Typography variant="body-base" weight="regular" height="tight">{subheader}</Typography>
      </div>
      { footer && (
        <div className="sui-flex sui-flex-col sui-w-full">
          <Button
            href={footerUrl}
            fullWidth
            variant="secondary"
            role="button"
            tag="a"
          >
            {footer}
          </Button>
        </div>
      )}
    </div>
  );
};

FallbackCard.propTypes = {
  header: stringType,
  subheader: stringType,
  footerUrl: stringType,
  footer: stringType,
  componentName: stringType,
  icon: elementType
};

FallbackCard.defaultProps = {
  header: null,
  subheader: null,
  footerUrl: null,
  footer: null,
  componentName: '',
  icon: null
};

export { FallbackCard };