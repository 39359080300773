import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { generateKey, generateNestedKey, getVariant, isNodeHyperLink } from './utils';

const RenderParagraph = ({ node, children }) => {
  const mainVariant = getVariant(node?.nodeType);
  return (
    <Typography
      paragraph
      key={generateNestedKey(node, `${getVariant(node?.nodeType)}-node`, 0)}
      component={getVariant(node?.nodeType) === 'body-base' ? 'p' : getVariant(node?.nodeType)}
      variant={mainVariant}
    >
      {children.map((child, i) => {
        if (child === '' && !isNodeHyperLink(node)) {
          return <mark key={generateKey(null, i, 'empty')}>&nbsp;</mark>;
        }
        return child;
      })}
    </Typography>
  );
};

RenderParagraph.propTypes = {
  node: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
    nodeType: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export { RenderParagraph };
