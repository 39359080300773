import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import createTypography from '../utils/createTypography';

/**
 * The `Link` component allows to customize anchor elements with typography styles.
 *
 * The `Link` component is build on top of the `Typography` component, meaning that you can use its props.
 *
 * Related components: [Typography](#typography), [Button](#button)
 *
 * Usage:
 *
 * ```jsx
 * import { Link } from '@one-thd/sui-atomic-components';
 * ```
 */
const Link = React.forwardRef((props, ref) => {

  const {
    align,
    children,
    color = 'primary',
    component: LinkRoot = 'a',
    decoration,
    href,
    height,
    variant = 'body-base',
    onClick,
    underline = 'always',
    uppercase,
    weight,
    ...other
  } = props;

  const typography = createTypography({
    color,
    decoration,
    height,
    uppercase,
    variant,
    weight
  });

  const linkClasses = classNames(typography.classes, 'focus-visible:sui-bg-focus focus-visible:sui-outline-none', {
    'sui-underline': underline === 'always',
    'hover:sui-underline': underline === 'hover',
    'sui-no-underline': underline === 'none',
    'hover:sui-text-primary focus-visible:sui-text-primary': color !== 'primary',
    'sui-text-left': align === 'left',
    'sui-text-center': align === 'center',
    'sui-text-right': align === 'right',
  });

  const handleOnClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  // if (process.env.NODE_ENV !== 'production') {
  //   if (onClick && LinkRoot !== 'button') {
  //     console.error('Set component as "button" when using onClick prop');
  //   }
  // }

  return (
    <LinkRoot
      href={href}
      ref={ref}
      className={linkClasses}
      onClick={handleOnClick}
      {...other}
    >
      {children}
    </LinkRoot>
  );
});

Link.displayName = 'Link';

Link.propTypes = {
  /**
   * Set the text alignment on the component.
   */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /**
   *@ignore
   */
  children: PropTypes.node,
  /**
   * Applies the typography color.
   * @default 'primary'
   */
  color: PropTypes.oneOf([
    'primary',
    'medium',
    'subtle',
    'info',
    'success',
    'warning',
    'danger',
    'brand',
    'inverse',
    'inactive',
    'none'
  ]),
  /**
   * The html element to render
   * @default 'a'
   */
  component: PropTypes.string,
  /**
   * Applies the text decoration styles.
   */
  decoration: PropTypes.oneOf([
    'line-through',
    'underline'
  ]),
  /**
   * Applies the line height styles.
   */
  height: PropTypes.oneOf([
    'none',
    'tight',
    'snug',
    'normal',
    'loose'
  ]),
  /**
   *The URL of the page the Link component goes to.
   */
  href: PropTypes.string,
  /**
   * Controls when the link should have an underline.
   * @default 'always'
   */
  underline: PropTypes.oneOf(['always', 'hover', 'none']),
  /**
   * When component is 'button', the onClick event
   */
  onClick: PropTypes.func,
  /**
   * Applies the typography styles.
   * @default 'body-base'
   */
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'body-xs',
    'body-sm',
    'body-base',
    'body-lg'
  ]),
  /**
   * if true applies uppercase text transformation.
   */
  uppercase: PropTypes.bool,
  /**
   * Applies the weight sub type.
   */
  weight: PropTypes.oneOf([
    'bold',
    'display',
    'regular'
  ]),
};

export { Link };
