import React from 'react';
import { string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const RecentPurchasesTableDate = ({ orderDate = null }) => {

  if (!orderDate) {
    return <div data-testid="table-orderDate" />;
  }

  const [year, month, date] = orderDate.split('-');
  const formattedDate = `${month}/${date}/${year}`;

  return (
    <div
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden sui-h-20"
      data-testid="table-orderDate"
    >
      <Typography variant="body-base">{formattedDate}</Typography>
    </div>
  );
};

export function RecentPurchasesCardDate({ orderDate = null }) {
  if (!orderDate) {
    return (
      <div data-testid="card-orderDate">
        <Typography variant="body-base" weight="bold">
          Date
        </Typography>
      </div>
    );
  }
  const [year, month, date] = orderDate.split('-');
  const formattedDate = `${month}/${date}/${year}`;

  return (
    <div className="sui-flex" data-testid="card-orderDate">
      <span className="sui-mr-2">
        <Typography variant="body-base" weight="bold">
          Date
        </Typography>
      </span>
      <span>
        <Typography variant="body-base">{formattedDate}</Typography>
      </span>
    </div>
  );
}

RecentPurchasesTableDate.propTypes = {
  orderDate: stringType,
};

RecentPurchasesCardDate.propTypes = {
  orderDate: stringType,
};
