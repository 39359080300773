import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import {
  productShape
} from '../../helpers/pod-fulfillment-utils';

export const EmptyStoreMessage = ({ product, isGCC, customRender }) => {
  const defaultElements = () => {
    if (isGCC) {
      return <div data-testid="StoreEmptyGCC" data-component="FulfillmentStoreEmptyGCC" />;
    }
    return <div data-testid="StoreEmpty" data-component="FulfillmentStoreEmpty" />;
  };

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE, icon: null, values: { isGCC, product } });
  }

  return (
    defaultElements()
  );
};

EmptyStoreMessage.propTypes = {
  isGCC: PropTypes.bool,
  customRender: PropTypes.func,
  product: PropTypes.shape({ productShape })
};

EmptyStoreMessage.defaultProps = {
  isGCC: false,
  customRender: null
};
