/* eslint-disable max-len */
import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const SkeletonShapes = React.forwardRef((props, ref) => {

  const {
    aspect,
    disablePulse = false,
    fontSize,
    height,
    variant = 'text',
    width = '100%',
    ...other
  } = props;

  const animationClasses = classNames({
    'motion-safe:sui-animate-pulse': !disablePulse,
  });

  const baseTextClases = String.raw`sui-block sui-h-auto sui-bg-strong sui-mt-0 sui-mb-0 before:sui-content-["\00a0"] sui-leading-none`;

  const textClasses = classNames(baseTextClases, animationClasses, {
    'sui-text-xs': fontSize === 'xs',
    'sui-text-base': fontSize === 'base',
    'sui-text-lg': fontSize === 'lg',
    'sui-text-xl': fontSize === 'xl',
    'sui-text-2xl': fontSize === '2xl',
    'sui-text-3xl': fontSize === '3xl',
    'sui-w-1/5': width === '20%',
    'sui-w-2/5': width === '40%',
    'sui-w-3/5': width === '60%',
    'sui-w-4/5': width === '80%',
    'sui-w-full': width === '100%',
  });

  const heightClass = classNames({
    'sui-h-1': height === 1,
    'sui-h-2': height === 2,
    'sui-h-3': height === 3,
    'sui-h-4': height === 4,
    'sui-h-5': height === 5,
    'sui-h-6': height === 6,
    'sui-h-7': height === 7,
    'sui-h-8': height === 8,
    'sui-h-9': height === 9,
    'sui-h-10': height === 10,
    'sui-h-11': height === 11,
    'sui-h-12': height === 12,
    'sui-h-14': height === 14,
    'sui-h-16': height === 16,
    'sui-h-20': height === 20,
    'sui-h-24': height === 24,
    'sui-h-28': height === 28,
    'sui-h-32': height === 32,
    'sui-h-36': height === 36,
    'sui-h-40': height === 40,
    'sui-h-44': height === 44,
    'sui-h-48': height === 48,
    'sui-h-52': height === 52,
    'sui-h-56': height === 56,
    'sui-h-60': height === 60,
  });

  const widthClass = classNames({
    'sui-w-1': width === 1,
    'sui-w-2': width === 2,
    'sui-w-3': width === 3,
    'sui-w-4': width === 4,
    'sui-w-5': width === 5,
    'sui-w-6': width === 6,
    'sui-w-7': width === 7,
    'sui-w-8': width === 8,
    'sui-w-9': width === 9,
    'sui-w-10': width === 10,
    'sui-w-11': width === 11,
    'sui-w-12': width === 12,
    'sui-w-14': width === 14,
    'sui-w-16': width === 16,
    'sui-w-20': width === 20,
    'sui-w-24': width === 24,
    'sui-w-28': width === 28,
    'sui-w-32': width === 32,
    'sui-w-36': width === 36,
    'sui-w-40': width === 40,
    'sui-w-44': width === 44,
    'sui-w-48': width === 48,
    'sui-w-52': width === 52,
    'sui-w-56': width === 56,
    'sui-w-60': width === 60,
    'sui-w-full': width === '100%'
  });

  const rectClasses = classNames('sui-block sui-bg-strong', animationClasses, heightClass, widthClass, {
    'sui-rounded-full': variant === 'circular',
    'sui-rounded-none': variant === 'rectangular',
    'sui-aspect-square': aspect === 'square',
    'sui-aspect-video': aspect === 'wide',
  });

  return (
    <span
      ref={ref}
      className={variant === 'text' ? textClasses : rectClasses}
      {...other}
    />
  );
});

SkeletonShapes.displayName = 'SkeletonShapes';

SkeletonShapes.propTypes = {
  /**
   * The aspect ratio to use.
   */
  aspect: PropTypes.oneOf(['wide', 'square']),
  /**
   * Optional children to infer width and height from.
   */
  children: PropTypes.node,
  /**
   * If `true`, the pulse animation is removed.
   * @default false
   */
  disablePulse: PropTypes.bool,
  /**
   * Height of the skeleton.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The font size.
   * Useful for the `text` variant.
   */
  fontSize: PropTypes.string,
  /**
   * The type of content that will be rendered.
   * @default 'text'
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['circular', 'rectangular', 'text']),
    PropTypes.string,
  ]),
  /**
   * Width of the skeleton.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { SkeletonShapes };
