/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  autoUpdate, useFloating, arrow, flip
} from '@floating-ui/react-dom';
import ownerDocument from '../../utils/ownerDocument';
import useEnhancedEffect from '../../hooks/useEnhancedEffect';
import useForkRef from '../../hooks/useForkRef';
import { Portal } from '../portal/Portal';
import useSlotProps from './useSlotProps';

const resolveAnchorEl = (anchorEl) => {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
};

const isHTMLElement = (element) => {
  return (element).nodeType !== undefined;
};

const Dialog = React.forwardRef(function Dialog(
  props,
  ref
) {
  const {
    anchorEl,
    arrow: arrowProp,
    children,
    component,
    className,
    open,
    onArrow,
    onPositionSet,
    placement,
    floatingUIOptions = {},
    floatingStyles: floatingStylesProp,
    slotProps = {},
    slots = {},
    style: styleProp,
    TransitionProps,
    ...other
  } = props;

  // const rtlPlacement = flipPlacement(initialPlacement, direction);
  const [resolvedAnchorElement, setResolvedAnchorElement] = React.useState(resolveAnchorEl(anchorEl));

  const {
    floatingStyles, isPositioned, middlewareData, placement: usedPlacement, refs
  } = useFloating({
    open,
    elements: {
      reference: resolvedAnchorElement,
    },
    middleware: [
      arrow({
        element: arrowProp
      }),
      flip({
        fallbackAxisSideDirection: 'start',
      })
    ],
    placement,
    whileElementsMounted: autoUpdate,
    ...floatingUIOptions
  });

  const ownRef = useForkRef(refs.setFloating, ref);

  React.useEffect(() => {
    if (anchorEl) {
      setResolvedAnchorElement(resolveAnchorEl(anchorEl));
    }
  }, [anchorEl]);

  React.useEffect(() => {
    if (onArrow) {
      onArrow(middlewareData.arrow, usedPlacement);
    }
  }, [middlewareData, usedPlacement, floatingStyles]);

  React.useEffect(() => {
    if (onPositionSet) {
      onPositionSet(isPositioned);
    }
    return () => {
      if (onPositionSet) {
        onPositionSet(false);
      }
    };
  }, [isPositioned]);

  useEnhancedEffect(() => {
    if (!resolvedAnchorElement || !open) {
      return undefined;
    }
  }, [resolvedAnchorElement]);

  const childProps = { placement };
  if (TransitionProps !== null) {
    childProps.TransitionProps = TransitionProps;
  }

  const classes = `${className ?? ''} sui-group`.trim();
  const Root = component ?? slots.root ?? 'div';
  const rootProps = useSlotProps({
    elementType: Root,
    externalSlotProps: slotProps.root,
    externalForwardedProps: other,
    additionalProps: {
      role: 'tooltip',
      ref: ownRef
    },
    ownerState: {
      ...props
    },
    className: classes,
  });

  /* Obtains the general position (x,y) of the trigger element for the initial render and places the FloatingDialog
  on it to prevent focus events from scrolling the page */
  const anchorRect = anchorEl ? anchorEl.getBoundingClientRect() : undefined;
  const defaultStyles = {
    position: 'absolute',
    top: anchorEl ? anchorRect.top + window.scrollY : 'unset',
    left: anchorEl ? anchorRect.left + window.scrollX : 'unset'
  };

  const styles = floatingStylesProp || { ...floatingStyles, ...styleProp };

  return (
    <Root
      {...rootProps}
      style={isPositioned ? styles : defaultStyles}
    >
      {typeof children === 'function' ? children(childProps) : children}
    </Root>
  );
});

const FloatingDialog = React.forwardRef(function FloatingDialog(
  props,
  ref,
) {
  const {
    anchorEl,
    arrow: arrowProp,
    children,
    container: containerProp,
    disablePortal = false,
    keepMounted = false,
    open,
    onArrow,
    onPositionSet,
    placement = 'bottom',
    floatingUIOptions,
    floatingStyles: floatingStylesProp,
    style,
    transition = false,
    ...other
  } = props;

  const [exited, setExited] = React.useState(true);

  const handleEnter = () => {
    setExited(false);
  };

  const handleExited = () => {
    setExited(true);
  };

  if (!keepMounted && !open && (!transition || exited)) {
    return null;
  }

  let container;
  if (containerProp) {
    container = containerProp;
  } else if (anchorEl) {
    const resolvedAnchorEl = resolveAnchorEl(anchorEl);
    container = resolvedAnchorEl && isHTMLElement(resolvedAnchorEl)
      ? ownerDocument(resolvedAnchorEl).body
      : ownerDocument(null).body;
  }
  const display = !open && keepMounted && (!transition || exited) ? 'none' : undefined;
  const transitionProps = transition
    ? {
      in: open,
      onEnter: handleEnter,
      onExited: handleExited,
    }
    : undefined;
  return (
    <Portal disablePortal={disablePortal} container={container}>
      <Dialog
        anchorEl={anchorEl}
        arrow={arrowProp}
        className="sui-w-max sui-absolute sui-top-0 sui-left-0"
        floatingUIOptions={floatingUIOptions}
        floatingStyles={floatingStylesProp}
        onArrow={onArrow}
        onPositionSet={onPositionSet}
        placement={placement}
        TransitionProps={transitionProps}
        style={{
          display,
          ...style
        }}
        {...other}
      >
        {children}
      </Dialog>
    </Portal>
  );
});

export { FloatingDialog };
