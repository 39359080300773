/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import className from 'classnames';
import { useConfigService } from '@thd-nucleus/experience-context';
import { MAJOR_APPLIANCE } from '../core/constants';

export const useTabCountConfigValue = (showColUpLevel) => {
  let recsTabCount = useConfigService('fs:recsCollectionTabCount') || 3;
  let recsCollectionTabCount = !showColUpLevel ? recsTabCount : 3;
  return recsCollectionTabCount;
};

export const getProductPageStatus = (location) => {
  return (location.indexOf('/p/') > -1);
};

export const getSkippers = ({ skipSubscribeAndSave = true }) => {
  return {
    skipSpecificationGroup: true,
    skipKPF: true,
    skipInstallServices: true,
    skipSubscribeAndSave
  };
};

export const isAppliance = (product = {}) => {
  return !!(product?.identifiers?.productType?.toUpperCase() === MAJOR_APPLIANCE);
};

export const isLoading = (data, loading) => {
  return loading || data === undefined || data === null;
};

export const isSearchLoading = (data, searchData, multiData, loading, multiLoading) => {
  if (loading || multiLoading || (!data && !!searchData?.recs)
    || (!data && !searchData?.recs && (!!multiData?.recs || multiData === undefined))
    || (!data && searchData === undefined && multiData === undefined)) {
    return true;
  }

  return false;
};

export const isRecsLoading = (dataState, data, fallBackRecsData, loading, fallBackRecsLoading, tntTest) => {
  if (tntTest) {
    if (loading || fallBackRecsLoading || (!dataState && !!data?.recs)
      || (!dataState && !data?.recs && (!!fallBackRecsData?.recs || fallBackRecsData === undefined))
    || (!dataState && data === undefined && fallBackRecsData === undefined)) {
      return true;
    }
  } else if (loading || (!dataState && !!data?.recs)) {
    return true;
  }

  return false;
};

export const getAppId = (device, app) => {
  let appId = '';
  if (app) {
    appId = 'consumerApp';
  } else if (device === 'mobile') {
    appId = 'mobileweb';
  } else {
    appId = 'desktop';
  }
  return appId;
};

export const impressionContainerNameParser = (title) => {
  if (title && typeof title === 'string') {
    const name = title.replace(/ /g, '');
    return name;
  }
  return '';
};

const prependZero = (value) => {
  if (value && Number(value) <= 9) {
    return '0' + value;
  }
  return value;
};

export const getCustomerType = () => {
  let customer = {};
  if (typeof (window) !== 'undefined') {
    customer = window.top?.THDCustomer?.default || {};
  }
  return customer?.customerType;
};

export const getCurrentDate = () => {
  try {
    let currentDate = new Date();
    let formattedDate = prependZero(currentDate.getFullYear()) + '-'
      // eslint-disable-next-line radix
      + prependZero(parseInt(currentDate.getMonth() + 1)) + '-'
      + prependZero(currentDate.getDate()) + ' '
      + prependZero(currentDate.getHours()) + ':'
      + prependZero(currentDate.getMinutes()) + ':'
      + prependZero(currentDate.getSeconds());
    return formattedDate.toString();
  } catch (err) {
    console.error(err);
  }
  return null;
};
const buildIRGTitle = (brandname, tntTitle) => {
  // eslint-disable-next-line max-len
  return impressionContainerNameParser(tntTitle || ((brandname !== null) ? ('More from ' + brandname) : 'More from This Brand'));
};

export const getImpressionsNameValue = (isCollection, showColUpLevel, isCategoryTab, isIRG, metadata, products, loading,
  tntTitle) => {
  if (!loading) {
    if (isCollection && showColUpLevel) {
      return impressionContainerNameParser(tntTitle || 'Make It Match');
    // eslint-disable-next-line no-else-return
    } else if (isCollection && !showColUpLevel) {
      return impressionContainerNameParser(tntTitle || metadata?.collectionName);
    } else if (isIRG && isCategoryTab) {
      return buildIRGTitle(products[0]?.product?.identifiers?.brandName, tntTitle);
    } else if (isIRG && !isCategoryTab) {
      return impressionContainerNameParser(tntTitle || 'You Might Also Need');
    } else if (tntTitle || metadata?.title) {
      return impressionContainerNameParser(tntTitle || metadata?.title);
    } else if (tntTitle) {
      return impressionContainerNameParser(tntTitle);
    }
    return '';
  }
  return '';
};

export const TOP_PICKS = 'Top Picks';
export const ALL_ITEMS = 'All Items';

export const getCollectionTitle = (isIRG, isCategoryTab, isCollection) => {
  if (isIRG && isCategoryTab) {
    return ALL_ITEMS;
  }
  if (isCollection) {
    return TOP_PICKS;
  }
  return '';
};

export const getCollectionInitialIndexValue = (isIRG, isCategoryTab, isCollection) => {
  if ((isIRG && isCategoryTab) || isCollection) {
    return 1;
  }
  return '';
};

export const getAnalyticImpressionData = (component, name, id, type) => {
  return {
    component: component || 'ProductPod',
    name: name || 'ProductPod',
    id: id || '',
    type: type || ''
  };
};

export const getChannel = (channel) => {
  return channel === 'mobile' ? 'mobileweb' : 'desktop';
};

export const getTrendingBreakPoint = (slidesPer) => {
  return {
    sm: {
      slidesPerView: slidesPer || 3,
      slidesPerGroup: slidesPer || 3
    },
    md: {
      slidesPerView: slidesPer || 3,
      slidesPerGroup: slidesPer || 3
    },
    lg: {
      slidesPerView: slidesPer || 6,
      slidesPerGroup: slidesPer || 6
    },
    xl: {
      slidesPerView: slidesPer || 6,
      slidesPerGroup: slidesPer || 6
    }
  };
};

export const getReorderModel = (isB2BCustomer) => {
  return isB2BCustomer ? 'model2' : 'model1';
};

export const getImpressionID = (entryId) => {
  return entryId || '';
};

export const getAnalyticsParent = (tntData, parent) => {
  return (tntData?.isTNT && tntData?.isDts
    ? 'recs-dts-product-pod-carousel' : parent || 'supplemental-recs');
};

export const getLoyaltyMembershipInput = (membershipInformation) => {
  return membershipInformation?.data?.loyaltyMembership || null;
};

export const getDrecsImpressionID = (impressionData) => {
  return impressionData?.id || '';
};

export const getDrecsComponent = (impressionData, componentName) => {
  return impressionData?.component || componentName || 'DynamicRecs';
};

export const getDrecsimpressionType = (impressionData) => {
  return impressionData?.type || 'product';
};

export const getApiName = (isTNT, tntSchema, schemaName) => {
  return (isTNT && tntSchema) ? tntSchema : schemaName;
};

export const getTntSchema = (obj, schemaName) => {
  return obj?.originalScheme === schemaName && obj?.replacedScheme;
};

export const getWrapperClass = (schemaName, showLoading, loading) => {
  return className(
    'dynamic-recs',
    'sui-p-4',
    schemaName,
    {
      loading: showLoading && loading
    }
  );
};

export const getTrendingImpressionID = (entryId) => {
  return entryId || 'TrendingCategories';
};

export const getReorderCustType = (customerType, cType) => {
  return customerType || cType;
};

export const getReorderCustSegment = (customerSegment, segment) => {
  return customerSegment || segment;
};

export const getReorderCustID = (customerID, custIsLoggedIn, svocID, storefrontSvocId, mcvisID) => {
  return customerID || custIsLoggedIn ? svocID : (storefrontSvocId || mcvisID);
};

export const getCustIsLoggedIn = (customerIsLoggedIn, isLoggedIn) => {
  return customerIsLoggedIn || isLoggedIn;
};

export const getDisplayProducts = (isCategorySelected, filtered, products) => {
  return isCategorySelected ? filtered : products;
};

export const getCollectionTabCount = (showColUpLevel, recsTabCount) => {
  return !showColUpLevel ? recsTabCount : 3;
};

export const getRecsCollectionTitle = (tntTitle, showColUpLevel, metadata) => {
  return (tntTitle) || ((showColUpLevel) ? 'Make It Match' : metadata?.collectionName);
};

export const getCollectionClass = (showColUpLevel, isMobile) => {
  return className('sui-grid', 'sui-grid-cols-1', { 'sui-grid-cols-3 sui-gap-4': !showColUpLevel && !isMobile },
    { 'sui-grid-cols-5 sui-gap-4': showColUpLevel && !isMobile });
};

export const getCollectionTitleClass = (showColUpLevel, isMobile, metadata) => {
  return className('sui-grid', 'sui-grid-cols-1', { 'sui-pt-8': !isMobile && showColUpLevel },
    { 'sm:sui-grid-cols-3 sui-gap-4': (metadata?.collectionImageUrl && !showColUpLevel) });
};

export const getCollectionPod = (metadata, showColUpLevel, isB2BCustomer) => {
  return (metadata?.collectionImageUrl && !showColUpLevel && !isB2BCustomer) ? 4 : (isB2BCustomer && !showColUpLevel) ? 6 : 5;
};

export const getClassNames = (isMobile, showColUpLevel, metadata, isCategoryTab, isBatteryCollection, isB2BCustomer) => {
  if (!isMobile && showColUpLevel) {
    return className({ 'sui-col-span-4 sui-overflow-hidden': showColUpLevel });
  }
  if (isMobile && showColUpLevel) {
    return className({ 'sui-col-span-12': showColUpLevel });
  } return className({ 'sui-col-span-2 sui-overflow-hidden': (metadata?.collectionImageUrl && isCategoryTab),
    'sui-col-span-3': ((isB2BCustomer || !metadata?.collectionImageUrl) && isBatteryCollection) });
};

export const getCollPlaceholderLines = (showColUpLevel) => {
  return showColUpLevel ? 9 : 7;
};

export const getPlaceholderTxtLines = (isMobile, isCategoryTab, anchorImage, zoneName, hideATC) => {
  if (!isMobile && isCategoryTab) {
    return 7;
  }
  if (!isMobile && anchorImage) {
    return 5;
  }
  if (zoneName) {
    return 3;
  }
  return hideATC ? 4 : 6;
};

export const getRecsTitle = (tntTitle, metadata) => {
  return tntTitle || metadata?.title;
};

export const getRecsBreakPoint = (slidesToShow, slidesPer, anchorImage) => {
  const anchorImageValueNotSlidesPer = anchorImage ? 4 : 6;
  const anchorImageValueForSlidesPer = anchorImage ? 1 : slidesPer;
  if (slidesToShow) {
    return {
      sm: {
        slidesPerView: slidesToShow?.sm || 2,
        slidesPerGroup: slidesToShow?.sm || 2
      },
      md: {
        slidesPerView: slidesToShow?.md || 2,
        slidesPerGroup: slidesToShow?.md || 2
      },
      lg: {
        slidesPerView: slidesToShow?.lg || 5,
        slidesPerGroup: slidesToShow?.lg || 5
      },
      xl: {
        slidesPerView: slidesToShow?.xl || 5,
        slidesPerGroup: slidesToShow?.xl || 5
      }
    };
  }
  if (slidesPer) {
    return {
      sm: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      md: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      lg: {
        slidesPerView: anchorImageValueForSlidesPer,
        slidesPerGroup: anchorImageValueForSlidesPer
      },
      xl: {
        slidesPerView: anchorImageValueForSlidesPer,
        slidesPerGroup: anchorImageValueForSlidesPer
      }
    };
  }
  return {
    sm: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    md: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    lg: {
      slidesPerView: anchorImageValueNotSlidesPer,
      slidesPerGroup: anchorImageValueNotSlidesPer
    },
    xl: {
      slidesPerView: anchorImageValueNotSlidesPer,
      slidesPerGroup: anchorImageValueNotSlidesPer
    }
  };
};

export const getUdsProductTitle = (metadata) => {
  return metadata?.collectionName || metadata?.title;
};

export const getUdsProductBreakPoint = (slidesToShow, slidesPer) => {
  if (slidesToShow) {
    return {
      sm: {
        slidesPerView: slidesToShow?.sm || 2,
        slidesPerGroup: slidesToShow?.sm || 2
      },
      md: {
        slidesPerView: slidesToShow?.md || 2,
        slidesPerGroup: slidesToShow?.md || 2
      },
      lg: {
        slidesPerView: slidesToShow?.lg || 5,
        slidesPerGroup: slidesToShow?.lg || 5
      },
      xl: {
        slidesPerView: slidesToShow?.xl || 5,
        slidesPerGroup: slidesToShow?.xl || 5
      }
    };
  }
  if (slidesPer) {
    return {
      sm: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      md: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      lg: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      },
      xl: {
        slidesPerView: slidesPer,
        slidesPerGroup: slidesPer
      }
    };
  }
  return {
    sm: {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    md: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    lg: {
      slidesPerView: 6,
      slidesPerGroup: 6
    },
    xl: {
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  };
};

const recsBrand = (brandName) => {
  return (brandName !== null) ? 'More from ' + brandName : 'More from This Brand';
};

export const getTitle = (apiName, brandName, collectionName) => {
  const listTitles = {
    atc: 'Suggested Items',
    pipcollection: 'Make It Match',
    battery_collection: collectionName || 'More from This Collection',
    brand_based_collection: recsBrand(brandName),
    cart_instock: 'Explore Similar Items',
  };
  return listTitles[apiName] || '';
};