import React from 'react';
import { Typography, Skeleton, SkeletonBlock, SkeletonContent } from '@one-thd/sui-atomic-components';
import { Image } from '@thd-olt-component-react/core-ui';
import { DisplayUtil } from '../utils/DisplayUtils';
import { getImageURL } from '../utils/CardUtils';

export const NotificationSkeleton = () => {

  const SkeleBlock = () => {
    return (
      <Skeleton disablePadding="x" className="sui-col-span-6 sui-py-1">
        <SkeletonContent disablePadding grow>
          <SkeletonBlock aspect="wide" height={6} />
        </SkeletonContent>
      </Skeleton>
    );
  };

  const TabletSkele = () => {
    return (
      <div className="sui-pr-3 sui-w-full sui-py-2">
        <SkeletonContent grow disablePadding="x" orientation="horizontal">
          <SkeletonContent density="loose" disablePadding initialSize="2/4">
            <SkeletonBlock height={14} aspect="wide" />
          </SkeletonContent>
          <SkeletonContent density="loose" disablePadding initialSize="2/4">
            <SkeletonBlock height={6} />
          </SkeletonContent>
        </SkeletonContent>
      </div>
    );
  };

  return (
    <DisplayUtil
      mobileView={<SkeleBlock />}
      tabletView={<TabletSkele />}
      desktopView={<SkeleBlock />}
    />
  );
};

export const StatusUnavailable = ({ isLoadingPerks, refreshFn, isPerks = true }) => {
  const refreshLoading = !isLoadingPerks;
  const label = isPerks ? 'Perks' : 'Offers';

  return (
    <div className="sui-flex sui-flex-row sui-items-center sui-justify-start sui-w-full sui-py-3 sui-px-0">
      <div className="notification-image">
        <Image
          src={getImageURL('Pro-Xtra_Perk_Icon-whiteFill.svg')}
          width="48px"
          height="48px"
          alt="Perk Icon"
        />
      </div>
      <div className="sui-flex sui-flex-col notification-unavailable">
        <div className="sui-flex sui-flex-col sui-justify-center">
          <Typography variant="body-base" height="tight" weight="regular" color="inactive">
            {refreshLoading ? `${label} Status Unavailable` : `Loading ${label} Status`}
          </Typography>
        </div>
        <div>
          <div aria-hidden="true" onClick={refreshFn}><u>Refresh</u></div>
        </div>
      </div>
    </div>
  );
};

export const SkinnyBarSkeleton = () => {

  const SkeleBlock = () => {
    return (
      <Skeleton disablePadding className="sui-col-span-6 sui-pt-6 sui-pb-2">
        <SkeletonContent disablePadding grow>
          <SkeletonBlock aspect="wide" height={6} />
        </SkeletonContent>
      </Skeleton>
    );
  };

  const TabletSkele = () => {
    return (
      <div className="sui-pr-3 sui-w-full">
        <SkeletonContent grow disablePadding orientation="horizontal">
          <SkeletonContent density="loose" disablePadding initialSize="2/4">
            <SkeletonBlock height={14} aspect="wide" />
          </SkeletonContent>
          <SkeletonContent density="loose" disablePadding initialSize="2/4">
            <SkeletonBlock height={6} />
          </SkeletonContent>
        </SkeletonContent>
      </div>
    );
  };

  return (
    <DisplayUtil
      mobileView={<SkeleBlock />}
      tabletView={<TabletSkele />}
      desktopView={<SkeleBlock />}
    />
  );
};
