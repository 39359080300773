import React, { useEffect } from 'react';
import { bool, number as numberType, string as stringType } from 'prop-types';
import {
  arrayOf, customType,
  namedFragment, typename, params,
  shape, string, useDataModel
} from '@thd-nucleus/data-sources';
import { useImpression } from '@thd-olt-component-react/impression';
import { ThemeProvider } from '@one-thd/sui-atomic-components';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import { BannerRenderer } from './BannerRenderer';
import { withImpressions } from '../hocs/withImpressions';

const PiccolaPencil = withImpressions(({
  componentClass, componentId, componentPosition, lazyLoad, theme
}) => {

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: PiccolaPencil.displayName,
      component: PiccolaPencil.displayName,
      position: componentPosition,
      type: 'content'
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('piccola-pencil.ready');
  }, []);

  const { data, loading, error } = useDataModel('component', {
    variables: {
      componentClass,
      id: componentId
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
      <div
      // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        data-component-id={componentId}
        data-component-name={PiccolaPencil.displayName}
        data-component-position={componentPosition}
        data-component="PiccolaPencil"
        id={`${componentClass}-${componentPosition}-${componentId}`}
        ref={ref}
      >
        <BannerRenderer
          data={data?.component}
          mobileAspectRatio="sui-aspect-[125/29]"
          tabletAspectRatio="md:sui-aspect-[1160/60]"
          desktopAspectRatio="xl:sui-aspect-[1160/60]"
          lazyLoad={lazyLoad}
          componentId={componentId}
          componentPosition={componentPosition}
        />
      </div>
    </ThemeProvider>
  );
});

PiccolaPencil.propTypes = {
  componentClass: stringType,
  componentId: stringType.isRequired,
  componentPosition: numberType,
  lazyLoad: bool,
  theme: stringType
};

PiccolaPencil.defaultProps = {
  componentClass: '',
  componentPosition: 1,
  lazyLoad: false,
  theme: undefined
};

PiccolaPencil.displayName = 'PiccolaPencil';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: string()
      })
    )
  }),
  damDownloadedContent: shape({
    url: string()
  }),
  id: string(),
  imageHotspot: shape({
    id: string(),
    componentName: string()
  }),
  __typename: typename('DamMedia')
});

PiccolaPencil.dataModel = {
  component: params({
    id: string().isRequired(),
    componentClass: customType('ComponentClass').enum(['PiccolaPencil']).isRequired()
  }).shape({
    PiccolaPencil: namedFragment({
      inline: true,
      fragmentType: 'PiccolaPencil'
    }).shape({
      componentName: string(),
      id: string(),
      link: string(),
      altText: string(),
      desktopImage: DamMediaFragment,
      mobileImage: DamMediaFragment,
      tabletImage: DamMediaFragment,
      theme: string(),
    })
  })
};

export { PiccolaPencil };
