import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonShapes } from '../private/components/skeleton/SkeletonShapes';

/**
 * SkeletonBlock is a shape sub component of the [Skeleton](#skeleton) component.
 *
 * Usage:
 *
 * ```jsx
 * import { SkeletonBlock } from '@one-thd/sui-atomic-components';
 * ```
 */
const SkeletonBlock = React.forwardRef((props, ref) => {

  const {
    aspect = 'square',
    height = 32,
  } = props;

  return (
    <SkeletonShapes
      aspect={aspect}
      height={height}
      ref={ref}
      {...props}
      variant="rectangular"
    />
  );
});

SkeletonBlock.displayName = 'SkeletonBlock';

SkeletonBlock.propTypes = {
  /**
   * The aspect ratio to use.
   */
  aspect: PropTypes.oneOf(['wide', 'square']),
  /**
   * Height of the skeleton.
   * Number values correspond to the spacing scale.
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Width of the skeleton.
   * Number values correspond to the spacing scale.
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export { SkeletonBlock };