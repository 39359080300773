import React, { useEffect, useReducer } from 'react';
import { func, string } from 'prop-types';
import {
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  ButtonGroup,
  TextField
} from '@one-thd/sui-atomic-components';
import {
  customType,
  params,
  string as stringType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';

export const EmailListDrawerBody = ({
  listId,
  onClose,
  menuName,
  setMenuName,
  setEmailListDrawer
}) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('EmailListDrawerBody.ready', {});
  }, []);

  const initialValues = {
    email: '',
    emailError: false,
    emailValid: false,
    message: '',
    messageError: false,
    messageValid: false,
    senderName: '',
    senderNameError: null,
    senderNameValid: false,
    format: 'HTML'
  };

  const storeId = useStoreId();

  const [share, shareResponse] = useDataModel('emailList', {
    lazy: true,
    skip: !listId,
    context: {
      withAuth: true
    },
    fetchPolicy: 'no-cache'
  });

  const [formValues, setFormValues] = useReducer(
    (currentFormValues, newFormValues) => ({ ...currentFormValues, ...newFormValues }), initialValues
  );

  const {
    senderName,
    senderNameError,
    senderNameValid,
    email,
    emailError,
    emailValid,
    message,
    messageError,
    messageValid,
    format,
  } = formValues;

  const handleSenderNameChange = (event) => {
    const { id, value } = event.target;
    let error = null;
    const name = value.trim();
    if (name.length === 0) {
      error = 'You must enter a name.';
    }
    if (!name.match(/^[a-zA-Z0-9 ]*$/)) {
      error = 'Invalid Name: Only A-Z and 0-9 are allowed.';
    }
    setFormValues({ [id]: value, senderNameValid: !error, senderNameError: error });
  };

  const validateEmail = (value) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const { id, value } = event.target;
    const isError = !validateEmail(value);
    setFormValues({ [id]: value, emailValid: !isError, emailError: isError });
  };

  const handleMessageChange = (event) => {
    const { id, value } = event.target;
    let isError = false;
    if (value.trim().length === 0) {
      isError = true;
    }

    if (value.length < 300) {
      setFormValues({ [id]: value, messageValid: !isError, messageError: isError });
    } else {
      const truncated = value.slice(0, 300);
      setFormValues({ message: truncated, messageValid: !isError, messageError: isError });
    }
  };

  useEffect(() => {
    if (shareResponse?.data?.emailList?.id) {
      onClose();
    }
  }, [shareResponse?.data?.emailList?.id]);

  const handleFormSubmit = async (submitEvent) => {
    submitEvent.preventDefault();
    if (listId) {
      await share({
        variables: {
          email,
          format,
          from: senderName,
          id: listId,
          message,
          storeId
        }
      });
      LIFE_CYCLE_EVENT_BUS.trigger('product-list.email-list', {});
    } else {
      onClose();
    }
  };

  const PreviousDrawer = () => {
    setEmailListDrawer(false);
    setMenuName('Share List');
  };

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title={menuName} onClose={onClose} onBack={PreviousDrawer} />
        <DrawerBody>
          <form
            className="sui-grid sui-gap-6"
            autoComplete="off"
            noValidate
            onSubmit={handleFormSubmit}
          >
            <TextField
              required
              id="senderName"
              label="Your Name"
              type="text"
              value={senderName}
              fullWidth
              status={senderNameError ? 'error' : null}
              statusMessage={senderNameError}
              onChange={handleSenderNameChange}
            />
            <TextField
              required
              id="email"
              label="Recipients Email"
              status={emailError ? 'error' : null}
              statusMessage={emailError ? 'Please enter a valid email.' : null}
              type="email"
              value={email}
              fullWidth
              onChange={handleEmailChange}
            />
            <TextField
              required
              id="message"
              label="Message"
              value={message}
              type="text"
              helpMessage="300 Characters Max"
              fullWidth
              status={messageError ? 'error' : null}
              statusMessage={messageError ? 'You must enter a message.' : null}
              onChange={handleMessageChange}
              multiline
              minRows={4}
              maxRows={4}
            />
          </form>
        </DrawerBody>
        <DrawerFooter position="center">
          <ButtonGroup orientation="vertical">
            <Button
              variant="primary"
              fullWidth
              onClick={handleFormSubmit}
              disabled={!senderNameValid || !emailValid || !messageValid}
              type="submit"
              data-testid="send-email-list-button"
            >
              Send
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-testid="cancel-email-list-button"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </div>
    </>
  );
};

EmailListDrawerBody.propTypes = {
  onClose: func.isRequired,
  listId: string.isRequired,
  menuName: string.isRequired,
  setMenuName: func.isRequired,
  setEmailListDrawer: func.isRequired
};

EmailListDrawerBody.displayName = 'EmailListDrawerBody';

EmailListDrawerBody.dataModel = {
  emailList: params({
    id: stringType().isRequired(),
    email: stringType().isRequired(),
    from: stringType().isRequired(),
    message: stringType().isRequired(),
    format: customType('ListEmailFormat').enum(['HTML', 'PDF'], 'PDF'),
    storeId: stringType()
  }).mutation().shape({
    id: stringType()
  })
};
