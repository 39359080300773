import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import PropTypes from 'prop-types';
import { RenderQuote } from './RenderQuote';
import { RenderTable } from './RenderTable';
import { RenderParagraph } from './RenderParagraph';
import { RenderHyperlink } from './RenderHyperlink';

const shortid = require('shortid');

export const options = {
  preserveWhitespaces: true,
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <b
        className="sui-font-bold"
        key={`bold-text-${shortid.generate()}`}
      >
        {text}
      </b>
    ),
    [MARKS.ITALIC]: (text) => (
      <i key={`italic-text-${shortid.generate()}`}>
        {text}
      </i>
    ),
    [MARKS.UNDERLINE]: (text) => (
      <u
        key={`underline-text-${shortid.generate()}`}
        className="sui-underline"
      >
        {text}
      </u>
    ),
    [MARKS.SUPERSCRIPT]: (text) => (
      <sup key={`sup-text-${shortid.generate()}`}>
        {text}
      </sup>
    ),
    [MARKS.SUBSCRIPT]: (text) => (
      <sub key={`sub-text-${shortid.generate()}`}>
        {text}
      </sub>
    ),
    [MARKS.CODE]: (text) => (
      <mark
        key={`code-text-${shortid.generate()}`}
        style={{ backgroundColor: '#E1E2E0' }}
      >
        {text}
      </mark>
    )
  },
  renderNode: {
    [BLOCKS.HR]: () => <hr key={`hr-${shortid.generate()}`} />,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul
        key={`ul-list-${shortid.generate()}`}
        className="sui-pl-4 sui-list-disc"
      >
        {children}
      </ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol
        key={`ol-list-${shortid.generate()}`}
        className="sui-pl-4 sui-list-decimal"
      >
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li key={`li-item-${shortid.generate()}`}>
        {children}
      </li>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <RenderParagraph
        key={`paragraph-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h1-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h2-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h3-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h4-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h5-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <RenderParagraph
        key={`paragraph-h6-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderParagraph>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <RenderQuote
        key={`paragraph-quote-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderQuote>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <RenderHyperlink
        key={`hyperlink-${shortid.generate()}`}
        node={node}
      >
        {children}
      </RenderHyperlink>
    ),
    [BLOCKS.TABLE]: (node) => (
      <RenderTable
        key={`table-${shortid.generate()}`}
        node={node}
      />
    )
  }
};

export const RichText = ({ content, isLivePreview }) => {
  if (content) {
    let descriptionContent;
    try {
      descriptionContent = isLivePreview ? content : JSON.parse(content);
    } catch (error) {
      return null;
    }
    return documentToReactComponents(descriptionContent, options);
  }
  return null;
};

RichText.propTypes = {
  content: PropTypes.string.isRequired,
  isLivePreview: PropTypes.bool,
};

RichText.defaultProps = {
  isLivePreview: false,
};
