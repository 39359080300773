import { createTheme } from '@one-thd/sui-atomic-components';

const sbotdBFTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      primary: 'brand'
    }
  },
});

sbotdBFTheme.palette['--sui-palette-background-primary'] = '73 73 73';

export { sbotdBFTheme };
