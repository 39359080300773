import React, { useEffect, useState, Suspense } from 'react';
import { params, string as stringType, shape as shapeType, useDataModel } from '@thd-nucleus/data-sources';
import { arrayOf, instanceOf, shape, string } from 'prop-types';
import './media-carousel.scss';
import { Image } from '@thd-olt-component-react/core-ui';
import { usePresentation } from '../../../context/PresentationProvider';
import * as analytics from '../../../ratings-reviews-analytics';

// eslint-disable-next-line max-len
const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));
// eslint-disable-next-line max-len
const CustomerImageOverlay = React.lazy(() => import(/* webpackChunkName: "customer-image-overlay" */ '@thd-olt-component-react/customer-image-overlay')
  .then((module) => ({ default: module.CustomerImageOverlay })));

export const ReviewMediaCarousel = ({ itemId, media, review }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [overlayContent, setOverlayContent] = useState(null);
  const { useCondensedLayout } = usePresentation();

  const { data = {}, loading } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });

  const reviewPhotos = { Results: [review._props] };
  useEffect(() => {
    // clear current media when overlay is closed
    if (!overlayOpen) {
      setCurrentMedia(null);
    } else {
      // analytics when overlay is opened
      analytics.track('event', {
        origin: 'ratings and reviews', location: 'media', value: 'review', eventType: 'click'
      });

    }
  }, [overlayOpen]);
  useEffect(() => {
    // content can be a video or an images so handling that logic here instead of inline
    const {
      Sizes, VideoHost, VideoId, VideoIframeUrl, VideoUrl
    } = currentMedia || {};
    if (Sizes) {
      setOverlayContent(
        <div className="customer-review__wrapper">
          <Image
            src={Sizes.normal.Url}
            className={useCondensedLayout ? 'customer-review__image-mobile' : 'customer-review__image'}
            alt="img"
            lazy
            height="100%"
            width="100%"
          />
        </div>
      );
    } else if (VideoUrl) {
      const isYoutube = VideoHost === 'www.youtube.com' || VideoUrl.indexOf('www.youtube.com') > -1;
      let videoURL = VideoIframeUrl;
      if (!videoURL) {
        videoURL = isYoutube
          ? `${VideoUrl}?modestbranding=1`.replace('watch?v=', 'embed/')
          : VideoUrl;
      }
      setOverlayContent(
        <div className="customer-review__wrapper">
          <iframe
            className={
              !useCondensedLayout ? 'customer-review__image' : 'customer-review__image-mobile'
            }
            height="450px"
            width={useCondensedLayout ? '350px' : '900px'}
            src={videoURL}
            title={VideoId}
            allow="fullscreen"
          />
        </div>
      );
    }
    setOverlayOpen(!!currentMedia);
  }, [currentMedia]);

  if (loading || !data) {
    return '';
  }

  const { product = {} } = data;
  const { identifiers = {} } = product;
  const { productLabel = null } = identifiers;

  const onOverlayInteraction = () => {
    analytics.track('EVENT', {
      origin: 'ratings and reviews',
      location: 'media',
      value: 'gallery',
      eventType: 'click'
    });
  };

  return (
    <div className="media-carousel">
      <div className="media-carousel__medias">
        {
          media.map((med, mediaIndex) => {
            const { Sizes, VideoThumbnailUrl, VideoUrl } = med;
            let thumbnail;
            if (Sizes) {
              thumbnail = `url(${Sizes.thumbnail.Url})`;
            } else if (VideoUrl) {
              thumbnail = VideoThumbnailUrl
                ? `url(${VideoThumbnailUrl})`
                : 'url(\'https://assets.homedepot-static.com/images/v1/play-video-grey.svg\')';
            }
            return (
              <div
                key={`${mediaIndex}`}
                className="media-carousel__media"
              >
                <button
                  aria-label="Image"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: (thumbnail)
                  }}
                  onClick={() => {
                    setCurrentMedia(med);
                  }}
                  type="button"
                />
              </div>
            );
          })
        }
      </div>
      {(currentMedia?.Id || currentMedia?.VideoId)
        && (
          <Suspense fallback={(<div />)}>
            {currentMedia.VideoUrl
              ? (
                <>
                  <div className="media-carousel__overlay-header">
                    <span />
                    <button
                      onClick={() => setOverlayOpen(false)}
                      type="button"
                    >
                      <Image
                        height="20"
                        src="https://assets.thdstatic.com/images/v1/close-symbol-orange.svg"
                        alt="Close"
                      />
                    </button>
                  </div>
                  <div className="media-carousel__overlay-content">
                    {overlayContent}
                  </div>
                </>
              ) : (
                <Suspense fallback={(<div />)}>
                  <CustomerImageOverlay
                    channel={useCondensedLayout ? 'mobile' : 'desktop'}
                    photos={reviewPhotos}
                    onClose={() => {
                      setOverlayOpen(false);
                    }}
                    selectedPhotoID={currentMedia.Id}
                    loopPhotos={false}
                    onInteraction={onOverlayInteraction}
                    productLabel={productLabel}
                  />
                </Suspense>
              )}
          </Suspense>
        )}
    </div>
  );
};

ReviewMediaCarousel.displayName = 'ReviewMediaCarousel';

ReviewMediaCarousel.dataModel = {
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    identifiers: shapeType({
      productLabel: stringType()
    }),
  })
};

ReviewMediaCarousel.propTypes = {
  itemId: string,
  media: arrayOf(shape({})),
  review: shape({ _props: instanceOf(Object) }).isRequired,
};

ReviewMediaCarousel.defaultProps = {
  itemId: null,
  media: [],
};
