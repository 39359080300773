import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import {
  customType,
  extend,
  string as stringType,
  params,
  useLazyDataModel
} from '@thd-nucleus/data-sources';
import {
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Alert,
  Toggle,
  SelectionControlLabel
} from '@one-thd/sui-atomic-components';
import { Email } from '@one-thd/sui-icons';
import { LIST_SHAPE, triggerAnalytics } from '../../helpers';
import {
  privateAccessType,
  sharedAccessType,
  LIST_SETTINGS_SAVE,
} from '../../constants';
import { CopyList } from '../copyList/CopyList';
import { CopyLinkDrawerBody } from './CopyLinkDrawerBody';
import { EmailListDrawerBody } from './EmailListDrawerBody';

export const ShareListDrawerBody = ({
  list,
  onClose,
  onSave,
  menuName,
  setMainMenuDrawer,
  setShareListDrawer,
  setMenuName
}) => {

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.trigger('ShareListDrawerBody.ready', {});
  }, []);

  const {
    id: listId,
    name: initialName,
    listAccessType: initialListAccessType
  } = list || {};

  const initialShared = initialListAccessType === sharedAccessType;
  const [shared, setShared] = useState(initialShared);
  const [copyLinkDrawer, setCopyLinkDrawer] = useState(false);
  const [emailListDrawer, setEmailListDrawer] = useState(false);

  const [saveShareToggle, saveShareToggleResponse] = useLazyDataModel('updateList', {
    variables: {
      id: listId
    },
    context: { withAuth: true }
  });

  const handleShareToggle = async (event) => {
    event.preventDefault();
    const isShared = !shared;
    const variables = {
      listAccessType: isShared ? sharedAccessType : privateAccessType
    };
    await saveShareToggle({
      variables,
      update: (cache, { data: responseData }) => {
        const { updateList } = responseData || {};
        if (updateList) {
          cache.modify({
            id: cache.identify(updateList),
            fields: {
              name() {
                return initialName;
              }
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    if (saveShareToggleResponse?.data?.updateList?.id) {
      const isShared = !shared;
      triggerAnalytics(LIST_SETTINGS_SAVE, {
        type: isShared ? 'share' : 'stop sharing'
      });
      setShared(!shared);
      onSave();
    }
  }, [saveShareToggleResponse?.data, saveShareToggleResponse?.error]);

  const emailListMenuButtonClick = () => {
    setEmailListDrawer(true);
    setMenuName('Email List');
  };

  const PreviousDrawer = () => {
    setShareListDrawer(false);
    setMainMenuDrawer(true);
    setMenuName('List Settings');
  };

  if (copyLinkDrawer) {
    return (
      <CopyLinkDrawerBody
        onClose={onClose}
        menuName={menuName}
        setMenuName={setMenuName}
        setShareListDrawer={setShareListDrawer}
        setCopyLinkDrawer={setCopyLinkDrawer}
      />
    );
  }

  if (emailListDrawer) {
    return (
      <EmailListDrawerBody
        listId={listId}
        onClose={onClose}
        menuName={menuName}
        setMenuName={setMenuName}
        setShareListDrawer={setShareListDrawer}
        setEmailListDrawer={setEmailListDrawer}
      />
    );
  }

  return (
    <>
      <div className="sui-flex sui-flex-col sui-min-h-screen">
        <DrawerHeader title={menuName} onClose={onClose} onBack={() => setMenuName(PreviousDrawer)} />
        <DrawerBody>
          <div className="sui-flex sui-grid sui-gap-2">
            <hr />
            <div className="sui-flex sui-gap-2 sui-mt-6 sui-ml-1">
              <SelectionControlLabel label="Allow sharing">
                <Toggle
                  checked={shared}
                  onChange={handleShareToggle}
                  inputAttributes={{ 'aria-label': 'share-toggle' }}
                />
              </SelectionControlLabel>
            </div>
            <Alert status="info" data-testid="shared-list-status-alert">
              {shared
                ? 'This list is currently viewable via link, to disable, turn off "Allow sharing"'
                : 'This list is currently not viewable via link, to enable, turn on "Allow sharing"' }
            </Alert>
            <hr />
            <CopyList
              listId={listId}
              setCopyLinkDrawer={setCopyLinkDrawer}
              setMenuName={setMenuName}
              disabled={!shared}
            />
            <Button
              variant="ghost"
              startIcon={Email}
              onClick={emailListMenuButtonClick}
              disabled={!shared}
              data-testid="email-list-button"
            >
              Email List
            </Button>
          </div>
        </DrawerBody>
        <DrawerFooter>
          <Button
            variant="secondary"
            fullWidth
            onClick={onClose}
            data-testid="close-share-list-drawer"
          >
            Close
          </Button>
        </DrawerFooter>
      </div>
    </>
  );
};

ShareListDrawerBody.displayName = 'ShareListDrawerBody';

ShareListDrawerBody.propTypes = {
  onClose: func.isRequired,
  list: shape({
    id: string,
    name: string,
    public: bool
  }).isRequired,
  onSave: func,
  menuName: string.isRequired,
  setMainMenuDrawer: func.isRequired,
  setShareListDrawer: func.isRequired,
  setMenuName: func.isRequired,
};

ShareListDrawerBody.defaultProps = {
  onSave: () => { }
};

ShareListDrawerBody.dataModel = extend({
  updateList: params({
    id: stringType().isRequired(),
    name: stringType(),
    listAccessType: customType('AccessTypes').enum(['Private', 'Public', 'Shared'])
  }).mutation().shape(LIST_SHAPE),
},
EmailListDrawerBody
);
