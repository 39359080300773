import React, { useState, useContext, useCallback } from 'react';
import { Tab, Tabs } from '@one-thd/sui-atomic-components';
import {
  arrayOf, bool, func, shape, string, number
} from 'prop-types';

const CategoryTabs = ({
  categories = [],
  nameOfEventAttribute = 'Model2',
  handleTabClick = () => {},
  activeIndex
}) => {
  return (
    <div className="sui-w-full">
      <Tabs
        value={activeIndex}
        aria-label="digital-endcap-tabs"
        variant="scrollable"
        TabScrollButtonProps={{
          LeftIconButtonProps: { 'aria-label': 'scroll button left' },
          RightIconButtonProps: { 'aria-label': 'scroll button right' }
        }}
      >
        {(categories || []).map((category, categoryIndex) => (
          <Tab
            key={categoryIndex}
            label={category.category}
            value={categoryIndex}
            onClick={(ev) => handleTabClick(
              ev,
              categoryIndex,
              category.nValue,
              nameOfEventAttribute,
            )}
          />
        ))}
      </Tabs>
    </div>
  );
};

CategoryTabs.propTypes = {
  activeIndex: number.isRequired,
  categories: arrayOf(shape({
    category: string,
    nValue: string,
    imageUrl: string,
    url: string,
    shopAllLink: string,
    exclude: arrayOf(string),
    displayImageOnMobileWeb: bool,
  })),
  nameOfEventAttribute: string,
  handleTabClick: func
};

export { CategoryTabs };