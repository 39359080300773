import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade } from '../fade/Fade';

const Backdrop = React.forwardRef((props, ref) => {

  const {
    children,
    RootComponent = Fade,
    // components = {},
    // componentsProps = {},
    className,
    invisible = false,
    open,
    transitionDuration,
    onClick,
    ...other
  } = props;

  const styles = [
    'sui-fixed',
    'sui-flex',
    'sui-items-center',
    'sui-justify-center',
    'sui-top-0 sui-right-0 sui-bottom-0 sui-left-0',
    // 'sui-opacity-50',
  ].join(' ');

  const classes = classNames(styles, {
    'sui-bg-inverse/50': !invisible,
    'sui-bg-transparent': invisible
  });

  return (
    <RootComponent
      aria-hidden
      classes={classes}
      in={open}
      onClick={onClick}
      ref={ref}
      timeout={transitionDuration}
      {...other}
    >
      {children}
    </RootComponent>
  );
});

Backdrop.displayName = 'Backdrop';

Backdrop.propTypes = {
  children: PropTypes.node,
  RootComponent: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  open: PropTypes.bool.isRequired,
  transitionDuration: PropTypes.number,
  onClick: PropTypes.func,
  invisible: PropTypes.bool
};

export { Backdrop };
