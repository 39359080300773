import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { promoVisNavDataModel } from './dataModel';
import { PromoVisualNavigationRenderer } from './PromoVisualNavigationRenderer';

const PromoVisualNavigation = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  experienceHasSideNav = false,
  title = '',
  lazyLoad = false,
  enableTheme = false,
  isHomePage = false,
  fontWeight = 'display'
}) => {
  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass: 'PromoVisualNavigation'
    }
  });

  if (!data || loading || error) {
    return null;
  }

  return (
    <PromoVisualNavigationRenderer
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      experienceHasSideNav={experienceHasSideNav}
      title={title}
      lazyLoad={lazyLoad}
      enableTheme={enableTheme}
      isHomePage={isHomePage}
      dataComponent={data?.component}
      promoVisNavList={data?.component?.promoVisualNavigationList}
      fontWeight={fontWeight}
    />
  );
};

PromoVisualNavigation.displayName = 'PromoVisualNavigation';
PromoVisualNavigation.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number,
  experienceHasSideNav: PropTypes.bool,
  title: PropTypes.string,
  lazyLoad: PropTypes.bool,
  enableTheme: PropTypes.bool,
  isHomePage: PropTypes.bool,
  fontWeight: PropTypes.string
};

PromoVisualNavigation.dataModel = promoVisNavDataModel;

export { PromoVisualNavigation };
