import {
  BACKWARDS_BOGO_SUBEXPERIENCES,
  CATEGORIZED_SUBEXPERIENCES,
  DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCES,
  EXPERIENCE_TAGS,
  FORWARDS_B1GY_SUBEXPERIENCES,
  FORWARDS_BOGO_SUBEXPERIENCES,
  FORWARDS_BXG1_SUBEXPERIENCES,
  MESSAGE_NUMBER_WORD_MAP,
  TWO_LIST_UI_SUBEXPERIENCES,
} from './constants';

export const noop = () => { };

/**
 * @param {string} url
 * @returns {string}
 */
export const sanitizeImageUrl = (url = '') => url.replace(/<SIZE>/g, '400');

export const getMinimumPrice = (tiers) => {
  return tiers.reduce((currentMinPrice, { rewardFixedPrice }) => Math.max(currentMinPrice, rewardFixedPrice), 0);
};

export const pipe = (...funcs) => (arg) => funcs.reduce((acc, cur) => cur(acc), arg);

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isTwoListUi = ({ nvalues, subExperienceTag } = {}) => {
  return (
    TWO_LIST_UI_SUBEXPERIENCES.includes(subExperienceTag)
    && !nvalues?.length
  );
};

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isForwardsBogo = ({ subExperienceTag } = {}) => {
  return FORWARDS_BOGO_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isBackwardsBogo = ({ subExperienceTag } = {}) => {
  return BACKWARDS_BOGO_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isDollarThresholdBogo = ({ subExperienceTag } = {}) => {
  return DOLLAR_THRESHOLD_BOGO_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isForwardsB1gy = ({ subExperienceTag }) => {
  return FORWARDS_B1GY_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
 * @param {Object} obj
 * @param {string} obj.subExperienceTag
 * @returns {boolean}
 */
export const isForwardsBxg1 = ({ subExperienceTag }) => {
  return FORWARDS_BXG1_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
* @param {Object} obj
* @param {string} obj.subExperienceTag
* @returns {boolean}
*/
export const isCategorizedPromotion = ({ subExperienceTag }) => {
  return CATEGORIZED_SUBEXPERIENCES.includes(subExperienceTag);
};

/**
 * @param {Object} promotion
 * @param {string} itemGroup identifies which of the eligibilityCriteria to use
 * @param {string[]} categoriesItemIds
 * @returns {string[]}
 */
export const getItemIdList = (promotion, itemGroup, categoriesItemIds = []) => {
  const eligibilityCriterion = promotion?.eligibilityCriteria?.find(({ itemGroup: criterionItemGroup }) => {
    return criterionItemGroup === itemGroup;
  });

  const itemIds = (
    eligibilityCriterion?.itemIds?.length && eligibilityCriterion.itemIds.slice()
  ) || [];

  return categoriesItemIds.length > 0 ? categoriesItemIds : itemIds;
};

/**
 * applies business logic to determine the "first" and "second" lists.
 * @param {Object} promotion
 * @returns {string[]}
 */
export const getItemGroupsForEachList = (promotion) => {
  const itemGroupsInOrder = [
    isForwardsBogo(promotion) ? 'TGT-1' : 'SRC-1'
  ];

  if (isTwoListUi(promotion) || isBackwardsBogo(promotion)) {
    // we don't have two list promos that put SRC-1 in the second list
    itemGroupsInOrder.push('TGT-1');
  }

  if (isForwardsBogo(promotion)) {
    itemGroupsInOrder.push('SRC-1');
  }

  return itemGroupsInOrder;
};

/**
 * @param {Object} promotion
 * @returns {string[][]}
 */
export const getItemIdsForEachList = (promotion) => {
  const itemGroupsInOrder = getItemGroupsForEachList(promotion);
  return itemGroupsInOrder.map((itemGroup) => {
    return getItemIdList(promotion, itemGroup, []);
  });
};

export const getProductImage = (product) => product.media?.image?.url || '';

const hasPriceAboveMinimum = (anchorItem, tiers) => {
  return !(tiers?.[0]?.rewardFixedPrice && !(anchorItem.pricing.value >= getMinimumPrice(tiers)));
};

export const hasEligibleAnchor = ({ anchorItem, tiers }) => {
  const isPreferredPricing = Boolean(anchorItem.pricing?.preferredPriceFlag);
  const isPriceAboveMinimum = hasPriceAboveMinimum(anchorItem, tiers);
  return (
    !isPreferredPricing
    // hide component if anchor price is less than minimum for rewardFixedPrice scenarios
    && isPriceAboveMinimum
  );
};

/**
 *
 * @param {Object} obj
 * @param {boolean | null} [obj.prop]
 * @param {boolean | null} [obj.config]
 * @param {boolean} [obj.defaultValue]
 * @returns {boolean}
 */
export const getIsFeatureEnabled = ({ prop, config, defaultValue = false }) => {
  if (prop === false || config === false) return false;
  if (prop === true || config === true) return true;
  return Boolean(defaultValue);
};

/**
 * @param {Object} obj
 * @param {string | null} obj.experienceTag
 * @param {string | null} obj.subExperienceTag
 * @param {boolean | null} [obj.backwardsBogoEnabled] Backwards BOGO feature switch
 * @param {boolean | null} [obj.bogoDollarThresholdEnabled] BOGO dollar threshold feature switch
 * @param {boolean | null} [obj.bxg1Enabled] BOGO dollar threshold feature switch
 * @param {boolean | null} [obj.bogoEnabled] Overall BOGO feature switch
 * @param {boolean | null} [obj.bmsmEnabled] BMSM feature switch
 * @param {boolean | null} [obj.categorizedPromotionEnabled] Categorized promotion feature switch
 * @param {boolean | null} [obj.msbEnabled] MSB feature switch
 * @returns {boolean} Whether the promotion is enabled
 */
export const hasEnabledPromotion = ({
  experienceTag,
  subExperienceTag,
  backwardsBogoEnabled,
  bogoDollarThresholdEnabled,
  bxg1Enabled,
  bogoEnabled,
  bmsmEnabled,
  msbEnabled,
  categorizedPromotionEnabled,
  type
}) => {
  const isCard = type === 'card';
  switch (experienceTag) {
  case EXPERIENCE_TAGS.BOGO: {
    if (isForwardsBogo({ subExperienceTag })) {
      return Boolean(bogoEnabled);
    }

    if (isBackwardsBogo({ subExperienceTag })) {
      return Boolean(bogoEnabled && backwardsBogoEnabled && !isCard);
    }

    if (isDollarThresholdBogo({ subExperienceTag })) {
      return Boolean(bogoEnabled && bogoDollarThresholdEnabled && !isCard);
    }

    if (isForwardsB1gy({ subExperienceTag })) {
      return Boolean(bogoEnabled && !isCard);
    }

    if (isForwardsBxg1({ subExperienceTag })) {
      return Boolean(bogoEnabled && bxg1Enabled && !isCard);
    }

    return false;
  }
  case EXPERIENCE_TAGS.BMSM:
    if (isCategorizedPromotion({ subExperienceTag })) {
      return Boolean(bmsmEnabled && categorizedPromotionEnabled && !isCard);
    }
    return Boolean(bmsmEnabled && !isCard);
  case EXPERIENCE_TAGS.MSB:
    return Boolean(msbEnabled && !isCard);
  default:
    return false;
  }
};

export const toWord = (num) => {
  return MESSAGE_NUMBER_WORD_MAP[num] || num;
};
