import React, { useState } from 'react';
import {
  bool, func, shape, string, number
} from 'prop-types';
import { Tile, TileMedia, TileContent } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';

export const SwatchSui = ({
  enableHover,
  isSelected,
  onClick,
  onMouseEnter,
  onMouseLeave,
  swatchGuid,
  value,
  index,
  swatchItemId,
  componentImpressionData,
  isImpressionsRequired,
  wrapperClasses
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClickedMobile, setIsClickedMobile] = useState(false);

  const mouseEnter = (event) => {
    if (enableHover) {
      setIsHovered(true);
      onMouseEnter(event);
    }
  };
  const mouseLeave = (event) => {
    if (enableHover) {
      setIsHovered(false);
      onMouseLeave(event);
    }
  };

  const onClickMobile = () => {
    if (!enableHover) {
      setIsClickedMobile(true);
    }
  };

  const impressionProviderContextData = useImpression({
    data: {
      id: swatchItemId || '',
      name: componentImpressionData?.name || '',
      component: componentImpressionData?.component || '',
      position: componentImpressionData?.position || 1,
      type: componentImpressionData?.type || '',
      category: 'ssku'
    }
  });

  const swatchRef = index !== 0 && isImpressionsRequired && (isHovered || isClickedMobile)
    ? impressionProviderContextData.ref
    : null;

  const swatchClickId = index !== 0 && isImpressionsRequired && (isHovered || isClickedMobile)
    ? impressionProviderContextData.clickID
    : null;

  return (
    <div className={wrapperClasses}>
      <Tile
        value={value}
        selected={isSelected}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={(event) => {
          onClickMobile();
          onClick(event);
        }}
        clickid={swatchClickId}
        ref={swatchRef}
      >
        <TileContent alignItems="center">
          <TileMedia
            swatch
            src={swatchGuid}
            height="7"
            key={index}
          />
        </TileContent>
      </Tile>
    </div>
  );
};

SwatchSui.displayName = 'SwatchSui';

SwatchSui.propTypes = {
  swatchGuid: string.isRequired,
  value: string.isRequired,
  isSelected: bool.isRequired,
  enableHover: bool.isRequired,
  onClick: func.isRequired,
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
  swatchItemId: string,
  index: number,
  componentImpressionData: shape({
    name: string,
    component: string,
    position: number,
    type: string,
  }),
  isImpressionsRequired: bool,
  wrapperClasses: string,
};

SwatchSui.defaultProps = {
  swatchItemId: null,
  index: 0,
  componentImpressionData: {
    name: '',
    component: '',
    position: 1,
    type: '',
  },
  isImpressionsRequired: false,
  wrapperClasses: null
};
