/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Col } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import { LoyaltyWelcomeCardModel } from '../../common/models/UserInfoModels';
import { WelcomeCardContext } from '../../contexts';
import { ComponentProviders } from '../../core/ComponentProviders';
import { WelcomeCardHeader } from './WelcomeCardHeader/WelcomeCardHeader';
import { SpendSummary } from './SpendSummary/SpendSummary';
import { WelcomeCardFooter } from './WelcomeCardFooter';
import { NotificationSection } from './NotificationSection';
import { PromotionSection } from './PromotionSection';
import { WelcomeCardErrorPage } from './WelcomeCardErrorPage/WelcomeCardErrorPage';
import { TabletWelcomeCard } from './TabletWelcomeCard/TabletWelcomeCard';
import { UserProfileSummary } from './UserProfileSummary/UserProfileSummary';
import './LoyaltyWelcomeCard.scss';
import './TabletNotification.scss';
import { SkinnyBarNotifications } from './NotificationSection/SkinnyBarNotifications';
import { DisplayUtil } from '../../common/utils/DisplayUtils';
import { DrawerContainer } from '../../common/Drawer/DrawerContainer';

const exportsForTesting = {};

const App = ({ componentId }) => {
  const { channel } = useContext(ExperienceContext);
  const { isLoading, refreshData, tiles, isAdmin, userDataError, userData } = useContext(WelcomeCardContext);
  const isMobile = channel === 'mobile';

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'Loyalty Benefits',
      component: 'Loyalty Benefits',
      position: 1,
      type: 'content',
    },
  });

  const handleOnClick = () => {
    refreshData();
  };

  const WelcomeCard = () => {
    const { customerInfo } = userData || {};
    const enrolledInPerks = customerInfo?.enrolledInPerksProgram;
    return (
      <Col className="loyalty_benefits__welcomeCard
        sui-border-primary sui-border-solid sui-border-1 sui-rounded-base"
      >
        <div
          className="wrapper"
          ref={ref}
          // eslint-disable-next-line react/no-unknown-property
          clickid={clickID}
        >
          <header className="page-header">
            <WelcomeCardHeader />
          </header>
          <main>
            <div>{!isMobile && <UserProfileSummary />}</div>
            <div>{!isMobile && isAdmin && <SpendSummary />}</div>
            <div className="loyalty-welcome">
              {isAdmin ? (
                <>
                  <div className={(enrolledInPerks
                    ? 'loyalty_benefits__mainNotifications' : 'loyalty_benefits____mainSingleNotification')}
                  >
                    <NotificationSection tiles={tiles.NOTIFICATION} />
                  </div>
                  <SkinnyBarNotifications />
                </>
              ) : (
                <PromotionSection tiles={tiles.PROMOTION} />
              )}
            </div>
          </main>
          <footer className="loyalty_benefits__footerSection">
            <WelcomeCardFooter />
            <DrawerContainer />
          </footer>
        </div>
      </Col>
    );
  };

  const RenderWelcomeCard = () => {
    return (
      <div className="sui-grid" data-component="LoyaltyBenefits">
        {!isLoading && !userDataError ? (
          <WelcomeCard />
        ) : (
          <WelcomeCardErrorPage handleOnClick={handleOnClick} cardLoaded={!isLoading} />
        )}
      </div>
    );
  };

  const RenderLoyaltyBenefits = () => {
    return (
      <DisplayUtil
        mobileView={<RenderWelcomeCard />}
        tabletView={<TabletWelcomeCard handleOnClick={handleOnClick} />}
        desktopView={<RenderWelcomeCard />}
      />
    );
  };

  exportsForTesting.handleOnClick = handleOnClick;
  exportsForTesting.WelcomeCard = WelcomeCard;
  exportsForTesting.RenderWelcomeCard = RenderWelcomeCard;
  exportsForTesting.RenderLoyaltyBenefits = RenderLoyaltyBenefits;

  return <RenderLoyaltyBenefits />;
};

const LoyaltyWelcomeCard = ({ componentId }) => {
  return (
    <ComponentProviders componentName="LoyaltyWelcomeCard">
      <App componentId={componentId} />
    </ComponentProviders>
  );
};

LoyaltyWelcomeCard.propTypes = {
  componentId: string,
};
LoyaltyWelcomeCard.defaultProps = {
  componentId: '',
};

LoyaltyWelcomeCard.displayName = 'Loyalty Benefits';

LoyaltyWelcomeCard.dataModel = LoyaltyWelcomeCardModel;

export { LoyaltyWelcomeCard, exportsForTesting };
