/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const GIFT_CARD = 'GIFT_CARD';
const PAINT = 'PAINT';

const GENERIC_ERROR = 'There was a problem adding your item. Please try again.';
export const CART_ITEM_ERROR_MSG_CODE_MAP = {
  CART_ERR_169: 'Please reduce the quantity of this item.',
  CART_ERR_303_4: 'Please reduce the quantity of this item.',
  CART_ERR_152: 'Limited Quantity at {{storeName}}',
  CART_ERR_151: 'Out of stock',
  INVENTORY_ERROR: 'We are sorry, the quantity of items is not available',
  CART_GEN_ERR1: 'The service is currently unavailable. Please try again later.',
  CART_ERR_114: 'Please select your local Home Depot store to help us determine product availability',
  CART_ERR_115: 'Please reduce the number of stores you are shopping from',
  CART_ERR_168: 'There are not enough items available to meet your order. Please reduce the number of items you are requesting or select another item.',
  CART_ERR_167: `Quantity requested exceeds available amount at your local store.
    Please select remaining quantity from other store(s).`,
};

export const QUANTITY_ERROR_CODES = [
  'CART_ERR_311',
  'CART_ERR_135'
];

export const CART_PAGE_ERROR_MSG_CODE_MAP = {
  CART_ERR_500: GENERIC_ERROR,
  INVENTORY_ERROR: 'We are sorry, the quantity of items is not available',
  CART_GEN_ERR1: 'The service is currently unavailable. Please try again later.',
  CART_ERR_101: GENERIC_ERROR,
  CART_ERR_114: 'Please select your local Home Depot store to help us determine product availability',
  CART_ERR_115: 'Please reduce the number of stores you are shopping from',
  CART_ERR_168: 'There are not enough items available to meet your order. Please reduce the number of items you are requesting or select another item.',
  CART_ERR_167: `Quantity requested exceeds available amount at your local store.
    Please select remaining quantity from other store(s).`,
  CART_ERR_179: GENERIC_ERROR, // Bodfs delivery store is mandatory for bodfs item 206936873.
  ERR_PAYPAL_PAYMENT: 'Unable to checkout with PayPal. Please try again with other payment Options.',
  ERR_PAYPAL_UNAVAILABLE: 'Sorry, this item cannot be sold in or shipped to this state.',
  ERR_BODFS_FLOC_UNAVAILABLE_IN_CART: 'Sorry, item is not available for delivery in this ZIP code. Enter a valid ZIP code or select another fulfillment method.',
  ERR_BODFS_COM_LOW_INVENTORY_IN_CART: 'There are not enough items in stock to meet your order. Please reduce the number of items you are requesting.',
  ERR_BODFS_COM_DOWN_IN_CART: 'Delivery scheduling is unavailable but you may complete your order. Please select another fulfillment method.',
  ERR_BODFS_INVALID_DELIVERY_IN_CART: 'Sorry, one or more items are not available for delivery in this ZIP Code.',
  CART_INFO_ERR_4001: 'We are sorry. The item could not be saved for later. Please refresh the page and try again.'
};

// item is out of stock (This item is out of stock. Please remove it or save it for later.) no back in stock email
// item is discontinued
//
const customizeMessage = (description, itemDetails = {}, errorCode) => {
  try {
    const { location } = itemDetails;
    if (description.includes('{{storeName}}')) {
      return description.replace('{{storeName}}', location?.storeName || 'this store');
    } if (errorCode === 'CART_ERR_135') {
      return `Your store has only ${itemDetails?.location?.inventory?.quantity}  in stock. Please reduce the quantity, check nearby pickup stores, or change your fulfillment to proceed.`;
    } if (errorCode === 'CART_ERR_311') {
      return `Only ${itemDetails?.location?.inventory?.quantity} in stock. Please reduce the quantity or change your fulfillment to proceed.`;
    }
  } catch (err) {
    console.error(err);
  }
  return description;
};

export const transformErrorMsg = (message, itemDetails, selectedFulfillment) => {
  try {
    if (message?.inventory) {
      message.description = `Only ${message.inventory} in stock. Please reduce the quantity or change your fulfillment to proceed.`;
    } else if (message?.correlationType === 'cartLineItem' && QUANTITY_ERROR_CODES.includes(message?.errorCode)) {
      message.description = customizeMessage(message.description, itemDetails, message.errorCode);
    } else if (message?.correlationType === 'cartLineItem' && CART_ITEM_ERROR_MSG_CODE_MAP[message?.errorCode]) {
      const mappedDescription = CART_ITEM_ERROR_MSG_CODE_MAP[message.errorCode];
      message.description = customizeMessage(mappedDescription, itemDetails);
    } else if (message?.correlationType !== 'cartLineItem' && CART_PAGE_ERROR_MSG_CODE_MAP[message?.errorCode]) {
      const mappedDescription = CART_PAGE_ERROR_MSG_CODE_MAP[message.errorCode];
      message.description = customizeMessage(mappedDescription, itemDetails);
    } else if (message.type === 'BOSS_UPDATE_MESSAGE' && selectedFulfillment.type === 'boss') {
      message.type = 'warning';
      message.longDesc = message?.longDesc || 'Your pickup type has been updated based on item availability. We will ship it to your store for free pickup.';
    } else if (message?.longDesc && message?.messageCategoryType?.toLowerCase() === 'warning') {
      message = { ...message, messageCode: message.type, type: message.messageCategoryType.toLowerCase() };
    }
  } catch (err) {
    console.error(err);
  }
  return message;
};

export const getOutOfStockMessage = (correlationId, productType) => {
  let description;
  let errorCode;
  if (productType === GIFT_CARD || productType === PAINT) {
    description = 'This item is out of stock. Please remove it.';
  } else {
    description = 'This item is out of stock. Please remove it or save for later.';
  }
  errorCode = 'outOfStock';
  return {
    correlationType: 'cartLineItem',
    type: 'error',
    correlationId,
    errorCode,
    description,
    inventory: null
  };
};