import React from 'react';
import Responsive from 'react-responsive';
import { Typography } from '@one-thd/sui-atomic-components';
import { analytics } from '../../../../core/Constants';
import { WelcomeCardTile } from './WelcomeCardTile';
import { formatPhoneNumber } from '../../../../common/utils/CardUtils';

const RHLink = '/myaccount/proXtraRewards?drawerList=offer';

export const PreferredUnlocked = () => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_Preferred_Pricing.svg"
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Preferred_Pricing_Unlocked}
      analyticsLinks={analytics.ANALYTICS_LINKS.LEARN_MORE}
      refLink="/myaccount/proXtraPricing"
      altText="Preferred Unlocked"
      linkText="Learn More"
    >
      <Responsive minWidth={1024}>
        <Typography variant="body-base" weight="bold" height="normal">
          Preferred Pricing Unlocked
        </Typography>
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          Preferred Pricing Unlocked
        </Typography>
      </Responsive>
      <Responsive maxWidth={767}>
        <Typography variant="body-lg" weight="regular" height="normal">
          Preferred Pricing Unlocked
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};

export const EliteSupportLine = ({ phoneTag }) => {
  const { value } = phoneTag;
  const phoneNumberFormatted = formatPhoneNumber(value);
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_ESL.svg"
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Elite_Support_Line}
      analyticsLinks={analytics.ANALYTICS_LINKS.CONTACT}
      refLink={`tel:${value}`}
      altText="Elite Support Line"
      linkText={phoneNumberFormatted}
    >
      <Responsive minWidth={1024}>
        <Typography variant="body-base" weight="bold" height="normal">
          Contact Elite Support Line
        </Typography>
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          Contact Elite Support Line
        </Typography>
      </Responsive>
      <Responsive maxWidth={767}>
        <Typography variant="body-lg" weight="regular" height="normal">
          Contact Elite Support Line
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};

export const ExpiringOffers = ({ expiringOffersCount }) => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_offers.svg"
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Offers_Expiring_Soon}
      analyticsLinks={analytics.ANALYTICS_LINKS.VIEW_OFFERS}
      refLink={RHLink}
      altText="Expiring Offers"
      linkText="View Your Offers"
    >
      <Responsive minWidth={1024}>
        <Typography variant="body-base" weight="bold" height="normal">
          {`${expiringOffersCount} Offers Expiring Soon`}
        </Typography>
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          {`${expiringOffersCount} Offers Expiring Soon`}
        </Typography>
      </Responsive>
      <Responsive maxWidth={767}>
        <Typography variant="body-lg" weight="regular" height="normal">
          {`${expiringOffersCount} Offers Expiring Soon`}
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};

export const AvailableOffers = ({ availableOffersCount }) => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/active_offers.svg"
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Offers_Available}
      analyticsLinks={analytics.ANALYTICS_LINKS.VIEW_OFFERS}
      refLink={RHLink}
      altText="Available Offers"
      linkText="View Your Offers"
    >
      <Responsive minWidth={1024}>
        <Typography variant="body-base" weight="bold" height="normal">
          {`${availableOffersCount || 0} Offers Available`}
        </Typography>
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          {`${availableOffersCount || 0} Offers Available`}
        </Typography>
      </Responsive>
      <Responsive maxWidth={767}>
        <Typography variant="body-lg" weight="regular" height="normal">
          {`${availableOffersCount || 0} Offers Available`}
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};

export const PaintMemberProducts = ({ currentPaintLevel }) => {
  let paintImage;
  let paintLabel = '';
  let paintSublabel = '';
  let paintTabletSublabel = '';
  if (currentPaintLevel === 'Bronze') {
    paintImage = 'WelcomeCard/paint_Bronze.svg';
    paintLabel = 'Save Up To 10% On Paint Products';
  } else if (currentPaintLevel === 'Silver') {
    paintImage = 'WelcomeCard/paint_Silver.svg';
    paintLabel = 'Save Up To 15% On Paint Products';
  } else if (currentPaintLevel === 'Gold') {
    paintImage = 'WelcomeCard/paint_Gold.svg';
    paintLabel = 'Save Up To 20% On Paint Products';
  } else {
    paintImage = 'WelcomeCard/paint_Member.svg';
    paintLabel = 'Save Up To 20%';
    paintSublabel = 'After Qualifying Spend';
    paintTabletSublabel = 'on Paint Products';
  }
  return (
    <WelcomeCardTile
      imageURL={paintImage}
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Paint_Rewards}
      analyticsLinks={analytics.ANALYTICS_LINKS.VIEW_PAINT}
      refLink={RHLink}
      altText="Paint Offer Icon"
      linkText="View Paint Rewards"
    >
      <Responsive minWidth={1024}>
        <Typography variant="body-base" weight="bold" height="normal">
          {paintLabel}
        </Typography>
        {!!paintSublabel && (
          <Typography variant="body-base" weight="regular" height="normal">
            {paintSublabel}
          </Typography>
        )}
      </Responsive>
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="regular" height="normal">
          {paintLabel}
        </Typography>
        {!!paintSublabel && (
          <Typography variant="body-base" weight="regular" height="normal">
            {paintTabletSublabel}
          </Typography>
        )}
      </Responsive>
      <Responsive maxWidth={767}>
        <Typography variant="body-lg" weight="regular" height="normal">
          {paintLabel}
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};
