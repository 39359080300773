/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import diff from 'deep-diff';
import { getTemplate } from './templates/cart-assert-template';
import { wait } from './utils/util';

let ASSERT_TEMPLATE = getTemplate();

let wrappedQuerySelector = async (node, locator) => new Promise((resolve) => {
  resolve(node.querySelector(locator));
});
let wrappedQuerySelectorAll = async (node, locator) => new Promise((resolve) => {
  resolve(node.querySelectorAll(locator));
});

let LEGACY = false;
let PUPPETEER = false;
let ELEMENT_MAP = null;
let START = false;

const IGNORE_ID_ARRAY = [];

const CUSTOM_VALUE_ELEMENTS = {};

const snap = async (action) => {
  let uiElements = null;
  try {
    if (!START) {
      uiElements = init(action);
      if (uiElements) {
        console.log('\n<<<<<<<<<<<<<<<<<<<<<<<<<<<< SNAP (init) >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', uiElements, action);
      }
    } else {
      uiElements = await getDiffs();
      console.log('\n<<<<<<<<<<<<<<<<<<<<<<<<<<<< SNAP (diffs) >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', uiElements, action);
    }
  } catch (err) {
    console.error(err);
  }
  return uiElements;
};

const init = (action) => {
  // console.log('assertUI.init()', action);
  try {
    if (action && action.type === 'API_ACTION') {
      return;
    }
    ELEMENT_MAP = snapshotUi();
    START = true;
  } catch (err) {
    console.error(err);
  }
  // eslint-disable-next-line consistent-return
  return ELEMENT_MAP;
};

// eslint-disable-next-line consistent-return
const getDiffs = async () => {
  try {
    if (START) {
      const currentElementMap = await getElements();
      const diffs = await diff(ELEMENT_MAP, currentElementMap);
      ELEMENT_MAP = currentElementMap;
      return diffs;
    }
  } catch (err) {
    console.error(err);
  }
};

function restoreElementMap(uiElements) {
  try {
    if (Array.isArray(uiElements)) {
      const changes = {};
      uiElements.forEach((delta) => {
        diff.applyChange(changes, true, delta);
      });
      uiElements = changes;
    }
  } catch (err) {
    console.error(err);
  }
  return uiElements;
}

const getValueAutomation = async (element, node) => {
  const { valueField } = element;
  let value = '';
  try {
    if (valueField === 'checked') {
      value = await node.evaluate(((elem, vField) => elem[vField]), valueField);
    } else {
      // console.log('getValueAutomation()', valueField, node.evaluate);
      value = await node.evaluate(((elem, vField) => elem[vField]), valueField)
      || await node.evaluate((elem) => elem.textContent);
    }
    if (value && value.replaceAll) {
      value = value.replaceAll('\n', ' ');
    }
  } catch (err) {
    console.error(element, err);
  }
  return value;
};

const getValueDom = async (element, node) => {
  let value = '';
  try {
    if (element.valueField === 'checked') {
      value = node[element.valueField];
    } else {
      value = node[element.valueField] || node.textContent;
    }
    if (value && value.replaceAll) {
      value = value.replaceAll('\n', ' ');
    }
  } catch (err) {
    console.error(element, err);
  }
  return value;
};

const getValue = async (element, node) => {
  if (PUPPETEER) {
    return getValueAutomation(element, node);
  }
  return getValueDom(element, node);
};

const addElement = async (id, element, node, elMap) => {
  try {
    if (node && id && !IGNORE_ID_ARRAY.includes(id)) {
      const value = await getValue(element, node);
      try {
        elMap[id] = {
          id,
          value,
        };
      } catch (err) {
        console.error('addElement() elMap[id]={id,value}', id, value, elMap);
      }
      if (element.props) {
        const hidden = isHidden(node);
        elMap[id].props = {
          hidden,
        };
        element.props.forEach((prop) => {
          elMap[id].props[prop] = node[prop];
        });
      }
      if (id.includes('itemGroupTitle')) {
        try {
          const parent = PUPPETEER ? (await node.getProperty('parentNode')) : (node.parentElement);
          const subId = id.replace('itemGroupTitle', 'itemGroupSubtitle');
          let elem = null;
          if (LEGACY) {
            elem = PUPPETEER ? (await parent.getProperty('nextSibling')) : (parent.nextSibling);
          } else {
            elem = await wrappedQuerySelector(parent, '[data-automation-id="itemGroupSubtitle"]');
          }
          await addElement(
            subId,
            {
              id: subId,
              valueField: 'innerText',
            },

            elem,
            elMap,
          );
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      console.log('no elem, no id, or ignored id: ', id, node);
    }
  } catch (err) {
    console.error(id, element, err);
  }
};

const handleElements = async (element, elNodes, elMap, cartItemIndex) => {
  const { id } = element;
  if ((!elNodes || elNodes.length === 0) && !element.optional) {
    // console.warn('element not found', id, element);
  } else if (elNodes.length === 1) {
    const node = elNodes[0];
    await addElement(id, element, node, elMap);
  } else { // multiple
    // eslint-disable-next-line no-restricted-syntax
    for (const node of Array.from(elNodes)) {
    // Promise.all(Array.from(elNodes).forEach(async (node) => {
      // console.warn('\n\n\n!!!!!!!!!!!!!!!!!!!! elMap1 ?', elMap, id, '\nelMap[id]', elMap[id], '\n\n');
      try {
        if (element.allowMultiple) {
          let multiId = '';
          if (typeof cartItemIndex !== 'undefined') {
            multiId = cartItemIndex;
          } else {
            const value = await getValue(element, node) || '';
            if (value.replaceAll) {
              multiId = value.replaceAll(' ', '');
            } else {
              // console.error('value.replaceAll??', id, value, value.replaceAll);
              multiId = value;
            }
          }
          // const multiId = (cartItemIndex !== 'undefined') ? cartItemIndex : (getValue(element, node).replaceAll(' ', ''));
          await addElement(`${id}_${multiId}`, element, node, elMap);
        } else if (elMap[id]) {
          const { count } = elMap[id];
          elMap[id].count = count ? elMap[id].count++ : 2;
          // console.warn('multiple elements found', elMap[id].count, element);
          await addElement(id, element, node, elMap);
        } else {
          await addElement(id, element, node, elMap);
        }
      } catch (err) {
        console.error(id, err);
      }
    // }));
    }
  }
};

function getLocator(element) {
  let locator = '';
  if (!LEGACY && (!element.locator || element.locator === element.id)) {
    locator = `[data-automation-id="${element.id}"]`;
  } else {
    locator = element[`locator${LEGACY ? '_legacy' : ''}`];
  }
  // if (!locator) {
  //   console.warn('section locator not found', element.id);
  // }

  return locator;
}

const handleItemList = async (cartItems, cartItemNodes, elMap) => {
  // console.log('\n\nhandleItemList() cartItemNodes.length:', cartItemNodes.length);
  let index = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const itemNode of Array.from(cartItemNodes)) {
  // Promise.all(Array.from(cartItemNodes).forEach((itemNode, index) => {
    elMap[`cartItem_${index}`] = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const itemElement of Array.from(cartItems.itemElements)) {
    // cartItems.itemElements.forEach(async (itemElement) => {
      // console.log('check for: ', itemElement.id);
      try {
        const locator = getLocator(itemElement, itemElement.id);
        if (locator) {
          const elNode = await wrappedQuerySelectorAll(itemNode, locator);
          // console.log('check for: ', itemElement.id);
          // if (locator.includes('CartAlert')) {
          //   console.warn(itemNode.innerHTML);
          // }
          // console.warn('\n\n\n!!!!!!!!!!!!!!!!!!!! elMap?', elMap, elMap[`cartItem_${index}`], '\n\n');
          await handleElements(itemElement, elNode, elMap[`cartItem_${index}`], index);
        }
      } catch (err) {
        console.error(itemElement, err);
      }
      // });
    }
    index += 1;
  // }));
  }
};

const getSectionElements = async (sectionKey, section, sectionNode, elMap, topLevelElement) => {
  const notFound = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const id of Object.keys(section)) {
  // Promise.all(Object.keys(section).forEach(async (id) => {
    try {
      if (!id.includes('locator')) {
        const element = section[id];
        const locator = getLocator(element, id);
        if (locator) {
          const elNodes = await wrappedQuerySelectorAll(sectionNode, locator);
          // if ([
          //   'paymentEstimator',
          //   'shareCartLink',
          //   'yourCartText',
          //   'removeAllItemsLink',
          // ].includes(id)) {
          //   const locator2 = getLocator(element, id);
          //   if (locator2) {
          //     // eslint-disable-next-line no-undef
          //     elNodes = await wrappedQuerySelectorAll(topLevelElement, locator2);
          //   }
          // }
          if (id === 'cartItems') {
            elMap[sectionKey] = elMap[sectionKey] || {};
            elMap[sectionKey].cartItems = {};
            await handleItemList(element, elNodes, elMap[sectionKey].cartItems);
          } else if (elNodes.length) {
            elMap[sectionKey] = elMap[sectionKey] || {};
            await handleElements(element, elNodes, elMap[sectionKey]);
          } else {
            notFound.push(element);
            // console.warn('section element not found', id, element);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    if (notFound.length) {
      elMap.notFound = notFound;
    }
  // }));
  }
};

function isHidden(el) {
  return el && el.offsetParent === null;
}

function getElementValue(elem, id) {
  let value = '';
  try {
    if (CUSTOM_VALUE_ELEMENTS[id]) {
      value = CUSTOM_VALUE_ELEMENTS[id](elem, id);
    } else if (elem) {
      if (elem.getAttribute('value') || elem.value) {
        // eslint-disable-next-line no-undef
        value = $(elem).val();
      } else {
        // eslint-disable-next-line no-undef
        value = $(elem).text();
      }
      // console.log('\n\n~~~~~~~~~~~~~~~~~~~~~~~~~~~ getElementValue()', value);
    }
  } catch (err) {
    console.error(elem);
    console.error(err);
  }
  return value;
}

const getElements = async (topLevelElement) => {
  // console.log('\n\n\n#########################################################################');
  // console.log('getElements()');
  // console.log('#########################################################################\n\n');

  const elMap = {};

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(ASSERT_TEMPLATE)) {
    // Promise.all(Object.keys(ASSERT_TEMPLATE).map(async (key) => {
      const section = ASSERT_TEMPLATE[key];
      const locator = getLocator(section, key);
      if (locator) {
        // eslint-disable-next-line no-undef
        // const sectionElem = locator ? await wrappedQuerySelectorAll(topLevelElement, locator) : [topLevelElement];
        let sectionElem = await wrappedQuerySelectorAll(topLevelElement, locator);
        if (sectionElem.length === 0 && (key.includes('Item'))) {
          await wait(1000, 'wait for sectionElem');
          sectionElem = topLevelElement.querySelectorAll(locator);
        }
        if (sectionElem.length > 0) {
          if (sectionElem.length > 1) {
            console.log('FOUND > 1 of section: ', key, section.locator);
          }
          switch (key) {
          case 'recsPods':
            console.log('ignore recs pods for now');
            break;
          default:
            await getSectionElements(key, section, sectionElem[0], elMap, topLevelElement);
          }
        }
      }
    }
    // }));
  } catch (err) {
    console.error(err);
  }

  // console.log('\n\n\n#########################################################################');
  // console.log('getElements() FINISHED');
  // console.log('#########################################################################\n\n');
  // console.log(elMap);
  // eslint-disable-next-line consistent-return
  return elMap;
};

// function getPaypalButtonCustom(elem) {
//   return !!elem;
// }

// function getElementValueCustom(elem, id) {
//   let value = '';
//   try {
//     if (id.includes('PodItems')) {
//       if (elem.getAttribute('value') || elem.value) {
//         // eslint-disable-next-line no-undef
//         value = $(elem).val();
//       } else {
//         // eslint-disable-next-line no-undef
//         value = $(elem).text();
//       }
//       if (elem.getAttribute('class').includes('collapsedCaret')) {
//         value += 'Closed';
//       } else {
//         value += 'Open';
//       }
//     }
//   } catch (err) {
//     console.error(err);
//   }
//   return value;
// }

// function getElementValueCheckbox(elem, id) {
//   let value = '';
//   try {
//     // eslint-disable-next-line no-undef, no-shadow
//     const elem = $(`#${id}`)[0];
//     value = elem.checked;
//   } catch (err) {
//     console.error(err);
//   }
//   return value;
// }

// function getElementValueDropdown(elem) {
//   let value = '';
//   try {
//     // eslint-disable-next-line no-undef
//     value = $(elem).find(':selected')[0].text;
//   } catch (err) {
//     console.error(err);
//   }
//   return value;
// }

const setQuerySelectorsForAutomation = () => {
  // console.log('setQuerySelectorsForAutomation()');
  wrappedQuerySelector = async (node, locator) => node.$(locator);
  wrappedQuerySelectorAll = async (node, locator) => node.$$(locator);
  // wrappedQuerySelectorAll = async (node, locator) => {
  //   console.log('\n\n>>>>>>>>>>>>>>>>>>>> pageRoot', node.$$, locator);
  //   const results = await node.$$(locator);
  //   console.log('resultArray:', results);
  //   console.log('resultArray[0]:', results[0]);
  //   return results;
  // };
};

const getIsLegacy = () => {
  let isLegacy = false;
  try {
    if (typeof window === 'undefined') return false;
    const url = window.location.href;
    if (url.includes('/mycart/home') || url.includes(':8443')) {
      isLegacy = true;
    }
  } catch (err) {
    console.error(err);
  }
  return isLegacy;
};

const getIsPuppeteer = (isPuppeteer) => {
  let _isPuppeteer = false;
  try {
    // export const EVALUATION_SCRIPT_URL = '__puppeteer_evaluation_script__';
    // isPuppeteer = true;
    _isPuppeteer = (typeof isPuppeteer !== 'boolean') ? isPuppeteer : _isPuppeteer;
  } catch (err) {
    console.error(err);
  }
  return _isPuppeteer;
};

const getAssertTemplate = (assertTemplate) => {
  let template = assertTemplate || ASSERT_TEMPLATE;
  try {
    const _template = localStorage.getItem('ASSERT_TEMPLATE');
    if (_template) {
      template = JSON.parse(_template);
    }
  } catch (err) {
    console.error(err);
  }
  return template;
};

const snapshotUi = async ({
  container,
  assertTemplate = null,
  isPuppeteer = null
} = {}) => {
  // console.log('\n\n@@@@@@@@ snapshotUi...');
  PUPPETEER = getIsPuppeteer(isPuppeteer);
  if (PUPPETEER) {
    setQuerySelectorsForAutomation();
  }
  container = container || document;
  LEGACY = getIsLegacy();
  ASSERT_TEMPLATE = getAssertTemplate(assertTemplate);

  let uiElements = null;
  try {
    uiElements = await getElements(container);
    // console.log('@@@@@@@@ snapshotUi()...uiElements', uiElements);
  } catch (err) {
    console.error(err);
  }
  return uiElements;
};

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-undef
  window.snapshot = snapshotUi;
  // eslint-disable-next-line no-undef
  window.getAllElements = snapshotUi;
  // eslint-disable-next-line no-undef
  window.snap = snap;
}

export { snapshotUi };
