import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { bool, number } from 'prop-types';
import { Skeleton, SkeletonLine, SkeletonBlock } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { CarouselHeader } from './CarouselHeader';

const LoadingPlaceholder = ({
  hideTitle, itemsCount, itemHeight, txtPlaceholderLines, showMoreLines
}) => {

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';
  let numOfPlaceholders = isMobile ? 2 : 6;
  let placeholderHeight = isMobile ? 48 : 56;
  const totalTextPlaceholderHeight = (txtPlaceholderLines / 3) * 2.4;

  const placeholderGridClass = classNames('sui-grid', { 'sui-grid-cols-2': isMobile,
    'sui-grid-cols-6': (!isMobile && !itemsCount) }, { [`sui-grid-cols-${itemsCount}`]: (!isMobile && itemsCount) });

  if (itemsCount) {
    numOfPlaceholders = itemsCount;
  }

  if (itemHeight) {
    placeholderHeight = Math.floor((((itemHeight * 0.4) - totalTextPlaceholderHeight) / (0.4)) / 4) * 4;
  }

  const RenderPlaceholders = () => {
    let placeholder = [];
    for (let i = 0; i < numOfPlaceholders; i += 1) {
      placeholder.push(
        <Skeleton
          orientation="vertical"
          key={i}
        >
          <SkeletonBlock aspect="square" height={placeholderHeight} />
          {txtPlaceholderLines > 0 && <SkeletonLine variant="multi" fullWidth numberOfLines={txtPlaceholderLines} />}
        </Skeleton>);
    }
    return placeholder;
  };

  return (
    <>
      {!hideTitle && <CarouselHeader title="Loading Recommendations" />}
      {isMobile && showMoreLines && (
        <div className="sui-grid sui-grid-cols-1">
          <Skeleton
            orientation="vertical"
          >
            <SkeletonBlock aspect="wide" height={60} />
          </Skeleton>
        </div>
      )}
      <>
        <div className={placeholderGridClass} key="placeholder-row">
          <RenderPlaceholders />
        </div>
      </>
    </>
  );
};

LoadingPlaceholder.propTypes = {
  hideTitle: bool,
  itemsCount: number,
  itemHeight: number,
  showMoreLines: bool,
  txtPlaceholderLines: number
};

LoadingPlaceholder.defaultProps = {
  hideTitle: false,
  itemsCount: null,
  txtPlaceholderLines: 6,
  itemHeight: null,
  showMoreLines: false
};

export { LoadingPlaceholder };
