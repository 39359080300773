import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useFormController from '../hooks/useFormController';
import formControllerState from '../utils/formControllerState';

/**
 * A utility component to help render the main label associated to a form input.
 *
 * Related components: [TextField](#textfield), [QuantityPicker](#quantitypicker), [DropdownField](#dropdownfield),
 * [FormController](#formcontroller)
 *
 * Usage:
 *
 * ```jsx
 * import { FormLabel } from '@one-thd/sui-atomic-components';
 * ```
 */
const FormLabel = React.forwardRef((props, ref) => {

  const {
    children,
    component: FormLabelRoot = 'label',
    disabled,
    optional,
    required,
    ...other
  } = props;

  const formControl = useFormController();
  const fcs = formControllerState({
    props,
    formControl,
    states: ['required', 'disabled'],
  });

  const classes = classNames('sui-font-regular sui-text-base sui-leading-tight sui-flex sui-items-baseline sui-relative sui-mb-2', {
    'sui-text-primary': !fcs.disabled,
    'sui-text-inactive sui-cursor-default': fcs.disabled
  });

  const requiredClasses = classNames('sui-font-regular sui-text-base sui-leading-tight sui-text-left', {
    'sui-text-primary': !fcs.disabled,
    'sui-text-inactive': fcs.disabled
  });

  const optionalClases = classNames('sui-font-regular sui-text-xs sui-leading-tight sui-text-left', {
    'sui-text-subtle': !fcs.disabled,
    'sui-text-inactivelt': fcs.disabled
  });

  return (
    <FormLabelRoot
      className={classes}
      ref={ref}
      {...other}
    >
      {children}
      {fcs.required && (
        <span aria-hidden className={requiredClasses}>
          &thinsp;*
        </span>
      )}
      {optional && !fcs.required && (
        <p className={optionalClases}>
          &nbsp;(optional)
        </p>
      )}
    </FormLabelRoot>
  );
});

FormLabel.displayName = 'FormLabel';

FormLabel.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, the label should be displayed in a disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the label will indicate that the `input` is optional.
   */
  optional: PropTypes.bool,
  /**
   * If `true`, the label will indicate that the `input` is required.
   */
  required: PropTypes.bool
};

export { FormLabel };
