import React, { useState, useEffect, useContext } from 'react';
import {
  useDataModel,
  params,
  string,
  id,
  shape,
  number,
  bool,
  customType,
  arrayOf,
  extend,
} from '@thd-nucleus/data-sources';
import { bool as boolType, func, instanceOf, string as stringType } from 'prop-types';

import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Card } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { SignIn } from '@thd-olt-component-react/sign-in';
import {
  RecentOrdersCardHeader,
} from './components/subcomponents/cardHeader/RecentOrdersHeader';
import { RecentOrdersCard } from './components/recentOrdersCard/RecentOrdersCard';
import { CardOrder } from './components/recentOrdersCard/CardOrder';
import { getChannelId, isOnlineOrder } from '../recentPurchases/components/helpers';
// eslint-disable-next-line
import { RecentOrdersCardPlaceholder } from './components/placeholders/orderHistoryLoading/RecentOrdersCardPlaceholder';
import { RecentOrdersFallbackCard } from './components/fallback/RecentOrdersFallback';
import { getThdCustomer, useWindowWidth } from '../utils';

export function RecentOrders({
  date = new Date(), onError = () => {}, removeInStoreOrders = false, urlTmxId = null, componentId = ''
}) {
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const [mounted, setMounted] = useState(false);
  const [hiddenOrders, setHiddenOrders] = useState([]);
  useEffect(() => {
    setMounted(true);

    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('NATIVE_APP.HIDE_CURBSIDE_ORDER', (data) => {
      const shouldUpdateHiddenOrders = data?.output && typeof data.output === 'string';
      if (shouldUpdateHiddenOrders) {
        setHiddenOrders((prevState) => [...new Set([...prevState, data.output])]);
      }
    });
  }, []);

  const channelId = getChannelId(channel);
  const thdCustomer = getThdCustomer();
  const {
    isLoggedIn, isPersisted, username, email, userID: userId, svocID: customerAccountId
  } = thdCustomer;
  function getThreatMetrixId() {
    if (typeof window === 'undefined') return '';

    return window.THD_ACCOUNT_LIB?.TMX?.sessionId;
  }
  const sessionId = urlTmxId || getThreatMetrixId();
  const windowWidth = useWindowWidth();

  // Set end date for today and start date for 90 days prior
  //    subracting date by 90 days in milliseconds
  const startDate = new Date(date - 90 * 24 * 3600 * 1000).toISOString().split('T')[0];
  const endDate = date.toISOString().split('T')[0];

  const purchaseHistoryVariableOptions = {
    customerAccountId,
    endDate,
    pageNumber: 1,
    pageSize: 20,
    resultsFilter: 'allOrders',
    searchType: 'ORDERS',
    sortBy: 'salesDate',
    sortOrder: 'DESC',
    startDate,
  };
  const purchaseHistoryQueryOptions = {
    variables: {
      userId,
      request: purchaseHistoryVariableOptions,
      headers: {
        tmxProfileId: sessionId,
        channelId
      },
    },
    skip: !isLoggedIn,
    context: { withAuth: true },
  };

  const {
    data: orderHistoryData,
    loading: orderHistoryLoading,
    error: orderHistoryError,
    refetch: orderHistoryRefetch
  } = useDataModel('purchaseHistory', purchaseHistoryQueryOptions);

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'RecentOrderDetails',
      component: 'RecentOrderDetails',
      position: 1,
      type: 'content',
    },
  });

  const handleVisibiltyChange = () => {
    if (!document.hidden) {
      orderHistoryRefetch();
    }
  };

  useEffect(() => {
    if (isConsumerApp) {
      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('page.visibilitychange', handleVisibiltyChange);
    }
  }, []);

  if (orderHistoryLoading || !mounted) {
    return <RecentOrdersCardPlaceholder />;
  }

  const hasOnlineOrders = orderHistoryData?.purchaseHistory?.orders?.some((order) => isOnlineOrder(order?.orderNumbers?.[0]));

  const isUnauthorized = orderHistoryError?.graphQLErrors?.some((gqlError) => gqlError?.extensions['X-Http-Status-Code'] === 400 || gqlError?.extensions['X-Http-Status-Code'] === 401);
  if (
    !isLoggedIn
    || isPersisted
    || isUnauthorized
  ) {
    return (
      <SignIn
        customerName={username}
        customerEmail={email}
        componentId={componentId}
      />
    );
  }

  if ((
    !orderHistoryData?.purchaseHistory?.orders?.length && !orderHistoryLoading)
    || (removeInStoreOrders && !hasOnlineOrders && !orderHistoryLoading)
  ) {
    if (orderHistoryError) {
      if (typeof onError === 'function') onError(orderHistoryError);
      return <RecentOrdersFallbackCard isError componentId={componentId} />;
    }
    return <RecentOrdersFallbackCard noData componentId={componentId} />;
  }
  return (
    <Card
      data-testid="recentOrders-card"
      className="sui-h-full sui-w-full"
      data-component="RecentOrders"
      ref={ref}
      clickid={clickID}
    >
      <RecentOrdersCardHeader />
      <RecentOrdersCard
        customerInfo={{
          userId,
          customerAccountId,
          sessionId,
          channelId,
        }}
        hiddenOrders={hiddenOrders}
        orderHistoryData={orderHistoryData}
        removeInStoreOrders={removeInStoreOrders}
        windowWidth={windowWidth}
      />
    </Card>
  );
}

RecentOrders.propTypes = {
  // passing date as a prop for testing purposes
  date: instanceOf(Date),
  onError: func,
  removeInStoreOrders: boolType,
  urlTmxId: stringType,
  componentId: stringType,
};

RecentOrders.dataModel = extend({
  purchaseHistory: params({
    userId: id().isRequired(),
    headers: customType('HeaderExtras').shape({
      tmxProfileId: string(),
      channelId: string()
    }),
    request: customType('PurchaseHistoryRequest!').shape({
      searchType: string(),
      pageSize: number(),
      pageNumber: number(),
      resultsFilter: string(),
      sortBy: string(),
      customerAccountId: id().isRequired(),
      sortOrder: string(),
      startDate: string(),
      endDate: string(),
    }),
  }).shape({
    customerAccountId: id().isRequired(),
    showWarning: bool(),
    orderCount: number(),
    orders: arrayOf(
      shape({
        salesDate: string(),
        orderNumber: string(),
        orderNumbers: arrayOf(string()),
        totalAmount: number(),
        receiptDetails: string(),
        orderOrigin: string(),
        storeNumber: number(),
      })
    ),
  }),
  CardOrder
});
