import React from 'react';
import { Drawer } from '@one-thd/sui-atomic-components';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { PacDrawerProvider, PacDrawerContext } from '../../contexts';
import { PerkDetailsDrawer, SelectionDrawer, RewardBarcodeDrawer } from '../../components/LoyaltyDrawers';

export const DrawerContainer = () => {
  const { open, setOpen, drawerType } = React.useContext(PacDrawerContext);
  const drawerSwitch = {
    'perk-details-drawer': <PerkDetailsDrawer />,
    'reward-barcode-drawer': <RewardBarcodeDrawer />,
    'perk-selection-drawer': <SelectionDrawer />,
  };
  const RenderDrawer = drawerSwitch[drawerType] || <></>;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      open={open ?? false}
      onClose={onClose}
      position="right"
      data-testid="loyalty-benefits-drawer-container"
    >
      {RenderDrawer}
    </Drawer>
  );
};

export const LoyaltyDrawerContainer = () => {
  return (
    <QueryProvider cacheKey="loyalty-drawers-cacheKey">
      <PacDrawerProvider>
        <DrawerContainer />
      </PacDrawerProvider>
    </QueryProvider>
  );
};
