import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Contains the main content of `Drawer`
 *
 * Related components: [Drawer](#drawer)
 *
 * Usage:
 *
 * ```jsx
 * import { DrawerBody } from '@one-thd/sui-atomic-components';
 * ```
 */
const DrawerBody = React.forwardRef((props, ref) => {

  const {
    children,
    disableGrow = false,
    overflow = 'visible',
    ...other
  } = props;

  const rootCls = classNames('sui-bg-primary sui-p-4 sui-shrink sui-basis-auto', {
    'sui-overflow-y-auto': overflow === 'scroll',
    'sui-grow': !disableGrow,
    'sui-grow-0': disableGrow
  });

  return (
    <div
      className={rootCls}
      ref={ref}
      {...other}
    >
      {children}
    </div>
  );
});

DrawerBody.displayName = 'DrawerBody';

DrawerBody.propTypes = {
  /**
   * The contents of the drawer
   */
  children: PropTypes.node,
  /**
   * If true will prevent the flex container from growing.
   * @default false
   */
  disableGrow: PropTypes.bool,
  /**
   * Controls how the content within the component overflows.
   * @default visible
   */
  overflow: PropTypes.oneOf(['visible', 'scroll'])
};

export { DrawerBody };