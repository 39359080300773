/* eslint-disable max-len */
import * as React from 'react';
import { Checkmark } from '@one-thd/sui-icons';
import {
  Alert,
  Button,
  Skeleton,
  SkeletonBlock,
  SkeletonLine,
} from '@one-thd/sui-atomic-components';
import base64 from 'base-64';
import Barcode from 'react-barcode';
import { BARCODE_TEXT } from '../../core/DrawerConstants';
import { getRewardAttributes } from '../utils/DrawerUtils';
import { useFetchRedeemPerks } from '../../hooks';

const barcodeSetup = (encodedString) => {
  let decoded = '';
  if (encodedString !== undefined && encodedString?.length > 0) {
    decoded = base64.decode(encodedString);
  }
  return decoded;
};

const getBarcodeDisplayConditions = (currentReward, isDesktop) => {
  const {
    isGiftCard,
    isPricingEngine,
    isOnlineOrOmni,
    isInStoreOrOmni,
  } = getRewardAttributes(currentReward);

  const showScannableBarcode = isInStoreOrOmni && !isDesktop;
  const showCopyCodeButton = isOnlineOrOmni && isPricingEngine;
  const showBarcodeNumberLabel = !isDesktop || showCopyCodeButton;

  return {
    showBalanceAndPin: isGiftCard,
    showScannableBarcode,
    showCopyCodeButton,
    showBarcodeNumberLabel,
    showBarcodeSection: showScannableBarcode || showCopyCodeButton || showBarcodeNumberLabel,
  };
};

export const DrawerBarcode = ({ barcodeProps }) => {
  const { currentReward, isOpen, isNewlyActivated, isDesktop } = barcodeProps ?? {};
  const { perkId, perkType, paymentId, perkSourceUUID } = currentReward ?? {};
  const [barCodeImageSize, setBarCodeImageSize] = React.useState(1.25);
  const [copyCodeButtonClicked, setCopyCodeButtonClicked] = React.useState(false);
  const {
    showBalanceAndPin, showScannableBarcode, showCopyCodeButton, showBarcodeNumberLabel, showBarcodeSection
  } = getBarcodeDisplayConditions(currentReward, isDesktop);

  const perkRedemptionRequest = React.useMemo(() => ({
    perkId, perkType, paymentId, perkSourceUUID,
  }), [currentReward]);
  const {
    isLoadingRedeemPerks,
    redeemPerksError,
    redeemPerksData,
    refreshRedeemPerks,
    getRedeemResponse,
  } = useFetchRedeemPerks({ perkRedemptionRequest });

  React.useEffect(() => {
    const fetchData = async () => getRedeemResponse();
    if (isOpen && (showBarcodeSection || isNewlyActivated)) fetchData();
  }, [showBarcodeSection]);

  const { barCode, perkSourceUUID: redeemPsUUID, pin } = redeemPerksData ?? {};
  const decodedBarcode = barcodeSetup(barCode);
  const decodedGiftCardPin = barcodeSetup(pin);
  const perksRedeem = redeemPerksData;

  const RenderIsLoading = () => {
    return (
      <div data-testid="skeleton-loader" className="sui-grid sui-grid-cols-1 sui-mb-2">
        <Skeleton grow>
          <SkeletonBlock aspect="wide" />
          <SkeletonLine variant="multi" numberOfLines={2} />
        </Skeleton>
      </div>
    );
  };

  const RenderBalanceAndPin = () => {
    if (isLoadingRedeemPerks && isNewlyActivated) return <div className="sui-py-2"><SkeletonLine variant="single" /></div>;
    return (
      <div className="sui-gap-1 sui-py-2 sui-flex sui-flex-row sui-justify-between sui-align-center sui-text-base sui-font-bold">
        <div className="sui-flex sui-flex-row sui-justify-start sui-align-center">
          Balance: {` $${perksRedeem?.giftCardBalanceAmount || currentReward?.availableBalance}`}
        </div>
        {!isDesktop && (
          <div className="sui-flex sui-flex-row sui-justify-end sui-align-center">
            PIN:&nbsp;{decodedGiftCardPin}
          </div>
        )}
      </div>
    );
  };

  const RenderPerksRedeemError = () => {
    return (
      <div className="sui-flex sui-flex-row sui-justify-center sui-align-center sui-mb-4">
        <div className="sui-w-full">
          <Alert status="error">
            <div>{BARCODE_TEXT.errorBarcodeMessage}</div>
            <button
              onClick={getRedeemResponse}
              type="button"
              className="sui-text-base sui-underline sui-py-2"
            >
              {BARCODE_TEXT.errorBarcodeRefresh}
            </button>
          </Alert>
        </div>
      </div>
    );
  };

  const RenderBarcode = () => {
    return (
      <div data-testid="barcode" className="sui-flex sui-flex-row sui-justify-center sui-align-center sui-py-2">
        {decodedBarcode && (
          <Barcode
            value={decodedBarcode}
            height={72}
            width={barCodeImageSize}
            fontSize={16}
            displayValue={false}
          />
        )}
      </div>
    );
  };

  const RenderNumberLabel = () => {
    return (
      <div className="sui-font-bold sui-text-base sui-pt-2">
        {decodedBarcode?.replace(/(.{4})(?=.)/g, '$1 ')}
      </div>
    );
  };

  const CopyCodeButton = () => {
    const handleCopyCodeClick = () => {
      navigator.clipboard.writeText(decodedBarcode);
      setCopyCodeButtonClicked(true);
      setTimeout(() => {
        setCopyCodeButtonClicked(false);
      }, 2000);
    };
    return (
      <div className="sui-pt-4 sui-w-11/12">
        <Button onClick={handleCopyCodeClick} variant="secondary" fullWidth>
          {copyCodeButtonClicked && (
            <div className="sui-pt-1 sui-px-2">
              <Checkmark color="current" />
            </div>
          )}
          {copyCodeButtonClicked
            ? BARCODE_TEXT.copyCodeButtonTextClicked
            : BARCODE_TEXT.copyCodeButtonText}
        </Button>
      </div>
    );
  };

  if (!showBalanceAndPin && !showBarcodeSection) return null;
  if (isLoadingRedeemPerks && showBarcodeSection) return <RenderIsLoading />;
  if (redeemPerksError && showBarcodeSection) return <RenderPerksRedeemError />;

  return (
    <div className="sui-grid sui-grid-cols-1 sui-gap-1 sui-mb-8">
      {showBalanceAndPin && <RenderBalanceAndPin />}
      {showBarcodeSection && (
        <div className="sui-border-solid sui-rounded-md sui-border-primary sui-border-1 sui-bg-primary sui-flex sui-flex-col sui-justify-center sui-align-center sui-items-center sui-py-6">
          {showScannableBarcode && (
            <>
              <div className="sui-text-base">
                {BARCODE_TEXT.barcodeInstructions}
              </div>
              <RenderBarcode />
              <div className="sui-flex sui-flex-row sui-justify-between sui-align-center sui-items-center sui-pb-2 sui-w-4/5">
                <hr className="sui-flex sui-flex-row sui-justify-end sui-w-full" />
                <div className="sui-flex sui-flex-row sui-justify-center sui-font-bold sui-px-2">OR</div>
                <hr className="sui-flex sui-flex-row sui-justify-start sui-w-full" />
              </div>
            </>
          )}
          <div className="sui-text-base sui-pb-2">
            {showCopyCodeButton ? BARCODE_TEXT.onlineInstructions : BARCODE_TEXT.inStoreInstructions}
          </div>
          {showBarcodeNumberLabel && <RenderNumberLabel />}
          {showCopyCodeButton && <CopyCodeButton />}
        </div>
      )}
    </div>
  );
};
