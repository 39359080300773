import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isHyperlinkInternal } from './utils';

const RenderHyperlink = ({ node, children }) => {
  const [anchorClass, setAnchorClass] = useState('sui-underline sui-underline sui-opacity-50');
  const url = node.data.uri;
  const target = isHyperlinkInternal(url) ? '_self' : '_blank';

  return (
    <a
      href={url}
      target={target}
      onMouseEnter={
        () => {
          setAnchorClass('sui-underline');
        }
      }
      onMouseLeave={
        () => {
          setAnchorClass('sui-underline sui-opacity-50');
        }
      }
      className={anchorClass}
    >
      {children.map((child, i) => {
        return child;
      })}
    </a>
  );
};

RenderHyperlink.propTypes = {
  node: PropTypes.shape({
    data: PropTypes.shape({
      uri: PropTypes.string
    }),
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export { RenderHyperlink };
