import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { useBreakpoint, createTheme, ThemeProvider } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import classNames from 'classnames';

const EventCountdownTimer = ({
  endDate = null,
  eventMessage = 'Event Ends In:',
  eventLink = null,
  backgroundImage = '',
  componentId,
  mobileFontSize
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('event-countdown-timer.ready'); }, []);
  const { lesser: isMobile } = useBreakpoint('md');

  // eslint-disable-next-line max-len

  const { ref, clickID } = useImpression({
    data: {
      id: componentId,
      name: 'EventCountdownTimer',
      component: 'EventCountdownTimer',
      type: 'content',
      position: 1
    },
  });

  if (!endDate) {
    return null;
  }

  const style = {
    wrapper: classNames(
      'sui-block',
      'sui-p-3',
      'sui-py-8',
      'sui-bg-[#64994B]',
      'sui-h-full',
      'sui-bg-cover',
      'sui-bg-left',
      'hover:sui-no-underline'
    )
  };

  const EventCountdownTimerThemed = createTheme({
    palette: {
      mode: 'light',
      text: {
        primary: 'inverse'
      }
    }
  });

  return (
    <div
      data-component="EventCountdownTimer"
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <ThemeProvider theme={EventCountdownTimerThemed}>
        <a
          aria-label="Event Countdown Timer"
          href={eventLink}
          className={style.wrapper}
          // eslint-disable-next-line react/style-prop-object
          style={{ backgroundImage: `url(${backgroundImage})` }}
          alt="Event Countdown Timer"
        >
          <CountdownTimer
            shouldUseMessagingString
            shouldIncludeSvg
            shouldEventTimer
            invertText
            customMessagingString={eventMessage}
            endDate={endDate}
            fontSize={isMobile ? mobileFontSize : 'base'}
          />
        </a>
      </ThemeProvider>
    </div>
  );
};

EventCountdownTimer.propTypes = {
  componentId: PropTypes.string.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  eventMessage: PropTypes.string,
  eventLink: PropTypes.string,
  backgroundImage: PropTypes.string,
  mobileFontSize: PropTypes.string
};

EventCountdownTimer.displayName = 'EventCountdownTimer';

export { EventCountdownTimer };
