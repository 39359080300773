import React from 'react';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { string } from 'prop-types';

const ASSOCIATE_SVG = 'https://assets.homedepot-static.com/images/v1/plcc-associate-icon.svg';
const linkUrl = 'https://www.homedepot.com/b/Special-Values/N-5yc1vZ7';
const NoProductsMessage = ({ categoryName = '', title = '', url = '' }) => {
  const hasUrl = (
    <div className="fallback-message__wrapper sui-flex sui-place-content-center sui-w-full sui-h-44 sm:sui-h-80">
      <div className="sui-flex sui-flex-row sui-items-center">
        <img className="sui-w-20" src={ASSOCIATE_SVG} height="1" width="1" alt="" />
        <div className="sui-ml-3">
          <div className="sui-mb-5">
            <Typography variant="h6" weight="display" className="sui-mb-5">{title + ' are here!'}</Typography>
          </div>
          <Button href={url} variant="text">{'Shop all ' + categoryName}</Button>
        </div>
      </div>
    </div>
  );

  const noUrl = (
    <div className="fallback-message__wrapper sui-flex sui-place-content-center sui-w-full sui-h-44 sm:sui-h-80">
      <div className="sui-flex sui-flex-row sui-items-center">
        <img className="sui-w-20" src={ASSOCIATE_SVG} height="1" width="1" alt="" />
        <div className="sui-ml-3">
          <div className="sui-mb-5">
            <Typography
              variant="h6"
              weight="display"
              className="sui-mb-5"
            >
              There&apos;s Even More Deals to Discover
            </Typography>
          </div>
          <Button href={url} variant="text">Shop All Savings</Button>
        </div>
      </div>
    </div>
  );

  return url ? hasUrl : noUrl;
};

NoProductsMessage.propTypes = {
  title: string,
  categoryName: string,
  url: string
};

export { NoProductsMessage };
