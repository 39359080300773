import React, { useContext, useEffect } from 'react';
import {
  bool, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import classNames from 'classnames';
import {
  BRIGHTCOVE_ACCOUNT_ID,
  BRIGHTCOVE_PROD_PLAYER_ID,
  BRIGHTCOVE_PROD_PLAYER_ID_CONTENT,
} from '../../constants';
import { initializeBrightCovePlayer } from '../../helpers/videoPlayerHelpers';
import { VideoPlayerContext } from '../../contexts/VideoPlayerContext';
import styles from '../video-player.module.scss';

export const BrightCovePlayer = React.forwardRef((props, ref) => {
  const { autoPlayInViewport } = useContext(VideoPlayerContext) || {};
  const {
    analyticsData, aspectRatio, autoPlay, className, height, width, videoId, videoStill, shouldUseContentPlayer
  } = props;
  const frameId = `thd-brightcove-iframe-${videoId}`;

  const videoWrapperStyle = classNames(
    styles['video-player__wrapper'],
    styles['video-player__responsive'],
    {
      [styles['video-player__responsive--16-9']]: aspectRatio === 16 / 9,
      [styles['video-player__responsive--4-3']]: aspectRatio === 4 / 3,
    }
  );

  const PLAYER_ID = shouldUseContentPlayer ? BRIGHTCOVE_PROD_PLAYER_ID_CONTENT : BRIGHTCOVE_PROD_PLAYER_ID;

  // eslint-disable-next-line max-len
  let iframeSrc = `https://players.brightcove.net/${BRIGHTCOVE_ACCOUNT_ID}/${PLAYER_ID}_default/index.html?videoId=${videoId}&uniqueId=${frameId}`;
  if (autoPlay || autoPlayInViewport || videoStill) {
    iframeSrc += '&playsinline=true';
    if (autoPlay || videoStill) {
      iframeSrc += '&autoplay=any';
    } else { // autoPlayInViewport === true
      iframeSrc += '&muted=true';
    }
  }

  useEffect(() => {
    initializeBrightCovePlayer({ analyticsData, muted: autoPlay || autoPlayInViewport || videoStill });
  }, []);

  return (
    <div
      className={styles['video-player__container']}
      style={{
        width,
        maxWidth: width
      }}
      data-component="BrightCovePlayer"
    >
      <div className={videoWrapperStyle}>
        <iframe
          id={frameId}
          ref={ref}
          className={classNames(styles['video-player'], className)}
          height={height}
          src={iframeSrc}
          title={videoId}
          allow="autoplay"
          allowFullScreen
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          width={width}
        />
      </div>
    </div>
  );
});

BrightCovePlayer.propTypes = {
  analyticsData: shape({}),
  autoPlay: bool,
  aspectRatio: oneOf([16 / 9, 4 / 3]),
  className: string,
  height: number,
  shouldUseContentPlayer: bool,
  videoId: oneOfType([string, number]),
  videoStill: bool,
  width: number,
};

BrightCovePlayer.defaultProps = {
  analyticsData: null,
  aspectRatio: 16 / 9,
  autoPlay: false,
  className: null,
  height: null,
  shouldUseContentPlayer: false,
  videoId: null,
  videoStill: false,
  width: null
};