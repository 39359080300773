import { createTheme } from '@one-thd/sui-atomic-components';

const EndcapLightGreen = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

EndcapLightGreen.palette['--sui-palette-background-primary'] = '184 210 115';
EndcapLightGreen.palette['--sui-palette-button-background-primary'] = '32 32 32';
EndcapLightGreen.palette['--sui-palette-text-primary'] = '32 32 32';
EndcapLightGreen.palette['--sui-palette-text-subtle'] = '32 32 32';

export { EndcapLightGreen };
