import React from 'react';
import { shape, string, number } from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { Typography } from '@one-thd/sui-atomic-components';
import { useImpression } from '@thd-olt-component-react/impression';
import { publish } from '../../utils/publisher';

const CategoryTile = (props) => {
  const { category, componentName, entryId, podPosition } = props;

  const handleClickedCategory = (clickedCategory) => {
    publish({
      componentName,
      eventName: 'click',
      data: clickedCategory
    });
  };

  const { ref, clickID } = useImpression({
    data: {
      id: entryId,
      name: 'CategoryTile',
      component: 'CategoryTile',
      position: podPosition,
      type: 'product',
      category: category?.title?.replace(/\W+/g, '')
    }
  });

  return (
    <article className="sui-grid sui-grid-cols-1" key={category?.id} data-type="product">
      <meta data-prop="name" content={category?.title} />

      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        className="sui-mb-2"
      >
        <a href={category?.catUrl} onClick={() => handleClickedCategory(category)}>
          <div className="sui-mb-4">
            <Image
              alt={category?.title}
              className="sui-block sui-aspect-square sui-max-w-full"
              src={category?.catImage?.replace('<SIZE>', '400')}
              height="183px"
              width="183px"
            />
          </div>
          <div className="sui-overflow-hidden sui-text-center">
            <Typography lineClamp="2" variant="body-regular">{category?.title}</Typography>
          </div>
        </a>
      </div>
    </article>
  );
};

CategoryTile.displayName = 'CategoryTile';

CategoryTile.propTypes = {
  category: shape({
    id: string,
    title: string,
    catUrl: string,
    catImage: string
  }).isRequired,
  componentName: string,
  entryId: string,
  podPosition: number
};

CategoryTile.defaultProps = {
  componentName: '',
  entryId: '',
  podPosition: 0
};

export { CategoryTile };
