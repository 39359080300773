import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { PromoVisualNavigation as PromoVisualNavigationComponent } from '@thd-olt-component-react/visual-navigation';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import classnames from 'classnames/bind';

export function PromoVisualNavigation({ id, title }) {

  const marginTitle = classnames(
    'sui-h-full',
    { 'sui-mt-4': title }
  );

  return (
    <ErrorBoundary name="promo-visual-navigation">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`promo-visual-navigation-php-${id}`}
          className={marginTitle}
        >
          <PromoVisualNavigationComponent
            componentId={id}
            componentClass="PromoVisualNavigation"
            enableTheme
            isHomePage
            title={title}
            fontWeight="bold"
          />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

PromoVisualNavigation.propTypes = {
  id: string,
  title: string,
};

PromoVisualNavigation.defaultProps = {
  id: undefined,
  title: undefined,
};
