function getState(zipcode) {
  // Ensure we don't parse strings starting with 0 as octal values
  const zip = parseInt(zipcode, 10);
  if (Number.isNaN(zip)) {
    return '';
  }

  const withinRange = (min, max) => zip >= min && zip <= max;

  // Code blocks alphabetized by state
  switch (true) {
  case withinRange(35000, 36999):
    return 'AL';
  case withinRange(99501, 99950):
    return 'AK';
  case withinRange(85000, 86999):
    return 'AZ';
  case withinRange(71600, 72999):
    return 'AR';
  case withinRange(90000, 96699):
    return 'CA';
  case withinRange(80000, 81999):
    return 'CO';
  case withinRange(6000, 6999):
    return 'CT';
  case withinRange(19700, 19999):
    return 'DE';
  case withinRange(32000, 34999):
    return 'FL';
  case withinRange(30000, 31999):
    return 'GA';
  case withinRange(96910, 96932):
    return 'GU';
  case withinRange(96701, 96898):
    return 'HI';
  case withinRange(83200, 83999):
    return 'ID';
  case withinRange(60000, 62999):
    return 'IL';
  case withinRange(46000, 47999):
    return 'IN';
  case withinRange(50000, 52999):
    return 'IA';
  case withinRange(66000, 67999):
    return 'KS';
  case withinRange(40000, 42999):
    return 'KY';
  case withinRange(70000, 71599):
    return 'LA';
  case withinRange(3900, 4999):
    return 'ME';
  case withinRange(20600, 21999):
    return 'MD';
  case withinRange(1000, 2799):
    return 'MA';
  case withinRange(48000, 49999):
    return 'MI';
  case withinRange(55000, 56999):
    return 'MN';
  case withinRange(38600, 39999):
    return 'MS';
  case withinRange(63000, 65999):
    return 'MO';
  case withinRange(59000, 59999):
    return 'MT';
  case withinRange(27000, 28999):
    return 'NC';
  case withinRange(58000, 58999):
    return 'ND';
  case withinRange(68000, 69999):
    return 'NE';
  case withinRange(88900, 89999):
    return 'NV';
  case withinRange(3000, 3899):
    return 'NH';
  case withinRange(7000, 8999):
    return 'NJ';
  case withinRange(87000, 88499):
    return 'NM';
  case withinRange(10000, 14999):
    return 'NY';
  case withinRange(43000, 45999):
    return 'OH';
  case withinRange(73000, 74999):
    return 'OK';
  case withinRange(97000, 97999):
    return 'OR';
  case withinRange(15000, 19699):
    return 'PA';
  case withinRange(600, 799) || withinRange(900, 999):
    return 'PR';
  case withinRange(2800, 2999):
    return 'RI';
  case withinRange(29000, 29999):
    return 'SC';
  case withinRange(57000, 57999):
    return 'SD';
  case withinRange(37000, 38599):
    return 'TN';
  case withinRange(75000, 79999) || withinRange(88500, 88599):
    return 'TX';
  case withinRange(84000, 84999):
    return 'UT';
  case withinRange(5000, 5999):
    return 'VT';
  case withinRange(22000, 24699):
    return 'VA';
  case withinRange(801, 851):
    return 'VI';
  case withinRange(20000, 20599):
    return 'DC';
  case withinRange(98000, 99499):
    return 'WA';
  case withinRange(24700, 26999):
    return 'WV';
  case withinRange(53000, 54999):
    return 'WI';
  case withinRange(82000, 83199):
    return 'WY';
  default:
    return '';
  }
}

export const getStateFromZipcode = (zipcode) => {
  try {
    if (zipcode) {
      return getState(zipcode);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return '';
};