import { createTheme } from '@one-thd/sui-atomic-components';

const ThdDefaultLightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'neutral-0'
    }
  }
});

ThdDefaultLightTheme.palette['--sui-palette-background-primary'] = '255 255 255';

export { ThdDefaultLightTheme };