/* eslint-disable id-length */

import React, { useEffect } from 'react';
import PropTypes, { string, number } from 'prop-types';
import {
  params,
  shape,
  fragment,
  string as stringType,
  useDataModel,
  customType,
  arrayOf,
} from '@thd-nucleus/data-sources';

import { HotspotBlock } from './HotspotBlock';

const renderResponsiveImage = (ssrImageData, title = '') => (
  <picture>
    {ssrImageData?.responsiveImages?.map(({ imageSrc, mediaQuery }, index) => (
      <source
        key={index}
        className="sui-w-full"
        alt={title}
        srcSet={imageSrc}
        media={mediaQuery}
      />
    ))}
    {/* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */}
    <img
      className="sui-w-full"
      alt={title}
      src={ssrImageData?.mobileImageSrc}
      loading="eager"
      // eslint-disable-next-line react/no-unknown-property
      fetchpriority="high"
    />
  </picture>
);

const ImageHotspot = ({ componentId, componentClass, position, ssrImageData }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('image-hotspot.ready'); }, []);

  const { data, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
    ssr: false
  });

  if (error) {
    return null;
  }

  const hotspots = data?.component?.hotspots ? JSON.parse(data?.component?.hotspots) : null;
  const hotspotArr = hotspots?.hotspots ? hotspots.hotspots.reverse() : null;
  const actions = data?.component?.hotspotActions;
  const image = data?.component?.imageUrl || null;

  return (
    <div className="sui-relative sui-mx-auto sui-my-0" data-component="ImageHotspot">
      {!ssrImageData ? (
        /* eslint-disable-next-line @mizdra/layout-shift/require-size-attributes */
        <img
          className="sui-w-full"
          src={image}
          alt={data?.component?.title}
        />
      ) : (
        renderResponsiveImage(ssrImageData, data?.component?.title)
      )}

      {
        hotspotArr && hotspotArr.map((hotspot, idx) => {
          const hotspotStyle = {
            width: `${hotspot.width}%`,
            height: `${hotspot.height}%`,
            left: `${hotspot.x}%`,
            top: `${hotspot.y}%`,
          };
          const action = actions?.filter((actionItem) => actionItem.title === hotspot.name);
          if (action[0]?.id && action[0].url) {
            return (
              <HotspotBlock
                key={`hotspot-${idx}-${action[0].id}`}
                id={action[0].id}
                position={position}
                style={hotspotStyle}
                action={action[0].url}
                name={hotspot.name}
              />
            );
          }
          return null;
        })
      }
    </div>
  );
};

ImageHotspot.propTypes = {
  componentId: string.isRequired,
  componentClass: string,
  position: PropTypes.oneOfType([string, number]),
  ssrImageData: PropTypes.shape({
    mobileImageSrc: string,
    responsiveImages: PropTypes.arrayOf(
      PropTypes.shape({
        imageSrc: string,
        mediaQuery: string,
      })
    ),
  }),
};

ImageHotspot.defaultProps = {
  position: 1,
  ssrImageData: null,
  componentClass: 'ImageHotspot',
};

ImageHotspot.displayName = 'ImageHotspot';

ImageHotspot.dataModel = {
  component: params({
    componentClass: customType('ComponentClass').enum(['ImageHotspot']).isRequired(),
    id: stringType().isRequired(),
  }).shape({
    ImageHotspot: fragment().shape({
      id: stringType(),
      title: stringType(),
      imageUrl: stringType(),
      hotspots: stringType(),
      hotspotActions: arrayOf(shape({
        id: stringType(),
        url: stringType(),
        title: stringType(),
      }))
    })
  })
};

export { ImageHotspot, HotspotBlock };
