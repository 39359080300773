import {
  CapabilitiesTile,
  ComponentSelector,
  EventCountdownTimer,
  HeroFlattenImage,
  HomeDecorCard,
  Image,
  PiccolaPencil,
  MedioInline,
  LoyaltyBenefits,
  OnlineWorkshops,
  PersonalizedHomeServices,
  PopularCategories,
  PrivateLabelCreditCard,
  PromotionCard,
  PromoVisualNavigation,
  RecentOrders,
  RecentPurchases,
  Recommendations,
  SectionSelector,
  ShopByCategory,
  SignIn,
  SpecialBuyOfTheDay,
  SpecialBuyOfTheWeek,
  SponsoredBanner,
  Spotlight,
  SpotlightContainer,
  UserList,
  VisualNavigation,
  WelcomeMessage,
  BentoHero
} from '.';

export const components = {
  CapabilitiesTile,
  ComponentSelector,
  EventCountdownTimer,
  HeroFlattenImage,
  HomeDecorCard,
  Image,
  PiccolaPencil,
  MedioInline,
  LoyaltyBenefits,
  OnlineWorkshops,
  PersonalizedHomeServices,
  PopularCategories,
  PrivateLabelCreditCard,
  PromotionCard,
  PromoVisualNavigation,
  RecentOrders,
  RecentPurchases,
  Recommendations,
  Section: SectionSelector,
  ShopByCategory,
  SignIn,
  SpecialBuyOfTheDay,
  SpecialBuyOfTheWeek,
  SponsoredBanner,
  Spotlight,
  SpotlightContainer,
  UserList,
  VisualNavigation,
  welcomeMessage: WelcomeMessage,
  BentoHero
};
