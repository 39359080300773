import React, { memo } from 'react';

import { Locked } from '@one-thd/sui-icons';
import { ProductImageBadge } from './ProductImageBadge';

export const ProductImageLockBadge = memo(() => {
  return (
    <ProductImageBadge>
      <Locked size="inherit" data-testid="locked-icon" />
    </ProductImageBadge>
  );
});

ProductImageLockBadge.displayName = 'ProductImageLockBadge';
