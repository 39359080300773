import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = React.forwardRef((props, ref) => {

  const {
    children,
    component: ListRoot = 'ul',
    disablePadding = false,
    ...other
  } = props;

  const ListRootClasses = classNames('sui-list-none sui-m-0 sui-relative', {
    'sui-p-0': disablePadding,
    'sui-px-0 sui-py-2': !disablePadding,
  });

  return (
    <ListRoot
      className={ListRootClasses}
      ref={ref}
      {...other}
    >
      {children}
    </ListRoot>
  );
});

List.displayName = 'List';

List.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, vertical padding is removed from the list.
   * @default false
   */
  disablePadding: PropTypes.bool,
};

export { List };
