import React from 'react';
import { string } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { VisualNavigation as VisualNavigationComponent } from '@thd-olt-component-react/visual-navigation';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import classnames from 'classnames/bind';

export function VisualNavigation({ id, title }) {

  const isComponentTitle = title && title.trim() !== '';
  const marginTitle = classnames(
    'vis-nav-wrapper sui-h-full',
    { 'sui-mt-4': isComponentTitle }
  );

  return (
    <ErrorBoundary name="visual-navigation">
      <QueryProvider key={id} cacheKey={id}>
        <div
          id={`visual-navigation-php-${id}`}
          className={marginTitle}
        >
          <VisualNavigationComponent
            componentId={id}
            componentClass="VisualNavigation"
            enableTheme
            lazyLoad
            fontWeight="bold"
            isInSection={!isComponentTitle}
          />
        </div>
      </QueryProvider>
    </ErrorBoundary>
  );
}

VisualNavigation.propTypes = {
  id: string,
  title: string,
};

VisualNavigation.defaultProps = {
  id: undefined,
  title: string,
};
