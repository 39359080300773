import React from 'react';
import { string } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Profile } from '@one-thd/sui-icons';

const SignInCardContent = ({ userEmail = null, schema = null }) => {
  return (
    <div id={schema} data-automation-id="signinCard">
      <Typography variant="body-base" color="primary">
        Sign in again for a faster, personalized shopping experience.
      </Typography>
      <div className="sui-items-center sui-gap-4 sui-my-4 sui-flex sui-justify-center">
        <Profile size="large" />
        <Typography variant="body-base" color="primary">
          {userEmail}
        </Typography>
      </div>
    </div>
  );
};

SignInCardContent.propTypes = {
  userEmail: string,
  schema: string,
};

export { SignInCardContent };