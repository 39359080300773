import React from 'react';
import PropTypes from 'prop-types';
import { V1Carousel } from './V1Carousel';

const Carousel = (props) => {
  const {
    ...rest
  } = props;

  /* const version = 'v1';
  if (version === 'v2') {
    return <V2Carousel {...rest} />;
  } */

  return <V1Carousel {...rest} />;
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  /**
   * The action that goes in the upper right corner of carousel
   */
  action: PropTypes.node,
  /**
   * When true, carousel will autoplay
   * @default false
   */
  autoplay: PropTypes.bool,
  /**
   * Number of slides to display based on breakpoint
   */
  breakpoints: PropTypes.shape({
    sm: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    md: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    lg: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    xl: PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    }),
    '2xl': PropTypes.shape({
      slidesPerView: PropTypes.number,
      slidesPerGroup: PropTypes.number,
      spaceBetween: PropTypes.number
    })
  }),
  /**
   * Disables the slide box-shadow
   * @default false
   */
  disableShadow: PropTypes.bool,
  /**
   * Disable the default margin of 32px (mb-8) under the Carousel.
   * When set to true, the Carousel will have no margin below it.
   * @default false
  */
  disableMargin: PropTypes.bool,
  /**
   * If `true` will hide the carousel controls. (Note that hiding the controls will impact accesibility, especially when
   * the carousel has more than one slide. This prop won't prevent the scrollbar from showing up in these occasions.)
   * @ignore
  */
  hideControls: PropTypes.bool,
  /**
   * Hide pagination controls when set is placed all in one view
   * @default false
   */
  hidePaginationOnOneOfOne: PropTypes.bool,
  /**
   * When true, carousel will not disable the previous and next paginator buttons.
   * If Carousel is on the first slide and the previous button is clicked, it will paginate to the last group.
   * If Carousel is on the last slide and the next button is clicked, it will paginate to the first group.
   * Additionally this will hide the scrollbar as there is a
   * [known bug](https://github.com/nolimits4web/swiper/issues/2315#issuecomment-343162574) on the internal Swiper
   * library used.
   */
  loop: PropTypes.bool,
  /**
   * The carousel slides
   * @default []
   */
  children: PropTypes.node,
  /**
   * Number of slides to transition when clicking next/prev
   * @default 1
   */
  slidesPerGroup: PropTypes.number,
  /**
   * Number of slides to display at once
   * @default 2
   */
  slidesPerView: PropTypes.number,
  /**
   * The space between slides
   * @default 16
   */
  spaceBetween: PropTypes.number,
  /**
   * Props applied to the underlying Swiper component
   */
  SwiperProps: PropTypes.object,
  /**
   * Title of carousel in upper left corner
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Two display types of carousel
   * @default 'peek'
   */
  type: PropTypes.oneOf(['peek', 'peekless'])
};

export { Carousel };