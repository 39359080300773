import React from 'react';
import { Skeleton, SkeletonBlock, SkeletonLine } from '@one-thd/sui-atomic-components';

export const ProductRowPlaceholder = () => {
  return (
    <div data-testid="product-pod-placeholder" className="sui-min-h-475px">
      <Skeleton
        grow
        data-component="ProductPodPlaceholder"
        width="100%"
      >
        <SkeletonBlock aspect="square" width="100%" height="100%" />
        <SkeletonLine variant="multi" numberOfLines={4} fullWidth />
        <SkeletonBlock height={10} aspect="wide" />
      </Skeleton>
    </div>
  );
};

export const ProductRowsPlaceholder = () => (
  <div
    className="sui-pt-3"
    data-testid="ProductPodPlaceholder"
    data-component="ProductRowPlaceholder"
  >
    <div
      data-testid="product-placeholder"
      className={`sui-grid sui-grid-cols-4
      sui-m-0 sui-p-0 sui-w-full`}
    >
      <ProductRowPlaceholder />
      <ProductRowPlaceholder />
      <ProductRowPlaceholder />
      <ProductRowPlaceholder />
    </div>
  </div>
);
