import { createTheme } from '@one-thd/sui-atomic-components';

const EndcapSawdust = createTheme({
  palette: {
    mode: 'light',
    background: {
      primary: 'brand'
    }
  }
});

EndcapSawdust.palette['--sui-palette-background-primary'] = '246 243 239';
EndcapSawdust.palette['--sui-palette-button-background-primary'] = '32 32 32';
EndcapSawdust.palette['--sui-palette-text-primary'] = '32 32 32';
EndcapSawdust.palette['--sui-palette-text-subtle'] = '32 32 32';

export { EndcapSawdust };