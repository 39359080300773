import React from 'react';
import { bool, number, shape } from 'prop-types';
import { ImpressionableBay } from './ImpressionableBay';

// Responsible for rendering a bay with impressions or without impressions.
const BentoBay = ({ bentoBayData = {}, impressionReady = false, isSponsored = false, position = 1 }) => {

  return (
    <ImpressionableBay
      bentoBayData={bentoBayData}
      impressionReady={impressionReady}
      isSponsored={isSponsored}
      position={position}
    />
  );
};

BentoBay.propTypes = {
  bentoBayData: shape({}),
  impressionReady: bool,
  isSponsored: bool,
  position: number,
};

BentoBay.displayName = 'BentoBay';

export { BentoBay };
