import React from 'react';
import { string as stringType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { getFormattedOrderDate } from '../../../../utils';

export function RecentOrdersCardDate({ orderDate = null }) {
  const date = new Date(orderDate);
  const formattedDate = getFormattedOrderDate(date) || '';

  if (!orderDate) {
    return (
      <div data-testid="card-orderDate">
        <Typography variant="body-base" weight="bold">
          --/--/--
        </Typography>
      </div>
    );
  }

  return (
    <div className="sui-flex" data-testid="card-orderDate">
      <span>
        <Typography color="subtle" variant="body-base">{formattedDate}</Typography>
      </span>
    </div>
  );
}

RecentOrdersCardDate.propTypes = {
  orderDate: stringType
};