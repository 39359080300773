import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

const CustomerWelcomeMessage = ({ isCustomerAuthenticated = false }) => {
  const [message, setMessage] = useState(<span>Welcome Back!</span>);
  /*
    Set the message visibility to hidden on the server.
    This prevents the quick flash from a message without a username
    to a message with username on the client on the client.
  */
  const [hidden, setHidden] = useState(true);

  const messageStyles = classNames(
    'sui-flex sui-items-center sui-justify-center',
    { 'sui-invisible': hidden }
  );

  useEffect(() => {
    // Reset the message on customer authentication change.
    setMessage(<span>Welcome Back!</span>);
    if (isCustomerAuthenticated) {
      const customer = window.THDCustomer?.default || {};
      const { username } = customer;

      if (username && username.toLowerCase() !== 'self') {
        setMessage(<span className="sui-capitalize">Welcome Back, {username.toLowerCase()}!</span>);
      }
    }

    /*
      Even if we don't get a username from the cookie, we still want to display
      the initial message, which is why this is not inside the above `if`.
    */
    setHidden(false);
  }, [isCustomerAuthenticated]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('customer-welcome-message.ready');
  }, []);

  return (
    <div
      data-automation-id="customer-welcome-message"
      className={messageStyles}
      data-component="CustomerWelcomeMessage"
    >
      <Typography weight="display" variant="h3" align="center">{message}</Typography>
    </div>
  );
};

CustomerWelcomeMessage.displayName = 'CustomerWelcomeMessage';

CustomerWelcomeMessage.propTypes = {
  isCustomerAuthenticated: PropTypes.bool
};

export { CustomerWelcomeMessage };
