import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Contains the main actions for `Drawer`.
 *
 * Related components: [Drawer](#drawer), [Button](#button), [ButtonGroup](#buttongroup)
 *
 * Usage:
 *
 * ```jsx
 * import { DrawerFooter } from '@one-thd/sui-atomic-components';
 * ```
 */
const DrawerFooter = React.forwardRef((props, ref) => {

  const {
    children,
    position = 'normal',
    variant = 'sticky',
    ...other
  } = props;

  const stickyCls = 'sui-bg-primary sui-p-4 sui-relative sui-bottom-0 sui-sticky';

  const flexCls = classNames('sui-bg-primary sui-p-4 sui-flex sui-items-center sui-p-4 sui-grow-0 sui-shrink-0 sui-basis-auto', {
    'sui-justify-normal': position === 'normal',
    'sui-justify-start': position === 'start',
    'sui-justify-center': position === 'center',
    'sui-justify-end': position === 'end'
  });

  return (
    <div
      className={variant === 'sticky' ? stickyCls : flexCls}
      ref={ref}
      {...other}
    >
      {children}
    </div>
  );
});

DrawerFooter.displayName = 'DrawerFooter';

DrawerFooter.propTypes = {
  /**
   * The contents of the drawer footer.
   */
  children: PropTypes.node,
  /**
   * Sets the child items are positioned along the container's axis.
   *
   * Only applies when variant is set to `flex`.
   * @default 'normal'
   */
  position: PropTypes.oneOf(['normal', 'start', 'center', 'end']),
  /**
   * The variant to use.
   *
   * The `sticky` variant will set the container element as bottom positioned.
   * The `flex` variant will set the container as flex: 0 0 auto;
   * @default 'sticky'
   */
  variant: PropTypes.oneOf(['flex', 'sticky']),
};

export { DrawerFooter };