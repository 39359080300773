import React, { useState, useContext, useRef } from 'react';
import {
  Typography, Card, CardMedia, CardContent,
  Skeleton, SkeletonContent, SkeletonLine, SkeletonBlock,
  Button
} from '@one-thd/sui-atomic-components';
import * as Icons from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';

import { useImpression } from '@thd-olt-component-react/impression';
import PropTypes from 'prop-types';
import { selectPreferredImageSrc } from '../utils/selectPreferredImage';

const ShopByCategoryRenderer = (
  {
    data, loading, categoryList
  }
) => {

  const title = 'Shop By Category';
  const { channel } = useContext(ExperienceContext);
  const [showMore, setShowMore] = useState(false);
  const isMobile = channel === 'mobile';
  const categoryRef = useRef(null);

  const OnViewLessClick = () => {
    setShowMore(false);
    if (categoryRef?.current) {
      categoryRef.current.scrollIntoView({
        behavior: 'auto'
      });
    }
  };
  /* eslint-disable react/prop-types */
  const CustomImage = ({ imgSrc, imgAlt }) => {
    return (
      <img
        className="sui-aspect-square"
        src={imgSrc}
        alt={imgAlt}
        height="24"
        width="24"
      />
    );
  };

  const ShoppingCategoryCard = (category, index) => {
    const { ref, clickID } = useImpression({
      data: {
        id: category?.id || '',
        name: 'ShoppingCategories',
        component: 'ShoppingCategories',
        position: index + 1,
        type: 'content',
        category: category?.title
      }
    });
    const showStencilIcon = category?.stencilIcon !== undefined
      && category?.stencilIcon !== null
      && category?.stencilIcon !== '';
    const StencilIcon = showStencilIcon ? Icons[category?.stencilIcon] : <></>;
    const imgSrc = selectPreferredImageSrc({ damImage: category?.previewImage, defaultImageUrl: category?.icon?.url });
    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/no-unknown-property
        clickid={clickID}
        key={index}
        data-testid={`category ${index}`}
      >
        <a
          href={category?.link}
          className="sui-grid"
        >
          <Card
            disablePadding
            disableGutters
            value={category?.id}
            aria-label={category?.id}
            orientation="horizontal"
            data-contentful-entry-id={category?.id}
            data-contentful-field-id="internalName"
          >
            <CardContent orientation="horizontal" disableGutters grow>
              <div className="sui-flex sui-items-center">
                <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2">
                  {showStencilIcon ? (
                    <StencilIcon
                      data-contentful-entry-id={category?.id}
                      data-contentful-field-id="stencilIcon"
                    />
                  ) : (
                    <CardMedia
                      component={(() => <CustomImage imgSrc={imgSrc} imgAlt={`${category?.title} Placeholder`} />
                      )}
                    />
                  )}
                </div>
                <div
                  className="sui-flex-auto sui-text-xs sui-leading-normal sui-font-bold"
                  data-contentful-entry-id={category?.id}
                  data-contentful-field-id="title"
                >
                  {category?.title}
                </div>
              </div>
            </CardContent>
          </Card>
        </a>
      </div>
    );
  };

  const LoadingPlaceholders = () => {
    const placeHolderCount = isMobile ? 8 : 18;
    const placeholderElements = [];
    for (let i = 0; i < placeHolderCount; i += 1) {
      placeholderElements.push(
        <Skeleton
          orientation="horizontal"
          disablePadding
          disableGutters
          key={i}
        >
          <SkeletonContent disableGutters disableShrink orientation="horizontal" grow>
            <div className="sui-flex sui-items-center">
              <div className="sui-flex sui-grow-0 sui-shrink-0 sui-basis-auto sui-mr-2">
                <SkeletonBlock aspect="square" height={6} width={6} />
              </div>
              <div className="sui-flex-auto sui-text-xs sui-leading-normal sui-font-bold">
                <SkeletonLine variant="single" fullWidth />
              </div>
            </div>
          </SkeletonContent>
        </Skeleton>
      );
    }
    if (isMobile) {
      placeholderElements.push(
        <div className="sui-flex sui-items-center sui-justify-center" key={9}>
          <SkeletonBlock aspect="wide" height={11} width={24} />
        </div>);
    }
    return placeholderElements;
  };

  const ShoppingCategories = () => {
    const numberofCategory = (showMore || !isMobile) ? categoryList?.length : 8;
    let reducedCategoryList = categoryList?.slice(0, numberofCategory)?.map((category, index) => {
      return ShoppingCategoryCard(category, index);
    });
    if (isMobile) {
      reducedCategoryList.push(
        <div className="sui-flex sui-items-center sui-justify-center" key={9}>
          {showMore
            ? (
              <Button
                variant="text"
                endIcon={Icons.ArrowUp}
                onClick={OnViewLessClick}
              >
                Show Less
              </Button>
            )
            : (
              <Button
                variant="text"
                endIcon={Icons.ArrowDown}
                onClick={() => { setShowMore(true); }}
              >
                Show More
              </Button>
            )}
        </div>
      );
    }
    return reducedCategoryList;
  };

  return (
    <div className="sui-grid sui-p-2" ref={categoryRef} data-component="ShopByCategory">
      <Typography variant="h2">
        {title}
      </Typography>
      {loading && (
        <div className="sui-grid sui-grid-cols-1 sm:sui-grid-cols-3 lg:sui-grid-cols-5 sui-gap-4 sui-mt-5">
          <LoadingPlaceholders />
        </div>
      ) }
      {data && (
        <div
          className="sui-grid sui-grid-cols-1 sm:sui-grid-cols-3 lg:sui-grid-cols-5 sui-gap-4 sui-mt-5"
        >
          <ShoppingCategories />
        </div>
      )}
    </div>
  );
};

ShopByCategoryRenderer.propTypes = {
  data: PropTypes.shape({
    component: PropTypes.shape({
      shopByCategoryNavigationItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        link: PropTypes.string,
        previewImage: PropTypes.shape({}),
        icon: PropTypes.shape({ url: PropTypes.string }),
        stencilIcon: PropTypes.string
      }))
    })
  }),
  loading: PropTypes.bool,
  categoryList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    previewImage: PropTypes.shape({}),
    icon: PropTypes.shape({ url: PropTypes.string }),
    stencilIcon: PropTypes.string
  }))
};

ShopByCategoryRenderer.defaultProps = {
  data: null,
  loading: false,
  categoryList: []
};

export { ShopByCategoryRenderer };
