/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ECOREBATES_SCRIPT_URL = '//static.ecorebates.com/widgets/thd/product.js';
const getEcoRebatesScript = () => document.querySelector(`script[src="${ECOREBATES_SCRIPT_URL}"]`);
const hasEcoRebatesScript = () => typeof window !== 'undefined' && Boolean(getEcoRebatesScript());
const loadEcoRebatesScript = () => {
  const ecoRebatesScript = document.createElement('script');
  ecoRebatesScript.type = 'text/javascript';
  ecoRebatesScript.async = true;
  ecoRebatesScript.src = ECOREBATES_SCRIPT_URL;
  document.body.appendChild(ecoRebatesScript);
};

const getUiContext = () => {
  if (typeof window !== 'undefined') {
    const { href, host } = window.location;
    return href.includes(`${host}/p/`) ? "PDP" : "PLP";
  }
};

const triggerRebateAnalytics = () => {
  window.LIFE_CYCLE_EVENT_BUS.trigger('price.rebates_click', {});
};

export const Rebates = ({
  itemId,
  zipCode,
}) => {
  useEffect(() => {
    if (hasEcoRebatesScript()) return;
    loadEcoRebatesScript();
  }, []);

  return (
    <ecr-product-widget
      skus={itemId}
      zip={zipCode}
      uiContext={getUiContext()}
      rebateConsumerTypes="residential,commercial"
      data-nr-disable
    >
      <div slot="open-details" className="sui-underline" style={{ display: "none" }}>
        <a href="#" onClick={triggerRebateAnalytics}>{`Get up to {totalAmount} in {altRebateConsumerType} Rebates for {zipCode}`}</a>
      </div>
      <div slot="open-details-no-amount" className="sui-underline" style={{ display: "none" }}>
        <a href="#" onClick={triggerRebateAnalytics}>{`Get up to {offerCount} {altRebateConsumerType} Rebate{s} for {zipCode}`}</a>
      </div>
    </ecr-product-widget>
  );
}

Rebates.propTypes = {
  itemId: PropTypes.string,
  zipCode: PropTypes.string,
};

Rebates.defaultProps = {
  itemId: null,
  zipCode: null,
};
