import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react';
import {
  bool, func, node, oneOf, shape, string
} from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStoreId, useConfigService } from '@thd-nucleus/experience-context';
import { FEATURE_SWITCH_KEYS } from '../utils/constants';
import { usePromoModel } from '../hooks/usePromoModel';
import {
  emptyPromoCart,
  emptyPromoProgress,
  getCartStoreSkusWithoutPromoItems,
  transformCartModelToPromoCart
} from '../utils/promo-cart-utils';
import { useCart } from '../hooks/useCart';
import { PromoCartProviderDataModel } from '../models/PromoCartProviderDataModel';
import { noop } from '../utils/promo-utils';

export const defaultCartContext = {
  promoCartValue: 0,
  promoCartQuantity: 0,
  promoCartItemsObj: {},
  firstListCartItems: [],
  firstListCartItemIds: [],
  firstListCartValue: 0,
  firstListCartQuantity: 0,
  secondListCartItems: [],
  secondListCartItemIds: [],
  secondListCartValue: 0,
  secondListCartQuantity: 0,
  bogoItemInCart: null,
  promoSuccessLevel: '',
  hasAnchorItemInCart: false,
  progressMade: 0,
  isPromoAchieved: false,
  currentPromoTier: 0,
  previousPromoTier: 0,
  bogoSelectedProduct: null,
  setBogoSelectedProduct: noop,
  CartModel: {},
  addToCart: noop,
  updateCart: noop,
  deleteCart: noop,
  freezeCart: noop,
  isCartLoading: false,
  setIsCartLoading: noop,
  selectedItemsModel: [],
  setSelectedItemsModel: noop,
  promoProgress: {},
  setPromoProgress: noop,
  setCurrentCartModel: noop,
};

export const PromoCartContext = createContext(defaultCartContext);

export const PromoCartProvider = ({
  children,
  hasUserInteracted,
  isDrawerOpen,
  setHasUserInteracted,
  type,
  maAvailability,
}) => {
  const {
    allListProductsAndAnchor,
    anchorItem,
    displayableFirstListItemIds,
    displayableSecondListItemIds,
    hasSearchData,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isBmsm,
    isForwardsBogo,
    isMsb,
    isDollarThresholdBogo,
    originalNvalue,
    rewardTiers,
    src1MinPurchaseAmount,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    subExperience,
    isCategorizedPromotion,
    src1Categories,
    anchorCategory
  } = usePromoModel();
  const { deliveryZip } = useContext(ExperienceContext);
  const storeId = useStoreId();
  const initializeCall = hasSearchData && (type === 'pod' || isDrawerOpen);
  const {
    CartModel,
    addToCart,
    updateCart,
    deleteCart,
    isCartLoading,
    setIsCartLoading,
    setCurrentCartModel,
    freezeCart
  } = useCart({ initializeCall });
  const [promoCart, setPromoCart] = useState(emptyPromoCart);
  const [bogoSelectedProduct, setBogoSelectedProduct] = useState(null);
  const [selectedItemsModel, setSelectedItemsModel] = useState([]);
  const [promoProgress, setPromoProgress] = useState(emptyPromoProgress);
  const isEnableLocalizedPromotion = useConfigService(FEATURE_SWITCH_KEYS.enableLocalizedPromotion) || false;
  const isCartGraphQLEnabled = useConfigService(FEATURE_SWITCH_KEYS.enableCartGraphQL) || false;
  // storeSkus of items that are in cart, and not included in allListProductsAndAnchor
  const filteredCartStoreSkus = getCartStoreSkusWithoutPromoItems(CartModel,
    allListProductsAndAnchor, isCartGraphQLEnabled);
  const { data } = useDataModel('itemPromotionValidity', {
    variables: {
      navParam: originalNvalue,
      storeSkuIds: filteredCartStoreSkus,
    },
    ssr: false,
    skip: isCategorizedPromotion || !filteredCartStoreSkus.length || !originalNvalue
  });
  // promotionProducts 2nd call with cart line items
  const isWaitingForMaAvailability = maAvailability?.isLoading;
  const isWaitingForAnchorItemId = !anchorItem.itemId;
  const cartObject = isCartGraphQLEnabled ? CartModel?.items : CartModel?.itemModels;
  const {
    called: promotionProductsCalled,
    data: promotionProductsData,
    loading: promotionProductsLoading
  } = useDataModel('promotionProducts', {
    variables: {
      itemId: anchorItem.itemId,
      storeId: (isEnableLocalizedPromotion ? storeId : null),
      cartLineItems: cartObject?.map((cartItemId) => {
        const itemId = isCartGraphQLEnabled
          ? cartItemId.product.identifiers.storeSkuNumber : cartItemId.storeSku;
        return itemId && {
          itemId,
          quantity: cartItemId.quantity,
          price: isCartGraphQLEnabled
            ? cartItemId.product.pricing.total : cartItemId.totalItemPrice
        };
      })
    },
    skip: !cartObject
      || !isCategorizedPromotion
      || isWaitingForAnchorItemId
      || isWaitingForMaAvailability,
    ssr: false,
  });

  useEffect(() => {
    if (
      !promotionProductsLoading
      && promotionProductsCalled
      && promotionProductsData?.promotionProducts?.promotions?.[0]?.progress
    ) {
      setPromoProgress(promotionProductsData.promotionProducts.promotions[0].progress);
    }
  }, [promotionProductsData, promotionProductsLoading, promotionProductsCalled]);

  useEffect(() => {
    if (isForwardsBogo || isBackwardsBogo) {
      // let buybox know there has been a bogo selection
      // clear the buybox bogo state when an item has been successfully added or unselected
      const bogoInfo = bogoSelectedProduct
        ? { bogoSelectedProduct, isFreeProduct: true }
        : null;

      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('buybox.bogoSelectedProduct', bogoInfo);
    }
  }, [
    bogoSelectedProduct,
    isBackwardsBogo,
    isForwardsBogo,
    subExperience
  ]);

  useEffect(() => {
    if (promoCart?.bogoItemInCart) {
      setBogoSelectedProduct(null);
    }
  }, [promoCart]);

  // if add to cart to happens from another location, load our products/progress messaging
  useEffect(() => {
    if (CartModel && !hasUserInteracted) setHasUserInteracted(true);
  }, [CartModel, hasUserInteracted, setHasUserInteracted]);

  useEffect(() => {
    if (CartModel) {
      setPromoCart(transformCartModelToPromoCart({
        CartModel,
        selectedItemsModel,
        isCategorizedPromotion,
        isCartGraphQLEnabled,
        isForwardsB1gy,
        isForwardsBxg1,
        isBackwardsBogo,
        isForwardsBogo,
        isMsb,
        isBmsm,
        isDollarThresholdBogo,
        displayableFirstListItemIds,
        displayableSecondListItemIds,
        src1EligibilityCriteria,
        tgt1EligibilityCriteria,
        anchorItemId: anchorItem.itemId,
        anchorCategoryId: anchorCategory?.categoryId || '',
        bogoSelectedProduct,
        rewardTiers,
        src1Categories,
        src1MinPurchaseAmount,
        allListProductsAndAnchor,
        cartItemsPromotionValidity: data?.itemPromotionValidity || [],
        storeId,
        deliveryZip,
        promoProgress,
        setPromoProgress,
      }));
    }
  }, [
    isCartGraphQLEnabled,
    isCategorizedPromotion,
    CartModel,
    selectedItemsModel,
    isForwardsB1gy,
    isForwardsBxg1,
    isBackwardsBogo,
    isForwardsBogo,
    isMsb,
    isBmsm,
    isDollarThresholdBogo,
    anchorCategory?.categoryId,
    anchorItem.itemId,
    bogoSelectedProduct,
    rewardTiers,
    storeId,
    src1Categories,
    src1MinPurchaseAmount,
    src1EligibilityCriteria,
    tgt1EligibilityCriteria,
    allListProductsAndAnchor,
    data,
    deliveryZip,
    displayableFirstListItemIds,
    displayableSecondListItemIds,
    promoProgress,
  ]);

  const contextValue = useMemo(() => ({
    // promoCart is only mutated as a sideEffect of updating CartModel
    promoCartValue: promoCart.promoCartValue,
    promoCartQuantity: promoCart.promoCartQuantity,
    promoCartItemsObj: promoCart.promoCartItemsObj,
    promoCartItems: Object.values(promoCart.promoCartItemsObj || {}),
    promoCartItemIds: Object.values(promoCart.promoCartItemsObj || {}).map(({ itemId }) => itemId),
    firstListCartItems: promoCart.firstListCartItems,
    firstListCartItemIds: promoCart.firstListCartItemIds,
    firstListCartValue: promoCart.firstListCartValue,
    firstListCartQuantity: promoCart.firstListCartQuantity,
    secondListCartItems: promoCart.secondListCartItems,
    secondListCartItemIds: promoCart.secondListCartItemIds,
    secondListCartValue: promoCart.secondListCartValue,
    secondListCartQuantity: promoCart.secondListCartQuantity,
    bogoItemInCart: promoCart.bogoItemInCart,
    promoSuccessLevel: promoCart.promoSuccessLevel,
    hasAnchorItemInCart: promoCart.hasAnchorItemInCart,
    // Percentage of promotion completed. For BMSM percentage of current tier completed
    progressMade: promoCart.progressMade,
    isPromoAchieved: promoCart.isPromoAchieved,
    currentPromoTier: promoCart.currentPromoTier,
    previousPromoTier: promoCart.previousPromoTier,
    bogoSelectedProduct,
    setBogoSelectedProduct,
    secondListItemInCart: promoCart.secondListItemInCart,
    CartModel,
    addToCart,
    updateCart,
    deleteCart,
    freezeCart,
    isCartLoading,
    setIsCartLoading,
    selectedItemsModel,
    setSelectedItemsModel,
    src1Categories,
    promoProgress,
    setPromoProgress,
    setCurrentCartModel,
  }), [
    promoCart,
    bogoSelectedProduct,
    CartModel,
    addToCart,
    updateCart,
    deleteCart,
    freezeCart,
    isCartLoading,
    setIsCartLoading,
    selectedItemsModel,
    setSelectedItemsModel,
    src1Categories,
    promoProgress,
    setPromoProgress,
    setCurrentCartModel,
  ]);

  return (
    <PromoCartContext.Provider value={contextValue}>
      {children}
    </PromoCartContext.Provider>
  );
};

PromoCartProvider.propTypes = {
  children: node.isRequired,
  hasUserInteracted: bool.isRequired,
  isDrawerOpen: bool.isRequired,
  setHasUserInteracted: func.isRequired,
  type: oneOf(['card', 'pod']).isRequired,
  maAvailability: shape({
    isAvailable: bool.isRequired,
    isLoading: bool.isRequired,
    applianceDeliveryStore: string,
  }),
};

PromoCartProvider.defaultProps = {
  maAvailability: {
    isAvailable: false,
    isLoading: false,
    applianceDeliveryStore: null,
  },
};

PromoCartProvider.dataModel = PromoCartProviderDataModel;
