import React from 'react';
import { getImageURL } from './CardUtils';

/*
  Usage:
  const SvgIcon = getImageURL('logo-Pro-Xtra-grey.svg');
  <SvgComponent url={SvgIcon} height="56px" width="56px" />
*/
export const SvgComponent = (props) => {
  const { url, height, width, FallbackComponent } = props;
  const svgContainerRef = React.useRef(null);
  const [error, setError] = React.useState(false);
  const DefaultPerkIcon = getImageURL('iconSuiteToggle__ProXtraPerks.svg');

  React.useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(`${url}`);
        const text = (await response?.text()) ?? '<svg />';
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(text, 'image/svg+xml');
        const svgElement = svgDocument?.documentElement;

        if (svgElement) {
          // Ensure the viewBox is set correctly
          const svgWidth = svgElement.getAttribute('width') || '100';
          const svgHeight = svgElement.getAttribute('height') || '100';
          svgElement.removeAttribute('width');
          svgElement.removeAttribute('height');

          if (!svgElement.getAttribute('viewBox')) {
            svgElement.setAttribute('viewBox', `0 0 ${svgWidth} ${svgHeight}`);
          }

          // Apply styles directly to the SVG element
          svgElement.style.width = '100%';
          svgElement.style.height = '100%';
        }

        if (svgContainerRef.current) {
          svgContainerRef.current.innerHTML = ''; // Clear any previous SVG content
          svgContainerRef.current.appendChild(svgElement);
        }
      } catch (err) {
        console.error('Error fetching the SVG:', err);
        setError(true);
      }
    };

    fetchSvg();
  }, [url]);

  if (error) {
    return FallbackComponent ? (
      <FallbackComponent />
    ) : (
      <img
        src={DefaultPerkIcon}
        alt="Fallback-Icon"
        height={height}
        width={width}
      />
    );
  }

  return (
    <div
      ref={svgContainerRef}
      data-testid="svg-container"
      className="sui-inline-block"
      style={{ height, width, display: 'inline-block', overflow: 'hidden' }}
    />
  );
};

export const PxccMultiplierIcon = (props) => {
  const { activePxccFactor, isBorderless } = props || {};
  const borderWidth = isBorderless ? '' : 'sui-border-2';
  return (
    <div className="sui-inline-block sui-relative" data-testid="PxccMultiplier">
      <div
        className={`sui-text-base sui-text-inverse sui-font-w-bold sui-font-display
        sui-rounded-full sui-border-solid sui-border-white ${borderWidth}
        sui-flex sui-items-center sui-justify-center sui-bg-brand sui-leading-none`}
        style={{ padding: '2.8px 2.8px 2px 2px', height: '32px', width: '32px' }}
      >
        {activePxccFactor || 1}X
      </div>
    </div>
  );
};
