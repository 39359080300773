import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const ExchangePricingBadge = (props) => {
  const { padLeft, align } = props;
  const exchangePricingWrapperClasses = !padLeft && align === 'end'
    ? 'sui-flex sui-justify-end sui-mb-1' : 'sui-mb-1';

  return (
    <div className={exchangePricingWrapperClasses}>
      <Typography
        variant={!padLeft ? 'h6' : 'h5'}
        weight="display"
        data-testid="exchange-pricing-badge"
      >
        EXCHANGE PRICING
      </Typography>
    </div>
  );
};

ExchangePricingBadge.propTypes = {
  padLeft: PropTypes.bool,
  align: PropTypes.string,
};

ExchangePricingBadge.defaultProps = {
  padLeft: false,
  align: 'start'
};