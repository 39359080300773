import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { TransitionFade } from '../private/components/fade/TransitionFade';
import { ClickAwayListener } from '../private/components/clickaway/ClickAwayListener';
import { Paper } from '../private/components/paper/Paper';
import { Close } from '../private/icons/Close';
import { IconButton } from '../button/IconButton';
import { Typography } from '../typography/Typography';
import { FloatingDialog } from '../private/components/floating-dialog/FloatingDialog';
import { ModalFocus } from '../modal/ModalFocus';
import useForkRef from '../private/hooks/useForkRef';
import HTMLElementType from '../private/utils/HTMLElementType';

const getArrowStyle = (placement, arrowData) => {
  const { x: arrowX, y: arrowY } = arrowData || {};
  const mainPlacement = placement.split('-')[0];
  const staticSide = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' }[mainPlacement];
  // Avoid shadow overlapping by cutting off the excess
  // Coordinates are based on a 8px squared arrow
  const clipPath = {
    bottom: 'path(\'M18-10-10 18A1 1 0 0118-10\')',
    top: 'path(\'M18-10-10 18A1 1 0 0018-10\')',
    left: 'path(\'M18 18-10-10A1 1 0 0118 18\')',
    right: 'path(\'M18 18-10-10A1 1 0 0018 18\')',
  }[mainPlacement];
  return {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [staticSide]: '-4px',
    clipPath
  };
};

const offsetSizes = {
  bottom: {
    tight: 'sui-translate-y-0',
    snug: 'sui-translate-y-1',
    normal: 'sui-translate-y-2',
    relaxed: 'sui-translate-y-3',
    loose: 'sui-translate-y-4'
  },
  top: {
    tight: 'sui--translate-y-0',
    snug: 'sui--translate-y-1',
    normal: 'sui--translate-y-2',
    relaxed: 'sui--translate-y-3',
    loose: 'sui--translate-y-4'
  },
  right: {
    tight: 'sui-translate-x-0',
    snug: 'sui-translate-x-1',
    normal: 'sui-translate-x-2',
    relaxed: 'sui-translate-x-3',
    loose: 'sui-translate-x-4'
  },
  left: {
    tight: 'sui--translate-x-0',
    snug: 'sui--translate-x-1',
    normal: 'sui--translate-x-2',
    relaxed: 'sui--translate-x-3',
    loose: 'sui--translate-x-4'
  }
};

const smallPopoverStyles = {
  transform: 'none',
  top: 'unset',
  right: 'unset',
  position: 'fixed',
  width: '100%',
  bottom: 0,
  left: 0
};

/**
 * `Popover` is a floating container with a small to medium amount of clarifying information.
 *
 * Related components: [Tooltip](#tooltip)
 *
 * Usage:
 *
 * ```jsx
 * import { Popover } from '@one-thd/sui-atomic-components';
 * ```
 */
const Popover = React.forwardRef((props, ref) => {

  const {
    anchorEl,
    children,
    disableEnforceFocus = false,
    disablePortal = false,
    offset = 'normal',
    open,
    onClose,
    PaperProps,
    placement = 'bottom',
    title,
    ...other
  } = props;

  const handleRef = useRef(null);
  const arrowRef = useRef(null);

  const matches = useBreakpoint('sm');
  const isSmall = matches.lesser;
  const [arrowPos, setArrowPos] = useState();
  const [isPositioned, setIsPositioned] = useState(false);
  const sanitizedPlacement = placement ? placement.split('-')[0] : 'bottom';
  const getOffsetClasses = (usedOffset, usedPlacement) => {
    return matches.lesser ? undefined : offsetSizes[usedPlacement]?.[usedOffset];
  };
  const [offsetClasses, setOffsetClasses] = useState(getOffsetClasses(offset, sanitizedPlacement));
  const popoverRef = useForkRef(handleRef, ref);

  const classes = classNames('sui-bg-primary sui-text-primary sui-rounded-base sui-outline-none sui-flex sui-text-left sui-flex-col sui-gap-2 sui-p-3 sui-z-50', {
    'sui-max-w-[400px] sui-min-w-[228px]': !isSmall,
    'sui-max-w-full sui-w-full sui-max-h-1/2': isSmall,
  }, offsetClasses);

  const handleOnClose = (event) => {
    if (onClose) {
      onClose(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') onClose(event);
  };

  const handleArrow = (event, usedPlacement) => {
    const arrowStyle = getArrowStyle(usedPlacement, event);
    setOffsetClasses(getOffsetClasses(offset, usedPlacement));
    setArrowPos(arrowStyle);
  };

  const handlePositionSet = (value) => {
    setIsPositioned(value);
  };

  return (
    <FloatingDialog
      anchorEl={anchorEl}
      arrow={arrowRef}
      className="sui-z-tooltip"
      disablePortal={disablePortal}
      floatingUIOptions={isSmall ? { middleware: [] } : undefined}
      floatingStyles={isSmall ? smallPopoverStyles : undefined}
      onArrow={handleArrow}
      onKeyDown={handleKeyDown}
      onPositionSet={handlePositionSet}
      open={open}
      placement={placement}
      ref={popoverRef}
      transition
      {...other}
    >
      {({ TransitionProps }) => (
        <TransitionFade {...TransitionProps}>
          <ModalFocus
            open={!disableEnforceFocus && open && isPositioned}
          >
            <Paper className={classes} shadow="sm" {...PaperProps}>
              <ClickAwayListener onClickAway={handleOnClose}>
                <div className="sui-flex-auto sui-text-left">
                  {title ? (
                    <div className="sui-flex sui-relative sui-gap-4">
                      <span className="sui-grow">
                        <Typography variant="body-base" weight="bold">{title}</Typography>
                      </span>
                      <span>
                        <IconButton
                          aria-label="close"
                          onClick={handleOnClose}
                          icon={Close}
                        />
                      </span>
                    </div>
                  ) : null}
                  {children}
                </div>
              </ClickAwayListener>
              {!isSmall ? (
                <div
                  id="arrow"
                  ref={arrowRef}
                  className="sui-absolute sui-w-2 sui-h-2 sui-rotate-45 sui-bg-primary sui-shadow-sm"
                  style={{ ...arrowPos }}
                />
              ) : null}
            </Paper>
          </ModalFocus>
        </TransitionFade>
      )}
    </FloatingDialog>
  );
});

Popover.propTypes = {
  /**
   * An HTML element used to set the position of the Popover.
   */
  anchorEl: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
  /**
   * The Popover content
   */
  children: PropTypes.node,
  /**
   * If set to `true`, focus will not be prevented from leaving the Popover when it is open.
   * For most use cases, this should remain false as setting it otherwise will make the component less accesible for
   * assistive technologies.
   * @default false
   */
  disableEnforceFocus: PropTypes.bool,
  /**
   * The `children` will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: PropTypes.bool,
  /**
   * Specify how far the Popover should be from the bounding box of the trigger object
   */
  offset: PropTypes.oneOf([
    'tight',
    'snug',
    'normal',
    'relaxed',
    'loose',
  ]),
  /**
   * If `true`, the Popover is visible
   */
  open: PropTypes.bool.isRequired,
  /**
   * An event handler to be executed once the Popover is closed.
   */
  onClose: PropTypes.func,
  /**
   * @ignore
   */
  PaperProps: PropTypes.object,
  /**
   * The prefered placement of the Popover component relative to the trigger object.
   * Note that the final placement could be different depending on available space and trigger location.
   */
  placement: PropTypes.oneOf([
    'bottom',
    'bottom-start',
    'bottom-end',
    'top',
    'top-start',
    'top-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end'
  ]),
  /**
   * The Popover title
   */
  title: PropTypes.string,
};

export { Popover };
