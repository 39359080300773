import { shape, bool, string, number } from '@thd-nucleus/data-sources';

const proXtraSpend = shape({
  currentYearSpend: string(),
  currentYearLastUpdatedDate: string(),
  programTypCd: string(),
  isRebateProgram: bool(),
  rebateAmount: string(),
  previousYearSpend: string(),
  previousYearUpdatedDate: string(),
  currentYearSavings: string(),
  previousYearSavings: string(),
  totalCurrentYearSpend: string(),
  totalCurrentYearSavings: string(),
  totalPreviousYearSpend: string(),
  totalPreviousYearSavings: string(),
});

const proTiers = shape({
  currentTierName: string(),
  benefitTags: shape({
    tagName: string(),
    value: string(),
  }),
});

const perkSummary = shape({
  unclaimedPerksCount: number(),
  activePerksCount: bool(),
  availableActiveOffers: bool(),
  activeSurpriseDelightCount: number(),
  currentYearPerksProgramSpend: string(),
  perksProgramId: string(),
  amtReqToNextLevel: string(),
  perkCurrentTierProgressPercentage: string(),
  activePxccFactor: number(),
  pxccUserCategory: string(),
  accrualData: shape({
    year: number(),
    currentYear: bool(),
    accrual: number(),
    acceleratedAccrual: number(),
    pxccAccrual: number(),
    pxccAcceleratedAccrual: number(),
    nonPxccAccrual: number(),
    spendForNextPerk: number(),
    spendForNextPerkWithPxcc: number(),
    spendForFutureTierPerk: number(),
    spendForFutureTierPerkWithPxcc: number(),
    finalTierPerkAchieved: bool(),
  }),
});
const currentYearMemberships = shape({
  currentYearMembershipInfo: shape({
    programName: string(),
    currentLevelName: string(),
    currentYearSpend: number(),
    programId: number(),
  }),
});

const userProfile = shape({
  name: shape({
    firstName: string(),
    lastName: string(),
    nickName: string(),
  }),
  emailId: string(),
  userType: string(),
  emails: shape({
    emailAddress: string(),
    primaryFlag: string(),
    secondaryFlag: string(),
    emailLastUpdate: string(),
    preferenceType: string(),
  }),
  role: string(),
  localStoreId: string(),
  shipToAddress: shape({
    postalDetails: shape({
      addressLine1: string(),
      city: string(),
      state: string(),
      zipCode: string(),
    }),
  }),
});

const customerInfo = shape({
  companyName: string(),
  accountNumber: string(),
  memberSince: string(),
  managedAccount: bool(),
  nationalAccount: bool(),
  segment: string(),
  enrolledInPerksProgram: bool(),
  enrolledInPreferredPricing: bool(),
  preferredPricingTierZero: bool(),
  preferredPricingTierZeroMaxThreshold: string(),
  hierarchyOrgId: string(),
  isRecentlyEnrolledInPerksProgram: string(),
  isRecentlyEnrolledInPrefPricing: string(),
  isChildAccount: bool(),
  nationalAccountDetails: shape({
    agreementCodes: shape({
      agreementName: string(),
      agreementType: string(),
      abbreviatedName: string(),
      rebatePeriod: string(),
      einRequired: bool(),
    }),
  }),
  displayOldPlumbingExperience: bool(),
  preferredPricingPgmsUnlocked: number(),
  enrolledInMilitaryProgram: bool(),
  isMilitaryDiscountCapped: bool(),
  paintMembershipLevel: string(),
  paintSpend: string(),
});

export const homepageWelcomeCardModel = {
  proXtraSpend,
  proTiers,
  perkSummary,
  currentYearMemberships,
  userProfile,
  customerInfo,
};
