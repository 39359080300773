import React, {
  useContext, useEffect, useReducer, useRef, useState
} from 'react';
import { Button } from '@thd-olt-component-react/button';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Overlay } from '@thd-olt-component-react/overlay';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Col, Heading, Image, Row } from '@thd-olt-component-react/core-ui';
import { PresentationProvider } from '../context/PresentationProvider';
import { OverviewMobile } from './Overview/OverviewMobile';
import { CustomerImageCarousel } from './CustomerImageCarousel/CustomerImageCarousel.component';
import { ReviewSentiments } from './Filter/components/ReviewSentiments/ReviewSentiments.component';
import { Review } from './Review/Review.component';
import {
  RatingsAndReviewsDefaultProps,
  RatingsAndReviewsPropTypes
} from './RatingsAndReviewsPropTypes';
import { dataModel } from './ReviewDataModel';
import { initialState } from '../constants';
import { stateReducer } from '../reducer';
import { useProductReviews } from './useProductReviews';
import { getProductReviews, getReviewStatistics, getSeoLink, getUpdatedTotalPage } from '../helpers';
import { RatingsAndReviewsDesktop } from './RatingsAndReviewsDesktop';
import { ReviewResults } from './ReviewResults/ReviewResults';
import './Mobile/MobileOverlay.scss';

export const RatingsAndReviewsCondensed = ({
  apiKey = '', itemId, prosAndCons, seoPageNumber, category = 'product', seoStoreLink
}) => {
  const { channel } = useContext(ExperienceContext);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const { data: productData, loading: productLoading } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: category === 'store' || !itemId
  });
  const { product } = productData || {};
  const { identifiers, media } = product || {};
  const { brandName, productLabel } = identifiers || {};
  const [image] = media?.images || [];
  const imageUrl = image?.url?.replace('<SIZE>', '100');
  const persistedStatistics = useRef({});
  const initialReviews = useRef({});
  const persistedPageContext = useRef();
  const carouselRef = useRef();

  useEffect(() => {
    dispatch({ type: 'sort', value: 'photoreview' });
    dispatch({ type: 'pageLoad', value: seoPageNumber });
  }, [seoPageNumber]);

  const {
    averageRating, loading, reviewModels = [], statistics, totalReviews
  } = useProductReviews({ itemId, seoPageNumber, ...state });
  useEffect(() => {
    if (!persistedPageContext.current) {
      persistedPageContext.current = state.pageContext;
      return;
    }
    if (persistedPageContext.current.currentPage !== state.pageContext.currentPage) {
      persistedPageContext.current = state.pageContext;
      if (carouselRef.current) {
        carouselRef.current.scrollIntoView({
          // do not use smooth. It'll cause the page to scroll to the bottom while it updates the results.
          behavior: 'auto'
        });
      }
    }
  }, [state.pageContext]);

  const { filters, searchText, selectedSentiment } = state;
  const hasProductData = averageRating || totalReviews;
  if (loading && !persistedStatistics.current[itemId]) {
    return null;
  }
  const selected = selectedSentiment ? [selectedSentiment] : filters.filter((rf) => rf.checked);
  let productReviews = getProductReviews({
    reviewModels, initialReviews, itemId, searchText, selected
  });
  const noFallbacks = !(hasProductData || productReviews || persistedStatistics.current[itemId]);
  if (noFallbacks) {
    return null;
  }

  const seoLink = getSeoLink(category,
    productData?.product?.identifiers?.canonicalUrl,
    seoStoreLink
  );

  const updatedTotalpage = getUpdatedTotalPage(selectedSentiment, statistics?.totalPages);
  if (state.pageContext.totalPages !== updatedTotalpage) {
    dispatch({ type: 'totalPages', value: updatedTotalpage });
  }
  if (persistedStatistics.current && !persistedStatistics.current[itemId]) {
    persistedStatistics.current[itemId] = statistics;
    initialReviews.current[itemId] = reviewModels;
  }
  const reviewStatistics = getReviewStatistics(persistedStatistics, statistics, itemId);

  const firstThreeReviews = reviewModels.slice(0, 3).filter((review) => !!review);

  const onSeeAllClick = () => {
    setShowAllReviews(true);
  };

  const onSentimentChange = () => { };

  const closeAllReviews = (evt) => {
    if (!evt) {
      setShowAllReviews(false);
    }
  };

  return (
    <div data-component="RatingsAndReviewsCondensed">
      <PresentationProvider useCondensedLayout itemId={itemId}>
        <Heading className="ratings-reviews__condensed-heading" title="Customer Reviews" />
        <OverviewMobile
          apiKey={apiKey}
          reviewStatistics={reviewStatistics}
          itemId={itemId}
          handleCardClick={onSeeAllClick}
          hideSecondaryRatings
          category={category}
          seoStoreLink={seoStoreLink}
        />
        {category === 'product' && (<CustomerImageCarousel itemId={itemId} productLabel={productLabel} />)}
        {category === 'product' && prosAndCons
          && (
            <ReviewSentiments
              itemId={itemId}
              selectedSentiment={selectedSentiment}
              onSentimentChange={onSentimentChange}
            />
          )}
        <div className="ratings-and-reviews-mobile__feature-heading">
          Featured Reviews
        </div>
        {firstThreeReviews.map((review, reviewIndex) => {
          return (
            <Review
              review={review}
              id={reviewIndex}
              key={`review-${reviewIndex}`}
              apiKey={apiKey}
              shortenReviews
            />
          );
        })}
        <Button onClick={() => onSeeAllClick()}>
          <a className="seo_link" onClick={(evt) => evt.preventDefault()} href={`${seoLink}`}>
            See All Reviews
          </a>
        </Button>
      </PresentationProvider>
      {showAllReviews && (
        <Overlay onClose={closeAllReviews} open={showAllReviews} closeButton>
          {channel === 'desktop' && <RatingsAndReviewsDesktop itemId={itemId} productLabel={productLabel} />}
          {channel === 'mobile' && (
            <>
              <Row className="rr-mobile-overlay">
                <Heading className="ratings-reviews__condensed-heading" title="Customer Reviews" />
                <Row>
                  <Col fallback={4}>
                    <div className="rr-mobile-overlay__product-image">
                      <Image
                        src={imageUrl}
                        alt={productLabel}
                        lazy
                        width={100}
                        height={100}
                      />
                    </div>
                  </Col>
                  <Col fallback={8}>
                    <div className="rr-mobile-overlay__product-brand">
                      {brandName}
                    </div>
                    <div className="rr-mobile-overlay__product-label">
                      {productLabel}
                    </div>
                  </Col>
                </Row>
              </Row>
              <OverviewMobile
                apiKey={apiKey}
                reviewStatistics={reviewStatistics}
                itemId={itemId}
                isMobileOverlay
                hideSecondaryRatings
                category={category}
              />
              <CustomerImageCarousel itemId={itemId} productLabel={productLabel} />
              <ReviewResults itemId={itemId} condensed />
            </>
          )}
        </Overlay>
      )}
    </div>
  );
};

RatingsAndReviewsCondensed.displayName = 'RatingsAndReviewsCondensed';
RatingsAndReviewsCondensed.propTypes = RatingsAndReviewsPropTypes;
RatingsAndReviewsCondensed.defaultProps = RatingsAndReviewsDefaultProps;
RatingsAndReviewsCondensed.dataModel = dataModel;
