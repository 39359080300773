import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../helpers/price-helper';
import { Typography, List, ListItem } from '@one-thd/sui-atomic-components';
import { CustomTooltip } from './subcomponents/common/CustomTooltip';

const InstantRebates = ({ storePromotion, price, hasDetails }) => {
  const rebatePrice = helpers.toFixed(price.dollarOff, 2);
  const wasPrice = helpers.toFixed(price.wasPrice, 2);
  const nowPrice = helpers.toFixed(price.nowPrice, 2);
  const percentageOff = helpers.toFixed(price.percentageOff);

  const getDetails = () => {
    return (
      <div className="sui-p-4 sui-w-full sui-max-w-[300px]">
        <Typography color="subtle">
          <div className="sui-flex sui-justify-between sui-items-center">
            <div className="sui-text-subtle">Everyday Low Price</div>
            <div className="sui-font-bold">${wasPrice}</div>
          </div>
          <div className="sui-flex sui-justify-between sui-items-center sui-italic">
            <div className="sui-text-success sui-truncate sui-pl-4">{storePromotion.longDescription} Instant Rebate*</div>
            <div className="sui-font-bold">-${rebatePrice}</div>
          </div>
          <hr />
          <div className="sui-flex sui-justify-between sui-items-center">
            <div className="sui-text-success sui-font-bold">Save ${rebatePrice} ({percentageOff}%)</div>
            <div className="sui-font-bold sui-text-primary sui-text-lg">${nowPrice}</div>
          </div>
          <hr />
          <div className="sui-font-bold sui-text-primary">${rebatePrice} Instant Rebate</div>
          <List>
            <ListItem>1. Rebate amount applied instantly and reflected in price displayed</ListItem>
            <ListItem>2. Rebate availability is based on location</ListItem>
            <ListItem>3. Cannot be combined with another offer</ListItem>
          </List>
          <div className="sui-text-xs sui-text-subtle">
            * Online rebate may be provided by Home Depot
          </div>
        </Typography>
      </div>
    );
  };

  const handleOnClick = () => {
    helpers.triggerAnalytics('instant-rebate');
  };

  return (
    <Typography component="span">
      <div className="sui-flex sui-items-center">
        <span className={'sui-text-success sui-pr-2' + (!hasDetails ? ' sui-text-sm' : '')}>
          After ${rebatePrice} Instant Rebate
        </span>
        {hasDetails && (
          <CustomTooltip
            title="Price Details"
            pricingMessage={
              <Typography component="span" onClick={handleOnClick} data-testid="instant-rebates-details">
                <span className="sui-underline sui-cursor-pointer">Details</span>
              </Typography>
            }>{getDetails()}
          </CustomTooltip>
        )}
      </div>
      {hasDetails && (
        <div className="sui-text-xs" data-testid="instant-rebates-store-rebate">
          No mail-in necessary. Store rebate provided by {storePromotion.longDescription}
        </div>
      )}
    </Typography>
  );
}

InstantRebates.propTypes = {
  storePromotion: PropTypes.shape({
    longDescription: PropTypes.string,
    shortDescription: PropTypes.string
  }),
  price: PropTypes.shape({
    itemId: PropTypes.string,
    wasPrice: PropTypes.number,
    dollarOff: PropTypes.number,
    percentageOff: PropTypes.number,
    nowPrice: PropTypes.number
  }),
  hasDetails: PropTypes.bool
};

InstantRebates.defaultProps = {
  storePromotion: {},
  price: {},
  hasDetails: false
};

export default InstantRebates;
