import { handleUpdateCall, useCartMutation } from './helpers/mutation-helper';
import { getExchangeCartId, getIsBrandPricingPolicyCompliant } from '../util/utils';

export const useAddToCart = () => {
  const [addToCartMutation, atcResponse] = useCartMutation('addToCart');

  // mutation wrapper
  const addToCart = ({ cartRequest, onComplete }) => {
    const variables = {
      cartRequest,
      requestContext: {
        ...(getExchangeCartId() && { cartId: getExchangeCartId() }),
        isBrandPricingPolicyCompliant: getIsBrandPricingPolicyCompliant()
      }
    };
    // returns promise
    return handleUpdateCall({
      mutationName: 'addToCart',
      mutator: addToCartMutation,
      variables,
      onComplete
    });

  };

  return [addToCart, atcResponse];
};
