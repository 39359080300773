import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardMedia } from '@one-thd/sui-atomic-components';
import { InfoFilled } from '@one-thd/sui-icons';
import { LAYOUT_TYPES } from '../../utils/utils';
import { CustomTooltip } from './subcomponents/common/CustomTooltip';

const getPreferredPricingDetails = () => {
  return (
    <div>
      <span>
        {'As a Pro Xtra member, you are qualified for\xA0'}
        <a className="u__default-link" href="https://www.homedepot.com/c/pro_preferred_pricing">Preferred Pricing</a>.
      </span>
    </div>
  );
}

export const PreferredPricingBadge = ({
  channel,
  type,
  align,
  showPreferredPricingBadgeToolTip,
  isListView
}) => {

  const isMobile = channel === 'mobile';
  let flexClasses = 'sui-flex sui-items-center sui-content-center sui-mb-1';
  if (align === 'end' || (isListView && isMobile)) {
    flexClasses = 'sui-flex sui-justify-end';
  }
  const badgeSize = type === LAYOUT_TYPES.DETAILED ? '24px' : '16px';
  const typeVariant = type === LAYOUT_TYPES.DETAILED ? 'h5' : 'h6';

  return (
    <div className={flexClasses}>
      <div className='sui-mr-2'>
        <CardMedia
          src="https://assets.thdstatic.com/images/v1/preferred-pricing-icon.svg"
          style={{ width: `${badgeSize}`, height: `${badgeSize}` }}
          alt="Preferred Pricing Badge"
          title="Preferred Pricing" />
      </div>
      <Typography variant={typeVariant} weight="display">
        PREFERRED PRICING
      </Typography>
      {showPreferredPricingBadgeToolTip && !isListView && (
        <div className='sui-ml-2'>
          <CustomTooltip
            PricingIcon={InfoFilled}
            color="info-strong"
          >{getPreferredPricingDetails()}
          </CustomTooltip>
        </div>
      )}
    </div>
  );
};

PreferredPricingBadge.propTypes = {
  channel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  align: PropTypes.string,
  showPreferredPricingBadgeToolTip: PropTypes.bool
};

PreferredPricingBadge.defaultProps = {
  align: 'start',
  showPreferredPricingBadgeToolTip: false
};
