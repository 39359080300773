/* eslint-disable max-len */
export default {
  all: {
    addToCartButton: {
      one: 'Add Item to Cart',
      two: 'Add Both to Cart',
      many: 'Add Items to Cart',
      other: 'Add to Cart'
    },
    cardCtaExpDate: 'Ends {{expMonth}} {{expDay}}',
    cardCtaLoadingText: 'Searching the aisles to find deals for you.',
    cardCtaShopOfferButton: 'Shop This Offer',
    srcProducts: {
      one: '({{src1ListCount}} item)',
      other: '({{src1ListCount}} items)'
    },
    tgtProducts: {
      one: '({{tgt1ListCount}} item)',
      other: '({{tgt1ListCount}} items)'
    },
    podCtaExpDate: 'Ends {{expMonth}} {{expDay}}',
    podCtaShopThisOffer: 'Shop This Offer',
    podCtaShopAllEligible: 'Shop All Eligible',
    promoSummaryButtonTooltip: 'View Details',
    promoSummarySubtitle: 'Promotion Details',
    productCategoryLimits: 'Offer may limit discount to a maximum number of items per product category. Item limits may vary by product category. Offer discount applied at checkout.',
    quickviewOverviewButton: 'Product Overview',
    quickviewSpecsButton: 'Specs',
    quickviewReviewsButton: 'Reviews',
    removeFromCartButton: 'Remove',
    seeFullDetailsButton: 'See Full Details',
    selectButton: 'Select',
    selectedItemInCartMessage: 'Selected item is in Cart',
    selectThisInsteadButton: 'Select This Instead',
    shopAllButton: {
      one: 'Shop All Eligible',
      other: 'Shop All Eligible Items'
    },
    shopAllButtonCategory: 'Shop All {{categoriesPromo}}',
    soldOutButton: 'Sold Out',
    unselectButton: 'Unselect Item',
    viewCartButton: 'View Cart',
    warningDrawerContinueWithoutButton: 'Continue without Products',
    warningDrawerPrimaryMessage: 'Don\'t Forget!',
    warningDrawerSecondaryMessage: 'You still need to add your items to cart'
  },
  buyOneGetOne: {
    podCtaPrimaryMessage: 'Free Gift With Purchase',
    cardCtaHeaderText: 'Buy One, Get One Free',
    cardCtaProductImageListText: {
      one: 'Select your free product ({{src1ListCount}} item)',
      other: 'Select your free product ({{src1ListCount}} items)'
    },
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: 'FREE',
    bogoRewardImagePlaceholderInnerText: 'FREE',
    title: 'Free Gift With Purchase',
    srcListSubtitle: 'Select your free product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetPercentageOff: {
    podCtaPrimaryMessage: 'Buy One, Get One {{rewardAmount}} Off',
    cardCtaHeaderText: 'Buy One, Get One {{rewardAmount}} Off',
    cardCtaProductImageListText: {
      one: 'Select your discounted product ({{src1ListCount}} item)',
      other: 'Select your discounted product ({{src1ListCount}} items)'
    },
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy One, Get One {{rewardAmount}} Off',
    srcListSubtitle: 'Select your discounted product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetDollarOff: {
    podCtaPrimaryMessage: 'Buy One, Get One {{rewardAmount}} Off',
    cardCtaHeaderText: 'Buy One, Get One {{rewardAmount}} Off',
    cardCtaProductImageListText: {
      one: 'Select your discounted product ({{src1ListCount}} item)',
      other: 'Select your discounted product ({{src1ListCount}} items)'
    },
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy One, Get One {{rewardAmount}} Off',
    srcListSubtitle: 'Select your discounted product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetY: {
    podCtaPrimaryMessage: 'Free Gifts With Purchase',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: 'FREE',
    bogoRewardImagePlaceholderInnerText: 'FREE',
    title: 'Free Gifts With Purchase',
    srcListFooterText: 'Preview Your Free Gifts',
    srcListSubtitle: 'Select Your Main Item',
    tgtListSubtitle: 'Select Your Free Gifts',
    rewardButtonText: 'Select Free Gifts',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit Free Gifts',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not free until you\'ve selected your main item.',
    podCtaQualifyWarningMessage: 'Don\'t forget your main item!',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: ' These gifts are not free until you\'ve selected your main item.',
        editRewardsText: 'Edit Free Gifts',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'You’re ready to select your free gifts!',
        podCtaQualifyWarningMessage: 'Don\'t forget your main item!',
        podCtaRewardWarningMessage: 'Don\'t forget your free gifts!',
        podCtaWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit Free Gifts',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'Claim your FREE items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyXGetOne: {
    podCtaPrimaryMessage: 'Free Gift With Purchase',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: 'FREE',
    bogoRewardImagePlaceholderInnerText: 'FREE',
    title: 'Free Gift With Purchase',
    srcListSubtitle: 'Select Your Main Items',
    tgtListSubtitle: 'Select Your Free Gift',
    rewardButtonText: 'Select Free Gift',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit Free Gifts',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not free until you\'ve selected your main items.',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your free gift!',
    progressBarStatusText: 'Success! Discount applied in cart.',
    podCtaSuccessMessage: 'Success! Discount applied in cart.',
    srcListFooterText: 'Preview Your Free Gifts',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      initial: {
        srcListFooterText: 'Preview Your Free Gifts'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: ' These items are not free until you\'ve selected your main items.',
        editRewardsText: 'Edit Free Gifts',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'Preview Your Free Gifts',
        podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
        podCtaRewardWarningMessage: 'Don\'t forget your free gift!',
        podCtaAlmostWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit Free Gift',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'You’re ready to select your free gift!',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetYPercentageOff: {
    podCtaPrimaryMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    srcListFooterText: 'Preview Your {{rewardAmount}} Off Items',
    srcListSubtitle: 'Select Your Main Item',
    tgtListSubtitle: 'Select Your {{rewardAmount}} Off Items',
    rewardButtonText: 'Select Discounted Items',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit {{rewardAmount}} Off Items',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not free until you\'ve selected your main item.',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: 'These items are not {{rewardAmount}} off until you\'ve selected your main item.',
        editRewardsText: 'Edit Discounted Items',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'You’re ready to select your {{rewardAmount}} off items!',
        podCtaQualifyWarningMessage: 'Don\'t forget your main item!',
        podCtaRewardWarningMessage: 'Don\'t forget your discounted items!',
        podCtaWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit {{rewardAmount}} Off Items',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'Claim your discounted items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyXGetOnePercentageOff: {
    podCtaPrimaryMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    srcListSubtitle: 'Select Your Main Items',
    tgtListSubtitle: 'Select Your {{rewardAmount}} Off Item',
    rewardButtonText: 'Select Discounted Item',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit {{rewardAmount}} Off Items',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not discounted until you\'ve selected your main items.',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
    progressBarStatusText: 'Success! You\'re Saving {{rewardAmount}}',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      initial: {
        srcListFooterText: 'Preview Your {{rewardAmount}} Off Item'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: 'These items are not {{rewardAmount}} off until you\'ve selected your main item.',
        editRewardsText: 'Edit Discounted Item',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'Preview Your {{rewardAmount}} Off Items',
        podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
        podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
        podCtaWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit {{rewardAmount}} Off Items',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'You’re ready to select your {{rewardAmount}} off item!',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetYDollarOff: {
    podCtaPrimaryMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    srcListFooterText: 'Preview Your {{rewardAmount}} Off Items',
    srcListSubtitle: 'Select Your Main Item',
    tgtListSubtitle: 'Select Your {{rewardAmount}} Off Items',
    rewardButtonText: 'Select Discounted Items',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit Discounted Items',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not free until you\'ve selected your main item.',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: 'These items are not {{rewardAmount}} off until you\'ve selected your main item.',
        editRewardsText: 'Edit Discounted Items',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'You’re ready to select your {{rewardAmount}} off items!',
        podCtaQualifyWarningMessage: 'Don\'t forget your main item!',
        podCtaRewardWarningMessage: 'Don\'t forget your discounted items!',
        podCtaWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit {{rewardAmount}} Off Items',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'Claim your discounted items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyXGetOneDollarOff: {
    podCtaPrimaryMessage: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    bogoRewardImagePlaceholderInnerText: '{{rewardAmount}} Off',
    title: 'Buy {{src1MinPurchaseQuantityWord}}, Get {{tgt1MinPurchaseQuantityWord}} {{rewardAmount}} Off',
    srcListSubtitle: 'Select Your Main Items',
    tgtListSubtitle: 'Select Your {{rewardAmount}} Off Item',
    rewardButtonText: 'Select Discounted Item',
    editQualifyText: 'Go Back to Main Items',
    editRewardsText: 'Edit Discounted Item',
    missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
    missingQualifyDrawer2TextWarning: 'These items are not discounted until you\'ve selected your main items.',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
    state: {
      anchorItemUnfulfillable: {
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.',
        podCtaErrorMessage: 'This promotion is unavailable.'
      },
      initial: {
        srcListFooterText: 'Preview Your {{rewardAmount}} Off Item'
      },
      almost: {
        missingQualifyDrawer1TextWarning: 'You must add a main item to complete the promotion.',
        missingQualifyDrawer2TextWarning: 'These items are not {{rewardAmount}} off until you\'ve selected your main items.',
        editRewardsText: 'Edit Discounted Item',
        editQualifyText: 'Go Back to Main Items',
        srcListFooterText: 'Preview Your {{rewardAmount}} Off Items',
        podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
        podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
        podCtaWarningMessage: 'Don\'t miss out on savings!'
      },
      success: {
        editRewardsText: 'Edit {{rewardAmount}} Off Item',
        progressBarStatusText: 'Success! Discount applied in cart.',
        srcListFooterText: 'You’re ready to select your {{rewardAmount}} off item!',
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetOneBackwards: {
    podCtaPrimaryMessage: 'Get this item for free with a qualifying purchase',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoQualifyingImagePlaceholderInnerText: 'BUY ONE',
    bogoRewardImageBottomText: 'FREE',
    title: 'Free Gift With Purchase',
    srcListSubtitle: 'Select your qualifying product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetPercentageOffBackwards: {
    podCtaPrimaryMessage: 'Get this item {{rewardAmount}} off with a qualifying purchase',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoQualifyingImagePlaceholderInnerText: 'BUY ONE',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    title: '{{rewardAmount}} Off With Purchase',
    srcListSubtitle: 'Select your qualifying product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyOneGetDollarOffBackwards: {
    podCtaPrimaryMessage: 'Get this item {{rewardAmount}} off with a qualifying purchase',
    bogoQualifyingImageBottomText: 'Main Item',
    bogoQualifyingImagePlaceholderInnerText: 'BUY ONE',
    bogoRewardImageBottomText: '{{rewardAmount}} Off',
    title: '{{rewardAmount}} Off With Purchase',
    srcListSubtitle: 'Select your qualifying product',
    drawerSuccessText: 'Success! Discount applied in cart.',
    state: {
      anchorItemUnfulfillable: {
        podCtaSecondaryMessage: 'This Deal Has Sold Out'
      },
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      almost: {
        podCtaWarningMessage: 'Don\'t forget to add both items to cart'
      },
      success: {
        podCtaSuccessMessage: 'Success! Discount applied in cart.'
      }
    }
  },
  buyMinAmountGetOne: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}}, Get Additional Free Items',
    title: 'Spend {{qualifyingAmount}}, Get Additional Free Items',
    srcListSubtitle: 'Select Any Eligible Item',
    srcListFooterText: 'Preview your FREE items',
    progressBarEndLabel: '{{qualifyingAmount}}',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} more to get free additional items',
    tgtListAlertText: 'You must spend {{qualifyingAmount}} on eligible items before unlocking these free items.',
    tgtListSubtitle: 'Preview your FREE items',
    editQualifyText: 'Go Back to Main Items',
    rewardButtonText: 'Select Discounted Items',
    editRewardsText: 'Edit Discounted Items',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
    state: {
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      success: {
        progressBarStatusText: 'Congratulations! You\'ve earned free additional items',
        srcListFooterText: 'You\'re ready to select your FREE items!',
        tgtListAlertText: 'You\'ve unlocked free items. Up to {{maxRewardAmount}} off max savings. Discount applied in cart.',
        tgtListSubtitle: 'Select your FREE items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.',
      }
    }
  },
  buyMinAmountGetDollarOff: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Additional Items',
    title: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Additional Items',
    progressBarEndLabel: '{{qualifyingAmount}}',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} more to get {{rewardAmount}} off additional items',
    srcListSubtitle: 'Select Any Eligible Items',
    srcListFooterText: 'Items Eligible for {{rewardAmount}} Off',
    tgtListAlertText: 'You must spend {{qualifyingAmount}} on eligible items before unlocking {{rewardAmount}} off these items. Up to {{maxRewardAmount}} off max savings.',
    tgtListSubtitle: 'Preview your discounted items',
    editQualifyText: 'Go Back to Main Items',
    rewardButtonText: 'Select Discounted Items',
    editRewardsText: 'Edit Discounted Items',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
    state: {
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      success: {
        progressBarStatusText: 'Congratulations! You\'ve earned {{rewardAmount}} off additional items',
        srcListFooterText: 'You\'re ready to select your discounted items!',
        tgtListAlertText: 'You\'ve unlocked {{rewardAmount}} off these items. Up to {{maxRewardAmount}} off max savings. Discount applied in cart.',
        tgtListSubtitle: 'Select your discounted items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.',
      }
    }
  },
  buyMinAmountGetPercentageOff: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Additional Items',
    title: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off Additional Items',
    progressBarEndLabel: '{{qualifyingAmount}}',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} more to get {{rewardAmount}} off additional items',
    srcListSubtitle: 'Select Any Eligible Item',
    srcListFooterText: 'Items Eligible for {{rewardAmount}} Off',
    tgtListAlertText: 'You must spend {{qualifyingAmount}} on eligible items before unlocking {{rewardAmount}} off these items. Up to {{maxRewardAmount}} off max savings.',
    tgtListSubtitle: 'Preview your discounted items',
    editQualifyText: 'Go Back to Main Items',
    rewardButtonText: 'Select Discounted Items',
    editRewardsText: 'Edit Discounted Items',
    podCtaQualifyWarningMessage: 'Don\'t forget your main items!',
    podCtaRewardWarningMessage: 'Don\'t forget your discounted item!',
    state: {
      src1ListUnfulfillable: {
        srcListSubtitle: 'Sorry, this promotion has run out.'
      },
      success: {
        progressBarStatusText: 'Congratulations! You\'ve earned {{rewardAmount}} off additional items',
        srcListFooterText: 'You\'re ready to select your discounted items!',
        tgtListAlertText: 'You\'ve unlocked {{rewardAmount}} off these items. Up to {{maxRewardAmount}} off max savings. Discount applied in cart.',
        tgtListSubtitle: 'Select your discounted items',
        podCtaSuccessMessage: 'Success! Discount applied in cart.',
      }
    }
  },
  MSB1: {
    podCtaPrimaryMessage: 'Buy {{qualifyingAmount}} or More, Get {{rewardAmount}} Off',
    title: 'Buy {{qualifyingAmount}} or More, Get {{rewardAmount}} Off',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}} of {{qualifyingAmount}} items',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{rewardAmount}} off these items. Discount applied in cart.',
      }
    }
  },
  MSB2: {
    podCtaPrimaryMessage: 'Buy {{qualifyingAmount}} or More, Save {{rewardAmount}}',
    title: 'Buy {{qualifyingAmount}} or More, Save {{rewardAmount}}',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}} of {{qualifyingAmount}} items',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{rewardAmount}} off these items. Discount applied in cart.'
      }
    }
  },
  MSB3: {
    podCtaPrimaryMessage: 'Buy {{qualifyingAmount}} or More, Save {{rewardAmount}} On Each',
    title: 'Buy {{qualifyingAmount}} or More, Save {{rewardAmount}} On Each',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}} of {{qualifyingAmount}} items',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{totalRewardAmount}} off these items. Discount applied in cart.'
      }
    }
  },
  MSB4: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off',
    title: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{rewardAmount}} off these items. Discount applied in cart.'
      }
    }
  },
  MSB5: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off',
    title: 'Spend {{qualifyingAmount}}, Get {{rewardAmount}} Off',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{rewardAmount}} off these items. Discount applied in cart.'
      }
    }
  },
  MSB6: {
    podCtaPrimaryMessage: 'Spend {{qualifyingAmount}} or More, Save {{rewardAmount}} On Each',
    title: 'Spend {{qualifyingAmount}} or More, Save {{rewardAmount}} On Each',
    progressBarEndLabel: '{{rewardAmount}} Off',
    progressBarStartLabel: '{{currentAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} more to qualify',
    progressBarSuccessText: 'Success! Discount applied in cart.',
    srcListSubtitle: 'Select Any Eligible Item',
    state: {
      success: {
        progressBarStatusText: 'You\'ve earned {{totalRewardAmount}} off these items. Discount applied in cart.'
      }
    }
  },
  BMSM1: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Add {{remainingAmount}} more to save {{rewardAmount}}',
        itemLimitTextInfo: 'Item limits may apply per category for discount.',
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}',
        progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
      }
    }
  },
  BMSM2: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Add {{remainingAmount}} more to save {{rewardAmount}}',
        itemLimitTextInfo: 'Item limits may apply per category for discount.',
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM4: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Spend {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Spend {{remainingAmount}} more to save {{rewardAmount}}',
        itemLimitTextInfo: 'Item limits may apply per category for discount.',
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM5: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Spend {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Spend {{remainingAmount}} more to save {{rewardAmount}}',
        itemLimitTextInfo: 'Item limits may apply per category for discount.',
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM7: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: 'Pay {{rewardAmount}}',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{qualifyingAmount}} for {{rewardAmount}}',
    progressBarStatusText: 'Buy {{remainingAmount}} to unlock the next tier',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Buy {{remainingAmount}} more to save {{rewardAmount}}',
        itemLimitTextInfo: 'Item limits may apply per category for discount.',
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM1Categorized: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Add {{remainingAmount}} more to save {{rewardAmount}}'
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}',
        progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
      }
    }
  },
  BMSM2Categorized: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Add {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Add {{remainingAmount}} more to save {{rewardAmount}}'
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM4Categorized: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Spend {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Spend {{remainingAmount}} more to save {{rewardAmount}}'
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM5Categorized: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Spend {{qualifyingAmount}}',
    podCtaTierRewardTemplate: '{{rewardAmount}} Off',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{rewardAmount}}',
    progressBarStatusText: 'Spend {{remainingAmount}} to save {{rewardAmount}}',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Spend {{remainingAmount}} more to save {{rewardAmount}}'
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  },
  BMSM7Categorized: {
    podCtaPrimaryMessage: 'Buy More, Save More',
    podCtaTierQualifyingTemplate: 'Buy {{qualifyingAmount}}',
    podCtaTierRewardTemplate: 'Pay {{rewardAmount}}',
    title: 'Buy More, Save More',
    progressBarEndLabel: '{{qualifyingAmount}} for {{rewardAmount}}',
    progressBarStatusText: 'Buy {{remainingAmount}} to unlock the next tier',
    progressBarSuccessText: 'Success! You\'re saving {{currentMetAmount}}',
    itemLimitTextInfo: 'Item limits may apply per category for discount.',
    progressBarInfoText: 'Item limits may apply per category for discount.',
    state: {
      almost: {
        progressBarStatusText: 'Buy {{remainingAmount}} more to save {{rewardAmount}}'
      },
      success: {
        progressBarStatusText: 'Success! You\'re saving {{rewardAmount}}'
      }
    }
  }
};
