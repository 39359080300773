/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
const commonPIIFields = [
  'name',
  'lname',
  'fname',
  'firstName',
  'lastName',
  'phone',
  'phoneNumber',
  'line1',
  'line2',
  'email',
  'emailId',
  'xref',
  'paypalAccountId',
  'payerId',
  'token',
  'instruction',
  'taxExemptId',
  'savedPaymentId',
  'nickName',
  'cardHolderName',
  'expiryYear',
  'expiryMonth',
  'buyerId',
  'payerId',
  'accountEmailId',
  'rewardUuid',
  'pin',
  'nickName',
  'expiryMonth',
  'expiryYear',
  'cvv',
  'rewardUUID',
  'cardNumber'
];

export const piiFields = [...commonPIIFields];

const obfuscateField = (field, value, method) => {
  if (!value) {
    return value;
  }
  let obfuscatedValue;
  switch (method) {
  case 'full':
    obfuscatedValue = randomString();
    break;
  case 'synonymize':
    obfuscatedValue = simpleSynonymize(field, value);
    break;
  case 'advancedSynonymize':
    obfuscatedValue = advancedSynonymize(value, field);
    break;
  // case 'hash':
  //   obfuscatedValue = twoWayHash(value);
  //   break;
  default:
    obfuscatedValue = value;
  }
  return obfuscatedValue;
};

// Main function to obfuscate PII
export const obfuscatePii = (data, options = { method: 'advancedSynonymize', trainingMode: false }) => {
  try {
    data = JSON.parse(JSON.stringify(data));
    const recursiveObfuscate = (obj) => {
      for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // console.log('>', key);
          recursiveObfuscate(obj[key]);
          // console.log('<', key);
        } else if (piiFields.includes(key)) {
          const originalValue = obj[key];
          obj[key] = obfuscateField(key, obj[key], options.method);
          // console.log(originalValue, '=>', obj[key]);
        }
      }
    };

    recursiveObfuscate(data);
    // console.log(JSON.stringify(data, null, 2));
  } catch (err) {
    console.error(err);
  }
  return data;
};

const advancedSynonymize = (target, field) => {
  const fillValue = 'abcdefghijklmnopqrstuvwxyz'; // For letter replacement
  const numberFill = '1234567890'; // For number replacement
  let fillIndex = 0; // Tracks the current position in fillValue
  let numberIndex = 0; // Tracks the current position in numberFill

  const getNextChar = (char) => {
    if (/[a-zA-Z]/.test(char)) { // If the character is a letter
      let newChar = fillValue[fillIndex % fillValue.length]; // Cycle through the fillValue
      fillIndex++; // Increment fillIndex for next letter

      // Match the case of the original letter
      if (char === char.toUpperCase()) {
        return newChar.toUpperCase();
      }
      return newChar.toLowerCase();

    } if (/[0-9]/.test(char)) { // If the character is a number
      let newNum = numberFill[numberIndex % numberFill.length]; // Cycle through the numberFill
      numberIndex++; // Increment numberIndex for next number
      return newNum;
    }
    // If it's a space or special character, retain it
    return char;

  };

  // Iterate over the target string and replace characters
  return target.split('').map(getNextChar).join('');
};

const randomString = (length = 10) => {
  return Math.random().toString(36).substring(2, 2 + length);
};

const simpleSynonymize = (field, value) => {
  const synonyms = {
    name: 'John Doe',
    email: 'example@example.com',
    phone: '123-456-7890',
    address: '123 Main St, Anytown',
    ssn: '123-45-6789',
    dob: '2000-01-01',
    creditCard: '4111-1111-1111-1111',
    passport: 'X1234567',
    userId: 'user123',
    username: 'johndoe'
  };
  return synonyms[field] || value;
};

const getSampleData = () => {
  return {
    data:
    {
      updateCart:
        {
          cartId: 'HA100136104104',
          itemCount: 1,
          customer:
            {
              userId: '052B28BFFF37932C0U',
              customerId: '042B28AEF8027EBA0S',
              type: 'R',
              emailId: 'alanb2b@yopmail.com',
              __typename: 'Customer'
            }
        },
      addresses:
        [
          {
            firstName: 'Alan',
            lastName: 'Denslow',
            phone: '4691506621',
            hideCVV: true,
            type: 'Shipping',
            isDefault: false,
            phoneId: '1',
            businessAddress: false,
            lastModifiedDate: '2024-10-02T22:07:56.215Z',
            instantCheckoutPrimary: false,
            id: '10000002',
            userId: null,
            name: null,
            line1: '10629 Le Mans Dr',
            line2: null,
            city: 'Dallas',
            zipCode: '75238',
            state: 'TX',
            country: 'US',
            county: null,
            category: null,
            __typename: 'Address'
          }]
    }
  };
};