import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  arrayOf,
  customType,
  fragment,
  params,
  shape,
  string as stringType,
  namedFragment,
  typename,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';

import { EventCountdownTimer as EventCountdownTimerModule } from '../EventCountdownTimer';

const EventCountdownTimer = (props) => {
  const { componentId, componentClass = '' } = props;

  const { data, loading, error } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  if (error) {
    return null;
  }

  if (loading) {
    return (
      <Skeleton disablePadding className="sui-h-full" SkeletonContentProps={{ grow: true }}>
        <SkeletonBlock aspect="wide" />
      </Skeleton>
    );
  }
  const {
    eventMessage, eventLink, endDate, backgroundImage, fontSize: mobileFontSize
  } = data?.component;
  const countdownTimerBackgroundImage = backgroundImage?.damContentSelector?.assetData[0]?.selectedImageUrl
                                          || backgroundImage?.damDownloadedContent?.url;

  return (
    <div>
      <EventCountdownTimerModule
        componentId={componentId}
        eventMessage={eventMessage}
        eventLink={eventLink}
        endDate={endDate}
        backgroundImage={countdownTimerBackgroundImage}
        mobileFontSize={mobileFontSize}
      />
    </div>
  );
};

EventCountdownTimer.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
};

const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shape({
    assetData: arrayOf(
      shape({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shape({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

EventCountdownTimer.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['EventCountdownTimer'])
      .isRequired(),
  }).shape({
    EventCountdownTimer: fragment().shape({
      id: stringType(),
      eventMessage: stringType(),
      eventLink: stringType(),
      fontSize: stringType(),
      theme: stringType(),
      endDate: stringType(),
      backgroundImage: DamMediaFragment
    })
  })
};

EventCountdownTimer.displayName = 'EventCountdownTimer';

export { EventCountdownTimer };
