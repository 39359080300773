export const track = (key, opts) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger(`ratings-reviews.${key}`, opts);
  }
};

export const reportWriteAReviewFormError = (message, code) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('ratings-reviews.form-error', { message, code });
  }
};
