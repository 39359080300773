import React from 'react';
import {
  shape,
  string,
  arrayOf,
  number
} from 'prop-types';
import classNames from 'classnames/bind';
import { useImpression } from '@thd-olt-component-react/impression';
import { CardMedia } from '@one-thd/sui-atomic-components';

import { useSpecialBuy } from '../../context/SpecialBuyProvider';
import styles from './MobileCategoryTab.module.scss';

const cNames = classNames.bind(styles);

const MobileCategoryTab = ({ category, entryId, index }) => {
  const { specialBuyEndpoint } = useSpecialBuy();
  const { categoryId, name, image, itemIds } = category;

  const { ref, clickID } = useImpression({
    data: {
      id: entryId,
      name: 'SpecialBuyBanner',
      component: 'SpecialBuyBanner',
      position: index,
      type: 'product',
      category: categoryId
    },
  });

  return (
    <a
      href={`${specialBuyEndpoint}#${categoryId}`}
      className={cNames('category-tab')}
      ref={ref}
      // eslint-disable-next-line react/no-unknown-property
      clickid={clickID}
    >
      <CardMedia
        className="sui-w-124px sui-max-w-100 sui-min-h-85px"
        src={image.url}
        alt={`${name} product image`}
      />

      <span className={cNames('category-tab__text')}>{name} ({itemIds.length})</span>
    </a>
  );
};

MobileCategoryTab.propTypes = {
  category: shape({
    name: string.isRequired,
    itemIds: arrayOf(string.isRequired).isRequired,
    categoryId: string.isRequired,
    image: shape({
      url: string.isRequired
    }).isRequired
  }).isRequired,
  entryId: string,
  index: number.isRequired,
};

MobileCategoryTab.defaultProps = {
  entryId: '',
};

export { MobileCategoryTab };
