/* eslint-disable no-console */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * The `FormGroup` component can layout the `Checkbox` & `Toggle` components by setting the
 * `orientation` prop.
 *
 * See related components: [SelectionControlLabel](#selectioncontrollabel), [Checkbox](#checkbox),
 * [Toggle](#toggle), [RadioGroup](#radiogroup)
 *
 * Usage:
 *
 * ```jsx
 * import { FormGroup } from '@one-thd/sui-atomic-components';
 * ```
 */
const FormGroup = React.forwardRef((props, ref) => {
  const {
    alignment = 'center',
    children,
    orientation = 'vertical',
    gap = true,
    ...rest
  } = props;

  const isHorizontal = orientation === 'horizontal';
  const isVertical = orientation === 'vertical';
  const classes = classNames('sui-flex sui-flex-wrap', {
    'sui-flex-col': isVertical,
    'sui-flex-row': isHorizontal,
    'sui-gap-y-2': isVertical && gap,
    'sui-gap-x-6': isHorizontal && gap,
  });

  const controlledChildren = (Array.isArray(children) ? children : [children]).map((child, index) => {
    if (!Array.isArray(child) && child.type?.displayName === 'SelectionControlLabel') {
      return React.cloneElement(child, { key: `from-group-children-${index + 1}`, alignment });
    }
    return child;
  });

  return (
    <div
      data-testid="form-group"
      ref={ref}
      {...rest}
      className={classes}
    >
      {controlledChildren}
    </div>
  );
});

FormGroup.displayName = 'FormGroup';

FormGroup.propTypes = {
  /**
   * @ignore
   * Controls the vertical alignment of the label and and radio button.
   */
  alignment: PropTypes.oneOf(['top', 'center']),
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * The orientation of the children
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * If true, sui-gap-y-2 added to vertical or sui-gap-x-6 added to horizontal
   */
  gap: PropTypes.bool
};

export { FormGroup };
