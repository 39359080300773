import { useMemo, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import {
  determineLoadingState, determineErrorState, useDataSourceOpts
} from '../common/utils/ContextUtils';
import { ENDPOINTS } from '../core/Constants';

export const useFetchChallengesInfo = () => {
  const challengesDataRef = useRef({});
  const opts = useDataSourceOpts().opts;
  const challengesOpts = { ...opts };

  const challengesResponse = useDataModel(ENDPOINTS.CHALLENGES_INFO, challengesOpts);
  challengesDataRef.current = challengesResponse?.data && challengesResponse?.data[ENDPOINTS.CHALLENGES_INFO];

  const challengesData = challengesDataRef.current;
  const isLoadingChallenges = determineLoadingState(challengesResponse);
  const refreshChallengeData = () => challengesResponse.refetch();

  return useMemo(() => ({
    isLoadingChallenges,
    challengesData,
    refreshChallengeData
  }), [isLoadingChallenges]);
};

export const useFetchChallengesAccept = () => {

  return useMemo(() => ({

  }), []);
};
