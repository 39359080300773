import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ButtonBase = React.forwardRef((props, ref) => {
  const {
    component: ButtonBaseRoot = 'button',
    children = null,
    onClick = null,
    className = null,
    unstyled = false,
    ...rest
  } = props;

  const styledButtonClasses = classNames('sui-btn-base', className);

  const classes = unstyled ? className : styledButtonClasses;

  const handleOnClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <ButtonBaseRoot
      ref={ref}
      type="button"
      onClick={handleOnClick}
      className={classes}
      {...rest}
    >
      { children }
    </ButtonBaseRoot>
  );
});

ButtonBase.displayName = 'ButtonBase';

ButtonBase.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  unstyled: PropTypes.bool,
  className: PropTypes.string
};

export { ButtonBase };
