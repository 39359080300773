import React from 'react';
import { bool, node, string } from 'prop-types';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames/bind';
import styles from './component-selector.module.scss';

export const ComponentSelectorWrapper = ({
  backgroundColor,
  children,
  isVisible,
  usePlaceholder,
  isInCarousel
}) => {
  const cx = classNames.bind(styles);

  const placeholderStyles = cx({
    'component-selector__wrapper': isVisible,
    'component-selector__hidden': !isVisible,
  });
  const backgroundStyles = cx({
    'component-selector__background': isVisible,
    'component-selector__hidden': !isVisible,
  });
  const wrapperStyles = cx({
    'sui-w-full sui-h-full': isInCarousel
  });

  return (
    <div
      style={{
        position: 'relative',
        ...(!isVisible && { display: 'flex', flexDirection: 'column' })
      }}
      className={wrapperStyles}
      data-component="ComponentSelector"
    >
      {usePlaceholder && <Placeholder className={placeholderStyles} />}
      <div className={backgroundStyles} style={{ backgroundColor }} />
      {children}
    </div>
  );
};

ComponentSelectorWrapper.propTypes = {
  backgroundColor: string,
  children: node.isRequired,
  isVisible: bool,
  usePlaceholder: bool,
  isInCarousel: bool,
};

ComponentSelectorWrapper.defaultProps = {
  backgroundColor: 'white',
  isVisible: false,
  usePlaceholder: true,
  isInCarousel: false,
};
