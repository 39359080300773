import {
  arrayOf as arrayType,
  fragment,
  namedFragment,
  customType,
  bool as boolType,
  params,
  shape as shapeType,
  string as stringType,
  typename
} from '@thd-nucleus/data-sources';

export const categoryDataModel = {
  searchModel: params({ keyword: stringType(), navParam: stringType() }).shape({
    relatedResults: shapeType({
      visualNavs: arrayType(shapeType({
        label: stringType(),
        imageId: stringType(),
        webUrl: stringType(),
        categoryId: stringType(),
        imageURL: stringType()
      })),
      visualNavContainsEvents: boolType()
    }),
    searchReport: shapeType({
      keyword: stringType()
    }),
    dimensions: arrayType(shapeType({
      label: stringType(),
      refinements: arrayType(shapeType({
        url: stringType(),
        refinementKey: stringType()
      }))
    }))
  })
};

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shapeType({
    assetData: arrayType(
      shapeType({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shapeType({
    url: stringType()
  }),
  __typename: typename('DamMedia')
});

export const visNavDataModel = {
  component: params({
    id: stringType()
      .isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['VisualNavigation'])
      .isRequired()
  })
    .shape({
      VisualNavigation: namedFragment({
        inline: true,
        fragmentType: 'VisualNavigation'
      }).shape({
        id: stringType(),
        title: stringType(),
        componentName: stringType(),
        overflowBehavior: stringType(),
        theme: stringType(),
        itemTheme: stringType(),
        visualNavigationList: arrayType(shapeType({
          id: stringType(),
          title: stringType(),
          altText: stringType(),
          description: stringType(),
          link: stringType(),
          previewImage: DamMediaFragment
        }))
      })
    })

};

const simplePromotionalOfferFragment = fragment().shape({
  simpleOfferHeadline: stringType(),
  simpleOfferSubhead: stringType()
});

const dollarOrPercentageOffPromotionalOfferFragment = fragment().shape({
  eyebrow: stringType(),
  shouldShowDollar: boolType(),
  shouldShowOff: boolType(),
  specialOfferHeadline: stringType(),
  specialOfferSubhead: stringType()
});

export const promoVisNavDataModel = {
  component: params({
    id: stringType()
      .isRequired(),
    componentClass: customType('ComponentClass')
      .enum(['PromoVisualNavigation'])
      .isRequired()
  })
    .shape({
      PromoVisualNavigation: namedFragment({
        inline: true,
        fragmentType: 'PromoVisualNavigation'
      }).shape({
        id: stringType(),
        title: stringType(),
        componentName: stringType(),
        overflowBehavior: boolType(),
        theme: stringType(),
        itemTheme: stringType(),
        legalCopy: shapeType({
          legalText: stringType(),
          legalUrl: stringType()
        }),
        sectionCTAButton: shapeType({
          callToAction: stringType(),
          destinationLink: stringType()
        }),
        promoVisualNavigationList: arrayType(shapeType({
          id: stringType(),
          promotionalOffer: shapeType({
            SimplePromotionalOffer: simplePromotionalOfferFragment,
            DollarOrPercentageOffPromotionalOffer: dollarOrPercentageOffPromotionalOfferFragment
          }),
          link: stringType(),
          altText: stringType(),
          previewImage: DamMediaFragment
        })),
        __typename: typename('PromoVisualNavigation')
      })
    })
};