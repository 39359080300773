export const shouldIncludeThdDefaultLightTheme = (theme) => {
  return [
    'EndcapLightGreen',
    'EndcapDarkGreen',
    'EndcapSawdust',
    'EndcapBeige',
    'PrimarySpringGreen',
    'PrimarySpringLightGreen',
    'SecondarySpringDarkGreen',
    'SecondarySpringKhaki',
  ].includes(theme);
};