import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { string, elementType, object, oneOfType } from 'prop-types';
import { WelcomeCardProvider, PacDrawerProvider } from '../contexts';

const AllProviders = ({ children }) => {
  return (
    <QueryProvider cacheKey="loyalty-benefits-cacheKey">
      <WelcomeCardProvider>
        <PacDrawerProvider>
          {children}
        </PacDrawerProvider>
      </WelcomeCardProvider>
    </QueryProvider>
  );
};

const LoyaltyWelcomeCardProviders = ({ children }) => {
  return (
    <QueryProvider cacheKey="loyalty-benefits-cacheKey">
      <WelcomeCardProvider>
        {children}
      </WelcomeCardProvider>
    </QueryProvider>
  );
};

export const ComponentProviders = ({ children, componentName }) => {
  let ChildProviders = <AllProviders>{children}</AllProviders>;
  switch (componentName) {
  case 'LoyaltyWelcomeCard':
    ChildProviders = <LoyaltyWelcomeCardProviders>{children}</LoyaltyWelcomeCardProviders>;
    break;
  default:
    break;
  }
  return ChildProviders;
};

ComponentProviders.propTypes = {
  children: oneOfType([
    elementType,
    object
  ]),
  componentName: string,
};

ComponentProviders.defaultProps = {
  children: AllProviders,
  componentName: '',
};
