import React from 'react';
import { Typography, Button } from '@one-thd/sui-atomic-components';
import { CountdownTimer } from '@thd-olt-component-react/countdown-timer';
import { bool, shape, string, number } from 'prop-types';

const HeaderSection = ({
  badgeUrl = '',
  bannerImage = '',
  channel = '',
  end = '',
  endCapData = null,
  header = '',
  headerLinkText = '',
  headerLinkUrl = '',
  headerWeight = 'display',
  isUppercase = true,
  mobileAppBannerImg = '',
  start = '',
  subHeader = '',
  timer = {
    timeBombThreshold: '',
    daysLeftThreshold: '',
    dateDisplayThreshold: '',
    message: ''
  },
  timerDuration = 0
}) => {

  return (
    <div className="title-wrapper sui-w-full">
      <div
        className="sui-flex sui-flex-col lg:sui-flex-row lg:sui-justify-between"
      >
        <div>
          <Typography variant="h2" weight={headerWeight} uppercase={isUppercase}>{header}</Typography>
          {endCapData?.apiType === 'topDeals' && (
            <Typography variant="body-lg" color="subtle">{subHeader}</Typography>
          )}
        </div>
        {endCapData?.apiType === 'topDeals' && headerLinkText && (
          <div className="sui-mt-2 lg:sui-mt-0">
            <Button variant="primary" href={headerLinkUrl}>{headerLinkText}</Button>
          </div>
        )}
      </div>
      {endCapData?.apiType === 'topDeals' && (
        <div className={'sui-grow ' + (timer === null ? 'sui-hidden' : 'sui-block')}>
          <div className="sui-text-brand sui-text-base sui-leading-loose sui-mb-1">
            <CountdownTimer
              onRemove={() => {}}
              endDate={end}
              shouldShowSecondsRemaining
              showOrangeText
              shouldUseMessagingString
              shouldUseClockDisplay
              shouldUseDateDisplay
              shouldUseDaysLeftDisplay
              shouldIncludeSvg
              showOrangeTimerSvg
              timebombThreshold={parseFloat(timer?.timeBombThreshold)}
              daysLeftThreshold={parseFloat(timer?.daysLeftThreshold)}
              dateDisplayThreshold={parseFloat(timer?.dateDisplayThreshold)}
              customMessagingString={timer?.message}
            />
          </div>
        </div>
      )}
    </div>
  );
};

HeaderSection.propTypes = {
  header: string,
  start: string,
  end: string,
  bannerImage: string,
  headerWeight: string,
  isUppercase: bool,
  mobileAppBannerImg: string,
  headerLinkText: string,
  headerLinkUrl: string,
  timerDuration: number,
  badgeUrl: string,
  channel: string,
  subHeader: string,
  endCapData: shape({}),
  timer: shape({
    timeBombThreshold: string,
    daysLeftThreshold: string,
    dateDisplayThreshold: string,
    message: string
  })
};

export { HeaderSection };
