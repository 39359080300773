/* eslint-disable */
import React from 'react';
import { Typography, ThemeProvider } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import { getThemeByName } from '@thd-olt-component-react/stencil-themes';
import classnames from 'classnames/bind';

export function FallbackSection({ children, id, title, theme }) {
  const customRenderId = children?.[0]?.props?.customRenderId;
  const isB2BVariant = customRenderId === 'B2B Marketing Gallery';

  function shouldDisplaySectionTitle () {
    const isSectionTitleDefined = !!title;
    const componentContainerHasTitle = children.length === 1 &&
      !!children?.[0]?.props?.title;
    return !componentContainerHasTitle && isSectionTitleDefined; 
  }

  const sectionTitle = shouldDisplaySectionTitle() ? (
    <div className="sui-py-4">
      <Typography
        variant="h2"
        height="snug"
        color="primary"
      >
        {title}
      </Typography>
    </div>
  ) : null;

  const gridColumns = isB2BVariant
    ? classnames(
      'sui-grid',
      'sui-gap-5',
      'sui-grid-cols-1',
      'sm:sui-grid-cols-3'
    ) : classnames(
      'sui-grid',
      'sui-gap-5',
      'sui-grid-cols-1',
      'sm:sui-grid-cols-2',
      {
        'md:sui-grid-cols-4': children.length >= 4,
        'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-3':
          children.length === 3,
        'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-2 md:sui-grid-cols-2':
          children.length === 2,
        'sui-grid sui-gap-5 sui-grid-cols-1 sm:sui-grid-cols-1 md:sui-grid-cols-1':
          children.length <= 1,
      }
    );

  return (
    <ThemeProvider theme={getThemeByName(theme)}>
    <div
      id={`section-php-${id}`}
      className="sui-h-full sui-bg-primary sui-text-primary sui-p-4"
    >
      <ImpressionProvider
        data={{
          id,
          name: 'Section',
          component: 'Section',
          type: 'content',
        }}
      >
        <>
          {sectionTitle}
          <div className={classnames(gridColumns)}>
            {children.map(
              (child, index) => <React.Fragment key={index}>{child}</React.Fragment>
            )}
          </div>
        </>
      </ImpressionProvider>
    </div>
    </ThemeProvider>
  );
}