import React from 'react';
import Responsive from 'react-responsive';
import { Typography } from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { NotificationSkeleton } from '../../../../common/StatusUnavailableNotifications/StatusUnavailable';
import { analytics } from '../../../../core/Constants';
import { getHostCURL } from '../../../../common/utils/CardUtils';
import { analyticsStash } from '../../../../common/utils/AnalyticsUtils';
import { WelcomeCardTile } from './WelcomeCardTile';

const handleActiveChallenge = (props) => {
  const { list, activeChallenge, isLoadingChallenges, showSkinnyBarComponents } = props;
  if (activeChallenge) {
    if (isLoadingChallenges) {
      list.push(<NotificationSkeleton />);
    }
    if (!isLoadingChallenges) {
      showSkinnyBarComponents.earnProXtraDollars = true;
      list.push(<ChallengesTabletTile challenge={activeChallenge} />);
    }
  } else {
    list.push(<RegisterCardTile />);
  }
};

const RegisterCardTile = () => {
  return (
    <WelcomeCardTile
      imageURL="WelcomeCard/icon_VID.svg"
      imageClass="notification-offer"
      analyticsMessage={analytics.ANALYTICS_MESSAGE.Register_Tender}
      analyticsLinks={analytics.ANALYTICS_LINKS.REGISTER_CARD}
      refLink="/myaccount/payments"
    >
      <Responsive minWidth={768} maxWidth={1023}>
        <Typography variant="body-base" weight="normal" height="normal">
          Get Easy Purchase Tracking
        </Typography>
      </Responsive>
    </WelcomeCardTile>
  );
};

const ChallengesTabletTile = ({ challenge }) => {
  const challengeName = challenge;
  return (
    <div className="sui-w-full">
      <a
        className="welcome-card-tile link-url sui-flex sui-flex-row sui-justify-between sui-w-full sui-px-0"
        href={getHostCURL('/myaccount/proXtraRewards?drawerList=challenge')}
        rel=" noreferrer"
        onClick={
          () => analytics.ANALYTICS_MESSAGE
          && analyticsStash(
            'welcomeCard', analytics.ANALYTICS_MESSAGE.Offer_Challenge, analytics.ANALYTICS_LINKS.EARN_PROXTRA)
        }
      >
        <div className="sui-flex sui-flex-col sui-justify-center startAlign sui-mx-3 sui-w-full">
          <p
            className="sui-font-regular sui-text-base sui-leading-normal
          sui-tracking-normal sui-normal-case sui-line-clamp-2"
          >
            {challengeName}
          </p>
        </div>
        <div className="sui-flex sui-flex-row sui-justify-end sui-items-center sui-pr-0">
          <ArrowForward size="small" />
        </div>
      </a>
    </div>
  );
};

export const PrioritizeTabletList = (prioritizedList, availablePerksData, isLoadingChallenges) => {
  const list = [...prioritizedList];
  const showSkinnyBarComponents = {};

  // Get tiles and push to 3
  while (list.length < 3) {
    if (!showSkinnyBarComponents.earnProXtraDollars && availablePerksData) {
      const { challengeTitles } = availablePerksData;
      const activeChallenge = challengeTitles?.length > 0 && challengeTitles[0];
      handleActiveChallenge({ list, activeChallenge, isLoadingChallenges, showSkinnyBarComponents });
    } else if (!showSkinnyBarComponents.easyPurchaseTracking) {
      showSkinnyBarComponents.easyPurchaseTracking = true;
      list.push(<RegisterCardTile />);
    }
  }

  return list;
};

export const exportsForTesting = {
  RegisterCardTile,
  ChallengesTabletTile,
};
