// dropZeros removes decimals if price has no cents
// dropZeros:true  = 239 -> $239 || 239.23 -> $239.23
// dropZeros:false = 239 -> $239.00 || 239.23 -> $239.23
export const formatPrice = (price, dropZeros) => {
  try {
    if (price == null || price <= 0) return '';
    if (price < 1 && price !== 0) return `${(price * 100).toFixed()}¢`;
    // format options
    const opts = {
      style: 'currency',
      currency: 'USD'
    };
    if (dropZeros && price % 1 === 0) {
      opts.minimumFractionDigits = 0;
    }
    return new Intl.NumberFormat('en-US', opts).format(price);
  } catch (err) {
    console.log('Error in formatPrice:', err);
  }
  return '';
};

export const formattedReward = ({
  rewardPercent,
  rewardAmountPerOrder,
  rewardFixedPrice
}, dropZeros) => {
  if (rewardPercent) return `${rewardPercent}%`;
  // No savings messaging for rewardFixedPrice scenarios
  if (rewardFixedPrice) return '';
  return formatPrice(rewardAmountPerOrder, dropZeros);
};

export const isTouch = () => (typeof window !== 'undefined' && 'ontouchend' in document);

export const getPreviewImages = ({ allListProductsAndAnchor, channel, itemIds, type }) => {
  const numPreviewImagesCard = itemIds.length > 3 ? 2 : 3;
  const numPreviewImages = channel === 'mobile' ? 3 : 4;
  const Imageslimiter = type === 'card' ? numPreviewImagesCard : numPreviewImages;
  return itemIds.slice(0, Imageslimiter).map((itemId) => ({
    url: allListProductsAndAnchor.get(itemId).media?.image?.url,
    itemId
  }));
};
