/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useRef } from 'react';
import * as Utils from '../utils';
import { AD_INTERACTIONS_KEY, INTERACTION_MONITOR_INTERVAL_MS, PROCESS_INTERACTIONS_DELAY_MS } from '../constants';
import { useRetailMediaCarouselPodTelemetry } from './useRetailMediaCarouselPodTelemetry';

/**
 * Hook to detect "open in new tab" interaction.
 *
 * If an "open in new tab" interaction is detected, a click event is triggered
 * to Analytics, AdServer and NewRelic.
 *
 * This hook covers the 1st and 3rd parts of the "open in new tab" event detection and
 * triggering process:
 *
 *   1. [src_tab]: clicks "open in new tab" and logs the interaction in localStorage
 *   2. [new_tab]: detects the logged interaction and tags it as "seen".
 *   3. [src_tab]: detects the interaction was tagged as "seen" and triggers the click.
 */
export const useClickInteractionListener = ({
  targetNode,
  itemId,
  sponsoredId,
  trackSource,
  slotId,
  position,
  plaLocation
}) => {

  const loggedDataRef = useRef(null);
  const clickTriggeredRef = useRef(false);
  const monitorIntervalIdRef = useRef(null);

  // -----------------
  // Telemetry callbacks
  // -----------------
  const { triggerClickOnNewTab, triggerProductPodV7Click } = useRetailMediaCarouselPodTelemetry({
    itemId,
    trackSource,
    position,
    slotId,
  });

  /**
   * Triggers click event if the target item have been seen in a new tab.
   *
   * The click event is sent to Analytics, AdServer and NewRelic.
   */
  const triggerClickIfSeen = useCallback(() => {
    if (!loggedDataRef.current) return;
    if (clickTriggeredRef.current) return;

    const interactions = Utils.getLocalStorageObject(AD_INTERACTIONS_KEY) || {};

    if (interactions[sponsoredId]?.seenInNewTab) {
      triggerProductPodV7Click(); // analytic & ad-server
      triggerClickOnNewTab(); // new-relic

      clickTriggeredRef.current = true;
      clearInterval(monitorIntervalIdRef.current);
    }
  }, [sponsoredId, triggerProductPodV7Click, triggerClickOnNewTab]);

  /**
   * Monitors the logged interaction.
   *
   * If the logged interaction was tagged as 'seenInNewTab',
   * then a click event is triggered.
   *
   * The monitor runs every INTERACTION_MONITOR_INTERVAL ms.
   */
  const runInteractionMonitor = useCallback(() => {

    const fn = () => triggerClickIfSeen();
    monitorIntervalIdRef.current = setInterval(fn, INTERACTION_MONITOR_INTERVAL_MS);

  }, [triggerClickIfSeen]);

  /**
   * Log a new interaction to the localStorage using the 'sponsoredId' as the key, and
   * right after that, runs a monitor to detect if the interaction was seen in a new tab.
   */
  const logInteraction = useCallback((action, context) => {
    if (loggedDataRef.current) return; // interaction logged already.

    // get stored interactions
    const interactions = Utils.getLocalStorageObject(AD_INTERACTIONS_KEY) || {};

    // set the new interaction's data
    interactions[sponsoredId] = {
      itemId,
      sponsoredId,
      trackSource,
      slotId,
      position,
      action,
      context,
      plaLocation,
      seenInNewTab: false, // This property will be set to true from a new tab.
    };

    // logs the new data in the localStorage
    Utils.setLocalStorageObject(AD_INTERACTIONS_KEY, interactions);
    loggedDataRef.current = interactions[sponsoredId];

    // starts monitoring
    runInteractionMonitor();
  }, [itemId, sponsoredId, trackSource, slotId, position, plaLocation, runInteractionMonitor]);

  /**
   * Remove the interaction data from localStorage.
   * The interaction to remove is identified by the current 'sponsoredId'.
   */
  const removeInteraction = useCallback(() => {
    if (!loggedDataRef.current) return; // there's no logged interaction

    const interactions = Utils.getLocalStorageObject(AD_INTERACTIONS_KEY) || {};
    delete interactions[sponsoredId];
    Utils.setLocalStorageObject(AD_INTERACTIONS_KEY, interactions);

    loggedDataRef.current = null;
  }, [sponsoredId]);

  /**
   * Installs interaction listeners.
   *
   * This hook logs an interaction for this item only once.
   */
  useEffect(() => {
    if (!targetNode) return;
    if (loggedDataRef.current) return;

    // --
    const handleRightClick = () => {
      logInteraction('contextmenu', 'right-click');
    };

    // --
    const handleAuxClick = () => {
      logInteraction('auxclick', 'middle-click');
    };

    targetNode.addEventListener('contextmenu', handleRightClick);
    targetNode.addEventListener('auxclick', handleAuxClick);

    return () => {
      targetNode.removeEventListener('contextmenu', handleRightClick);
      targetNode.removeEventListener('auxclick', handleAuxClick);
    };
  }, [targetNode, logInteraction]);

  /**
   * Handle edge cases: right_click + [new_tab] + click.
   * Prevents duplicate click events.
   */
  useEffect(() => {
    if (!targetNode) return;
    if (clickTriggeredRef.current) return;

    const handleClick = () => {
      if (!clickTriggeredRef.current) {
        // 1. Else: right_click + click
        //   1.1 Prevent this item right-click interaction from being triggered after now.
        clickTriggeredRef.current = true;
        clearInterval(monitorIntervalIdRef.current);
      }
    };

    targetNode.addEventListener('click', handleClick);
    return () => targetNode.removeEventListener('click', handleClick);

  }, [targetNode]);

  /**
   * Clean up logic.
   */
  useEffect(() => {

    const handle = () => {
      clearInterval(monitorIntervalIdRef.current);
      // triggers a click event if the interaction was seen
      // but it was not triggered yet.
      triggerClickIfSeen();
      removeInteraction();
    };

    window.addEventListener('beforeunload', handle);
    return () => window.removeEventListener('beforeunload', handle);

  }, [triggerClickIfSeen, removeInteraction]);
};

/**
 * Hook to detect and mark as "seen" a previously logged "open in new tab" interaction.
 *
 * The logic on this hook is designed to run only once.
 *
 * This hook covers the 2nd part of the "open in new tab" event detection and triggering
 * process:
 *
 *   1. [src_tab]: clicks "open in new tab" and logs the interaction in localStorage
 *   2. [new_tab]: detects the logged interaction and tags it as "seen".
 *   3. [src_tab]: detects the interaction was tagged as "seen" and triggers the click.
 */
export const useClickInteractionProcessor = (itemId) => {

  useEffect(() => {
    // --
    const processInteractions = async () => {
      // All PIP pages have an associated itemId. If there is no itemId, we are not on a PIP page,
      // and therefore there is no right-click interaction of the type we are looking for (coming
      // from a sponsored product) associated with the current page.
      if (!itemId) return;

      // get interactions from localStorage
      const interactions = Utils.getLocalStorageObject(AD_INTERACTIONS_KEY) || {};

      // find the first right-click interaction that matches the conditions.
      const data = Object.values(interactions).find(
        (i) => i?.itemId === itemId && !i?.seenInNewTab
      );
      const sponsoredId = data?.sponsoredId;

      // consistency check
      if (!data || !sponsoredId || data !== interactions[sponsoredId]) return;

      // tag the interaction as 'seenInNewTab'
      data.seenInNewTab = true;

      // update localStorage
      interactions[sponsoredId] = data;
      Utils.setLocalStorageObject(AD_INTERACTIONS_KEY, interactions);
    };

    // delayed processing
    setTimeout(processInteractions, PROCESS_INTERACTIONS_DELAY_MS);
  }, []);
};

/**
 * HOC. Returns the given input component equipped with the
 * useClickInteractionProcessor hook.
 */
export const withClickInteractionProcessor = (Component) => {

  const WrapperComponent = ({ children, ...props }) => {
    useClickInteractionProcessor(props?.pageContext?.data?.itemId);

    return <Component {...props}>{children}</Component>;
  };

  WrapperComponent.propTypes = Component.propTypes;
  WrapperComponent.defaultProps = Component.defaultProps;
  WrapperComponent.dataModel = Component.dataModel;

  return WrapperComponent;
};
