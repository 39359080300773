import React from 'react';
import { number as numberType } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { orderTotalFormatter } from '../../helpers';

export function RecentPurchasesTableTotal({ orderTotal = null }) {
  const orderTotalFormatted = orderTotalFormatter(orderTotal);
  const totalAmount = orderTotalFormatted || '';

  return (
    <div
      data-testid="totalAmount"
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden sui-h-20"
    >
      <Typography variant="body-base">{totalAmount}</Typography>
    </div>
  );
}

export const RecentPurchasesCardTotal = ({ orderTotal = null }) => {
  const orderTotalFormatted = orderTotalFormatter(orderTotal);

  const totalAmount = orderTotalFormatted || '';
  return (
    <div
      data-testid="totalAmount"
      className="sui-flex sui-items-center sui-break-words sui-overflow-hidden"
    >
      <span className="sui-mr-2">
        <Typography variant="body-base" weight="bold">
          Total
        </Typography>
      </span>
      <span>
        <Typography variant="body-base">{totalAmount}</Typography>
      </span>
    </div>
  );
};

RecentPurchasesTableTotal.propTypes = {
  orderTotal: numberType,
};

RecentPurchasesCardTotal.propTypes = {
  orderTotal: numberType,
};
