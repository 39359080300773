import React, { useState } from 'react';
import {
  string, arrayOf, shape, func, bool
} from 'prop-types';
import { Tab, Tabs } from '@one-thd/sui-atomic-components';
import { TOP_PICKS, ALL_ITEMS } from '../utils/helpers';

const VisualTabs = (props) => {
  const {
    categories, products, filterItemIds, isIRG, setVisualTabsCategoriesData
  } = props;
  const [value, setValue] = useState(0);
  const onChange = (event, index) => {
    setValue(index);
  };

  let productItemIds;
  if (products?.length > 0) {
    productItemIds = products?.map((product) => product?.product?.itemId);
  }

  let categoryProducts;
  const setCategoriesIds = (itemIds, subCategory, index) => {
    categoryProducts = products?.filter((product) => itemIds.includes(product?.product?.itemId));
    filterItemIds(categoryProducts);
    setVisualTabsCategoriesData(subCategory, index);
  };

  let tabCategories = [];
  let allItemsCategory;

  allItemsCategory = {
    subCategory: isIRG ? ALL_ITEMS : TOP_PICKS,
    itemIds: productItemIds
  };

  tabCategories.push(allItemsCategory);

  categories.forEach((category) => {
    tabCategories.push({
      subCategory: `${category.category}`,
      itemIds: category.itemIds
    });
  });

  return (
    <Tabs value={value} onChange={onChange} aria-label="visualTabs" variant="scrollable">
      {tabCategories?.map((category, idx) => {
        return (
          <Tab
            label={category.subCategory}
            key={idx}
            onClick={() => { setCategoriesIds(category.itemIds, category.subCategory, idx); }}
          />
        );
      })}
    </Tabs>
  );
};

VisualTabs.propTypes = {
  categories: arrayOf(shape({
    category: string,
    itemIds: arrayOf
  })),
  products: arrayOf(shape({
    product: shape({
      itemId: string
    })
  })),
  filterItemIds: func,
  isIRG: bool,
  setVisualTabsCategoriesData: func
};

VisualTabs.defaultProps = {
  categories: {
    category: null,
    itemIds: null,
    products: null
  },
  products: {
    product: {
      itemId: null
    }
  },
  filterItemIds: null,
  isIRG: null,
  setVisualTabsCategoriesData: null
};

export default VisualTabs;
